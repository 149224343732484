import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Connector from '../../data/Connector';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Message from '../../components/Message';
import Moment from 'moment';
import Loader from '../../components/Form/loader';
import EventEmitter from '../../helper/Emitter';



class InvitePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'form',
      email: '',
      password: '',
      error: [false, false, false],
      checked: false,
      name: '',
      phone: '',
      birth: '',
      code: '',
      error2: [false, false, false],
      file: null,
      fileDisplay: null,
      loading: false,
      trainer: '',
      trainerName: '',
      label: "You've been invited by another trainer to join PT Mate and become their assistant trainer."
    };
  }


  componentDidMount() {
    document.title = 'Business Portal - PT Mate'
    EventEmitter.subscribe('trainersLoaded', (event) => this.getTrainerName())
    var arr = this.props.location.pathname.split('/')
    Connector.loadTrainers()
    this.setState({
      trainer: arr[arr.length-1]
    })
  }


  getTrainerName() {
    var name = 'another trainer'
    for(var item of global.spaces) {
      if(item.id === this.state.trainer) {
        name = item.data.name
      }
    }
    this.setState({
      label: "You've been invited by "+name+" to join PT Mate and become their assistant trainer.",
      trainerName: name
    })
  }


  onChange2 = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  toggleChange = () => {
    this.setState({
      checked: !this.state.checked,
    });
  }


  createUser() {
    var tmp = [false, false, false]
    this.setState({error: [false, false, false]})
    if(this.state.email !== '' && this.state.password !== '' && this.state.checked) {
      Firebase.auth().createUserWithEmailAndPassword(
        this.state.email,
        this.state.password
      ).then(authUser => {
        this.createEntry()
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      if(this.state.email === '') {
        tmp[0] = true
      }
      if(this.state.password === '') {
        tmp[1] = true
      }
      if(!this.state.checked) {
        tmp[2] = true
      }
      this.setState({error: tmp})
    }
  }


  createEntry() {
    global.uid = Firebase.auth().currentUser.uid;
    global.uidUser = Firebase.auth().currentUser.uid;
    Firebase.database().ref('/spaces/'+global.uidUser).set({
      uid: global.uid,
      owner: '',
      email: this.state.email,
      phone: '',
      subPlanId: '',
      card: '',
      stripe: '',
      image: '',
      imageDate: Moment().format('DD/MM/YYYY HH:mm'),
      community: true,
      communityPost: true,
      comments: true,
      showBooked: true,
      theme: 'blue',
      country: 'au',
    })
    Firebase.database().ref('/users/'+global.uidUser).set({
      uid: global.uid,
      name: '',
      email: this.state.email,
      phone: '',
      timer: 5,
      lbs: false,
      inches: false,
      birth: '01/01/1900',
      country: 'au',
      subPlanId: '',
      message: 0,
      image: '',
      imageDate: Moment().format('DD/MM/YYYY HH:mm'),
      pushToken: '',
    }).then(() => {
      Firebase.database().ref('/users/'+global.uidUser+'/assistant/'+this.state.trainer).update({
        uid: this.state.trainer,
        name: this.state.trainerName,
        permissions: 'clientview, programview, sessionrun',
      })
      Firebase.database().ref('/users/'+this.state.trainer+'/trainer/'+global.uidUser).update({
        uid: global.uidUser,
        name: 'Assistant Trainer',
        permissions: 'clientview, programview, sessionrun',
      })
      Firebase.database().ref('/spaces/'+this.state.trainer+'/staff/'+global.uidUser).update({
        uid: global.uidUser,
        name: 'Assistant Trainer',
        type: 'trainer',
      })
      Firebase.database().ref('/activity/'+this.state.trainer).update({
        type: 'traineraccepted',
        data: Firebase.auth().currentUser.uid,
        date: Moment().format('DD/MM/YYYY HH:mm')
      })
      this.setState({type: 'form2'})
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    }).catch((error)=>{})
  }



  // Page 2 stuff ------------------------------------------------------------



  checkUpdate() {
    var tmp = [false, false, false]
    this.setState({error2: [false, false, false]})
    if(this.state.name !== '' && this.state.phone !== '' && this.state.birth !== '') {
      this.updateUser()
    } else {
      if(this.state.name === '') {
        tmp[0] = true
      }
      if(this.state.phone === '') {
        tmp[1] = true
      }
      if(this.state.birth === '') {
        tmp[2] = true
      }
      this.setState({error2: tmp})
    }
  }


  updateUser() {
    this.setState({loading: true})
    var user = Firebase.auth().currentUser
    user.updateProfile({
      displayName: this.state.name,
    })

    var code = ''
    var codes = ['N5KDqvyI']
    for(var item of codes) {
      if(this.state.code === item) {
        code = item
      }
    }
    Firebase.database().ref('/users/'+this.state.trainer+'/trainer/'+global.uidUser).update({
      name: this.state.name,
    })
    Firebase.database().ref('/spaces/'+this.state.trainer+'/staff/'+global.uidUser).update({
      name: this.state.name,
    })
    Firebase.database().ref('/spaces/'+Firebase.auth().currentUser.uid).update({
      owner: this.state.name,
      phone: this.state.phone,
      name: this.state.name,
    })
    Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid).update({
      name: this.state.name,
      phone: this.state.phone,
      birth: this.state.birth,
      user: this.state.trainer,
    }).then(() => {
      if(this.state.file !== null) {
        this.uploadImage()
      } else {
        global.uid = ''
        global.uidUser = ''
        var createStripe = Firebase.functions().httpsCallable('createStripeCustomerWeb');
        createStripe({email: this.state.email, name: this.state.name, code: code, user: Firebase.auth().currentUser.uid, currency: global.userCurrency}).then(function(result) {
          window.location.pathname = '/overview'
        })
      }
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      this.setState({loading: false})
    })
  }


  uploadImage() {
    /*var code = ''
    var codes = ['N5KDqvyI']
    for(var item of codes) {
      if(this.state.code === item) {
        code = item
      }
    }*/

    var user = Firebase.auth().currentUser
    const sr = Firebase.storage().ref()
    const fr = sr.child('images/users/'+Firebase.auth().currentUser.uid+'.jpg')
    user.updateProfile({
      photoURL: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg'
    })
    fr.put(this.state.file).then(() => {
      Firebase.database().ref('/spaces/'+Firebase.auth().currentUser.uid).update({
        image: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg',
        imageDate: Moment().format('DD/MM/YYYY HH:mm')
      })
      Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid).update({
        image: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg',
        imageDate: Moment().format('DD/MM/YYYY HH:mm')
      }).then(() => {
        global.uid = ''
        global.uidUser = ''
      })
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      this.setState({loading: false})
    })
  }


  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  onChangeBirth = event => { 
    var valid = Moment(event.target.value, 'YYYY-MM-DD').isValid()
    if(valid) {
      this.setState({birth: Moment(event.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY')});
    }
  };


  setImage(file) {
    this.setState({
      file: file,
      fileDisplay:  URL.createObjectURL(file)
    })
  }


  displayImage() {
    if(this.state.file === null) {
      return (
        <div className="avatar"><img src="/img/data-client.svg" alt="Select your avatar"/></div>
      )
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.fileDisplay+')'}}></div>
      )
    }
  }



  // Display stuff ------------------------------------------------------------



  renderError() {
    var label = ''
    if(this.state.error[0]) {
      label += ' Email address'
    }
    if(this.state.error[1]) {
      if(label !== '') {
        label += ','
      }
      label += ' Password'
    }
    if(this.state.error[2]) {
      if(label !== '') {
        label += ','
      }
      label += ' Accept T&Cs and Privacy Policy'
    }
    if(this.state.error[0] || this.state.error[1] || this.state.error[2]) {
      return (
        <p className="error">Please review: {label}</p>
      )
    }
  }


  renderError2() {
    var label = ''
    if(this.state.error2[0]) {
      label += ' Full name'
    }
    if(this.state.error2[1]) {
      if(label !== '') {
        label += ','
      }
      label += ' Phone'
    }
    if(this.state.error2[2]) {
      if(label !== '') {
        label += ','
      }
      label += ' Date of birth'
    }
    if(this.state.error2[0] || this.state.error2[1] || this.state.error2[2]) {
      return (
        <p className="error">Please review: {label}</p>
      )
    }
  }


  renderForm2() {
    if(this.state.type === 'form2') {
      return (
        <div className="box">
          {this.displayImage()}
          <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0]);}}/>
          <div className="space-10"></div>
          <p>Almost there. Just enter a few more details about you so we can set you up.</p>
          <label>Full name*</label>
          <input type="text" placeholder="Your full name" name="name" value={this.state.name} onChange={this.onChange2}/>
          <label>Phone*</label>
          <input type="number" placeholder="Your mobile phone number" name="phone" value={this.state.phone} onChange={this.onChange2}/>
          <label>Date of birth*</label>
          <input type="date" placeholder="Your date of birth" value={Moment(this.state.birth, 'DD/MM/YYYY').format('YYYY-MM-DD')} onChange={this.onChangeBirth}/>
          <label>Promo code (if you have one)</label>
          <input type="text" placeholder="Enter your promo code" name="code" value={this.state.code} onChange={this.onChange2}/>
          {this.renderError2()}
          <div className="space-10"></div>   
          <button className="btn primary" onClick={() => this.checkUpdate()}>Let's go</button>
        </div>
      )
    }
  }


  renderForm() {
    if(this.state.type === 'form') {
      return (
        <div className="box">
          <img src="/img/logo.svg" alt="PT Mate Business Portal"/>
          <h1>Business Portal</h1>
          <p><strong>{this.state.label}</strong></p>
          <p>Sign up and get 14 days free access to Pro. If you don't love it you can cancel anytime. No credit card required.</p>
          <label>Email</label>
          <input type="text" placeholder="Your email address" name="email" value={this.state.email} onChange={this.onChange}/>
          <label>Password</label>
          <input type="password" placeholder="Choose a password" name="password" value={this.state.password} onChange={this.onChange}/>
          <div className="terms">
             <input type="checkbox" value={this.state.checked} onChange={this.toggleChange}/>
             <p>I have read and accept the <a href="http://www.ptmate.net/terms-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> and <a href="http://www.ptmate.net/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
          </div>
          {this.renderError()}
          <button className="btn primary" onClick={() => this.createUser()}>Sign up for free</button>
        </div>
      )
    }
  }


  render() {
    return (
      <div className="login">
        <div className="content">
          {this.renderForm()}
          {this.renderForm2()}
        </div>
        <Loader show={this.state.loading} label={'Setting you up'}/>
        <Message/>
      </div>
    )
  }
}

const InviteForm = compose(
  withRouter,
)(InvitePage);

export default InviteForm;
