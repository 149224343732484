import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import { withRouter } from 'react-router-dom';
import InputText from '../../components/Form/input-text';
import EventEmitter from '../../helper/Emitter';



class UnsubscribePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      email: '',
      list: [],
      sent: false,
    };
  }


  componentDidMount() {
    document.title = 'Unsubscribe - '+this.state.name
    window.scrollTo(0, 0)
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-1]
    if(id === '') {
      id = arr[arr.length-2]
    }
    this.setState({
      id: id
    }, () => {
      this.loadSpace()
    });
    EventEmitter.subscribe('publicListLoaded', (event) => this.configureData())
  }


  loadSpace() {
    let itemsRef = Firebase.database().ref('/public/'+this.state.id);
    itemsRef.on("value", function(snapshot) {
      if (snapshot.exists()) {
        var data = snapshot.val();
        global.publicData.push({
          list: data.blacklist
        });
      }
      EventEmitter.dispatch('publicListLoaded', 'loaded');
    });
  }


  configureData() {
    var list = []
    if(global.publicData[0].list !== undefined) {
      list = global.publicData[0].list
    }
    this.setState({
      list: list
    })
  }


  updateList() {
    if(this.state.email !== '' && this.state.list.indexOf(this.state.email) === -1) {
      this.setState({
        sent: true
      })
      var tmp = this.state.list
      tmp.push(this.state.email)
      Firebase.database().ref('/public/'+this.state.id).update({
        blacklist: tmp
      })
    }
  }



  // Display stuff ------------------------------------------------------------



  render() {
    if(this.state.sent) {
      return (
        <div>
          <div className="content">
            <p style={{fontSize: '20px',textAlign:'center'}}>You are now unsubscribed from our mailing list.</p>
            <p style={{fontSize: '14px',textAlign:'center'}}>You can close this window now.</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="content">
          <h3>Unsubscribe from our mailing list</h3>
          <div className="clear space-40"></div>
          <div className="col-9">
            <InputText label='Enter your email address' value={this.state.email} onChange={(event) => this.setState({email: event})}/>
          </div>
          <div className="col-3" style={{paddingTop: 18}}>
            <button className="btn primary" onClick={() => this.updateList()}>Submit</button>
          </div> 
        </div>
      )
    }
    
  }
}



export default withRouter(UnsubscribePage);

