import React, { Component } from 'react';



const DataListHeader  = [
  {
    id: 'activeclients',
    title: 'clients',
    single: '1 client',
    empty: 'No clients',
  },
  {
    id: 'inactiveclients',
    title: 'Inactive clients',
    single: '1 Inctive client',
    empty: 'No inactive clients',
  },
  {
    id: 'clientgroups',
    title: 'Client groups',
    single: '1 Client group',
    empty: 'No client groups',
  },
  {
    id: 'rectemplates',
    title: 'Recurring templates',
    single: '1 recurring template',
    empty: 'No recurring templates',
  },
  {
    id: 'recexptemplates',
    title: 'Expired recurring templates',
    single: '1 Expired recurring template',
    empty: 'No expired recurring templates',
  },
  {
    id: 'programs',
    title: 'Programs',
    single: '1 Program',
    empty: 'No programs',
  },
  {
    id: 'benchmark',
    title: 'Benchmark programs',
    single: '1 Benchmark program',
    empty: 'No benchmark programs',
  },
  {
    id: 'plans',
    title: 'Training plans',
    single: '1 Training plan',
    empty: 'No training plans',
  },
  {
    id: 'nutrition',
    title: 'Active nutrition plans',
    single: '1 Active nutrition plan',
    empty: 'No active nutrition plans',
  },
  {
    id: 'nutritioninactive',
    title: 'Inactive nutrition plans',
    single: '1 Inactive nutrition plan',
    empty: 'No inactive nutrition plans',
  },
  {
    id: 'habits',
    title: 'Habits',
    single: '1 Habit',
    empty: 'No habits',
  },
  {
    id: 'payments',
    title: 'Payments',
    single: '1 Payment',
    empty: 'No payments',
  },
  {
    id: 'debits',
    title: 'Active memberships',
    single: '1 Active membership',
    empty: 'No active memberships',
  },
  {
    id: 'products',
    title: 'Products',
    single: '1 Product',
    empty: 'No products',
  },
  {
    id: 'invoices',
    title: 'Invoices',
    single: '1 Invoice',
    empty: 'No invoices',
  },
  {
    id: 'expenses',
    title: 'Expenses',
    single: '1 Expense',
    empty: 'No expenses',
  },
  {
    id: 'exppacks',
    title: 'Packs of sessions with expiry dates',
    single: '1 Pack of sessions with expiry dates',
    empty: 'No packs of sessions with expiry dates',
  },
  {
    id: 'unpaidinvoices',
    title: 'Unpaid invoices',
    single: '1 Unpaid invoice',
    empty: 'No unpaid invoices',
  },
  {
    id: 'planssent',
    title: 'Training plans sent',
    single: '1 Training plan sent',
    empty: 'No training plans sent',
  },
  {
    id: 'programssent',
    title: 'Programs sent',
    single: '1 Program sent',
    empty: 'No programs sent',
  },
  {
    id: 'staff',
    title: 'Staff members',
    single: '1 Staff member',
    empty: 'No staff members',
  },
  {
    id: 'leads',
    title: 'Leads',
    single: '1 Lead',
    empty: 'No leads',
  },
  {
    id: 'emails',
    title: 'Emails',
    single: '1 email',
    empty: 'No emails',
  },
  {
    id: 'forms',
    title: 'Forms',
    single: '1 form',
    empty: 'No forms',
  },
  {
    id: 'documents',
    title: 'Uploaded documents',
    single: '1 Uploaded document',
    empty: 'No uploaded documents',
  },
  {
    id: 'locations',
    title: 'Locations',
    single: '1 Location',
    empty: 'No locations',
  },
]



class ListHeader extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      number: props.number ?? 0
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      id: props.id,
      number: props.number ?? 0
    }
  }


  render() {
    var data = null
    for(var item of DataListHeader) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(data !== null) {
      if(this.state.number === 0) {
        return <h3>{data.empty}</h3>
      } else if(this.state.number === 1) {
        return <h3>{data.single}</h3>
      } else {
        return <h3>{this.state.number} {data.title}</h3>
      }
    }
  }
}


export default ListHeader;