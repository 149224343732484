import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import HelperBusiness from '../../helper/Business';



class HeaderClients extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      type: props.type ?? 'header',
      active: props.active ?? '',
    };
  }


  renderGroups() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.CLIENTSGROUPS} className={this.state.active === 'groups' ? 'tab active' : 'tab'}>
            <p>Groups</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.CLIENTSGROUPS} className="item">
            <div className="bg"></div>
            <div className="text">Groups</div>
          </Link>
        )
      }
    }
  }


  renderReport() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.CLIENTSREPORT} className={this.state.active === 'reports' ? 'tab active' : 'tab'}>
            <p>Reports</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.CLIENTSREPORT} className="item">
            <div className="bg"></div>
            <div className="text">Reports</div>
          </Link>
        )
      }
    }
  }


  render() {
    if(this.state.type === 'header') {
      return (
        <div>
          <Link to={ROUTES.CLIENTS} className={this.state.active === 'list' ? 'tab active' : 'tab'}>
            <p>Client List</p>
            <div className="bg"></div>
          </Link>
          {this.renderGroups()}
          {this.renderReport()}
        </div>
      )
    } else {
      return (
        <div>
          <Link to={ROUTES.CLIENTS} className="item">
            <div className="bg"></div>
            <div className="text">Client list</div>
          </Link>
          {this.renderGroups()}
          {this.renderReport()}
        </div>
      )
    }
  }
}


export default HeaderClients;