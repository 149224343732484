import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import { withRouter } from 'react-router-dom';
import Message from '../../components/Message';
import Cal from '../../helper/Calendar';
import EventEmitter from '../../helper/Emitter';



class MemberTrialPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      status: 'disabled',
      name: 'PT Mate',
      desc: '',
      color: 'blue',
      avatar: '',
      image: '',
      imageURL: '',
      video: '',
      sessions: [],
      currentDay: Moment().format('D'),
      days: [],
      empty: [],
      months: ['', ''],
      selected: Moment().format('DD/MM/YYYY'),
      today: [],
      num: 99,
      done: false,
      week: 'this',
      // Field inputs
      showOverlay: false,
      hidden: 'hidden',
      margin: 0,
      value1: '',
      value2: '',
      value3: '',
      value4: '',
      error: [false, false],
      sessionSelected: '',
      mtitle: '',
      mtext: '',
      showOverlayConfirm: false,
      hiddenConfirm: 'hidden',
      emptyLabel: 'Loading...'
    };
  }


  componentDidMount() {
    document.title = 'Book session - '+this.state.name
    window.scrollTo(0, 0)

    Moment.updateLocale("en", { week: {
      dow: 1, // First day of week is Monday
    }});

    var arr = this.props.location.pathname.split('/')
    this.setState({
      id: arr[arr.length-1]
    }, () => {
      this.loadSpace()
      this.loadSessions()
    });
    EventEmitter.subscribe('publicLoaded', (event) => this.configureData())
    EventEmitter.subscribe('publicSessionsLoaded', (event) => this.configureData())

    setTimeout(() => {
      this.setState({emptyLabel: "This page doesn't exist or has been deactivated."});
    }, 5500);

    this.setState({
      done: true
    })

  }


  loadSpace() {
    let itemsRef = Firebase.database().ref('/public/'+this.state.id);
    itemsRef.on("value", function(snapshot) {
      if (snapshot.exists()) {
        var data = snapshot.val();
        global.publicData.push({
          status: data.status,
          name: data.name,
          desc: data.desc,
          color: data.color,
          logo: data.logo,
          video: data.video
        });
      }
      EventEmitter.dispatch('publicLoaded', 'loaded');
    });
  }


  loadSessions() {
    let itemsRef = Firebase.database().ref('/sessions/'+this.state.id);
    itemsRef.on('value', (snap) => {
      global.sessions = [];
      snap.forEach((child) => {
        var group = child.val().group
        var date = child.val().timestamp
        var add = false
        if( child.val().invitees === undefined &&  child.val().groups === undefined) {
          add = true
        }
        if(group && Moment(date, 'X') > Moment() && add) {
          global.sessions.push({
            id: child.key,
            data: child.val(),
          });
        }
        
      });
      EventEmitter.dispatch('publicSessionsLoaded', 'loaded');
    });
  }


  configureData() {
    if(global.publicData.length > 0) {
      var image = ''
      if(global.publicData[0].logo !== undefined) {
        image = global.publicData[0].logo
      }
      this.setState({
        status: global.publicData[0].status,
        name: global.publicData[0].name,
        desc: global.publicData[0].desc,
        color: global.publicData[0].color,
        image: image,
        video: global.publicData[0].video,
        sessions: global.sessions,
        imageURL: '/img/public-'+global.publicData[0].color+'.svg'
      }, () => {
        document.title = 'Book session - '+this.state.name
        //this.setSelected(this.state.currentDay)
        this.getLogo()
        this.selectWeek(this.state.week)
      })
    }
  }


  selectWeek(value) {
    var date = Moment().startOf('week').format('DD/MM/YYYY')
    if(value === 'next') {
      date = Moment().add(7, 'days').startOf('week').format('DD/MM/YYYY')
    }
    this.setState({
      week: value,
      selected: date,
    })
  }


  getBg() {
    var label = 'image'
    if(this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }


  getClientNameAvail(session) {
    var label = 'Booked'
    return label
  }


  getLogo() {
    if(this.state.image !== '') {
      Firebase.storage().ref().child(global.publicData[0].logo).getDownloadURL().then((url) => {
        this.setState({
          imageURL: url
        })
      }).catch((error) => {
        this.setState({
          imageURL: ''
        })
      })
    } else {
      this.setState({
        imageURL: ''
      })
    }
  }


  getSessionVirtual(item) {
    if(item.data.link !== undefined && item.data.link !== '') {
      return (
        <span>
          <img src='/img/cam.svg' alt='Virtual' className='show-light'/>
          <img src='/img/cam-dark.svg' alt='Virtual' className='show-dark'/>
        </span>
      )
    }
  }


  getSessionsDay(day, starttime, endtime) {
    if(!this.state.loading) {
    var week = Moment(this.state.selected, 'DD/MM/YYYY').startOf('week').format('DD/MM/YYYY')
    var array = []
    var date = Moment(week, 'DD/MM/YYYY').add(day, 'days').format('DD/MM/YYYY')
    //var time = '12:00'
    var start = Moment(date+' '+starttime, 'DD/MM/YYYY HH:mm')
    var end = Moment(date+' '+endtime, 'DD/MM/YYYY HH:mm')
    for(var item of this.state.sessions) {
      if(Moment(item.data.timestamp, 'X') >= start && Moment(item.data.timestamp, 'X') < end && item.data.availability === undefined) {
        array.push(item)
      }
    }
    array.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));

    var array2 = []
    for(var item2 of this.state.sessions) {
      if(Moment(item2.data.timestamp, 'X') >= start && Moment(item2.data.timestamp, 'X') < end && item2.data.availability) {
        if(item2.data.clients === undefined) {
          array2.push(item2)
        } else {
          if(item2.data.clients.length === 0) {
            array2.push(item2)
          }
        }
      }
    }
    array2.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));

    return (
      <div>
        {array.map(item => (
          <div className="cal-session" key={item.id} onClick={() => this.showOverlay(item.id)}>
            <div className="icon clear primary">
              <div className="inner group"></div>
            </div>
            <p className="lft"><strong>{this.getSessionName(item)}{this.getSessionVirtual(item)}</strong><br/>{Moment(item.data.timestamp, 'X').format('LT')} - {Cal.getDuration(item.data.duration)}</p>
            <div className="clear"></div>
          </div>
        ))}
        {array2.map(item => (
          <div className="cal-session" key={item.id} onClick={() => this.showOverlay(item.id)}>
            <div className="icon clear secondary">
              <div className="inner session"></div>
            </div>
            <p className="lft"><strong>Available 1:1 Session{this.getSessionVirtual(item)}</strong><br/>{Moment(item.data.timestamp, 'X').format('LT')} - {Cal.getDuration(item.data.duration)}</p>
            <div className="clear"></div>
          </div>
        ))}
      </div>
    )}
  }


  getSessionName(item) {
    var label = 'Group Session'
    if(item.data.group && item.data.client !== '') {
      label = item.data.client
    }
    if(!item.data.group) {
      label = '1:1 Session'
    }
    if(item.data.link !== '') {
      label += ' (Virtual)'
    }
    return label
  }



  // Booking overlay ------------------------------------------------------------



  showOverlay(item) {
    var title = 'Request session'
    var text = 'Please enter your details below to request '+this.getSessionDetails(item)+'.'
    if(item === '') {
      title = 'Request contact'
      text = 'Please enter your details below to allow us to get in touch.'
    }
    this.setState({showOverlay: true})
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2,
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        sessionSelected: item,
        mtitle: title,
        mtext: text,
        error: [false, false]
      });
    }, 100);
  }


  hideOverlay() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlay: false});
    }, 500);
  }


  getSessionDetails(session) {
    var label = 'Group Session '
    for(var item of this.state.sessions) {
      if(item.id === session) {
        if(item.data.client !== '') {
          label = item.data.client+' '+Moment(item.data.timestamp, 'X').format('LLL')
        } else {
          label = 'Group Session '+Moment(item.data.timestamp, 'X').format('LLL')
        }
        if(item.data.availability) {
          label = 'Available 1:1 Session '+Moment(item.data.timestamp, 'X').format('LLL')
        }
      }
    }
    return label
  }


  renderOverlay() {
    if(this.state.showOverlay) {
      return (
        <div className={'overlay '+this.state.color+' '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{this.state.mtitle}</h2>
            <button className={'btn tertiary small close rgt '+this.state.color} onClick={() => this.hideOverlay()}>Close</button>
            <p className="clear mb-30">{this.state.mtext}</p>
            <label className={this.state.error[0] ? 'error' : ''}>Your name*</label>
            <input type="text" value={this.state.value1} name="value1" onChange={this.onChange} className={this.state.error[0] ? 'error' : ''}/>
            <div className="sv-20"></div>
            <label className={this.state.error[1] ? 'error' : ''}>Your phone number*</label>
            <input type="number" value={this.state.value2} name="value2" onChange={this.onChange} className={this.state.error[1] ? 'error' : ''}/>
            <div className="sv-20"></div>
            <label>Your email address (optional)</label>
            <input type="email" value={this.state.value3} name="value3" onChange={this.onChange}/>
            <div className="sv-20"></div>
            <label>Additional comments (optional)</label>
            <textarea value={this.state.value4} name="value4" onChange={this.onChange}></textarea>
            <div className="clear mb-30"></div>
            <button className={'btn primary '+this.state.color} onClick={() => this.sendRequest()}>Send request</button>
          </div>
        </div>
      )
    }
  }


  onChange = event => {
    this.setState({[event.target.name]: event.target.value });
  };


  sendRequest() {
    if(this.state.value1 !== '' && this.state.value2 !== '') {
      this.hideOverlay()
      Firebase.database().ref('/public/'+this.state.id+'/requests').push({
        name: this.state.value1,
        phone: this.state.value2,
        email: this.state.value3,
        notes: this.state.value4,
        session: this.state.sessionSelected,
        sent: Moment().format('DD/MM/YYYY HH:mm'),
        status: 'new'
      }).then(() => {
        EventEmitter.dispatch('showMessage', 'Request successfully sent')
        setTimeout(() => {
          this.showOverlayConfirmation()
        }, 500);
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      var error = [false, false]
      if(this.state.value1 === '') {
        error[0] = true
      }
      if(this.state.value2 === '') {
        error[1] = true
      }
      this.setState({
        error: error
      })
    }
  }


  showOverlayConfirmation(item) {
    this.setState({showOverlayConfirm: true})
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenConfirm: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayConfirmation() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenConfirm: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayConfirm: false});
    }, 500);
  }


  renderOverlayConfirm() {
    if(this.state.showOverlayConfirm) {
      return (
        <div className={'overlay '+this.state.color+' '+this.state.hiddenConfirm}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Request sent. What's next?</h2>
            <button className={'btn tertiary small close rgt '+this.state.color} onClick={() => this.hideOverlayConfirmation()}>Close</button>
            <p className="clear mb-30">Your request has been sent to {this.state.name}. Your trainer will be in touch to confirm the details and explain the next steps.</p>
            <button className={'btn primary '+this.state.color} onClick={() => this.hideOverlayConfirmation()}>Got it</button>
          </div>
        </div>
      )
    }
  }



  // Display stuff ------------------------------------------------------------



  getWeekDate(num) {
    var label = '1 Jan'
    var week = Moment(this.state.selected, 'DD/MM/YYYY').startOf('week')
    label = Moment(week).add(num, 'days').format('D MMM')
    if(Moment(week).add(num, 'days').format('D MM YYYY') === Moment().format('D MM YYYY')) {
      label = 'Today'
    }
    return label
  }


  getDayClass(type, day) {
    var label = ''
    if(type === 'week') {
      var week = Moment(this.state.selected, 'DD/MM/YYYY').startOf('week')
      if(Moment(week).add(day, 'days').format('D MM YYYY') === Moment().format('D MM YYYY')) {
        label = 'today'
      }
    } else if(type === 'month') {
      var second = Moment().startOf('month').add(this.state.current-1, 'months').format('MM/YYYY')
      var dy2 = Moment(day+'/'+second, 'D/MM/YYYY').format('D MM YYYY')
      if(dy2 === Moment().format('D MM YYYY')) {
        label = 'today'
      }
    }
    return label
  }


  renderContent() {
    return (
      <div>
        <div className="listheader">
            <h3>{Moment(this.state.selected, 'DD/MM/YYYY').format('D MMM')} - {Moment(this.state.selected, 'DD/MM/YYYY').add(6, 'days').format('D MMM')}</h3>
            <div className="clear"></div>
          </div>
          <div className="calendar">
            <div style={{minWidth: '800px'}}>
              <div className={'weekday lft cal-top '+this.getDayClass('week', 0)}><h3>Mon <span>{this.getWeekDate(0)}</span></h3></div>
              <div className={'weekday lft cal-top '+this.getDayClass('week', 1)}><h3>Tue <span>{this.getWeekDate(1)}</span></h3></div>
              <div className={'weekday lft cal-top '+this.getDayClass('week', 2)}><h3>Wed <span>{this.getWeekDate(2)}</span></h3></div>
              <div className={'weekday lft cal-top '+this.getDayClass('week', 3)}><h3>Thu <span>{this.getWeekDate(3)}</span></h3></div>
              <div className={'weekday lft cal-top '+this.getDayClass('week', 4)}><h3>Fri <span>{this.getWeekDate(4)}</span></h3></div>
              <div className={'weekday lft cal-top '+this.getDayClass('week', 5)}><h3>Sat <span>{this.getWeekDate(5)}</span></h3></div>
              <div className={'weekday lft cal-top '+this.getDayClass('week', 6)}><h3>Sun <span>{this.getWeekDate(6)}</span></h3></div>
              <div className="clear"></div>

              <div style={{display: 'flex'}}>
                <div className={'weekday cal-data '+this.getDayClass('week', 0)}>
                  <h4>Morning</h4>
                  {this.getSessionsDay(0, '00:00', '12:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 1)}>
                  <h4>Morning</h4>
                  {this.getSessionsDay(1, '00:00', '12:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 2)}>
                  <h4>Morning</h4>
                  {this.getSessionsDay(2, '00:00', '12:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 3)}>
                  <h4>Morning</h4>
                  {this.getSessionsDay(3, '00:00', '12:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 4)}>
                  <h4>Morning</h4>
                  {this.getSessionsDay(4, '00:00', '12:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 5)}>
                  <h4>Morning</h4>
                  {this.getSessionsDay(5, '00:00', '12:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 6)}>
                  <h4>Morning</h4>
                  {this.getSessionsDay(6, '00:00', '12:00')}
                </div>
              </div>
              <div className="clear"></div>

              <div style={{display: 'flex'}}>
                <div className={'weekday cal-data '+this.getDayClass('week', 0)}>
                  <h4>Midday</h4>
                  {this.getSessionsDay(0, '12:00', '14:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 1)}>
                  <h4>Midday</h4>
                  {this.getSessionsDay(1, '12:00', '14:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 2)}>
                  <h4>Midday</h4>
                  {this.getSessionsDay(2, '12:00', '14:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 3)}>
                  <h4>Midday</h4>
                  {this.getSessionsDay(3, '12:00', '14:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 4)}>
                  <h4>Midday</h4>
                  {this.getSessionsDay(4, '12:00', '14:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 5)}>
                  <h4>Midday</h4>
                  {this.getSessionsDay(5, '12:00', '14:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 6)}>
                  <h4>Midday</h4>
                  {this.getSessionsDay(6, '12:00', '14:00')}
                </div>
              </div>
              <div className="clear"></div>

              <div style={{display: 'flex'}}>
                <div className={'weekday cal-data '+this.getDayClass('week', 0)}>
                  <h4>Afternoon</h4>
                  {this.getSessionsDay(0, '14:00', '18:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 1)}>
                  <h4>Afternoon</h4>
                  {this.getSessionsDay(1, '14:00', '18:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 2)}>
                  <h4>Afternoon</h4>
                  {this.getSessionsDay(2, '14:00', '18:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 3)}>
                  <h4>Afternoon</h4>
                  {this.getSessionsDay(3, '14:00', '18:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 4)}>
                  <h4>Afternoon</h4>
                  {this.getSessionsDay(4, '14:00', '18:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 5)}>
                  <h4>Afternoon</h4>
                  {this.getSessionsDay(5, '14:00', '18:00')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 6)}>
                  <h4>Afternoon</h4>
                  {this.getSessionsDay(6, '14:00', '18:00')}
                </div>
              </div>
              <div className="clear"></div>

              <div style={{display: 'flex'}}>
                <div className={'weekday cal-data '+this.getDayClass('week', 0)}>
                  <h4>Evening</h4>
                  {this.getSessionsDay(0, '18:00', '23:59')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 1)}>
                  <h4>Evening</h4>
                  {this.getSessionsDay(1, '18:00', '23:59')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 2)}>
                  <h4>Evening</h4>
                  {this.getSessionsDay(2, '18:00', '23:59')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 3)}>
                  <h4>Evening</h4>
                  {this.getSessionsDay(3, '18:00', '23:59')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 4)}>
                  <h4>Evening</h4>
                  {this.getSessionsDay(4, '18:00', '23:59')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 5)}>
                  <h4>Evening</h4>
                  {this.getSessionsDay(5, '18:00', '23:59')}
                </div>
                <div className={'weekday cal-data '+this.getDayClass('week', 6)}>
                  <h4>Evening</h4>
                  {this.getSessionsDay(6, '18:00', '23:59')}
                </div>
              </div>
              <div className="clear"></div>

            </div>
          </div>
          <div className="clear sv-40"></div>
      </div>
    )
  }


  renderBase() {
    if(this.state.status === 'disabled' || this.state.status === undefined) {
      return (
        <div>
          <div className="content">
            <p className="empty">{this.state.emptyLabel}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+this.state.color}>
          <div className="content">
            <div className="member-header">
              <div className={this.getBg()}>
                <div className={'gradient theme-'+this.state.color}></div>
              </div>
              <div className="member-main">
                <div className="business">
                  <div className="avatar" style={{backgroundImage: 'url('+this.state.imageURL+')'}}> </div>
                  <h3>{this.state.name}<br/><span>Book session</span></h3>
                </div>
                <p>{this.state.desc === '' ? 'Request a session by selecting a group session from the calendar. Simply fill out the form and send your request through. '+this.state.name+' will be in touch with you. Alternatively, you can book a 1:1 session using the button on the right hand side.' : this.state.desc}</p>
                <div className="sv-30"></div>
                <div className={this.state.week === 'this' ? 'tab active' : 'tab'} onClick={() => this.selectWeek('this')}>
                  <p>This week</p>
                  <div className="bg"></div>
                </div>
                <div className={this.state.week === 'next' ? 'tab active' : 'tab'} onClick={() => this.selectWeek('next')}>
                  <p>Next week</p>
                  <div className="bg"></div>
                </div>
                <div className="tab" onClick={() => this.showOverlay('')}>
                  <p>Request contact</p>
                  <div className="bg"></div>
                </div>
              </div>
              <div className="clear"></div>
            </div>
            <div className="clear"></div>
            {this.renderContent()}
          </div>
          {this.renderOverlay()}
          {this.renderOverlayConfirm()}
          <Message/>
        </div>
      )
    }
  }


  render() {
    return (
      this.renderBase()
    )
  }
}



export default withRouter(MemberTrialPage);

