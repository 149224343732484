import React, { Component } from 'react';
import Connector from '../../data/Connector';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';

import { AuthUserContext, withAuthorization } from '../../components/Session';


class AccountBadgesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      badges: global.userBadges,
    };
  }


  componentDidMount() {
    document.title = 'Achievements - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)
    Connector.setUser()
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('updateBadges', (event) => this.configureData())
    this.configureData()
  }


  configureData() { 
    this.setState({
      badges: global.userBadges
    })
  }



  // Display stuff ------------------------------------------------------------



  renderBox1() {
    return (
      <div className="box achievements mt-10">
        <h3 className="mb-20">Spark your business</h3>
        <p className="clear mb-40">Let's get the ball rolling, add one client or import your entire clients list to help you get into the swing of setting up your Personal Training business.</p>
        <div className="col-2 achievement">
          <img src="/img/badges/account.svg" alt="badge"/>
          <h4 className="mb-20">Create your account</h4>
          <p>Sign up with PT Mate and create your account.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l1-client') !== -1 ? '/img/badges/create-client.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Your first client</h4>
          <p>Create a client manually using the form or invite them to the app.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l1-import') !== -1 ? '/img/badges/import-clients.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Your client list</h4>
          <p>Import your existing clients from a file, or invite 5 clients to join PT Mate.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l1-session') !== -1 ? '/img/badges/first-session.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Your first session</h4>
          <p>Create a client session and start using your calendar.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l1-program') !== -1 ? '/img/badges/create-program.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Your first program</h4>
          <p>Create a program you can assign to your sessions or send to your clients.</p>
        </div>
        <div className="col-2 achievement">
          <img src="/img/badges/portal.svg" alt="badge" className="mb-20"/>
          <h4 className="mb-20">Business Portal</h4>
          <p>Log in to the Business Web Portal for the first time.</p>
        </div>
        <div className="clear"></div>
      </div>
    )
  }


  renderBox2() {
    return (
      <div className="box achievements">
        <h3 className="mb-20">Start with the basics</h3>
        <p className="clear mb-40">What services do you offer? Create your business products and get paid at a tap of a button, we give trainers the power back for all things billing & finance.</p>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l2-billing') !== -1 ? '/img/badges/enable-billing.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Enable billing</h4>
          <p>Enable billing so you can charge your clients directly through PT Mate. Powered by Stripe.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l2-attendance') !== -1 ? '/img/badges/run-session.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Track attendance</h4>
          <p>Track your clients' attendance of a session, either by running it or by logging it afterwards.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l2-product') !== -1 ? '/img/badges/create-product.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Create a product</h4>
          <p>Create your first pack of sessions or membership you can sell to your clients.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l2-payment') !== -1 ? '/img/badges/payment.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Make a payment</h4>
          <p>Make a card or other payment, or set up a membership for one of your clients.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l2-report') !== -1 ? '/img/badges/create-report.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Create a report</h4>
          <p>Create a report you can download, either in your billing or calendar section.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l2-nutrition') !== -1 ? '/img/badges/enable-nutrition.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Set up Nutrition</h4>
          <p>Enable the Nutrition feature on the Business Portal.</p>
        </div>
        <div className="clear"></div>
      </div>
    )
  }


  renderBox3() {
    return (
      <div className="box achievements">
        <h3 className="mb-20">Unlimited potential</h3>
        <p className="clear mb-40">Your clients are lucky to have you, you probably don’t hear that enough! Simply continue to assist your clients and be rewarded for the effort you give your business to help your clients. </p>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l3-exercise') !== -1 ? '/img/badges/exercise.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Custom movement</h4>
          <p>Create a custom movement you can use in the Program Builder.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l3-plan') !== -1 ? '/img/badges/plan.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Training plan</h4>
          <p>Create your first training plan you can send to your clients.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l3-sessions') !== -1 ? '/img/badges/sessions-100.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">100 sessions</h4>
          <p>Set up 100 client sessions, either 1:1 or group (both in person and virtual).</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l3-programs') !== -1 ? '/img/badges/programs-10.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">10 programs</h4>
          <p>Create 10 programs with the Program Builder you can use in your client sessions.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l3-run') !== -1 ? '/img/badges/run-sessions-20.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Run 20 sessions</h4>
          <p>Run 20 client sessions through the app and log your clients' results.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l3-nutrition') !== -1 ? '/img/badges/nutrition-10.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">10 nutrition plans</h4>
          <p>Create 10 nutrition plans for your clients.</p>
        </div>
        
        <div className="clear"></div>
      </div>
    )
  }


  renderBox4() {
    return (
      <div className="box achievements">
        <h3 className="mb-20">Becoming a business PRO</h3>
        <p className="clear mb-40">Looks like you’re getting it all figured out! The more business you have the bigger your business grows, What’s next for your business? Continue to collect all of the badges.<br/>
This may take some time to collect all four badges. PT Mate will help keep you accountable and assist you as your business grows while you work towards achieving your business goals. </p>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l4-sessions') !== -1 ? '/img/badges/sessions-500.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">500 sessions</h4>
          <p>Set up 500 client sessions, either 1:1 or group (both in person and virtual).</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l4-payments') !== -1 ? '/img/badges/clients-charged-500.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">500 payments</h4>
          <p>Make 500 card or other payments, or set up 500 memberships for clients.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l4-programs') !== -1 ? '/img/badges/programs-60.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">60 programs</h4>
          <p>Create 60 programs with the Program Builder you can use in your client sessions.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l4-plans') !== -1 ? '/img/badges/plans-20.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">20 training plans</h4>
          <p>Create 20 training plans you can send to your clients.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l4-run') !== -1 ? '/img/badges/run-sessions-200.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Run 200 sessions</h4>
          <p>Run 200 client sessions through the app and log your clients' results.</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l4-public') !== -1 ? '/img/badges/public.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">Leads page</h4>
          <p>Enable your leads page to make it easy for potential clients to book trial sessions.</p>
        </div>
        <div className="clear"></div>
      </div>
    )
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          {this.renderBox1()}
          <div className="sv-30"></div>
          {this.renderBox2()}
          <div className="sv-30"></div>
          {this.renderBox3()}
          <div className="sv-30"></div>
          {this.renderBox4()}
          <div className="sv-30"></div>
        </div>
        <Header type='account' active='badges'/>
        <Navigation active='' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;

export default withRouter(withAuthorization(condition)(AccountBadgesPage));

