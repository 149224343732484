import React, { Component } from 'react';
import Moment from 'moment';
import Firebase from 'firebase';
import EventEmitter from '../../helper/Emitter';



class ModalPayment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      payment: props.payment,
      show: props.show,
      hidden: 'hidden',
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      payment: props.payment,
      show: props.show,
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  refundCash() {
    Firebase.database().ref('/payments/'+global.uidUser+'/'+this.state.payment.id).remove().then(() => {
      this.hideOverlayPayment()
      EventEmitter.dispatch('showMessage', 'Payment marked as refunded');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    Firebase.database().ref('/activity/'+global.uidUser).push({
      type: 'cashrefunded',
      data: this.state.payment.data.client+','+this.state.payment.data.amount,
      date: Moment().format('DD/MM/YYYY HH:mm')
    })
  }


  getClientName(id) {
    var name = 'Deleted client'
    for(var client of global.clients) {
      if(client.id === id) {
        name = client.data.name
      }
    }
    for(var inact of global.clientsInactive) {
      if(inact.id === id) {
        name = client.data.name
      }
    }
    return name
  }


  renderPaymentName(item) {
    var label = item.data.name
    if(item.data.subscription !== undefined && item.data.subscription !== '' && this.state.item !== null) {
      for(var sub of this.state.products) {
        if(sub.id === item.data.subscription) {
          label = sub.data.name
        }
      }
    }
    if(item.data.name.indexOf('Invoice ') !== -1 || item.data.name.indexOf('Subscription ') !== -1) {
      label = 'Membership'
    }
    return label
  }


  renderPaymentDate(item) {
    var label = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('D MMM YYYY')
    if(item.data.timestamp !== undefined) {
      label = Moment(item.data.timestamp, 'X').format('D MMM YYYY')
    }
    return label
  }


  renderNet() {
    if(this.state.payment.data.fee !== undefined) {
      if(this.state.payment.data.fee === 0) {
        return (
          <div className="lft">{global.curSym}{(this.state.payment.data.amount/100).toFixed(2)}</div>
        )
      } else {
        return (
          <div className="lft">{global.curSym}{((this.state.payment.data.amount/100)*0.9775-0.3).toFixed(2)}</div>
        )
      }
    } else {
      return (
        <div className="lft">{global.curSym}{((this.state.payment.data.amount/100)*0.9775-0.3).toFixed(2)}</div>
      )
    }
  }


  renderFees() {
    if(this.state.payment.data.fee !== undefined) {
      if(this.state.payment.data.fee === 0) {
        return (
          <div className="lft">-</div>
        )
      } else {
        return (
          <div className="lft">{global.curSym}{((this.state.payment.data.amount/100)*0.0225+0.3).toFixed(2)} ({global.stripeFee})</div>
        )
      }
    } else {
      return (
        <div className="lft">{global.curSym}{((this.state.payment.data.amount/100)*0.0225+0.3).toFixed(2)} ({global.stripeFee})</div>
      )
    }
  }


  renderNotes() {
    if(this.state.payment.data.notes !== '' && this.state.payment.data.notes !== undefined) {
      return (
        <div className="clear">
          <div className="label lft clear">Notes</div>
          <div className="lft">{this.state.payment.data.notes}</div>
        </div>
      )
    }
  }


  renderMethod() {
    if(this.state.payment.data.fee !== undefined) {
      if(this.state.payment.data.fee === 0) {
        return (
          <div className="clear">
            <div className="label lft clear">Method</div>
            <div className="lft">Other payment method</div>
          </div>
        )
      } else {
        return (
          <div className="clear">
            <div className="label lft clear">Card</div>
            <div className="lft">{this.state.payment.data.type} ending {this.state.payment.data.last4}</div>
          </div>
        )
      }
    } else {
      return (
        <div className="clear">
          <div className="label lft clear">Card</div>
          <div className="lft">{this.state.payment.data.type} ending {this.state.payment.data.last4}</div>
        </div>
      )
    }
  }


  renderButton() {
    if(global.showHabits) {
      return (
        <a className="btn primary" href={'https://ptmate.app/billing/receipt/'+this.state.payment.id} target="_blank" rel="noopener noreferrer">View receipt</a>
      )
    } else {
      if(this.state.payment.data.fee === undefined || this.state.payment.data.fee !== 0) {
        return (
          <a className="btn primary" href={this.state.payment.data.receipt} target="_blank" rel="noopener noreferrer">View receipt</a>
        )
      } else {
        return (
          <button className="btn tertiary width-12" onClick={() => this.refundCash()}>Mark as refunded</button>
        )
      }
    }
  }


  renderGST() {
    if(global.spaceGST !== 0) {
      var label = '-'
      var mul = (this.state.payment.data.amount/100)/11
      var num = mul/10*global.spaceGST
      label = global.curSym+num.toFixed(2)
      return (
        <div>
          <div className="label lft clear">GST incl.</div>
          <div className="lft">{label}</div>
        </div>
      )
    }
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">Payment</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <div className="info clear">
              <p className="label">{this.renderPaymentName(this.state.payment)}</p>
              <p className="big">{global.curSym}{(this.state.payment.data.amount/100).toFixed(2)}</p>
            </div>
            <div className="table">
              {this.renderGST()}
              <div className="label lft clear">Stripe fees</div>
              {this.renderFees()}
              <div className="label lft clear">After fees</div>
              {this.renderNet()}
              <div className="clear"></div>
            </div>
            <div className="table notop">
              <div className="label lft">Client</div>
              <div className="lft">{this.getClientName(this.state.payment.data.client)}</div>
              <div className="label lft clear">Date</div>
              <div className="lft">{this.renderPaymentDate(this.state.payment)}</div>
              {this.renderMethod()}
              {this.renderNotes()}
              <div className="clear"></div>
            </div>
            <div className="clear sv-30"></div>
            {this.renderButton()}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default ModalPayment;