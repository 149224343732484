import React, { Component } from 'react';
import Moment from 'moment';
import Connector from '../../data/Connector';

import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetOverview from '../../components/Widgets/overview';
import InputDate from '../../components/Form/input-date';
import InputRadio from '../../components/Form/radio';
import ListIcon from '../../components/List/icon';
import ListEmpty from '../../components/List/empty';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class ArchivePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: global.clients,
      sessionsList: [],
      archive: global.archive,
      start: Moment().add(-120, 'days').format('YYYY-MM-DD'),
      end: Moment().add(-60, 'days').format('YYYY-MM-DD'),
      total: 0,
      pt: 0,
      group: 0,
      created: false,
      margin: 0,
      trainers: global.userStaff,
      trainer: 'all',
      type: 'both',
      search: '',
    };
  }


  componentDidMount() {
    HelperPage.initialize('Session Archive - PT Mate', '/calendar/archive')
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      if(global.archive.length === 0 && global.uidUser !== '') {
        Connector.loadArchive((result) => {})
      }
      this.setState({
        clients: global.clients,
        archive: global.archive,
      }, () => {
        this.configureList()
      })
    }
  }


  configureList() {
    var tmp = []
    var total = 0
    var pt = 0
    var group = 0
    var served = []
    var endStr = this.state.end+' 23:59'
    var start = Moment(this.state.start, 'YYYY-MM-DD').format('X')
    var end = Moment(endStr, 'YYYY-MM-DD HH:mm').format('X')

    var list = []
    for(var cl of this.state.clients) {
      if(cl.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && this.state.search !== '') {
        list.push(cl.id)
      }
    }

    // Archive
    for(var item of this.state.archive) {
      var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
      var add = false
      if(this.state.trainer === 'all') {
        add = true
      } else {
        if(item.data.trainerId === this.state.trainer || (item.data.trainerId === undefined && this.state.trainer === global.uidUser)) {
          add = true
        }
      }
      if(add) {
        if(this.state.type === '11' && item.data.group) {
          add = false
        }
        if(this.state.type === 'group' && !item.data.group) {
          add = false
        }
        if(this.state.search !== '') {
          add = false
          if(list.indexOf(item.data.client) !== -1 && !item.data.group) {
            add = true
          }
          if(item.data.group) {
            if(item.data.clients !== undefined) {
              for(var at of item.data.clients) {
                if(list.indexOf(at) !== -1) {
                  add = true
                }
              }
            }
            if(item.data.client.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
              add = true
            }
          }
        }
      }
      if(date >= start && date <= end && add) {
        tmp.push(item)
        total++
        if(item.data.group) {
          group++
          if(item.data.clients !== undefined) {
            for(var att of item.data.clients) {
              if(served.indexOf(att) === -1) {
                served.push(att)
              }
            }
          }
        } else {
          pt++
          if(served.indexOf(item.data.client) === -1) {
            served.push(item.data.client)
          }
        }
      }
    }
    this.setState({
      sessionsList: tmp,
      total: total,
      pt: pt,
      group: group,
    })
  }


  getSessions(type) {
    var num = 0
    for(var item of this.state.sessionsList) {
      if(item.data.group && type === 'group') {
        num++
      } else if(!item.data.group && type === '11') {
        num++
      }
    }
    return num
  }



  // Interactive stuff ------------------------------------------------------------



  onChangeStart(event) {
    this.setState({
      start: Moment(event, 'YYYY-MM-DD').format('YYYY-MM-DD'),
    }, () => {
      this.configureList()
    });
  };


  onChangeEnd(event) {
    this.setState({
      end: Moment(event, 'YYYY-MM-DD').format('YYYY-MM-DD'),
    }, () => {
      this.configureList()
    });
  };


  selectType(type) {
    this.setState({
      type: type
    }, () => {
      this.configureList()
    });
  }


  setTrainer(event) {
    this.setState({
      trainer: event.target.value
    }, () => {
      this.configureList()
    })
  }


  filterSessions(event) {
    this.setState({
      search: event.target.value
    }, () => {
      this.configureList()
    })
  }



  // Display stuff ------------------------------------------------------------



  renderSessionType(item) {
    if(item.data.group && item.data.availability === undefined) {
      return ['group', 'primary']
    } else {
      return ['session', 'secondary']
    }
  }


  renderSessionTypeText(item) {
    if(item.data.group) {
      if(item.data.availability === undefined) {
        if(item.data.client === '') {
          return 'Group session'
        } else {
          return item.data.client
        }
      } else {
        return 'Available 1:1 session'
      }
    } else {
      var label = 'Deleted client'
      for(var cl of this.state.clients) {
        if(cl.id === item.data.client) {
          label = cl.data.name
        }
      }
      return label
    } 
  }


  renderAttendance(item) {
    if(item.data.attendance === 0 || item.data.attendance === 3) {
      return (
        <span className="pill green">Attended</span>
      )
    } else if(item.data.attendance === 1 || item.data.attendance === 4) {
      return (
        <span className="pill red">Not attended</span>
      )
    } else {
      if(item.data.group) {
        return (
          <span className="pill grey">Confirm attendance</span>
        )
      } else {
        return (
          <span className="pill grey">Confirm attendance</span>
        )
      }
    }
  }


  renderFilterSelect() {
    if(global.userStaff.length > 0 && global.uid === global.uidUser) {
      var list = global.userStaff
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <select className="search light ml-10 rgt" onChange={event => this.setTrainer(event)} value={this.state.currentGroup}>
          <option value='all' key='none'>All trainers</option>
          <option value={global.uidUser} key={global.uidUser}>Yourself</option>
          {list.map(item => (
            <option value={item.id} key={item.id}>{item.data.name}</option>
          ))}
        </select>
      )
    }
  }


  renderSessions() {
    if(this.state.sessionsList.length === 0) {
      return (
        <ListEmpty id='noarchive' type='simple'/>
      )
    } else {
      var list = this.state.sessionsList
      list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
      return (
        <div>
          {list.map(item => (
            <ListIcon 
              key={item.id}
              icon={this.renderSessionType(item)[0]}
              color={this.renderSessionType(item)[1]}
              link={!item.data.group ? '/calendar/session/'+item.id : (item.data.availability === undefined ? '/calendar/group-session/'+item.id : '/calendar/availability/'+item.id)}
              columns={[{text: Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('D MMM YYYY - HH:mm')+' h', width: 5}, {text: this.renderSessionTypeText(item), width: 3}, {text: this.renderAttendance(item), width: 2}]}
            />
        ))}
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          <div className="col-9">
            <WidgetOverview title='Session Archive'
              item1={{id: 'archive', value: this.state.sessionsList.length, prev: ''}}
              item2={{id: 'cal11', value: this.getSessions('11'), prev: ''}}
              item3={{id: 'calgroup', value: this.getSessions('group'), prev: ''}}
              item4={{id: '', value: ''}}
            />
          </div>
          <div className="col-3">
            <h3 className="mb-20">Show</h3>
            <div className="col-6 clear">
              <InputDate label="From" value={this.state.start} format='YYYY-MM-DD' max={Moment().add(-60, 'days').format('YYYY-MM-DD')} onChange={(event) => this.onChangeStart(event)}/>
            </div>
            <div className="col-6">
              <InputDate label="Until" value={this.state.end} format='YYYY-MM-DD' max={Moment().add(-60, 'days').format('YYYY-MM-DD')} onChange={(event) => this.onChangeEnd(event)}/>
            </div>
            <div className="col-12 clear">
              <InputRadio
                label='Session type'
                value={this.state.type}
                clickElement={(event) => this.selectType(event)}
                values={[{name: '1:1', value: '11'}, {name: 'Group', value: 'group'}, {name: 'Both', value: 'both'}]}
              />
            </div>
            <div className="clear"></div>
          </div>

          <div className="col-12">
            <div className="listheader">
              <h3 className="lft mt-5">Sessions</h3>
              {this.renderFilterSelect()}
              <input type="text" className="search light large rgt" placeholder="Filter by name or client" value={this.state.search} onChange={(val) => this.filterSessions(val)} />
              <div className="clear"></div>
            </div>
            {this.renderSessions()}
            <div className="sv-40"></div>
          </div>

          <div className="sv-40"></div>
        </div>
        <Header type='calendar' active=''/>
        <Navigation  active='calendar' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(ArchivePage));