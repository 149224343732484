import React, { Component } from 'react';



class WidgetCard extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      id: props.id,
    }
  }


  renderExpiry(item) {
    if(item.data.cardExpiry !== undefined) {
      return (
        <div className="info">
          <label>Expires</label>
          <p>{item.data.cardExpiry}</p>
        </div>
      )
    }
  }


  render() {
    var data = null
    for(var item of global.clients) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(data !== null) {
      return (
        <div className="card-info">
          <div className={'logo mb-30 '+data.data.cardBrand}></div>
          <div className="info clear mb-20">
            <label>Card number</label>
            <p>**** **** **** {data.data.cardLast4}</p>
          </div>
          {this.renderExpiry(data)}
        </div>
      )
    } else {
      return <div></div>
    }
  }
}


export default WidgetCard;