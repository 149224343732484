import React, { Component } from 'react';
import Firebase from 'firebase';
import InputText from '../Form/input-text';



class ModalBlacklist extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      list: props.list ?? [],
      value: '',
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      list: props.list ?? []
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      this.setState({
        hidden: '',
      });
    }, 100);
  }


  hide() {
    this.setState({
      hidden: 'hidden',
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  addEmail() {
    if(this.state.value !== '') {
      var tmp = this.state.list
      tmp.push(this.state.value)
      Firebase.database().ref('/public/'+global.uid).update({
        blacklist: tmp
      })
      this.setState({
        list: tmp
      })
    }
  }


  removeClient(item) {
    var tmp = this.state.list
    for(var i=0; i<tmp.length; i++) {
      if(tmp[i] === item) {
        tmp.splice(i, 1)
      }
    }
    Firebase.database().ref('/public/'+global.uid).update({
      blacklist: tmp
    })
    this.setState({
      list: tmp,
      email: '',
    })
  }


  clickMainButton() {
    this.hide()
    this.props.clickMainButton()
  }


  renderContent() {
    if(this.state.list.length === 0) {
      return (
        <div className="scroll withbutton">
          <div className="empty small">
            <h4>The list is empty</h4>
          </div>
        </div>
      )
    } else {
      var list = []
      for(var em of this.state.list) {
        var name = 'Unknown client'
        for(var cl of global.clients) {
          if(cl.data.email === em) {
            name = cl.data.name
          }
        }
        list.push({email: em, name: name})
      }
      list.sort((a,b) => a.name.localeCompare(b.name))
      return (
        <div className="scroll withbutton attendance">
          {list.map(item => (
            <div className="list bare" key={item.email}>
              <div className="icon tertiary">
                <div className="inner inbox"></div>
              </div>
              <div className="main">
              <button className="btn tertiary small rgt close" onClick={() => this.removeClient(item.email)}>Remove</button>
                <h4>{item.name}</h4>
                <p>{item.email}</p>
              </div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box clients">
            <h2 className="mb-20 lft">No-send list</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <div className="clear sv-20"></div>
            {this.renderContent()}
            <InputText label='Add an email address' value={this.state.value} onChange={(event) => this.setState({value: event})}/>
            <button className="btn primary" onClick={() => this.addEmail()}>Add to list</button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default ModalBlacklist;