import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';

import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import HelperEmptyImg from '../../helper/EmptyLarge';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import InputText from '../../components/Form/input-text';
import Loader from '../../components/Form/loader';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class AccountBillingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: ['', '', '', '', ''],
      valueState: '',
      error: [false, false, false, false, false],
      valuesBank: ['', ''],
      errorBank: [false, false],
      address: [],
      bank: [],
      verified: global.userVerified,
      stripe: global.userStripeConnect,
      file1: null,
      file2: null,
      file3: null,
      showOverlay: false,
      hidden: 'hidden',
      margin: 0,
      log: global.userLog,
      loading: false,
      doing: false,
      ssn: '',
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Stripe Payments - PT Mate')
    this._isMounted = true

    global.currentClient = '';
    var bank = ["", ""]
    if(global.userStripeBank !== undefined && global.userStripeBank !== "") {
      bank = global.userStripeBank.split(',')
    }
    if(global.userStripeConnect !== '' && global.userStripeConnect !== undefined) {
      this.setState({
        address: global.spaceAddress.split('||'),
        bank: bank
      })
    }
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('logLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
    this.setState({
      loading: false
    })
  }


  configureData() {
    if(this._isMounted) {
      if(global.userStripeConnect !== '' && global.userStripeConnect !== undefined) {
        var bank = ["", ""]
        if(global.userStripeBank !== undefined && global.userStripeBank !== "") {
          bank = global.userStripeBank.split(',')
        }
        this.setState({
          address: global.spaceAddress.split('||'),
          //bank: global.userStripeBank.split(',')
          bank: bank
        })
      }
      this.setState({
        verified: global.userVerified,
        stripe: global.userStripeConnect,
        log: global.userLog,
      }, () => {
        if(global.spaceAddress !== undefined && global.spaceAddress !== '') {
          var state = ''
          if(this.state.address.length === 5) {
            state = this.state.address[4]
          }
          this.setState({
            values: [this.state.address[0], this.state.address[1], this.state.address[2], this.state.address[3]],
            valueState: state
          })
        }
        if(global.userStripeBank !== undefined && global.userStripeBank !== '') {
          this.setState({
            valuesBank: [this.state.bank[1], '******'+this.state.bank[2]],
          })
        }
      });
      this.checkLog()
    }
  }


  checkLog() {
    for(var item of this.state.log) {
      if(item.data.title === 'createconnecterror' || item.data.title === 'createbankerror' || item.data.title === 'updateconnecterror' || item.data.title === 'updatebankerror' || item.data.title === 'createbankerror') {
        EventEmitter.dispatch('showMessageError', item.data.message);
        Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove()
        this.setState({
          loading: false,
          doing: false
        })
      }
      if(item.data.type === 'success' && (item.data.title === 'updateconnect' || item.data.title === 'updatebank' || item.data.title === 'createbank')) {
        EventEmitter.dispatch('showMessage', 'Details successfully updated');
        Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove()
        this.setState({
          loading: false,
          doing: false
        })
      }
      if(item.data.type === 'success' && item.data.title === 'createconnect') {
        this.setState({showMessage: 'success2'})
        Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove()
        this.setState({
          loading: false,
          doing: false
        })
      }
    }
  }



  // Enable billing ------------------------------------------------------------



  checkEnable() {
    if(!this.state.doing) {
      var tmp1 = [false, false, false, false, false]
      var tmp2 = [false, false]
      if(this.state.valueState === '' || this.state.valueState === undefined) { tmp1[0] = true }
      if(this.state.values[1] === '' || this.state.values[1] === undefined) { tmp1[1] = true }
      if(this.state.values[2] === '' || this.state.values[2] === undefined) { tmp1[2] = true }
      if(this.state.values[3] === '' || this.state.values[3] === undefined) { tmp1[3] = true }
      if((this.state.ssn === '' || this.state.ssn === undefined) && global.userCountry === 'us' && !global.userVerified) { tmp1[4] = true }
      if(this.state.valuesBank[0] === '') { tmp2[0] = true }
      if(this.state.valuesBank[1] === '') { tmp2[1] = true }
      this.setState({
        error: tmp1,
        errorBank: tmp2,
        doing: true
      })
      if(!tmp1[0] && !tmp1[1] && !tmp1[2] && !tmp1[3] && !tmp1[4] && !tmp2[0] && !tmp2[1]) {
        this.enableBilling()
      }
    }
    
  }


  enableBilling() {
    var pc = this.state.values[3]
    var state = this.state.valueState
    var bname = this.state.values[0]
    let bsb = this.state.valuesBank[0].replace(' ', '').replace('-', '').replace(',', '').replace('.', '').replace('e', '')
    let acc = this.state.valuesBank[1].replace(' ', '').replace('-', '').replace(',', '').replace('.', '').replace('*', '').replace('e', '')

    if(bname === '') {
      bname = global.userName
    }

    var ar = global.userName.split(' ')
    var fname = ar[0]
    var lname = ''
    if(ar.length > 1) {
      lname = ar[1]
    }

    this.uploadImages()

    var dd = Moment(global.userBday, 'DD/MM/YYYY').format('DD')
    var dm = Moment(global.userBday, 'DD/MM/YYYY').format('MM')
    var dy = Moment(global.userBday, 'DD/MM/YYYY').format('YYYY')

    var callFunction = Firebase.functions().httpsCallable('createConnect');
    callFunction({name: global.userName, email: global.userEmail, business: bname, address: this.state.values[1], city: this.state.values[2], postcode: pc, bsb: bsb, acc: acc, dd: dd, dm: dm, dy: dy, fname: fname, lname: lname, state: state, phone: global.userPhone, country: global.userCountry, currency: global.userCurrency, ssn: this.state.ssn}).then(function(result) {
      //var sanitizedMessage = result.data.text;
    });
    this.setState({loading: true})
  }



  // Update address details ------------------------------------------------------------



  updateValues(val, num) {
    var tmp = this.state.values
    tmp[num] = val
    this.setState({
      values: tmp
    });
  }


  configureState(code) {
    var pc = parseInt(code)
    var state = "NSW"
    if((pc > 2999 && pc < 4000) || (pc > 7999 && pc < 9000)) {
        state = "VIC"
    }
    if((pc > 3999 && pc < 5000) || (pc > 8999 && pc < 10000)) {
        state = "QLD"
    }
    if(pc > 4999 && pc < 6000) {
        state = "SA"
    }
    if(pc > 5999 && pc < 7000) {
        state = "WA"
    }
    if(pc > 6999 && pc < 8000) {
        state = "TAS"
    }
    if(pc > 799 && pc < 1000) {
        state = "NT"
    }
    if((pc > 199 && pc < 300) || (pc >= 2600 && pc <= 2618) || (pc >= 2900 && pc <= 2920)) {
        state = "ACT"
    }
    return state
  }


  checkAddress() {
    this.setState({error: [false, false, false, false, false]})
    var tmp = [false, false, false, false, false]
    if(this.state.valueState === '' || this.state.valueState === undefined) { tmp[0] = true }
    if(this.state.values[1] === '' || this.state.values[1] === undefined) { tmp[1] = true }
    if(this.state.values[2] === '' || this.state.values[2] === undefined) { tmp[2] = true }
    if(this.state.values[3] === '' || this.state.values[3] === undefined) { tmp[3] = true }
    this.setState({error: tmp})
    if(!tmp[0] && !tmp[1] && !tmp[2] && !tmp[3]) {
      this.updateAddress()
    }
  }


  updateAddress() {
    var pc = this.state.values[3]
    var state = this.state.valueState
    var bname = this.state.values[0]

    if(bname === '') {
      bname = global.userName
    }

    this.setState({loading: true})
    var callFunction2 = Firebase.functions().httpsCallable('updateConnect');
    callFunction2({account: this.state.stripe, business: bname, address: this.state.values[1], city: this.state.values[2], postcode: pc, state: state, name: global.userName, country: global.userCountry, currency: global.userCurrency, ssn: this.state.ssn}).then(function(result) {
      //var sanitizedMessage = result.data.text;
    });
  }
  


  // Update bank details ------------------------------------------------------------



  updateValuesBank(val, num) {
    var tmp = this.state.valuesBank
    tmp[num] = val
    this.setState({
      valuesBank: tmp
    });
  }


  onChangeBank = event => {
    var tmp = this.state.valuesBank
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({valuesBank: tmp});
  };


  checkBank() {
    this.setState({errorBank: [false, false]})
    var tmp = [false, false]
    if(this.state.valuesBank[0] === '') { tmp[0] = true }
    if(this.state.valuesBank[1] === '') { tmp[1] = true }
    this.setState({errorBank: tmp})
    if(!tmp[0] && !tmp[1]) {
      this.updateBank()
    }
  }


  updateBank() {
    this.setState({loading: true})
    let bsb = this.state.valuesBank[0].replace(' ', '').replace('-', '').replace(',', '').replace('.', '').replace('e', '')
    let acc = this.state.valuesBank[1].replace(' ', '').replace('-', '').replace(',', '').replace('.', '').replace('*', '').replace('e', '')
    // Update bank details
    var callFunction3 = Firebase.functions().httpsCallable('updateConnectBank');
    callFunction3({account: this.state.stripe, bsb: bsb, acc: acc, id: this.state.bank[0], country: global.userCountry, currency: global.userCurrency}).then(function(result) {
      //var sanitizedMessage = result.data.text;
    });
  }



  // ID verification ------------------------------------------------------------



  setImg1(file) { this.setState({file1: file}) }
  setImg2(file) { this.setState({file2: file}) }
  setImg3(file) { this.setState({file3: file}) }


  uploadImages() {
    var num = 0
    var finished = 0
    if(this.state.file1 != null) { num++ }
    if(this.state.file2 != null) { num++ }
    if(this.state.file3 != null) { num++ }

    const sr = Firebase.storage().ref()
    const fr1 = sr.child('images/verification/'+Firebase.auth().currentUser.uid+'/photoid.jpg')
    const fr2 = sr.child('images/verification/'+Firebase.auth().currentUser.uid+'/photoid-back.jpg')
    const fr3 = sr.child('images/verification/'+Firebase.auth().currentUser.uid+'/address.jpg')

    if(num > 0) {
      if(this.state.file1 != null) {
        fr1.put(this.state.file1).then(() => {
          finished++
          if(finished === num) {
            this.showOverlayID()
          }
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }
      if(this.state.file2 != null) {
        fr2.put(this.state.file2).then(() => {
          finished++
          if(finished === num) {
            this.showOverlayID()
          }
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }
      if(this.state.file3 != null) {
        fr3.put(this.state.file3).then(() => {
          finished++
          if(finished === num) {
            this.showOverlayID()
          }
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }
    }
  }


  showOverlayID() {
    this.setState({
      showOverlay: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2,
        valuesPass: ['', ''],
        errorPass: [false, false]
      });
    }, 100);
  }


  hideOverlayID() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlay: false});
    }, 500);
  }


  renderOverlay() {
    if(this.state.showOverlay) {
      return (
        <div className={'overlay '+this.state.hidden}   >
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Documents uploaded</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayID()}>Close</button>
            <div className="clear mb-30" style={{textAlign: 'center'}}>
              <img src="/img/tick.svg" className="tick" alt="Documents uploaded"/>
            </div>
            <p className="clear">Your documents have been uploaded successfully. Stripe needs to verify them so it might take up to 24 hours to verify your documents. You can contact us at help@ptmate.net if you have any questions.</p>
          </div>
        </div>
      )
    }
  }



  // Display stuff ------------------------------------------------------------



  renderVerified() {
    if(this.state.verified) {
      return (
        <div className="infobox small green">
          <h5>You're all set</h5>
          <p>All your details are verified with Stripe, and payouts are enabled.</p>
        </div>
      )
    } else {
      return (
        <div className="infobox small yellow">
          <h5>Payouts not enabled</h5>
          <p>Please verify your identity by uploading photo ID documents so Stripe can enable payouts for you. If you've already provided a document, please note that it can take up to 24h to get verified.</p>
        </div>
      )
    }
  }


  renderVerifiedForm() {
    if(!this.state.verified) {
      return (
        <div className="clear">
          <div className="clear sv-40"></div>
          <h3 className="mb-20">ID Verification</h3>
          <div className="form-item">
            <label>Photo ID (Front)</label>
            <input type="file" className="default" onChange={(e: any) => {this.setImg1(e.target.files[0]);}}/>
          </div>
          <div className="form-item">
            <label>Photo ID (Back)</label>
            <input type="file" className="default" onChange={(e: any) => {this.setImg2(e.target.files[0]);}}/>
            <div className="sv-20"></div>
          </div>
          <button className="btn tertiary width-12" onClick={() => this.uploadImages()}>Upload verification documents</button>
        </div>
      )
    }
  }


  renderSSN() {
    if(global.userCountry === 'us' && !global.userVerified) {
      return <InputText label='Last 4 digits of Social Security number*' value={this.state.ssn} error={this.state.error[4]} errormsg="Please enter your SSN's last 4 digits" onChange={(event) => this.setState({ssn: event})} info='The last 4 digits of your SSN are only used to verify your identity by Stripe — no credit checks'/>
    }
  }


  renderEnabled() {
    var standard = false
    if(global.userStripeBank === undefined) {
      standard = true
    } else {
      if(global.userStripeBank.indexOf('-,-') !== -1) {
        standard = true
      }
    }
    if(standard) {
      return (
        <div className="content form-sidebar">
          <div className="col-6 clear mt-20">
            <div className="box clear">
              <h3 className="mb-20">Billing enabled</h3>
              {this.renderVerified()}
              <div className="sv-40"></div>
              <h4 className="mb-20">Note:</h4>
              <p className="mb-20">Your billing details are managed in Stripe.</p>
              <p className="mb-20">Please charge your clients and set up memberships through PT Mate to ensure the data is displayed correctly in the system.</p>
              <p>Managing your client's billing directly in Stripe might result in missing or incomplete information (such as payment history) within PT Mate.</p>
            </div>
          </div>

          <div className="col-6 sidebar mt-20">
            <h2 className="mb-20">Stripe payments</h2>
            <div className="image empty large mb-30">
              <div className="illustration">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmptyImg.renderImage('payments')}
              </div>
            </div>
            <p className="mb-30">If you have any questions, feel free to reach out and chat to us.</p>
            
          </div>
          <div className="clear sv-40"></div>
        </div>
      )
    } else {
      return (
        <div className="content form-sidebar">
          <div className="col-6 clear mt-20">
            <div className="box clear">
              <h3 className="mb-20">Your details</h3>
              <div className="form">
                <InputText label='Business name' value={this.state.values[0]} onChange={(event) => this.updateValues(event, 0)}/>
                <InputText label='Address' value={this.state.values[1]} error={this.state.error[1]} errormsg='Please enter an address' onChange={(event) => this.updateValues(event, 1)}/>
                <InputText label={global.userCountry === 'au' ? 'Suburb*' : 'City*'} error={this.state.error[2]} errormsg='Please enter a suburb' value={this.state.values[2]} onChange={(event) => this.updateValues(event, 2)}/>
                <InputText label={global.userCountry === 'au' ? 'Postcode*' : 'Zip code*'} error={this.state.error[3]} errormsg='Please enter a valid postcode' value={this.state.values[3]} onChange={(event) => this.updateValues(event, 3)}/>
                <InputText label={'State*'} error={this.state.error[0]} errormsg='Please enter a state' value={this.state.valueState} onChange={(event) => this.setState({valueState: event})}/>
                {this.renderSSN()}
                <div className="clear space-20"></div>
              </div>
              <button className="btn tertiary width-12" onClick={() => this.checkAddress()}>Update address details</button>
              <div className="clear sv-40"></div>
              <h3 className="mb-20">Bank details</h3>
              <div className="form">
                <InputText label={global.userCountry === 'au' ? 'BSB*' : 'Routing number*'} value={this.state.valuesBank[0]} error={this.state.errorBank[0]} errormsg='Please enter a BSB' onChange={(event) => this.updateValuesBank(event, 0)}/>
                <InputText label='Account*' value={this.state.valuesBank[1]} error={this.state.errorBank[1]} errormsg='Please enter a valid account number' onChange={(event) => this.updateValuesBank(event, 1)}/>
              </div>
              <button className="btn tertiary width-12" onClick={() => this.checkBank()}>Update bank details</button>
              {this.renderVerifiedForm()}
            </div>
          </div>

          <div className="col-6 sidebar mt-20">
            <h2 className="mb-20">Stripe payments</h2>
            <div className="image empty large mb-30">
              <div className="illustration">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmptyImg.renderImage('payments')}
              </div>
            </div>
            <p className="mb-30">You can update your business information with Stripe here and make sure it's up to date. If you have any questions regarding Stripe, feel free to reach out and chat to us.</p>
            {this.renderVerified()}
          </div>
          <div className="clear sv-40"></div>
        </div>
      )
    }
  }


  renderSetupExisting() {
    return (
      <div className="infobox large green mb-20">
        <p>
          <strong>Do you already have a Stripe account?</strong><br/>
          We're happy to assist you connecting your existing Stripe account to PT Mate. <button className="btn tertiary small" style={{display: 'inline'}} onClick={() => window.Intercom('show')}>click here</button> to chat to us and get your integration setup started.
        </p>
        <div className="clear"></div>
      </div>
    )
  }


  renderSetup() {
    /*return (
      <div className="content form-sidebar">
        {this.renderSetupExisting()}
        <div className="col-6 mt-20 clear">
          <div className="box">
            <h3 className="mb-20">Your details</h3>
            <div className="clear">
              <InputText label='Business name' value={this.state.values[0]} onChange={(event) => this.updateValues(event, 0)}/>
              <InputText label='Address' value={this.state.values[1]} error={this.state.error[1]} errormsg='Please enter an address' onChange={(event) => this.updateValues(event, 1)}/>
              <InputText label={global.userCountry === 'au' ? 'Suburb*' : 'City*'} error={this.state.error[2]} errormsg='Please enter a suburb' value={this.state.values[2]} onChange={(event) => this.updateValues(event, 2)}/>
              <InputText label={global.userCountry === 'au' ? 'Postcode*' : 'Zip code*'} error={this.state.error[3]} errormsg='Please enter a valid postcode' value={this.state.values[3]} onChange={(event) => this.updateValues(event, 3)}/>
              <InputText label={'State*'} error={this.state.error[0]} errormsg='Please enter a state' value={this.state.valueState} onChange={(event) => this.setState({valueState: event})}/>
            </div>
            <div className="sv-30"></div>
            <h3 className="mb-20">Bank details</h3>
            <div className="clear form">
              <InputText label={global.userCountry === 'au' ? 'BSB*' : 'Routing number*'} value={this.state.valuesBank[0]} error={this.state.errorBank[0]} errormsg='Please enter a BSB' onChange={(event) => this.updateValuesBank(event, 0)}/>
              <InputText label='Account*' value={this.state.valuesBank[1]} error={this.state.errorBank[1]} errormsg='Please enter a valid account number' onChange={(event) => this.updateValuesBank(event, 1)}/>
            </div>
            <div className="sv-30"></div>
            <h3 className="mb-20">ID Verification</h3>
            <div className="form">
              <label>Photo ID (Front)</label>
              <input type="file" className="default" onChange={(e: any) => {this.setImg1(e.target.files[0]);}}/>
              <label>Photo ID (Back)</label>
              <input type="file" className="default" onChange={(e: any) => {this.setImg2(e.target.files[0]);}}/>
              {this.renderSSN()}
            </div>
            <div className="sv-40 clear"></div>
            <button className="btn primary" onClick={() => this.checkEnable()}>Enable billing</button>
          </div>
        </div>

        <div className="col-6 sidebar mt-20">
          <h2 className="mb-20">Enable Stripe payments</h2>
          <div className="image empty large mb-30">
            <div className="illustration">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('payments')}
            </div>
          </div>
          <p className="mb-30">This allows you to create packs of sessions, set up memberships and charge your clients directly through PT Mate. Financial insights give you an overview of your business.</p>
          <p className="mb-30">To enable payments with Stripe, just enter your business details.</p>
          <div className="infobox">
            <h5>Note</h5>
            <p>Please make sure all the information is correct as Stripe will verify your details. Once verified, you'll be able to process payments securely via Stripe.</p>
          </div>
        </div>
        <div className="clear sv-40"></div>
      </div>
    )*/
    return (
      <div className="content form-sidebar">
        {this.renderSetupExisting()}
        <div className="col-6 mt-20 clear">
          <div className="box">
            <h3 className="mb-20">Steps to enable Stripe Payments</h3>
            <img src="/img/stripe-logo.svg" alt="Stripe" className="mb-20"/>
            <p className="mb-40">You will need a Stripe Standard Account to create memberships, charge your clients and get paid out via Stripe.</p>
            <h4 className="mb-20">Step 1: Create a Stripe account</h4>
            <p className="mb-10" style={{fontSize: 14}}>Go to Stripe and follow the steps indicated. If you already have a Stripe account, continue with Step 2.</p>
            <a href="https://dashboard.stripe.com/register" target="_blank" rel="noopener noreferrer" className="btn tertiary small lft mb-40">Create your Stripe account</a>
            <h4 className="mb-20 clear">Step 2: Connect your account to PT Mate</h4>
            <p className="mb-10" style={{fontSize: 14}}>Once created, message us through the in-platform chat and we'll send you a connection link to connect your account.</p>
            <button className="btn tertiary small lft mb-40" onClick={() => window.Intercom('show')}>Open chat</button>
            <h4 className="mb-20 clear">Step 3: You're connected</h4>
            <p style={{fontSize: 14}}>Please allow up to 12 hours for the connection to occur.</p>
          </div>
        </div>

        <div className="col-6 sidebar mt-20">
          <h2 className="mb-20">Enable Stripe payments</h2>
          <div className="image empty large mb-30">
            <div className="illustration">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('payments')}
            </div>
          </div>
          <p className="mb-30">This allows you to create packs of sessions, set up memberships and charge your clients directly through PT Mate. Financial insights give you an overview of your business.</p>
          <div className="infobox">
            <h5>We're here to help</h5>
            <p>Reach out if you have any questions or need help with the process.</p>
          </div>
        </div>
        <div className="clear sv-40"></div>
      </div>
    )
  }


  renderBase() {
    if(this.state.stripe === undefined || this.state.stripe === '') {
      return (
        <div className={'theme-'+global.spaceTheme}>
          {this.renderSetup()}
          <Header title='Stripe Payments' link={ROUTES.ADMINSETTINGS}/>
          <Navigation active=''/>
          {this.renderOverlay()}
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          {this.renderEnabled()}
          <Header title='Stripe Payments' link={ROUTES.ADMINSETTINGS}/>
          <Navigation active=''/>
          {this.renderOverlay()}
          <Loader show={this.state.loading}/>
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
    
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(AccountBillingPage));