import React, { Component } from 'react';
import { Link } from 'react-router-dom';



class ListIcon extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      icon: props.icon,
      color: props.color ?? 'primary',
      columns: props.columns,
      link: props.link ?? '',
      button: props.button ?? ''
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      icon: props.icon,
      color: props.color ?? 'primary',
      columns: props.columns,
      link: props.link ?? '',
      button: props.button ?? ''
    }
  }


  renderButton() {
    if(this.state.button === '') {
      return (
        <div className="col-2">
          <button className="btn tertiary small rgt">View details</button>
        </div>
      )
    } else if(this.state.button === 'edit') {
      return (
        <div className="col-1">
          <button className="btn tertiary edit small rgt">Edit</button>
        </div>
      )
    } else if(this.state.button === 'manage') {
      return (
        <div className="col-1">
          <button className="btn tertiary small rgt">Manage</button>
        </div>
      )
    }
  }


  render() {
    var list = []
    if(this.state.columns !== undefined) {
      for(var col of this.state.columns) {
        list.push(col)
      }
    }
    list.splice(0, 1)
    if(this.state.link !== '') {
      return (
        <div className="box list simple highlight mb-10">
          <Link to={this.state.link}>
            <div className={"col-"+this.state.columns[0].width+(this.state.columns[0].w1280 === undefined ? '' : ' col-'+this.state.columns[0].w1280+'-1280')}>
              <div className={"icon "+this.state.color}>
                <div className={'inner '+this.state.icon}></div>
              </div>
              <p><strong>{this.state.columns[0].text}</strong></p>
            </div>
            {list.map(item => (
              <div className={"col-"+item.width+(item.w1280 === undefined ? '' : ' col-'+item.w1280+'-1280')} key={item.text}>
                <p>{item.text}</p>
              </div>
            ))}
            {this.renderButton()}
            <div className="clear"></div>
          </Link>
        </div>
      )
    } else {
      return (
        <div className="box list simple highlight mb-10" onClick={() => this.props.clickElement()}>
          <div className={"col-"+this.state.columns[0].width+(this.state.columns[0].w1280 === undefined ? '' : ' col-'+this.state.columns[0].w1280+'-1280')}>
            <div className={"icon "+this.state.color}>
              <div className={'inner '+this.state.icon}></div>
            </div>
            <p><strong>{this.state.columns[0].text}</strong></p>
          </div>
          {list.map(item => (
            <div className={"col-"+item.width+(item.w1280 === undefined ? '' : ' col-'+item.w1280+'-1280')} key={item.text}>
              <p>{item.text}</p>
            </div>
          ))}
          {this.renderButton()}
          <div className="clear"></div>
        </div>
      )
    }
    
  }
}


export default ListIcon;