import React, { Component } from 'react';
import Moment from 'moment';
import Firebase from 'firebase';

import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import HelperEmptyImg from '../../helper/EmptyLarge';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ListHeader from '../../components/List/header';
import ListIcon from '../../components/List/icon';
import ListEmpty from '../../components/List/empty';
import ModalDocument from '../../components/Modals/document';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class DocumentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      search: '',
      item: null,
      forms: global.forms,
      documents: [],
      showModalDocument: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Forms & Documents - PT Mate', ROUTES.ADMINDOC)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('formsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('documentsLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() { 
    if(this._isMounted) {
      var tmp = []
      for(var client of global.clients) {
        for(var item of client.documents) {
          tmp.push(item)
        }
      }
      for(var doc of global.documents) {
        tmp.push(doc)
      }
      
      this.setState({
        clients: global.clients,
        forms: global.forms,
        documents: tmp
      })
    }
  }


  filterDocuments(value) {
    var tmp = []
    for(var client of global.clients) {
      for(var item of client.documents) {
        if(item.data.name.toString().toLowerCase().indexOf(value.target.value.toLowerCase()) !== -1 || value.target.value === '') {
          tmp.push(item)
        }
      }
    }
    for(var doc of global.documents) {
      if(doc.data.name.toString().toLowerCase().indexOf(value.target.value.toLowerCase()) !== -1 || value.target.value === '') {
        tmp.push(doc)
      }
    }
    this.setState({
      documents: tmp,
      search: value.target.value
    })
  }


  getDocsNum() {
    var label = 0
    for(var item of this.state.clients) {
      if(item.documents !== null) {
        label += item.documents.length
      }
    }
    label += global.documents.length
    return label
  }


  getClientsNum() {
    var label = 0
    for(var item of this.state.clients) {
      if(item.documents !== undefined) {
        if(item.documents.length > 0) {
          label++
        }
      }
    }
    return label
  }


  getDocClass(item) {
    var label = 'primary'
    var icon = 'doc'
    if(item === 'pdf') {
      label = 'red'
      icon = 'pdf'
    } else if(item === 'doc' || item === 'docx') {
      label = 'secondary'
      icon = 'docx'
    } else if(item === 'jpg' || item === 'png' || item === 'jpeg' || item === 'gif') {
      label = 'quarternary'
      icon = 'img'
    }
    return [label, icon]
  }



  // Modals ------------------------------------------------------------



  showModalDocument(id) {
    this.setState({
      showModalDocument: true,
      doc: id,
    })
  }


  hideModals() {
    this.setState({
      showModalDocument: false,
    })
  }


  uploadDocument(event) {
    this.hideModals()
    if(event[0] === 'client') {
      var obj = Firebase.database().ref('/clients/'+global.uid+'/'+event[3]+'/documents').push()
      var ext = event[2].name.split('.').pop()
      const sr = Firebase.storage().ref()
      const fr = sr.child('documents/'+global.uid+'/'+event[3]+'/'+obj.key+'.'+ext)
      fr.put(event[2]).then(() => {
        Firebase.database().ref('/clients/'+global.uid+'/'+event[3]+'/documents/'+obj.key).update({
          name: event[1],
          date: parseInt(Moment().format('X')),
          ext: ext,
        })
        EventEmitter.dispatch('showMessage', "Document successfully uploaded");
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })

    } else {
      var obj2 = Firebase.database().ref('/documents/'+global.uid).push()
      var ext2 = event[2].name.split('.').pop()
      const sr2 = Firebase.storage().ref()
      const fr2 = sr2.child('documents/'+global.uid+'/'+obj2.key+'.'+ext2)
      fr2.put(event[2]).then(() => {
        Firebase.database().ref('/documents/'+global.uid+'/'+obj2.key).update({
          name: event[1],
          date: parseInt(Moment().format('X')),
          ext: ext2,
          type: 'public'
        })
        EventEmitter.dispatch('showMessage', "Document successfully uploaded");
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  updateDocument(event) {
    this.hideModals()
    if(event[1] === '') {
      Firebase.database().ref('/documents/'+global.uid+'/'+this.state.doc).update({
        name: event[0],
      }).then(() => {
        EventEmitter.dispatch('showMessage', "Document name successfully updated");
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.database().ref('/clients/'+global.uid+'/'+event[1]+'/documents/'+this.state.doc).update({
        name: event[0],
      }).then(() => {
        EventEmitter.dispatch('showMessage', "Document name successfully updated");
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  deleteDocument(event) {
    this.hideModals()
    var ext = ''
    for(var item of this.state.documents) {
      if(item.id === this.state.doc) {
        ext = item.data.ext
      }
    }
    if(event === '') {
      Firebase.storage().ref('documents/'+global.uid+'/'+this.state.doc+'.'+ext).delete()
      Firebase.database().ref('/documents/'+global.uid+'/'+this.state.doc).remove().then(() => {
        EventEmitter.dispatch('showMessage', "Document successfully deleted");
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.storage().ref('documents/'+global.uid+'/'+event+'/'+this.state.doc+'.'+ext).delete()
      Firebase.database().ref('/clients/'+global.uid+'/'+event+'/documents/'+this.state.doc).remove().then(() => {
        EventEmitter.dispatch('showMessage', "Document successfully deleted");
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
    
  }



  // Display stuff ------------------------------------------------------------



  renderStatus(item, num) {
    var label = 'status-undefined'
    if(item.data.files !== undefined) {
      if(item.data.files[num] !== '') {
        label = 'status-ok'
      }
    }
    return label
  }


  renderClientName(id, type) {
    var label = ''
    for(var item1 of global.clients) {
      if(item1.id === id) {
        label = item1.data.name
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        label = item2.data.name
      }
    }
    if(type === 'public') {
      label = 'Public library file'
    }
    return label
  }


  renderDocuments() {
    if(this.state.documents.length === 0) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('documents')}
          </div>
          <div className="data">
            <h3 className="mb-20">No documents yet</h3>
            <p className="mb-20">You haven't uploaded any<br/>client documents yet.</p>
            <button className="btn tertiary add width-12" onClick={() => this.showModalDocument('')}>Upload a document</button>
          </div>
        </div>
      )
    } else {
      var list = this.state.documents
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          {list.map(item => (
            <ListIcon 
              key={item.id}
              icon={this.getDocClass(item.data.ext)[1]}
              color={this.getDocClass(item.data.ext)[0]}
              clickElement={() => this.showModalDocument(item.id)}
              button='manage'
              columns={[{text: item.data.name, width: 5}, {text: this.renderClientName(item.client, item.data.type), width: 4}, {text: Moment(item.data.date, 'X').format('D MMM YYYY')+' ('+item.data.ext+')', width: 2}]}
            />
          ))}
        </div>
      )
    }
  }


  renderForms() {
    if(this.state.forms.length === 0) {
      return (
        <ListEmpty id='noforms'/>
      )
    } else {
      var list = this.state.forms
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className="col-12">
          <div className="listheader clear">
            <ListHeader id='documents' number={this.state.forms.length}/>
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <ListIcon 
              key={item.id}
              link={'/admin/documents/form/'+item.id}
              icon='form'
              color='quarternary'
              button='manage'
              columns={[{text: item.data.name, width: 5}, {text: item.data.pre ? 'Pre Exercise Questionnaire' : ' ', width: 4}, {text: 'Version '+item.data.version, width: 2}]}
            />
          ))}
          <div className="space-50"></div>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      if(global.maxPlans === 0 && HelperBusiness.getRoleIncl('')) {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              <ListEmpty id='documents-locked'/>
            </div>
            <Header type='admin' active='forms'/>
            <Navigation active='admin' />
          </div>
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              <div className="col-9">
                <WidgetOverview showSelect={true} updateSelect={() => this.setDateRange()}
                  item1={{id: 'forms', value: this.state.forms.length, prev: ''}} 
                  item2={{id: 'formdocs', value: this.getDocsNum(), prev: ''}}
                  item3={{id: 'formclients', value: this.getClientsNum(), prev: ''}}
                  item4={{id: '', value: ''}}
                />
              </div>
              <WidgetActions
                item1={{id: 'newform', link: ROUTES.ADMINNEWDOC}}
                item2={{id: 'uploaddoc', link: ''}} clickItem2={() => this.showModalDocument('')}
                item3={{id: 'downloaddoc', link: '/pdf/documents.zip'}} ext={true}
              />
              {this.renderForms()}
              <div className="col-12">
                <div className="listheader clear">
                  <div className="mt-5 lft">
                    <ListHeader id='documents' number={this.state.documents.length}/>
                  </div>
                  <input type="text" className="search light rgt" placeholder="Filter by name" value={this.state.search} onChange={(val) => this.filterDocuments(val)} />
                  <div className="clear"></div>
                </div>
                {this.renderDocuments()}
                <div className="clear sv-80"></div>
              </div> 
            </div>
            <Header type='admin' active='forms'/>
            <Navigation active='admin' />
            <ModalDocument doc={this.state.doc} show={this.state.showModalDocument} documents={this.state.documents} onHide={() => this.hideModals()} clickUpload={(event) => this.uploadDocument(event)} clickUpdate={(event) => this.updateDocument(event)} clickDelete={(event) => this.deleteDocument(event)}/>
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='forms-locked'/>
          </div>
          <Header type='simple' title='Admin'/>
          <Navigation active='admin' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(DocumentsPage));