import React, { Component } from 'react';
import Moment from 'moment';
import ListClient from '../List/client';



class ModalBookings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      activity: props.activity ?? [],
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      activity: props.activity ?? [],
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      this.setState({
        hidden: '',
      });
    }, 100);
  }


  hide() {
    this.setState({
      hidden: 'hidden',
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  renderContent() {
    if(this.state.activity.length === 0) {
      return (
        <div className="scroll">
          <div className="empty small mt-30">
            <h4>Nothing yet</h4>
          </div>
        </div>
      )
    } else {
      var list = this.state.activity
      list.sort((a,b) => Moment(b.date, 'DD/MM/YYYY HH:mm') - Moment(a.date, 'DD/MM/YYYY HH:mm'));
      return (
        <div className="scroll">
          {list.map(item => (
            <ListClient client={item.client} line1={Moment(item.date).format('ddd, D MMMM - HH:mm')+' h'} line2={<span className={item.title === 'Client booked in' ? 'pill green' : 'pill red'}>{item.title === 'Client booked in' ? 'Booked in' : 'Booking cancelled'}</span>} type='static'/>
          ))}
        </div>
      )
    }
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box clients">
            <h2 className="mb-20 lft">Booking history</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <div className="clear"></div>
            {this.renderContent()}
            <div>
              <p className="center" style={{opacity: .5}}>&nbsp;<br/>Note: Only activity of the past 14 days is shown</p>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default ModalBookings;