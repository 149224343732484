import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import HelperBusiness from '../../helper/Business';



class HeaderHealth extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      type: props.type ?? 'header',
      active: props.active ?? '',
    };
  }


  renderHabits() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.HABITS} className={this.state.active === 'habits' ? 'tab active' : 'tab'}>
            <p>Habit Tracker</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.HABITS} className="item">
            <div className="bg"></div>
            <div className="text">Habit Tracker</div>
          </Link>
        )
      }
    }
  }


  render() {
    if(this.state.type === 'header') {
      return (
        <div>
          <Link to={ROUTES.NUTRITION} className={this.state.active === 'nutrition' ? 'tab active' : 'tab'}>
            <p>Nutrition Plans</p>
            <div className="bg"></div>
          </Link>
          {this.renderHabits()}
        </div>
      )
    } else {
      return (
        <div>
          <Link to={ROUTES.NUTRITION} className="item">
            <div className="bg"></div>
            <div className="text">Nutrition Plans</div>
          </Link>
          {this.renderHabits()}
        </div>
      )
    }
  }
}


export default HeaderHealth;