import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import HelperBusiness from '../../helper/Business';



class HeaderCalendar extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      type: props.type ?? 'header',
      active: props.active ?? '',
    };
  }


  renderInsights() {
    if(HelperBusiness.getRoleIncl('admin,cs')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.CALINSIGHTS} className={this.state.active === 'insights' ? 'tab active' : 'tab'}>
            <p>Insights</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.CALINSIGHTS} className="item">
            <div className="bg"></div>
            <div className="text">Insights</div>
          </Link>
        )
      }
    }
  }


  renderRoster() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.ROSTER} className={this.state.active === 'roster' ? 'tab active' : 'tab'}>
            <p>Roster</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.ROSTER} className="item">
            <div className="bg"></div>
            <div className="text">Roster</div>
          </Link>
        )
      }
    }
  }


  renderRecurring() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.RECURRING} className={this.state.active === 'recurring' ? 'tab active' : 'tab'}>
            <p>Recurring Templates</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.RECURRING} className="item">
            <div className="bg"></div>
            <div className="text">Recurring Templates</div>
          </Link>
        )
      }
    }
  }


  render() {
    if(this.state.type === 'header') {
      return (
        <div className="track">
          <Link to={ROUTES.CALENDAR} className={this.state.active === 'calendar' ? 'tab active' : 'tab'}>
            <p>Calendar</p>
            <div className="bg"></div>
          </Link>
          {this.renderRecurring()}
          {this.renderRoster()}
          {this.renderInsights()}
          <Link to={ROUTES.CALREPORT} className={this.state.active === 'reports' ? 'tab active' : 'tab'}>
            <p>Reports</p>
            <div className="bg"></div>
          </Link>
        </div>
      )
    } else {
      return (
        <div>
          <Link to={ROUTES.CALENDAR} className="item">
            <div className="bg"></div>
            <div className="text">Calendar</div>
          </Link>
          {this.renderRecurring()}
          {this.renderRoster()}
          {this.renderInsights()}
          <Link to={ROUTES.CALREPORT} className="item">
            <div className="bg"></div>
            <div className="text">Reports</div>
          </Link>
        </div>
      )
    }
  }
}


export default HeaderCalendar;