import React, { Component } from 'react';
import Moment from 'moment';

import HelperBilling from '../../helper/Billing';
import HelperBusiness from '../../helper/Business';
import Cal from '../../helper/Calendar';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import HelperEmptyImg from '../../helper/EmptyLarge';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import ListEmptySearch from '../../components/List/empty-search';
import ListAvatar from '../../components/List/avatar';
import ModalBilling from '../../components/Modals/billing';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class DebitsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: global.clients,
      clientsInactive: global.clientsInactive,
      payments: global.payments,
      products: global.products,
      groups: global.clientGroups,
      debits: [],
      trials: [],
      debitsList: [],
      dateRange: global.dateRange,
      search: '',
      currentGroup: '',
      stripe: global.userStripeConnect,
      bank: global.userStripeBank,
      showModalInfo: false,
      showModalLocked: false
    };
  }


  componentDidMount() {
    HelperPage.initialize('Memberships - PT Mate', ROUTES.DEBITS)
    HelperPage.checkMessage()
    this._isMounted = true
    
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.getClients())
    EventEmitter.subscribe('groupsLoaded', (event) => this.getClients())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('paymentsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.getClients())
    this.configureData()
  }

  
  componentWillUnmount() {
    this._isMounted = false
  }


  getClients() {
    if(this._isMounted) {
      var tmp1 = []
      var tmp2 = []
      var list = []
      if(global.loc === '') {
        for(var cl of global.clients) {
          list.push(cl.id)
        }
        for(var cl2 of global.clientsInactive) {
          list.push(cl2.id)
        }
      } else {
        for(var loc of global.locations) {
          if(loc.id === global.loc && loc.data.clients !== undefined) {
            list = loc.data.clients
          }
        }
      }
      for(var item1 of global.clients) {
        if(list.indexOf(item1.id) !== -1) {
          tmp1.push(item1)
        }
      }
      for(var item2 of global.clientsInactive) {
        if(list.indexOf(item2.id) !== -1) {
          tmp2.push(item2)
        }
      }
      this.setState({
        clients: tmp1,
        clientsInactive: tmp2,
        groups: global.clientGroups,
      }, () => {
        this.configureData()
        this.getDebits()
      });
    }
  }


  configureData() {
    if(this._isMounted) {
      var minDate = Cal.getDateRange()

      var list = []
      if(global.loc === '') {
        for(var cl of global.clients) {
          list.push(cl.id)
        }
        for(var cl2 of global.clientsInactive) {
          list.push(cl2.id)
        }
      } else {
        for(var loc of global.locations) {
          if(loc.id === global.loc && loc.data.clients !== undefined) {
            list = loc.data.clients
          }
        }
      }

      var tmp = []
      for(var item of global.payments) {
        var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
        if(item.data.timestamp !== undefined) {
          date = Moment(item.data.timestamp, 'X').format('X')
        }
        if(date > minDate && (item.data.name.indexOf('Invoice ') !== -1 || item.data.name.indexOf('Subscription ') !== -1) && list.indexOf(item.data.client) !== -1) {
          tmp.push(item)
        }
      }

      var tmp2 = []
      var tmpt = []
      for(var client of this.state.clients) {
        if(client.subscriptions !== null) {
          for(var item2 of client.subscriptions) {
            if(item2.id !== 'trial') {
              tmp2.push({
                id: item2.id,
                data: item2.data,
                client: client,
              })
            } else {
              tmpt.push({
                id: item2.id,
                data: item2.data,
                client: client,
              })
            }
          }
        }
      }
      for(var client2 of this.state.clientsInactive) {
        if(client2.subscriptions !== null) {
          for(var item3 of client2.subscriptions) {
            tmp2.push({
              id: item3.id,
              data: item3.data,
              client: client2,
            })
          }
        }
      }
      this.configureList(tmp2)

      this.setState({
        payments: tmp,
        debits: tmp2,
        trials: tmpt,
        stripe: global.userStripeConnect,
        bank: global.userStripeBank,
        products: global.products,
      })
    }
  }


  configureList(debits) {
    var tmp = []
    var tmp2 = []
    for(var item of debits) {
      var name = item.client.data.name
      if(this.state.search === '' || (name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && this.state.search !== '')) {
        tmp.push(item)
      }
    }

    if(this.state.currentGroup !== '') {
      var clients = []
      for(var gr of this.state.groups) {
        if(gr.id === this.state.currentGroup) {
          clients = gr.data.clients
        }
      }
      for(var tc of tmp) {
        for(var cc of clients) {
          if(cc === tc.client.id) {
            tmp2.push(tc)
          }
        }
      }
    } else {
      tmp2 = tmp
    }

    this.setState({
      debitsList: tmp
    })
  }


  getClient(id) {
    var item = null
    for(var client of this.state.clients) {
      if(client.id === id) {
        item = client
      }
    }
    for(var client2 of this.state.clientsInactive) {
      if(client2.id === id) {
        item = client2
      }
    }
    return item
  }


  getClientName(id) {
    var name = '<Deleted>'
    for(var client of this.state.clients) {
      if(client.id === id) {
        name = client.data.name
      }
    }
    for(var client2 of this.state.clientsInactive) {
      if(client2.id === id) {
        name = client2.data.name
      }
    }
    return name
  }


  getDebits() {
    var count = 0
    for(var item of this.state.clients) {
      if(item.subscriptions !== undefined) {
        count += item.subscriptions.length
      }
    }
    for(var item2 of this.state.clientsInactive) {
      if(item2.subscriptions !== undefined) {
        count += item2.subscriptions.length
      }
    }
    return count
  }


  getStatus(item) {
    if(item.data.pause !== undefined && item.data.pause !== '') {
      return (
        <span className="pill yellow">Paused</span>
      )
    } else {
      if(item.data.status === 'past_due') {
        return (
          <span className="pill yellow">Payment issue</span>
        )
      } else if(item.data.status === 'unpaid') {
        return (
          <span className="pill red">Issue</span>
        )
      } else if(item.data.status === 'canceled') {
        return (
          <span className="pill red">Cancelled</span>
        )
      } else {
        var start = Moment().add(-1, 'years').format('X')
        if(item.data.start !== undefined && item.data.start !== '') {
          start = Moment(item.data.start, 'X')
        }
        if(Moment(start, 'X').isAfter(Moment())) {
          return (
            <span className="pill">Starts {Moment(start, 'X').format('ddd, D MMM YYYY')}</span>
          )
        } else if(item.data.end !== undefined && item.data.end !== '') {
          if(Moment(item.data.end, 'X').isBefore(Moment())) {
            return (
              <span className="pill yellow">Cancelled {Moment(item.data.end, 'X').format('ddd, D MMM YYYY')}</span>
            )
          }
        }
      }
    }
  }



  // Interactive stuff ------------------------------------------------------------



  setDateRange() {
    this.setState({
        dateRange: global.dateRange
    }, () => {
        this.configureData()
    });
  }


  setGroup(event) {
    this.setState({
      currentGroup: event.target.value
    }, then => {
      this.configureList(this.state.debits)
    })
  }


  filterDebits = event => {
    this.setState({
      search: event.target.value
    }, () => {
      this.configureList(this.state.debits)
    });
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalInfo: false,
      showModalLocked: false,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderPaymentName(item) {
    var label = item.data.name
    if(item.data.subscription !== undefined && item.data.subscription !== '' && this.state.item !== null) {
      for(var sub of this.state.item) {
        if(sub.id === item.data.subscription) {
          label = sub.data.name
        }
      }
    }
    return label
  }


  renderTrials() {
    if(this.state.trials.length > 0) {
      var list = this.state.trials
      list.sort((a,b) => a.client.data.name.localeCompare(b.client.data.name))
      return (
        <div>
          <div className="listheader">
            <h3>Trials</h3>
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <ListAvatar 
              key={item.id}
              client={item.client}
              link={'/clients/'+item.client.id+'/billing'}
              columns={[{text: item.client.data.name, width: 4}, {text: 'Free group trial', width: 3}, {text: 'Ends '+Moment(item.data.next, 'X').format('ddd, D MMM YYYY'), width: 3}]}
            />
          ))}
        </div>
      )
    }
  }


  renderFamily(item) {
    if(item.client.data.linked !== undefined) {
      var label = ''
      if(item.client.data.linked.length > 0 && (item.data.account === '' || item.data.account === undefined)) {
        label = '(Family)'
      }
      if(item.client.data.linked.length > 0 && item.data.account !== item.client.id && item.data.account !== '' && item.data.account !== undefined) {
        for(var cl of this.state.clients) {
          if(cl.id === item.data.account) {
            label = '('+cl.data.name+')'
          }
        }
      }
      return label
    }
    
  }


  renderButtonDebitEmpty() {
    if(HelperBusiness.getRoleIncl('admin')) {
      if(global.products.length > 0) {
        return (
          <Link to={'/billing/direct-debits/new'} className={this.state.search === '' ? 'btn tertiary add width-12' : 'none'}>Set up a membership</Link>
        )
      } else {
        return (
          <button className='btn tertiary add width-12' onClick={() => this.setState({showModalLocked: true})}>Set up a membership</button>
        )
      }
      
    }
  }


  renderDebits() {
    if(this.state.debitsList.length === 0) {
      if(this.state.search === '') {
        return (
          <div className="empty large pt-60 clear">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('debits')}
            </div>
            <div className="data">
              <h3 className="mb-20">Set up memberships</h3>
              <p className="mb-20">Automate your billing with recurring<br/>payments charged automatically via Stripe.</p>
              {this.renderButtonDebitEmpty()}
            </div>
          </div>
        )
      } else {
        return <ListEmptySearch id='debits'/>
      }
    } else {
      var list = this.state.debitsList
      list.sort((a,b) => a.client.data.name.localeCompare(b.client.data.name))
      return (
        <div>
          {list.map(item => (
            <ListAvatar 
              key={item.id}
              client={item.client}
              link={'/billing/direct-debits/'+item.client.id+'/'+item.id}
              columns={[{text: item.client.data.name, width: 4}, {text: item.data.name, width: 3}, {text: <span>{global.curSym}{(item.data.price/100).toFixed(2)} per {item.data.billing} {this.getStatus(item)}</span>, width: 3}]}
            />
          ))}
          <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.DEBITNEW)}>Set up another membership</button>
        </div>
      )
    }
  }


  renderFilterSelect() {
    if(this.state.groups.length > 0 && HelperBusiness.getRoleIncl('admin')) {
      var list = this.state.groups
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <select className="light rgt" onChange={event => this.setGroup(event)} value={this.state.currentGroup}>
          <option value=''>All client groups</option>
          {list.map(item => (
            <option value={item.id} key={item.id}>{item.data.name}</option>
          ))}
        </select>
      )
    }
  }


  renderActions() {
    var item1 = {id: 'newdebit', link: ROUTES.DEBITNEW}
    var item2 = {id: 'newproduct', link: '/billing/products/new'}
    if(this.state.products.length === 0) {
      item1 = {id: 'newdebit', link: ''}
    }
    if(HelperBusiness.getRoleIncl('')) {
      //no change
    } else if(HelperBusiness.getRole('admin')) {
      item2 = {id: '', link: ''}
    } else if(HelperBusiness.getRole('trainer')) {
      item2 = {id: '', link: ''}
    } else {
      item1 = {id: 'report', link: ROUTES.REPORT}
      item2 = {id: '', link: ''}
    }
    return (
      <WidgetActions
        item1={item1} clickItem1={() => this.setState({showModalLocked: true})}
        item2={item2}
        item3={{id: '', link: ''}}
      />
    )
  }


  renderChargeMismatch() {
    if(global.userChargeSessions && (global.userStripeConnect === undefined || global.userStripeConnect === '')) {
      return (
        <div className="infobox large primary mb-30 mt-10">
          <p>Do you use another app for your billing? Update your settings to disable automatic tracking of your clients' available sessions <Link to={ROUTES.ADMINSETTINGS} className="btn tertiary small rgt">Manage</Link></p>
        </div>
      )
    } else if(!global.userChargeSessions && global.userStripeConnect !== undefined && global.userStripeConnect !== '') {
      return (
        <div className="infobox large primary mb-30 mt-10">
          <p>You have disabled automatic tracking of your clients' available sessions and packs <Link to={ROUTES.ADMINSETTINGS} className="btn tertiary small rgt">Manage</Link></p>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRole('assistant,assistant2,trainer')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='debits-locked' type='simple'/>
          </div>
          <Header type='simple' title='Billing & Finance'/>
          <Navigation active='billing' />
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            {this.renderChargeMismatch()}
            <div className="col-9">
              <WidgetOverview
                item1={{id: 'income', value: HelperBilling.getIncome(this.state.payments, '', this.state.dateRange), prev: HelperBilling.getIncomeDebitDifference('', this.state.dateRange)}}
                item2={{id: '', value: ''}}
                item3={{id: 'payments', value: this.state.payments.length, prev: HelperBilling.getPaymentsDebitDifference(this.state.dateRange)}}
                item4={{id: 'debits', value: this.getDebits(), prev: ''}}
              />
            </div>
            {this.renderActions()}
            <div className="col-12 clear">
              {this.renderTrials()}
              <div className="listheader">
                <div className="mt-5 lft">
                  <ListHeader id='debits' number={this.state.debitsList.length}/>
                </div>
                {this.renderFilterSelect()}
                <input type="text" className="search light large rgt" placeholder="Filter by client name" value={this.state.search} onChange={(val) => this.filterDebits(val)} />
                <div className="clear"></div>
              </div>
              {this.renderDebits()}
            </div>
            <div className="sv-80 clear"></div>
          </div>
          <Header type='billing' active='debits' selector={true} updateSelect={() => this.setDateRange()} onClickInfo={() => this.setState({showModalInfo: true})}/>
          <Navigation active='billing' />
          <ModalBilling type='info' show={this.state.showModalInfo} onHide={() => this.hideModals()}/>
          <ModalBilling type='locked2' show={this.state.showModalLocked} onHide={() => this.hideModals()}/>
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(DebitsPage));