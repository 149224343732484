import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import HelperEmpty from '../../helper/EmptyLarge';



const DataEmpty  = [
  {
    id: 'activity',
    title: 'Your activity feed',
    text: "This feed shows you an overview of what's happened\nin the last 14 days so you are on top of everything\nregarding your clients and business.",
    icon: 'activity',
  },
  {
    id: 'clientactivity',
    title: "Your client's activity",
    text: "This feed gives you an overview of everything that's\nhappened with your client in the last 14 days, such as\nbookings, payments, assessments and training.",
    icon: 'activity',
  },
  {
    id: 'community',
    title: 'Community',
    text: 'The community allows you to stay connected\nand share wins, post updates, images and comments.',
    icon: 'community'
  },
  {
    id: 'communityoff',
    title: "Your tribe's community",
    text: "Your community allows your tribe to stay connected\nand share wins, post updates, images and comments.",
    icon: 'community',
    button: 'Activate your community now',
    link: ROUTES.ADMINSETTINGS
  },
  {
    id: 'clients',
    title: 'No clients yet',
    text: 'Clients are the core of your business. You can manage\nevery aspect of your clients here, such as\nsessions, progress, nutrition and much more.',
    icon: 'clients',
    button: 'Add your first client',
    link: ROUTES.NEWCLIENT
  },
  {
    id: 'noclients',
    title: 'No clients found',
    text: 'No clients found that\nmatch your search criteria',
    icon: 'clients',
  },
  {
    id: 'clientgroups',
    title: 'No client groups yet',
    text: 'Groups allow you to organise your clients and manage\naccess to group sessions and session bookings.',
    icon: 'groups',
    button: 'Create your first client group',
    link: ROUTES.CLIENTSGROUPSNEW
  },
  {
    id: 'programs',
    title: 'Training programs',
    text: 'Create programs to run your client sessions\nand log their results, or send them to your clients\nso they can access them on the Member App.',
    icon: 'programs',
    button: 'Create your first program',
    link: ROUTES.NEWPROGRAM
  },
  {
    id: 'noprograms',
    title: 'No programs found',
    text: '',
    icon: 'programs',
  },
  {
    id: 'noprogramsyet1',
    title: 'No programs yet',
    text: 'Send training programs to your clients\nclient so they do them and log results on the Member App.\nNote that your client needs to use the Member App to receive programs.',
    icon: 'programs',
  },
  {
    id: 'noprogramsyet2',
    title: 'No programs yet',
    text: 'Send training programs to your clients\nclient so they do them and log results on the Member App.',
    icon: 'programs',
    button: 'Send a program now',
    link: '-func-'
  },
  {
    id: 'nonutrition',
    title: 'No active nutrition plans',
    text: 'Get started by creating your\nfirst nutrition plan for a client.',
    icon: 'nutrition',
    button: 'Create your first plan',
    link: ROUTES.NEWNUTRITION
  },
  {
    id: 'nutenabled',
    title: 'Nutrition not enabled',
    text: 'Nutrition capabilities are not yet\nenabled for this training space\nPlease contact your business owner or manager.',
    icon: 'nutrition',
  },
  {
    id: 'nonutrition2',
    title: 'No active nutrition plans',
    text: '',
    icon: 'nutrition',
  },
  {
    id: 'nohabits',
    title: "Track your clients' habits",
    text: "Set up various habits for your clients and track their compliance.\nYour clients log them on their Member App/Web Area.",
    icon: 'habits',
    button: 'Create your first habit',
    link: ROUTES.NEWHABIT
  },
  {
    id: 'nobenchmark',
    title: "No benchmark programs yet",
    text: "Use benchmark programs to\ntrack your client's progress.",
    icon: 'programs',
  },
  {
    id: 'nopayments',
    title: "No payments made",
    text: "Here you can see a list of all payments\nprocessed for all clients $var",
    icon: 'payments',
  },
  {
    id: 'nopayments2',
    title: "No payments found",
    text: "Here you can see a list of all payments\nprocessed for all clients $var",
    icon: 'payments',
  },
  {
    id: 'nopaymentsclient',
    title: "No payments made",
    text: "Here you can see a list of all payments\nprocessed for this client $var",
    icon: 'payments',
  },
  {
    id: 'noproducts',
    title: "No products yet",
    text: "Products allow you to charge your clients through\nthe app or set up recurring memberships.",
    icon: 'products',
    button: 'Create your first product',
    link: '/billing/products/new'
  },
  {
    id: 'noinvoices',
    title: "No invoices created",
    text: "Here you can see a list of all\ninvoices you created $var",
    icon: 'invoices',
  },
  {
    id: 'noinvoices2',
    title: "No invoices found",
    text: "Here you can see a list of all\ninvoices you created $var",
    icon: 'invoices',
  },
  {
    id: 'noexpenses',
    title: "No expenses logged",
    text: "Here you can see a list of all\nexpenses you logged $var",
    icon: 'expenses',
  },
  {
    id: 'noexpenses2',
    title: "No expenses found",
    text: "Here you can see a list of all\nexpenses you logged $var",
    icon: 'expenses',
  },
  {
    id: 'finreports',
    title: "Create financial reports",
    text: "You can create reports on your financials<br/>to help you see the bigger picture.\nYou need to upgrade your subscription to Pro or Business\nto be able access financial reports.",
    icon: 'reports',
    button: 'Upgrade your subscription',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'clientreports',
    title: "Create client reports",
    text: "You can create reports on your clients\nto help you see the bigger picture.\nYou need to upgrade your subscription to Pro or Business\nto be able access client reports.",
    icon: 'reports',
    button: 'Upgrade your subscription',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'admin',
    title: "More information available",
    text: "Upgrade your subscription to Unlimited pro\nto access more information and insights.",
    icon: 'insights',
    button: 'Upgrade your subscription',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'staff',
    title: "You don't have any staff",
    text: "Your staff helps you to run your business.\nAdd a staff member by using the buttons above.",
    icon: 'clients',
  },
  {
    id: 'noforms',
    title: "No forms yet",
    text: "You can use forms to create surveys, questionnaires\nand a Pre Exercise Questionnaire for your clients\nto fill out when connecting with you.",
    icon: 'documents',
    button: 'Create your first form',
    link: ROUTES.ADMINNEWDOC
  },
  {
    id: 'noleads',
    title: "No leads",
    text: "Here you can see requests from leads for a trial session\nmade on your public leads page.",
    icon: 'leads',
  },
  {
    id: 'noemails',
    title: "Email Marketing",
    text: "Create emails and communications\nyou can send to your clients and segment them\nusing a variety of filters.",
    icon: 'emails',
  },
  {
    id: 'recurring',
    title: "Automate your schedule",
    text: "Create both 1:1 and group recurring session\ntemplates to automate your training schedule.",
    icon: 'recurring',
    button: 'Create a recurring session template',
    link: ROUTES.NEWRECSESSION
  },
  {
    id: 'noarchive',
    title: "No sessions",
    text: "No archived sessions found",
    icon: 'newsession',
  },
  {
    id: 'clientgroups-locked',
    title: 'Not available',
    text: "You don't have access to client groups.",
    icon: 'groups',
  },
  {
    id: 'newclient-locked',
    title: 'Not available',
    text: "You don't have access to add clients.",
    icon: 'newsession',
  },
  {
    id: 'newimport-locked',
    title: 'Not available',
    text: "You don't have access to import clients.",
    icon: 'newsession',
  },
  {
    id: 'programs-locked',
    title: 'Not available',
    text: "You don't have access to the programs list.",
    icon: 'programs',
  },
  {
    id: 'trainingplans-locked',
    title: 'Not available',
    text: "You don't have access to training plans.",
    icon: 'plans',
  },
  {
    id: 'nutrition-locked',
    title: 'Not available',
    text: "You don't have access to Nutrition.",
    icon: 'nutrition',
  },
  {
    id: 'habits-locked',
    title: "Set and track your clients' habits",
    text: "Set up various habits for your clients and track their compliance.\nYou need to upgrade your subscription to Business\nto be able to create habits.",
    icon: 'habits',
    button: 'Upgrade your subscription',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'habits-locked2',
    title: 'Not available',
    text: "You don't have access to the Health tracker.",
    icon: 'habits',
  },
  {
    id: 'billing-locked',
    title: 'Not available',
    text: "You don't have access to Billing & Finance.",
    icon: 'newsession',
  },
  {
    id: 'debits-locked',
    title: 'Not available',
    text: "You don't have access to memberships.",
    icon: 'debits',
  },
  {
    id: 'products-locked',
    title: 'Not available',
    text: "You don't have access to products.",
    icon: 'products',
  },
  {
    id: 'expenses-locked',
    title: 'Not available',
    text: "You don't have access to expenses.",
    icon: 'expenses',
  },
  {
    id: 'invoices-locked',
    title: "Invoice your clients",
    text: "You can create and send invoices to your clients\nso they can pay them either on the App or Member Portal.\nYou need to upgrade your subscription to Business\nto be able to create invoices.",
    icon: 'invoices',
    button: 'Upgrade your subscription',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'invoices-locked2',
    title: 'Not available',
    text: "You don't have access to invoices.",
    icon: 'invoices',
  },
  {
    id: 'payment-locked',
    title: 'Not available',
    text: "You don't have access to processing payments.",
    icon: 'payments',
  },
  {
    id: 'admin-locked',
    title: 'Not available',
    text: "You don't have access to admin information.",
    icon: 'newsession',
  },
  {
    id: 'stats-locked',
    title: 'Not available',
    text: "You don't have access to\nAdmin stats and insights.",
    icon: 'insights',
  },
  {
    id: 'staff-locked',
    title: 'Not available',
    text: "You don't have access to staff.",
    icon: 'clients',
  },
  {
    id: 'forms-locked',
    title: 'Not available',
    text: "You don't have access to forms and documents.",
    icon: 'documents',
  },
  {
    id: 'emails-locked',
    title: 'Not available',
    text: "You don't have access to emails.",
    icon: 'emails',
  },
  {
    id: 'documents-locked',
    title: "Upgrade to Pro or Business",
    text: "You need to upgrade your subscription to\nPro or Business to be able to upload documents\nand store them with your clients.",
    icon: 'documents',
    button: 'Upgrade your subscription',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'leads-locked',
    title: 'Not available',
    text: "You don't have access to leads.",
    icon: 'leads',
  },
  {
    id: 'leads-locked2',
    title: 'Upgrade to Pro',
    text: "You need to upgrade your subscription to Pro or Business\nto access leads. People will then be able to book trial\nsessions without having to register with PT Mate.",
    icon: 'leads',
    button: 'Upgrade your subscription',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'settings-locked',
    title: 'Not available',
    text: "You don't have access to admin settings.",
    icon: 'settings',
  },
  {
    id: 'recurring-locked',
    title: 'Not available',
    text: "You don't have access to recurring sessions.",
    icon: 'recurring',
  },
  {
    id: 'roster-locked',
    title: 'Not available',
    text: "You don't have access to roster.",
    icon: 'roster',
  },
  {
    id: 'roster-locked2',
    title: 'Organise your schedule',
    text: "The Roster allows you to organise your schedule\nand bulk assign sessions to your staff.\nYou need to upgrade your subscription to Business\nto be able access Roster.",
    icon: 'roster',
    button: 'Upgrade your subscription',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'insights-locked',
    title: 'Not available',
    text: "You don't have access to insights.",
    icon: 'insights',
  },
  {
    id: 'insights-locked2',
    title: 'Understand your business',
    text: "Insights allow you to analyse your business\nand draw the right conclusions to grow.\nYou need to upgrade your subscription to Pro or Business\nto be able access insights.",
    icon: 'insights',
    button: 'Upgrade your subscription',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'reports-locked',
    title: 'Not available',
    text: "You don't have access to reports.",
    icon: 'reports',
  },
  {
    id: 'reports-locked2',
    title: 'Understand your business',
    text: "You can create reports on your clients\nto help you see the bigger picture.\nYou need to upgrade your subscription to Pro or Business\nto be able access calendar reports.",
    icon: 'reports',
    button: 'Upgrade your subscription',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'calendar-locked',
    title: 'Not available',
    text: "You don't have access to creating sessions.",
    icon: 'newsession',
  },
  {
    id: 'calendar-locked2',
    title: 'Not available',
    text: "You don't have access to creating events.",
    icon: 'newsession',
  },
  {
    id: 'sessions-locked',
    title: 'Not available',
    text: "You don't have access to sessions.",
    icon: 'newsession',
  },
  {
    id: 'family-locked',
    title: 'Not available',
    text: "You don't have access to family profiles.",
    icon: 'newsession',
  },
  {
    id: 'healthlog-locked',
    title: 'Not available',
    text: "You don't have access to health logs.",
    icon: 'newsession',
  },
  {
    id: 'results-locked',
    title: 'Not available',
    text: "You don't have access to session results.",
    icon: 'newsession',
  },
  {
    id: 'running-locked',
    title: 'Not available',
    text: "You don't have access to running sessions.",
    icon: 'newsession',
  },
  {
    id: 'benchmark-locked',
    title: 'Not available',
    text: "You don't have access to benchmark programs.",
    icon: 'programs',
  },
  {
    id: 'health-locked',
    title: 'Not available',
    text: "You don't have access to Health information.",
    icon: 'nutrition',
  },
  {
    id: 'programming-locked',
    title: 'Not available',
    text: "You don't have access to programming.",
    icon: 'programs',
  },
  {
    id: 'noplansclient1',
    title: 'No training plans yet',
    text: "Send custom training plans and to your\nclient so they can access them on the Member App.\nNote that your client needs to use the Member App.",
    icon: 'plans',
  },
  {
    id: 'noplansclient2',
    title: 'No training plans yet',
    text: "Send custom training plans and to your\nclient so they can access them on the Member App.",
    icon: 'plans',
    button: 'Send a training plan now',
    link: '-func-'
  },
  {
    id: 'chat',
    title: 'This is the beginning of your conversation',
    text: "Note that messages will\nbe deleted after 30 days",
    icon: 'community',
  },
  {
    id: 'affiliates',
    title: 'No affiliates yet',
    text: "Copy your Affiliate Link and share it with your network,\nor send an email to invite users to the platform.\nOnce they've signed up to a paid subscription plan,\nwe'll start paying you.",
    icon: 'clients',
  },
  {
    id: 'nolocations',
    title: 'No locations yet',
    text: "Create locations and assign your sessions\nand recurring sessions to them. Give your clients\nand staff access to specific locations.",
    icon: 'locations',
  },
  {
    id: 'locations-locked',
    title: 'Not available',
    text: "You don't have access to locations.",
    icon: 'locations',
  },
]



class ListEmpty extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      type: props.type ?? '',
      var: props.var
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      id: props.id,
      type: props.type ?? '',
      var: props.var
    }
  }


  render() {
    var data = null
    var text = ''
    for(var item of DataEmpty) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(data !== null) {
      text = data.text.replace('$var', this.state.var)
      if(this.state.type === 'simple') {
        return (
          <div className="empty large pt-60 clear">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmpty.renderImage(data.icon)}
            </div>
            <div className="data">
              <h3 className="mb-20">{data.title}</h3>
              <p className="mb-20">{text}</p>
            </div>
          </div>
        )
      } else {
        if(data.link === '-func-') {
          return (
            <div className="empty large pt-60 clear">
              <div className="illustration mb-30">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmpty.renderImage(data.icon)}
              </div>
              <div className="data">
                <h3 className="mb-20">{data.title}</h3>
                <p className="mb-20">{data.text}</p>
                <button className="btn tertiary width-12 add" onClick={() => this.props.clickMainButton()}>{data.button}</button>
              </div>
            </div>
          )
        } else {
          return (
            <div className="empty large pt-60 clear">
              <div className="illustration mb-30">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmpty.renderImage(data.icon)}
              </div>
              <div className="data">
                <h3 className="mb-20">{data.title}</h3>
                <p className="mb-20">{data.text}</p>
                <Link to={data.link} className="btn tertiary width-12 add">{data.button}</Link>
              </div>
            </div>
          )
        }
      }
    }
  }
}


export default ListEmpty;