import React, { Component } from 'react';
import { Link } from 'react-router-dom';



class ModalBilling extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      hidden: 'hidden',
      type: props.type ?? 'info',
      cycle: props.cycle ?? 'week',
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      show: props.show,
      type: props.type ?? 'info',
      cycle: props.cycle ?? 'week',
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  render() {
    if(this.state.show && this.state.type === 'info') {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">Payout schedule</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <p className="clear mb-20">Payouts usually happen weekly every Monday. However, Public Holidays might interfere with this schedule. Generally, payments made after Thursday 11:59pm (GMT) will be added to the payout the week after. Here's an example:</p>
            <img src="/img/payouts.png" alt="Payout schedule" style={{width: '100%', marginBottom: '10px'}}/>
            <p className="mb-40">Contact us at help@ptmate.net if you wish to change your payout schedule.</p>
            <button className="btn primary" onClick={() => this.hide()}>Got it</button>
          </div>
        </div>
      )
    } else if(this.state.show && this.state.type === 'locked') {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">No products yet</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <p className="clear mb-30">Please create a product first to charge your client. You can create packs of sessions, memberships and other products, such as merchandising, equipment, etc.</p>
            <Link to={'/billing/products/new'} className="btn primary">Create your first product</Link>
          </div>
        </div>
      )
    } else if(this.state.show && this.state.type === 'locked2') {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">No products yet</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <p className="clear mb-30">Please create a product first to be able to set up a membership.</p>
            <Link to={'/billing/products/new'} className="btn primary">Create your first product</Link>
          </div>
        </div>
      )
    } else if(this.state.show && this.state.type === 'debit') {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">Membership proration</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <p className="clear mb-20">Cancelling an active membership before it completes a full billing cycle will cause Stripe to automatically prorate the payment - this will result in you not receiving the full amount due.<br/><br/>To avoid this, be sure to set the end date according to the billing cycle of the membership product in order to receive the full amount for all payments.</p>
            <img src={'/img/debits-'+this.state.cycle+'.png'} alt="Payout schedule" style={{width: '100%', marginBottom: '30px'}} className="show-light"/>
            <img src={'/img/debits-'+this.state.cycle+'-dark.png'} alt="Payout schedule" style={{width: '100%', marginBottom: '30px'}} className="show-dark"/>
            <button className="btn primary" onClick={() => this.hide()}>Got it</button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default ModalBilling;