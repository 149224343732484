import React, { Component } from 'react';
import Firebase from 'firebase';

import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import HelperEmptyImg from '../../helper/EmptyLarge';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import ListEmptySearch from '../../components/List/empty-search';
import ListTraining from '../../components/List/training';
import ModalMessageSimple from '../../components/Modals/message-simple';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class PlansPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: global.clients,
      plans: global.plans,
      plansList: global.plans,
      exercises: global.exercises,
      show: 'all',
      search: '',
      maxPrograms: global.maxPrograms,
      name: '',
      weeks: 0,
      showModalPlan: false,
      showModalProgram: false
    };
  }


  componentDidMount() {
    HelperPage.initialize('Training Plans - PT Mate', ROUTES.PLANS)
    HelperPage.checkMessage()
    this._isMounted = true
    
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
    EventEmitter.subscribe('plansLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      this.setState({
        clients: global.clients,
        plans: global.plans,
        exercises: global.exercises,
        plansList: global.plans,
        maxPrograms: global.maxPrograms
      })
    }
  }



  // Interactive stuff ------------------------------------------------------------



  filterPlans = event => {
    this.setState({
      search: event.target.value
    }, () => {
      this.configureList()
    });
  }


  configureList() {
    var tmp = []
    for(var item of global.plans) {
      if((item.data.name.toLowerCase()).indexOf(this.state.search.toLowerCase()) !== -1 || this.state.search === '') {
        tmp.push(item)
      }
    }
    this.setState({
      plansList: tmp
    })
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalPlan: false,
      showModalProgram: false
    })
  }


  modalClick() {
    this.hideModals()
    this.props.history.push(ROUTES.SUBSCRIPTION)
  }



  // Create new plan ------------------------------------------------------------



  checkNewPlan() {
    if(global.maxPlans === 0) {
      this.setState({
        showModalPlan: true
      })
    } else {
      this.createNewPlan()
    }
  }


  createNewPlan() {
    var obj = Firebase.database().ref('/plans/'+global.uid).push()
    obj.set({
      name: 'New Training Plan',
      clients: 0,
      description: '',
      video: '',
      sessions: 0,
      uid: global.uid,
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', 'Plan successfully created')
      this.createWeek(1, obj.key)
      setTimeout(() => {
        this.props.history.push('/programming/edit-plan/'+obj.key)
      }, 300);
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'plan');
      }, 1000);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  createWeek(num, key) {
    Firebase.database().ref('/plans/'+global.uid+'/'+key+'/weeks').push({
      id: num,
      name: 'Week '+num,
      day1: '',
      day2: '',
      day3: '',
      day4: '',
      day5: '',
      day6: '',
      day7: '',
    })
  }


  getWeeks() {
    var num = 0
    var weeks = 0
    for(var item of this.state.plans) {
      num++
      weeks += item.weeks.length
    }
    var total = 0
    if(num > 0) {
      total = weeks/num
    }
    return parseInt(total)
  }


  getClientsNum() {
    var num = 0
    for(var item of this.state.plans) {
      if(item.data.clients !== undefined) {
        num += item.data.clients
      }
    }
    return num
  }



  // Display stuff ------------------------------------------------------------



  renderPlanButton() {
    if(global.maxPlans === 0) {
      return <button onClick={() => this.showOverlayMessage('plan')} className="btn tertiary width-12 add">Create your first training plan</button>
    } else {
      return <button onClick={() => this.showOverlayPlan()} className="btn tertiary width-12 add">Create your first training plan</button>
    }
  }


  renderPlans() {
    if(this.state.plansList.length === 0) {
      if(this.state.plans.length === 0) {
        return (
          <div className="empty large pt-60 clear">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('plans')}
            </div>
            <div className="data">
              <h3 className="mb-20">Custom training plans</h3>
              <p className="mb-20">Create personalised training plans and send them to your<br/>clients so they can access them on the Member App.</p>
              {this.renderPlanButton()}
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <div className="listheader">
              <div className="lft mt-5">
                <ListHeader id='plans' number={this.state.plansList.length}/>
              </div>
              <input type="text" className="search light large rgt" placeholder="Filter by name" value={this.state.search} onChange={(val) => this.filterPlans(val)} />
              <div className="clear"></div>
            </div>
            <ListEmptySearch id='plans'/>
          </div>
        )
      }
    } else {
      if(global.maxPlans === 0) {
        return (
          <div className="empty large pt-60 clear">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('plans')}
            </div>
            <div className="data">
              <h3 className="mb-20">Custom training plans</h3>
              <p className="mb-20">Upgrade your subscription to Unlimited Pro<br/>to be able to send plans to your clients.</p>
              {this.renderPlanButton()}
            </div>
          </div>
        )
      } else {
        var list = this.state.plansList
        list.sort((a, b) => a.data.name.localeCompare(b.data.name))
        return (
          <div>
            <div className="listheader">
              <div className="lft mt-5">
                <ListHeader id='plans' number={this.state.plansList.length}/>
              </div>
              <input type="text" className="search light large rgt" placeholder="Filter by name" value={this.state.search} onChange={(val) => this.filterPlans(val)} />
              <div className="clear"></div>
            </div>
            {list.map(item => (
              <ListTraining
                key={item.id}
                value={item.weeks.length}
                unit='w'
                color='primary'
                link={'/programming/plan/'+item.id}
                columns={[{text: item.data.name, width: 5}, {text: item.programs.length+(item.programs.length === 1 ? ' program' : ' programs'), width: 3}, {text: 'Sent to '+item.data.clients+(item.data.clients === 1 ? ' client' : ' clients'), width: 2}]}
              />
            ))}
            <button className="inline-add list mb-10" onClick={() => this.checkNewPlan()}>Add another training plan</button>
            <div className="space-80 clear"></div>
          </div>
        )
      }
    }
  }


  renderActions() {
    var item1 = {id: 'newplan', link: ''}
    var item2 = {id: 'newmovement', link: ROUTES.NEWEXERCISE}
    if(HelperBusiness.getRole('trainer')) {
      item2 = {id: '', link: ''}
    }
    return (
      <WidgetActions
        item1={item1} clickItem1={() => this.checkNewPlan()}
        item2={item2} clickItem2={() => this.setState({showModalProgram: true})}
        item3={{id: '', link: ''}}
      />
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <div className="col-9">
              <WidgetOverview showSelect={false}
                item1={{id: 'plans', value: this.state.plans.length, prev: ''}}
                item2={{id: 'planweeks', value: this.getWeeks(), prev: ''}}
                item3={{id: 'planclients', value: this.getClientsNum(), prev: ''}}
                item4={{id: '', value: ''}}
              />
            </div>
            {this.renderActions()}
            <div className="col-12 clear">
              {this.renderPlans()}
            </div>
          </div>
          <Header type='programming' active='plans'/>
          <Navigation active='programming' />
          <ModalMessageSimple type='planlimit' show={this.state.showModalPlan} onHide={() => this.hideModals()} clickMainButton={() => this.modalClick()}/>
          <ModalMessageSimple type='programlimit' show={this.state.showModalProgram} onHide={() => this.hideModals()} clickMainButton={() => this.modalClick()} var={global.maxPrograms}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <ListEmpty id='trainingplans-locked' type='simple'/>
          </div>
          <Header active='plans'/>
          <Navigation active='programming' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(PlansPage));