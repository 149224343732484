import React, { Component } from 'react';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import ListHeader from '../../components/List/header';
import ListIcon from '../../components/List/icon';
import ListEmpty from '../../components/List/empty';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class LocationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: global.locations,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Locations - PT Mate', ROUTES.LOCATIONS)
    HelperPage.checkMessage()
    this._isMounted = true
    
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationsLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      this.setState({
        locations: global.locations,
      })
    }
  }


  getClients(item) {
    var label = 'No clients'
    if(item.data.clients !== undefined) {
      label = item.data.clients.length+' clients'
      if(item.data.clients.length === 1) {
        label = '1 client'
      }
    }
    return label
  }


  getAverage() {
    var label = 0
    for(var item of global.locations) {
      if(item.data.clients !== undefined) {
        label += item.data.clients.length
      }
    }
    if(label !== 0) {
      label = (label/global.locations.length).toFixed(1)
    }
    return label
  }



  // Display stuff ------------------------------------------------------------



  renderList() {
    var list = this.state.locations
    return (
      <div>
        {list.map(item => (
          <ListIcon 
            key={item.id}
            icon='location'
            color='quarternary'
            link={'/admin/locations/location/'+item.id}
            columns={[{text: item.data.name, width: 7}, {text: this.getClients(item), width: 3}]}
          />
        ))}
        <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.NEWLOCATION)}>Add another location</button>
      </div>
    )
  }


  renderLocations() {
    if(this.state.locations.length > 0) {
      return (
        <div className="col-12">
          <div className="listheader">
            <ListHeader id='locations' number={this.state.locations.length}/>
            <div className="clear"></div>
          </div>
          {this.renderList()}
        </div>
      )
    } else {
      return (
        <div className="col-12">
          <ListEmpty id='nolocations' type='simple'/>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('admin')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <div className="col-9">
              <WidgetOverview
                item1={{id: 'locationstotal', value: this.state.locations.length, prev: ''}}
                item2={{id: 'recclients', value: global.clients.length, prev: ''}}
                item3={{id: 'locationsavg', value: this.getAverage(), prev: ''}}
                item4={{id: '', value: ''}}
              />
            </div>
            <WidgetActions
              item1={{id: 'newlocation', link: ROUTES.NEWLOCATION}}
              item2={{id: '', link: ''}}
              item3={{id: '', link: ''}}
            />
            {this.renderLocations()}
            <div className="clear sv-80"></div>
          </div>
          <Header type='admin' active='locations'/>
          <Navigation type='Admin' active='admin' />
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='locations-locked' type='simple'/>
          </div>
          <Header type='simple' title='Admin'/>
          <Navigation active='admin' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(LocationsPage));