import React, { Component } from 'react';
import ConnectorMember from '../../data/ConnectorMember';
import Firebase from 'firebase';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import InputNumber from '../../components/Form/input-number';

import { AuthUserContext, withAuthorization } from '../../components/Session';


class AccountSpacesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spaces: global.userSpaces,
      allSpaces: global.spaces,
      list: [],
      images: [],
      loaded: false,
      showAdd: false,
      search: '',
      showModalConnect: false,
      hiddenConnect: 'hidden',
      margin: 0,
      error: false,
      value: '',
      selected: null,
      img: null,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Spaces - PT Mate')
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('spacesLoaded', (event) => this.configureData())
    EventEmitter.subscribe('allSpacesLoaded', (event) => this.setSpaces())
    this.configureData()
    this.getImages()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      if(this.state.allSpaces.length === 0) {
        ConnectorMember.loadAllSpaces((result) => {})
      }
      this.setState({
        spaces: global.userSpaces
      })
    }
  }


  setSpaces() {
    if(this._isMounted) {
      this.setState({
        allSpaces: global.spaces,
      }, () => {
        this.getImages()
      })
    }
  }


  getImages() {
    var images = []
    for(var item of this.state.spaces) {
      images.push({id: item.id, image: '', img: ''})
    }

    for(var space of this.state.allSpaces) {
      for(var item2 of images) {
        if(item2.id === space.id && space.data.image !== '' && item2.image === '') {
          item2.image = space.data.image
          this.storeImage(item2, space)
        }
      }
    }

    this.setState({
      images: images
    })
  }


  storeImage(item2, space) {
    Firebase.storage().ref().child(space.data.image).getDownloadURL().then((url) => {
      item2.img = url
      this.setState({
        loaded: true
      })
    }).catch((error) => {
      item2.img = ''
    })
  }


  switchSpace(item) {
    Firebase.database().ref('/users/'+global.uidUser).update({
      user: item
    })
    window.location.pathname = '/overview'
  }


  onChange = event => {
    this.setState({
      search: event.target.value
    }, () => {
      this.configureList()
    });
  };


  configureList() {
    if(this.state.search !== '') {
      var tmp = []
      for(var item of this.state.allSpaces) {
        if(item.data.name !== undefined && item.data.owner !== undefined) {
          if(item.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 || item.data.owner.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
            tmp.push(item)
          }
        }
      }
      this.setState({
        list: tmp
      })
    } else {
      this.setState({
        list: []
      })
    }
  }



  // Modal stuff ------------------------------------------------------------



  showModalConnect(item) {
    this.setState({
      showModalConnect: true,
      selected: item,
      img: null,
      value: ''
    })
    if(global.connect.length === 0) {
      ConnectorMember.loadConnect((result) => {})
    }
    setTimeout(() => {
      const height = this.divElement.clientHeight
      if(this.state.selected.data.image !== '') {
        this.getImageConnect()
      }
      this.setState({
        hiddenConnect: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideModalConnect() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenConnect: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModalConnect: false});
    }, 500);
  }


  getImageConnect() {
    if(this.state.selected.data.image !== '') {
      Firebase.storage().ref().child(this.state.selected.data.image).getDownloadURL().then((url) => {
        this.setState({
          img: url
        })
      }).catch((error) => {
        // Handle any errors
      })
    }
  }


  renderImageConnect() {
    if(this.state.img !== null) {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.img+')'}}></div>
      )
    } else {
      if(this.state.img !== null) {
        return (
          <div className="avatar" style={{backgroundImage: 'url('+this.state.img+')'}}></div>
        )
      } else {
        var inits = ''
        let arr = this.state.selected.data.name.split(' ')
        if(arr.length > 1) {
          inits = arr[0].charAt(0)+arr[1].charAt(0)
        } else {
          inits = arr[0].charAt(0)
        }
        return (
          <div className="avatar">
            <p>{inits}</p>
          </div>
        )
      }
    }
  }


  renderModalConnect() {
    if(this.state.showModalConnect) {
      return (
        <div className={'overlay '+this.state.hiddenConnect}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Connect to space</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalConnect()}>Close</button>
            <div className="client static">
              {this.renderImageConnect()}
              <div className="text">
                <h4>{this.state.selected.data.name}</h4>
                <p>{this.state.selected.data.owner}<br/>{this.state.selected.data.email}</p>
              </div>
            </div>
            <div className="clear sv-20"></div>
            <p className="clear mb-30">Please enter the Connection PIN. Unless stated otherwise, this is usually the space's phone number.</p>
            <InputNumber label='Connection PIN' placeholder="Connection PIN" value={this.state.value} error={this.state.error} errormsg='PIN incorrect' onChange={(event) => this.setState({value: event})}/>
            <div className="sv-30"></div>
            <button className="btn primary" onClick={() => this.checkConnect()}>Connect</button>
          </div>
        </div>
      )
    }
  }


  checkConnect() {
    if(this.state.value === this.state.selected.data.pin) {
      this.connectSpace()
    } else {
      this.setState({
        error: true
      })
    }
  }


  connectSpace() {
    this.hideModalConnect()
    Firebase.database().ref('users/'+global.uidUser+'/spaces/'+this.state.selected.id).update({
      role: 'assistant2',
      status: 'active'
    })
    Firebase.database().ref('/spaces/'+this.state.selected.id+'/staff/'+global.uidUser).update({
      id: global.uidUser,
      status: 'active',
      role: 'assistant2',
      name: global.userName
    }).then(() => {
      EventEmitter.dispatch('showMessage', 'You successfully connected');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Display stuff ------------------------------------------------------------



  renderImage(item) {
    var image = ''
    for(var space of this.state.images) {
      if(space.id === item) {
        image = space.img
      }
    }
    if(image !== '') {
      return (
        <div className="avatar" style={{marginTop:3, backgroundImage: 'url('+image+')'}}> </div>
      )
    } else {
      return (
        <div className="avatar" style={{marginTop:3}}><img src='/img/list-staff.svg' alt="Space" style={{width:40,height:40}}/></div>
      )
    }
  }


  renderActive(item) {
    if(global.uid === item) {
      return (
        <p><span className="pill green">Current space</span></p>
      )
    } else {
      return (
        <p> </p>
      )
    }
  }


  renderButton(item) {
    if(global.uid !== item) {
      return (
        <p><button className="btn tertiary small rgt" onClick={() => this.switchSpace(item)} style={{marginTop:3}}>Switch to this space</button></p>
      )
    }
  }


  renderName(item) {
    var label = 'Space'
    for(var space of this.state.allSpaces) {
      if(space.id === item) {
        label = space.data.name
      }
    }
    return label
  }


  renderButtonAdd() {
    if(!this.state.showAdd) {
      return (
        <button className="btn tertiary add width-12" onClick={() => this.setState({showAdd: true})}>Add a space</button>
      )
    } else {
      return (
        <div>
          <button className="btn tertiary close width-12" onClick={() => this.setState({showAdd: false, search: ''})}>Cancel</button>
          <div className="sv-20 clear"></div>
          <div className="col-4">&nbsp;</div>
          <div className="col-4">
            <input type="text" placeholder="Search for a business space" value={this.state.search} onChange={this.onChange}/>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderResults() {
    if(this.state.search !== '') {
      return (
        <div>
          <div className="sv-40 clear"></div>
          {this.state.list.map(item => (
            <div className="box list simple highlight mb-10" onClick={() => this.showModalConnect(item)}>
              <div className="col-6">
                <p><strong>{item.data.name}</strong></p>
              </div>
              <div className="col-3">
                <p>{item.data.owner}</p>
              </div>
              <div className="col-3">
                <button className="btn tertiary small rgt add">Connect to space</button>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          <div className="listheader">
            <h3>Your spaces</h3>
            <div className="clear"></div>
          </div>
          <div className="box list simple highlight mb-10">
            <div className="col-7">
              {this.renderImage(global.uidUser)}
              <p className="lft" style={{marginTop: 0}}><strong>{this.renderName(global.uidUser)}</strong><br/>Your business</p>
            </div>
            <div className="col-2">
              {this.renderActive(global.uidUser)}
            </div>
            <div className="col-3">
            {this.renderButton(global.uidUser)}
            </div>
            <div className="clear"></div>
          </div>
          {this.state.spaces.map(item => (
            <div className="item" key={item.id}>
              <div className="box list simple highlight mb-10">
                <div className="col-7">
                  {this.renderImage(item.id)}
                  <p className="lft" style={{marginTop: 0}}><strong>{this.renderName(item.id)}</strong><br/>{item.data.role === 'assistant2' ? 'Assistant trainer' : 'Trainer'}</p>
                </div>
                <div className="col-2">
                  {this.renderActive(item.id)}
                </div>
                <div className="col-3">
                  {this.renderButton(item.id)}
                </div>
                <div className="clear"></div>
              </div>
            </div>
          ))}
          <div className="sv-30"></div>
          {this.renderButtonAdd()}
          {this.renderResults()}
        </div>
        <Header type='account' active='spaces'/>
        <Navigation active='' />
        {this.renderModalConnect()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(AccountSpacesPage));