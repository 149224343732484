import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import Firebase from 'firebase';
import Connector from '../../data/Connector';

import Cal from '../../helper/Calendar';
import HelperClients from '../../helper/Clients';
import HelperBusiness from '../../helper/Business';
import HelperPage from '../../helper/Page';
import * as ROUTES from '../../constants/routes';
import EventEmitter from '../../helper/Emitter';

import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import ListEmptySearch from '../../components/List/empty-search';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalMessageDouble from '../../components/Modals/message-double';
import ModalItems from '../../components/Modals/items';
import ModalTrial from '../../components/Modals/trial';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class ClientsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: global.sessions,
      archive: global.archive,
      sessionsTraining: global.sessionsTraining,
      archiveTraining: global.archiveTraining,
      clients: global.clients,
      clientsInactive: global.clientsInactive,
      clientsTrial: [],
      groups: global.clientGroups,
      currentGroup: '',
      dateRange: global.dateRange,
      list: 'cards',
      search: '',
      searchInactive: '',
      order: 'name',
      maxClients: global.maxClients,
      clientType: 'clients',
      client: null,
      trialDate: Moment().add(7, 'days').format('X'),
      trialer: null,
      invitees: 0,
      bookings: [],
      showModalMessage: false,
      showModalInvite: false,
      showModalNotes: false,
      showModalBookings: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Clients - PT Mate', ROUTES.CLIENTS)
    this._isMounted = true

    if(global.message !== '') {
      if(global.message === 'Client successfully created' && global.userSubStatus === 'trialing' && global.clients.length === 1) {
        setTimeout(() => {
          if(global.userTypes.indexOf('pt') !== -1) {
            window.Intercom('startTour', '320224')
          } else if(global.userTypes.indexOf('group') !== -1 && global.userTypes.indexOf('pt') === -1) {
            window.Intercom('startTour', '320225')
          } else {
            window.Intercom('startTour', '320230')
          }
          global.message = ''
        }, 1100);
      }
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
    }
    
    EventEmitter.subscribe('clientsLoaded', (event) => this.updateMax())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.setSessions())
    EventEmitter.subscribe('archiveLoaded', (event) => this.setState({archive: global.archive}))
    EventEmitter.subscribe('clientsLoaded', (event) => this.setClients())
    EventEmitter.subscribe('locationSet', (event) => this.setClients())
    if(this.state.sessionsTraining.length === 0) {
      setTimeout(() => {
        this.updateTraining();
      }, 5000);
    }
    if(!global.updatingRec) {
      Connector.checkRecurring()
    }
    EventEmitter.subscribe('groupsLoaded', (event) => this.setState({
      groups: global.clientGroups
    }))

    this.setClients()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  setClients() {
    if(this._isMounted) {
      var tmp1 = []
      var tmp2 = []
      var tmp11 = []
      var tmp21 = []
      for(var item of global.clients) {
        if(item.subscriptions.length === 0) {
          tmp1.push(item)
        } else {
          var trial = false
          for(var sub of item.subscriptions) {
            if(sub.id === 'trial' && global.userStripeConnect !== '' && global.userStripeConnect !== undefined) {
              trial = true
            }
          }
          if(trial) {
            tmp2.push(item)
          } else {
            tmp1.push(item)
          }
        }
      }

      // Check locations
      if(global.loc !== '') {
        for(var loc of global.locations) {
          if(loc.id === global.loc && loc.data.clients !== undefined) {
            for(var t1 of tmp1) {
              if(loc.data.clients.indexOf(t1.id) !== -1) {
                tmp11.push(t1)
              }
            }
            for(var t2 of tmp2) {
              if(loc.data.clients.indexOf(t2.id) !== -1) {
                tmp21.push(t2)
              }
            }
          }
        }
      } else {
        tmp11 = tmp1
        tmp21 = tmp2
      }
      
      this.setState({
        clients: tmp11,
        clientsInactive: global.clientsInactive,
        clientsTrial: tmp21,
      })
    }
  }


  setSessions() {
    if(this._isMounted) {
      var tmp = []
      for(var item of global.sessions) {
        tmp.push(item)
      }
      this.setState({
        sessions: tmp
      })
    }
  }


  updateTraining() {
    this.setState({
      sessionsTraining: global.sessionsTraining,
      archiveTraining: global.archiveTraining,
    })
    if(this.state.sessionsTraining.length === 0) {
      setTimeout(() => {
        this.updateTraining();
      }, 5000);
    }
  }


  updateMax() {
    if(this._isMounted) {
      this.setState({
        clients: global.clients,
        clientsInactive: global.clientsInactive,
        maxClients: global.maxClients
      })
    }
  }


  activateClients() {
    for(var item of this.state.clientsInactive) {
      Firebase.database().ref('/clients/'+global.uid+'/'+item.id).update({
        active: true
      })
    }
  }



  // Overview Calculations ------------------------------------------------------------



  getActiveClients() {
    var minDate = Moment(Cal.getDateRange(), 'X')
    var clients = []
    for(var item of this.state.sessions) {
      var add = false
      if(this.state.dateRange === '7days' || this.state.dateRange === '30days' || this.state.dateRange === '60days') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > minDate && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          add = true
        }
      } else if(this.state.dateRange === 'month') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          add = true
        }
      } else if(this.state.dateRange === 'week') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          add = true
        }
      } else {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') < Moment()) {
          add = true
        }
      }
      if(global.loc !== '' && item.data.location !== global.loc) {
        add = false
      }
      if(add && clients.indexOf(item.data.client) === -1) {
        if(!item.data.group) {
          clients.push(item.data.client)
        } else {
          if(item.data.clients !== undefined) {
            for(var client of item.data.clients) {
              if(clients.indexOf(client) === -1) {
                clients.push(client)
              } 
            }
          }
        }
        
      }
    }
    return clients.length
  }


  getActiveDifference(type) {
    var arr = ['', '+0']
    var today = Moment().format('X')
    var minDate = Cal.getDateRangeDiff()[0]
    var minDate2 = Cal.getDateRangeDiff()[1]
    var prevDate = Cal.getDateRangeDiff()[2]
    var clients1 = []
    var clients2 = []

    for(var item of this.state.sessions) {
      var add1 = false
      var add2 = false
      var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
      if(this.state.dateRange === '7days' || this.state.dateRange === '30days' || this.state.dateRange === '60days') {
        if(idate > minDate && idate < today) {
          add1 = true
        }
        if(idate > prevDate && idate < minDate2) {
          add2 = true
        }
      } else if(this.state.dateRange === 'month') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          add1 = true
        }
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().add(-1, 'month').format('MM/YYYY')) {
          add2 = true
        }
      } else if(this.state.dateRange === 'week') {
        idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
        if(idate > minDate && idate < today) {
          add1 = true
        }
        if(idate > prevDate && idate < minDate2) {
          add2 = true
        }
      }
      if(global.loc !== '' && item.data.location !== global.loc) {
        add1 = false
        add2 = false
      }

      if(add1 && clients1.indexOf(item.data.client) === -1) {
        if(!item.data.group) {
          clients1.push(item.data.client)
        } else {
          if(item.data.clients !== undefined) {
            for(var client of item.data.clients) {
              if(clients1.indexOf(client) === -1) {
                clients1.push(client)
              } 
            }
          }
        }
      }
      if(add2 && clients2.indexOf(item.data.client) === -1) {
        if(!item.data.group) {
          clients2.push(item.data.client)
        } else {
          if(item.data.clients !== undefined) {
            for(var client2 of item.data.clients) {
              if(clients2.indexOf(client2) === -1) {
                clients2.push(client2)
              } 
            }
          }
        }
      }
    }

    arr[1] = String(clients1.length-clients2.length)
    if(clients2.length < clients1.length) {
      arr[0] = 'green'
      arr[1] = '+'+arr[1]
    } else if(clients2.length > clients1.length) {
      arr[0] = 'red'
    }

    if(type === 'badge') {
      if(this.state.dateRange !== '60days') {
        return (
          <div className={'badge '+arr[0]} title='Change compared to previous period'>{arr[1]}</div>
        )
      } else {
        return (
          <div className={'badge'} title='Change compared to previous period'>N/A</div>
        )
      }
    } else {
      return clients2.length
    }
  }


  setView(type) {
    this.setState({list: type})
  }


  setGroup(event) {
    this.setState({
      currentGroup: event.target.value
    }, then => {
      this.combineFilters()
    })
  }


  filterClients = event => {
    this.setState({
      search: event.target.value
    }, then => {
      this.combineFilters()
    })
  };


  combineFilters() {
    var tmp = global.clients
    var tmp2 = []
    if(this.state.search !== '') {
      tmp = []
      for(var item of global.clients) {
        if(item.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
          tmp.push(item)
        }
      }
    }

    if(this.state.currentGroup !== '') {
      var clients = []
      for(var gr of this.state.groups) {
        if(gr.id === this.state.currentGroup) {
          clients = gr.data.clients
        }
      }
      for(var tc of tmp) {
        for(var cc of clients) {
          if(cc === tc.id) {
            tmp2.push(tc)
          }
        }
      }
    } else {
      tmp2 = tmp
    }
    this.setState({clients: tmp2})
  }


  filterClientsInactive = event => {
    this.setState({
      searchInactive: event.target.value
    }, then => {
      var tmp = global.clientsInactive
      if(this.state.searchInactive !== '') {
        tmp = []
        for(var item of global.clientsInactive) {
          if(item.data.name.toLowerCase().indexOf(this.state.searchInactive.toLowerCase()) !== -1) {
            tmp.push(item)
          }
        }
      }
      this.setState({
        clientsInactive: tmp
      })
    })
  };



  // Client Info ------------------------------------------------------------



  getSessionsClient(id) {
    var count = 0
    for(var item of this.state.sessions) {
      if(item.data.client === id){
        count++
      }
    }
    return count
  }


  getGroupClient(id) {
    var count = 0
    for(var item of this.state.sessions) {
      if(item.data.clients !== undefined) {
        if(item.data.clients.indexOf(id) !== -1) {
          count++
        }
      }
    }
    return count
  }


  selectClient(id) {
    global.currentClient = id;
  }


  getDebit(item) {
    var label = 'No membership'
    if(item.subscriptions.length > 0) {
      label = 'Active membership'
      for(var sub of item.subscriptions) {
        if(sub.id === 'trial') {
          label = 'Free trial'
        }
      }
    }
    if(item.data.parent !== undefined && item.data.parent !== '') {
      for(var cl of global.clients) {
        if(cl.id === item.data.parent) {
          label = 'Managed by '+cl.data.name
        }
      }
    }
    if(HelperBusiness.getRole('assistant,assistant2')) {
      label = 'DOB: '+Moment(item.data.birth, 'DD/MM/YYYY').format('D MMM YYYY')
    }
    return label
  }



  // Modals ------------------------------------------------------------



  showModalNotes(e, client) {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      showModalNotes: true,
      client: client,
    })
  }


  showModalInvite() {
    console.log('loading this one')
    var num = 0
    for(var cl of global.clients) {
      if(cl.data.uid === '' && cl.data.email !== '') {
        num++
      }
    }
    this.setState({
      invitees: num,
      showModalInvite: true,
      
    })
  }


  showModalTrial(client) {
    var tmp2 = Moment().add(7, 'days')
    for(var item of client.subscriptions) {
      if(item.data.name === 'Free trial') {
        tmp2 = Moment(item.data.next, 'X')
      }
    }
    this.setState({
      showModalTrial: true,
      trialDate: Moment(tmp2).format('X'),
      trialer: client,
    })
  }


  showModalBookings(e, client) {
    e.stopPropagation()
    e.preventDefault()
    var list = []
    for(var item of this.state.sessions) {
      if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment() && item.data.clients !== undefined) {
        if(item.data.group && item.data.clients.indexOf(client.id) !== -1) {
          list.push({data:{
            type: 'Booked in',
            name: item.data.client === '' ? 'Group session' : item.data.client,
            timestamp: item.data.timestamp,
            link: '/calendar/group-session/'+item.id
          }})
        }
      }
      if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment() && !item.data.group) {
        if(item.data.client === client.id) {
          list.push({data:{
            type: 'Scheduled',
            name: '1:1 session',
            timestamp: item.data.timestamp,
            link: '/calendar/session/'+item.id
          }})
        }
      }
    }
    this.setState({
      showModalBookings: true,
      client: client,
      bookings: list,
    })
  }


  hideModals() {
    this.setState({
      showModalMessage: false,
      showModalNotes: false,
      showModalInvite: false,
      showModalTrial: false,
      showModalBookings: false,
    })
  }


  modalClick() {
    this.hideModals()
    this.props.history.push(ROUTES.SUBSCRIPTION)
  }


  sendInvites() {
    var business = global.userBusiness
    if(global.userBusiness === "") {
      business = global.userName
    }
    this.hideModals()
    EventEmitter.dispatch('showMessage', 'Clients successfully invited');
    for(var cl of global.clients) {
      if(cl.data.uid === '' && cl.data.email !== '') {
        var callFunction1 = Firebase.functions().httpsCallable('sendClientInvite');
        callFunction1({email: cl.data.email, name: global.userName, user: global.uidUser, business: business, phone: global.userPhone}).then(function(result) {})

        if(cl.data.phone !== "") {
          Firebase.database().ref('/connect/'+cl.id).update({
            phone: cl.data.phone,
            name: global.userName,
            client: cl.id,
            space: global.uid,
            email: cl.data.email,
          })
        }
      }
    }
  }


  updateTrial() {
    this.hideModals()
    var edate = this.state.trialDate
    if(Moment(edate, 'X') < Moment()) {
      edate = Moment().add(1, 'days').format('X')
    }
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.trialer.id+'/subscriptions/trial').update({
      next: parseInt(edate)
    }).then(() => {
      EventEmitter.dispatch('showMessage', 'Trial successfully extended');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  cancelTrial() {
    this.hideModals()
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.trialer.id+'/subscriptions/trial').remove()
    EventEmitter.dispatch('showMessage', 'Client successfully converted');
  }


  cancelDeleteClient() {
    this.hideModals()
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({
      deleted: true
    })
    EventEmitter.dispatch('showMessage', 'Client successfully deleted');
  }



  // Display stuff ------------------------------------------------------------



  renderListheaderSelect() {
    if(this.state.groups.length > 0 && HelperBusiness.getRoleIncl('trainer,admin')) {
      var list = this.state.groups
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <select className="light rgt ml-30" onChange={event => this.setGroup(event)} value={this.state.currentGroup}>
          <option value=''>All client groups</option>
          {list.map(item => (
            <option value={item.id} key={item.id}>{item.data.name}</option>
          ))}
        </select>
      )
    }
  }


  renderListheader() {
    return (
      <div className="listheader col-12 clear mb-20-992">
        <div className="pt-10 lft">
          <ListHeader id='activeclients' number={this.state.clients.length}/>
        </div>
        <div className="toggles ml-30">
          <div title="Card view" className={this.state.list === 'cards' ? 'icontoggle cards active lft' : 'icontoggle cards lft'} onClick={() => this.setView('cards')}></div>
          <div title="List view" className={this.state.list === 'list' ? 'icontoggle list active lft' : 'icontoggle list lft'} onClick={() => this.setView('list')}></div>
        </div>
        {this.renderListheaderSelect()}
        <input type="text" className="light search rgt" placeholder="Filter by name" value={this.state.search} onChange={(val) => this.filterClients(val)} />
        
        <div className="clear"></div>
      </div>
    )
  }


  renderStatus(item) {
    var label = 'Family Account'
    if(item.data.restricted) {
      label = 'Family Account'
    }
    if(item.data.uid === '') {
      if(item.data.parent === undefined || item.data.parent === '') {
        return (
          <span className="pill grey">Not connected</span>
        )
      } else {
        return (
          <span className="pill blue">{label}</span>
        )
      }
    } else {
      if(item.data.parent === undefined || item.data.parent === '') {
        if(item.data.linked !== undefined) {
          if(item.data.linked.length > 0) {
            return (
              <span className="pill green">Family Main Account</span>
            )
          }
        } else {
          return (
            <span className="pill green">Connected</span>
          )
        }
      } else {
        return (
          <span className="pill green">{label}</span>
        )
      }
    }
  }


  renderListClass(item) {
    var label = 'col-4 col-6-1280'
    if(item.data.parent !== undefined && item.data.parent !== '') {
      label = 'col-4 col-6-1280 family'
    }
    return label
  }


  renderChild(item) {
    if(item.data.restricted) {
      return (
        <span className="pill">Child</span>
      )
    }
  }


  renderClients() {
    var list = this.state.clients
    list.sort((a, b) => a.data.name.localeCompare(b.data.name))
    
    if(this.state.list === 'cards') {
      return (
        <div>
          {list.map(item => (
          <div className="col-2 col-3-1280" key={item.id}>
            <Link to={'/clients/'+item.id+'/overview'} onClick={() => this.selectClient(item.id)}>
            <div className="box card highlight mb-20">
              {HelperClients.getImage(item)}
              <div className="sv-20"></div>
              <h4 className="mb-10">{item.data.name}{HelperClients.getDotBirthday(item)}</h4>
              <p className="mb-10">Phone: {HelperClients.getPhone(item.data.phone)}<br/>{this.getDebit(item)}</p>
              {this.renderStatus(item)}
			        <div className="sv-30"></div>
              <button className="btn tertiary center small width-12">View details</button>
            </div>
            </Link>
          </div>
          ))}
          <div className="col-2 col-3-1280">
            <button className="inline-add card mb-20" onClick={() => this.props.history.push(ROUTES.NEWCLIENT)}></button>
          </div>
        </div>
      )
    } else {
      var list2 = list
      for(var c1 of list2) {
        c1.name = c1.data.name
        if(c1.data.parent !== undefined && c1.data.parent !== '') {
          for(var c2 of list) {
            if(c2.id === c1.data.parent) {
              c1.name = c2.data.name+'1'
            }
          }
        }
      }
      list2.sort((a, b) => a.name.localeCompare(b.name))
      return (
        <div>
          <div className="clear"></div>
          {list2.map(item => (
            <div className={'box list simple highlight mb-10'+(item.data.parent !== undefined && item.data.parent !== '' ? ' family' : '')} key={item.id}>
              <Link to={'/clients/'+item.id+'/overview'} onClick={() => this.selectClient(item.id)}>
                <div className={this.renderListClass(item)}>
                  {HelperClients.getImage(item)}
                  <p className="lft">
                    <span className="lft"><strong>{item.data.name}</strong> {this.renderChild(item)}</span>
                    <div className="icontoggle bookings lft" onClick={(e) => this.showModalBookings(e, item)} title="View bookings"></div>
                    <div className="icontoggle notes lft" onClick={(e) => this.showModalNotes(e, item)} title="View notes"></div></p>
                    {HelperClients.getDotBirthday(item)}
                </div>
                <div className="col-2 center">
                  <p title="1:1 sessions">{Cal.getAmountClient(this.state.sessions, this.state.archive, 'sessions', this.state.dateRange, item.id)} 1:1</p>
                </div>
                <div className="col-2 center">
                  <p title="Group sessions">{Cal.getAmountClient(this.state.sessions, this.state.archive, 'group', this.state.dateRange, item.id)} Group</p>
                </div>
                <div className="col-2 center hide-1280">
                  <p title="Training sessions">{Cal.getAmountClient(this.state.sessionsTraining, this.state.archiveTraining, 'sessions', this.state.dateRange, item.data.uid)} Training</p>
                </div>
                <div className="col-2">
                  <button className="btn tertiary small rgt">View details</button>
                </div>
              </Link>
            </div>
          ))}
          <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.NEWCLIENT)}>Add another client</button>
        </div>
      )
    }
  }


  renderExpiry(item) {
    var date = Moment()
    for(var sub of item.subscriptions) {
      if(sub.id === 'trial') {
        date = Moment(sub.data.next, 'X')
      }
    }
    if(Moment(date, 'X') > Moment()) {
      if(Moment(date, 'X') < Moment().add(2, 'days')) {
        return (
          <p>Ends {Moment(date, 'X').format('D MMM YYYY')} <span className="pill yellow">Ends soon</span></p>
        )
      } else {
        return (
          <p>Ends {Moment(date, 'X').format('D MMM YYYY')}</p>
        )
      }
      
    } else {
      return (
        <p>Ended {Moment(date, 'X').format('D MMM YYYY')} <span className="pill red">Ended</span></p>
      )
    }
  }


  renderTrialContent(item) {
    var date = Moment()
    for(var sub of item.subscriptions) {
      if(sub.id === 'trial') {
        date = Moment(sub.data.next, 'X')
      }
    }
    if(Moment(date, 'X') > Moment()) {
      return (
        <Link to={'/clients/'+item.id+'/overview'} onClick={() => this.selectClient(item.id)}>
          <div className="col-5">
            {HelperClients.getImage(item)}
            <p className="lft"><strong>{item.data.name}</strong></p>
            {HelperClients.getDotBirthday(item)}
          </div>
          <div className="col-5 center">
            {this.renderExpiry(item)}
          </div>
          <div className="col-2">
            <button className="btn tertiary small rgt">View details</button>
          </div>
        </Link>
      )
    } else {
      return (
        <div onClick={() => this.showModalTrial(item)}>
          <div className="col-5 col-7-1280">
            {HelperClients.getImage(item)}
            <p className="lft"><strong>{item.data.name}</strong></p>
            {HelperClients.getDotBirthday(item)}
          </div>
          <div className="col-5 col-3-1280 center">
            {this.renderExpiry(item)}
          </div>
          <div className="col-2">
            <button className="btn tertiary small rgt">Manage</button>
          </div>
        </div>
      )
    }
  }


  renderTrial() {
    var total = global.clientsInactive.length+global.clients.length
    if(this.state.clientsTrial.length > 0 && (total < global.maxClients || global.maxClients === 0)) {
      var list = this.state.clientsTrial
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className="clear">
          <div className="listheader mt-10">
            <h3>Clients on a free trial</h3>
          </div>
          <div className="clear"></div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id}>
              {this.renderTrialContent(item)}
            </div>
          ))}
        </div>
      )
    }
  }


  renderInactiveDate(item) {
    var label = ''
    for(var note of item.history) {
      if(note.data.title === 'Inactive') {
        label = 'since '+Moment(note.data.date, 'DD/MM/YYYY HH:mm').format('D MMM YYYY')
      }
    }
    return label
  }


  renderInactive() {
    var total = global.clientsInactive.length+global.clients.length
    if(global.clientsInactive.length > 0 && (total < global.maxClients || global.maxClients === 0) && HelperBusiness.getRoleIncl('trainer')) {
      var list = this.state.clientsInactive
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      if(list.length === 0) {
        return (
          <div className="clear">
            <div className="listheader">
              <div className="lft mt-10">
                <ListHeader id='inactiveclients' number={list.length}/>
              </div>
              <input type="text" className="light search rgt" placeholder="Filter by name" value={this.state.searchInactive} onChange={(val) => this.filterClientsInactive(val)} />
              <div className="clear"></div>
            </div>
            <div className="clear"></div>
            <ListEmptySearch id='clients'/>
          </div>
        )
      } else {
        return (
          <div className="clear">
            <div className="listheader">
              <div className="lft mt-10">
                <ListHeader id='inactiveclients' number={list.length}/>
              </div>
              <input type="text" className="light search rgt" placeholder="Filter by name" value={this.state.searchInactive} onChange={(val) => this.filterClientsInactive(val)} />
              <div className="clear"></div>
            </div>
            <div className="clear"></div>
            {list.map(item => (
              <div className="box list simple highlight mb-10" key={item.id}>
                <Link to={'/clients/'+item.id+'/details'} onClick={() => this.selectClient(item.id)}>
                  <div className="col-5">
                    {HelperClients.getImage(item)}
                    <p className="lft ml-10"><strong>{item.data.name}</strong></p>
                    {HelperClients.getDotBirthday(item)}
                  </div>
                  <div className="col-5 center">
                    <p>Inactive {this.renderInactiveDate(item)}</p>
                  </div>
                  <div className="col-2">
                    <button className="btn tertiary small rgt">View details</button>
                  </div>
                </Link>
              </div>
            ))}
            <div className="sv-20"></div>
            <button className="btn tertiary width-12" onClick={() => this.activateClients()}>Reactivate all</button>
          </div>
        )
      }
    } else if(global.clientsInactive.length > 0 && total > global.maxClients && global.maxClients !== 0 && HelperBusiness.getRoleIncl('trainer')) {
      var list2 = this.state.clientsInactive
      list2.sort((a, b) => a.data.name.localeCompare(b.data.name))
      if(list2.length === 0) {
        return (
          <div className="clear">
            <div className="listheader">
              <div className="lft mt-10">
                <ListHeader id='inactiveclients' number={list.length}/>
              </div>
              <input type="text" className="light search rgt" placeholder="Filter by name" value={this.state.searchInactive} onChange={(val) => this.filterClientsInactive(val)} />
              <div className="clear"></div>
            </div>
            <div className="clear"></div>
            <ListEmptySearch id='clients'/>
          </div>
        )
      } else {
        return (
          <div className="clear">
            <div className="listheader">
              <div className="lft mt-10">
                <ListHeader id='inactiveclients' number={list.length}/>
              </div>
              <input type="text" className="light search rgt" placeholder="Filter by name" value={this.state.searchInactive} onChange={(val) => this.filterClientsInactive(val)} />
              <div className="clear"></div>
            </div>
            <div className="clear"></div>
            {list2.map(item => (
              <div className="box list simple highlight mb-10" key={item.id} style={{opacity: 0.4}}>
                <Link to={ROUTES.SUBSCRIPTION}>
                <div className="col-4">
                  {HelperClients.getImage(item)}
                  <p className="lft ml-10"><strong>{item.data.name}</strong></p>
                  {HelperClients.getDotBirthday(item)}
                </div>
                <div className="col-8">
                  <p className="right">Upgrade your subscription to reactivate this client</p>
                </div>
                </Link>
              </div>
            ))}
          <div className="sv-40"></div>
          <p className="small center secondary">You've reached your clients maximum. Your current plan allows you to have {global.maxClients} clients & {global.maxPrograms} programs).<br/><Link to={ROUTES.SUBSCRIPTION}>Check your subscription now</Link></p>
          </div>
        )
      }
    }
  }


  // Content area

  renderBottom() {
    if(this.state.clients.length === 0) {
      if(global.clients.length === 0 && this.state.clientsInactive.length === 0 && this.state.clientsTrial.length === 0) {
        return (
          <ListEmpty id='clients'/>
        )
      } else {
        return (
          <div className="clear mb-20-992">
            {this.renderListheader()}
            <ListEmptySearch id='clients'/>
            {this.renderTrial()}
            {this.renderInactive()}
            <div className="sv-40 clear"></div>
          </div>
        )
      }
      
    } else {
      return (
        <div>
          <div className="clear mb-20-992"></div>
          {this.renderListheader()}
          {this.renderClients()}
          <div className="clear"></div>
          {this.renderTrial()}
          {this.renderInactive()}
          <div className="sv-40 clear"></div>
        </div>
      )
      
    }
  }


  renderActions() {
    var item1 = {id: 'newclient', link: ''}
    var item2 = {id: 'importclient', link: ''}
    var item3 = {id: '11session', link: ROUTES.NEWSESSION}
    if(HelperBusiness.getRoleIncl('')) {
      if(this.state.clients.length < this.state.maxClients || this.state.maxClients === 0) {
        item1 = {id: 'newclient', link: ROUTES.NEWCLIENT}
        item2 = {id: 'importclient', link: ROUTES.NEWCLIENTIMPORT}
        item3 = {id: '11session', link: ROUTES.NEWSESSION}
        var num = 0
        for(var cl of global.clients) {
          if(cl.data.uid === '' && cl.data.email !== '') {
            num++
          }
        }
        if(num > 0 && global.clients.length > 5) {
          item3 = {id: 'emailinvites', link: ''}
        }
      }
    } else if(HelperBusiness.getRole('trainer')) {
      item1 = {id: 'newclient', link: ROUTES.NEWCLIENT}
      item2 = {id: 'session', link: ROUTES.NEWSESSION}
      item3 = ''
    } else if(HelperBusiness.getRole('admin')) {
      item1 = {id: 'newclient', link: ROUTES.NEWCLIENT}
      item2 = ''
      item3 = ''
    } else if(HelperBusiness.getRole('cs')) {
      item1 = {id: 'report', link: ROUTES.CLIENTSREPORT}
      item2 = ''
      item3 = ''
    }
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      return (
        <WidgetActions
          item1={item1} clickItem1={() => this.setState({showModalMessage: true})}
          item2={item2} clickItem2={() => this.setState({showModalMessage: true})}
          item3={item3} clickItem3={() => this.showModalInvite()}
        />
      )
    }
  }


  renderOverview() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      return (
        <div className="col-9">
          <WidgetOverview
            item1={{id: 'activeclients', value: this.getActiveClients(), prev: this.getActiveDifference()}}
            item2={{id: 'sessions', value: Cal.getAmount(this.state.sessions, this.state.archive, 'sessions', this.state.dateRange), prev: Cal.getDifference(this.state.sessions, false, this.state.dateRange, 'num')}}
            item3={{id: 'group', value: Cal.getAmount(this.state.sessions, this.state.archive, 'group', this.state.dateRange), prev: Cal.getDifference(this.state.sessions, true, this.state.dateRange, 'num')}}
            item4={{id: 'training', value: Cal.getAmount(this.state.sessionsTraining, this.state.archiveTraining, 'sessions', this.state.dateRange), prev: Cal.getDifference(this.state.sessionsTraining, false, this.state.dateRange, 'num')}}
          />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              {this.renderOverview()}
              {this.renderActions()}
              {this.renderBottom()}
            </div>
            <Header type='clients' active='list' selector={true} updateSelect={() => this.setState({dateRange: global.dateRange})}/>
            <Navigation active='clients'/>
            <ModalTrial type={'list'} show={this.state.showModalTrial} date={this.state.trialDate} onHide={() => this.hideModals()} onChange={(event) => this.setState({trialDate: event})} clickMainButton={() => this.updateTrial()} clickSecondaryButton={() => this.cancelTrial()} clickTertiaryButton={() => this.cancelDeleteTrial()}/>
            <ModalMessageSimple type='clientlimit' show={this.state.showModalMessage} onHide={() => this.hideModals()} clickMainButton={() => this.modalClick()} var={global.maxClients}/>
            <ModalMessageDouble type='clientinvite' show={this.state.showModalInvite} var={this.state.invitees} onHide={() => this.hideModals()} clickMainButton={() => this.sendInvites()} clickSecondaryButton={() => this.hideModals()}/>
            <ModalItems show={this.state.showModalNotes} type={'notes'} title='Client notes' items={this.state.client === null ? [] : this.state.client.history} empty='No notes yet' onHide={() => this.hideModals()}/>
            <ModalItems show={this.state.showModalBookings} type={'bookings'} title='Upcoming sessions' items={this.state.bookings} empty='No upcoming bookings' onHide={() => this.hideModals()}/>
          </div>
        )}
      </AuthUserContext.Consumer>
    )
  }

}



const condition = authUser => !!authUser;

export default withAuthorization(condition)(ClientsPage);