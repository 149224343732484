import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import Connector from '../../data/Connector';
import HelperBusiness from '../../helper/Business';
import HelperPro from '../../helper/Programming';
import HelperPage from '../../helper/Page';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import InfoData from '../../components/Form/info';
import ListEmpty from '../../components/List/empty';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { gql } from "@apollo/client";
import ModalMessageSimple from '../../components/Modals/message-simple';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class ClientsAsessmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      image: '',
      aid: '',
      values: ['', '', '', '', '', '', '', '', '', '', '', ''],
      valuesBlood: ['',''],
      valueCustom: '',
      file: null,
      fileDisplay: null,
      showOverlay: false,
      hidden: 'hidden',
      deleted: false,
      back: '',
      nutrition: '',
      blood: '',
      image2: '',
      image3: '',
      image4: '',
      file2: null,
      fileDisplay2: null,
      file3: null,
      fileDisplay3: null,
      file4: null,
      fileDisplay4: null,
      showOverlayImages: false,
      hiddenImages: 'hidden',
      list: [],
      item1: null,
      item2: null,
      image11: '',
      image12: '',
      image13: '',
      image14: '',
      image21: '',
      image22: '',
      image23: '',
      image24: '',
      showModalDelete: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Client Details - Health Log - PT Mate')
    HelperPage.checkMessage()
    this._isMounted = true

    var tmp = this.props.location.pathname.split('/')
    this.setState({
      aid: tmp[tmp.length-1],
      id: tmp[tmp.length-3],
    })
    global.currentClient = tmp[tmp.length-3]
    EventEmitter.subscribe('clientsLoaded', (event) => {
      if(!this.state.deleted) {
        this.getClient(tmp[tmp.length-3], tmp[tmp.length-1])
      }
    })
    var arr = this.props.location.pathname.split('/')
    if(this.state.id === '') {
      global.currentClient = arr[arr.length-2]
    }
    if(!this.state.deleted) {
      this.getClient(tmp[tmp.length-3], tmp[tmp.length-1])
    }
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getClient(id, aid) {
    if(this._isMounted) {
      var tmp3 = null
      var tmps = []
      var tmpb = ''
      var tmpbv = ['','']
      var tmpc = ''
      var nutrition = ''
      var arr = this.props.location.pathname.split('/')
      global.currentClient = arr[arr.length-3]
      for(var client of global.clients) {
        if(client.id === global.currentClient) {
          for(var ass of client.assessments) {
            tmps.push(ass)
            if(ass.id === aid) {
              tmp3 = ass
            }
          }

          if(tmp3 !== null) {
            if(tmp3.data.image !== '') {
              this.getImage(tmp3.data.image, 'image')
            }
            if(tmp3.data.image2 !== '' && tmp3.data.image2 !== undefined) {
              this.getImage(tmp3.data.image2, 'image2')
            }
            if(tmp3.data.image3 !== '' && tmp3.data.image3 !== undefined) {
              this.getImage(tmp3.data.image3, 'image3')
            }
            if(tmp3.data.image4 !== '' && tmp3.data.image4 !== undefined) {
              this.getImage(tmp3.data.image4, 'image4')
            }
            if(tmp3.data.blood1 !== undefined && tmp3.data.blood1 !== '') {
              tmpb = ass.data.blood1+' / '
              tmpbv[0] = ass.data.blood1
            } else {
              tmpb = '- / '
            }
            if(tmp3.data.blood2 !== undefined && tmp3.data.blood2 !== '') {
              tmpb += tmp3.data.blood2
              tmpbv[1] = tmp3.data.blood2
            } else {
              tmpb += '-'
            }
            if(tmp3.data.custom !== undefined) {
              tmpc = tmp3.data.custom
            }

            var heart = 0
            if(tmp3.data.heart !== undefined) {
              heart = tmp3.data.heart
            }
            var vtmp = [HelperPro.showWeight(tmp3.data.weight), tmp3.data.fat, tmp3.data.notes, this.setInitValue(tmp3.data.neck), this.setInitValue(tmp3.data.chest), this.setInitValue(tmp3.data.abdomen), this.setInitValue(tmp3.data.hip), this.setInitValue(tmp3.data.armR), this.setInitValue(tmp3.data.armL), this.setInitValue(tmp3.data.thighR), this.setInitValue(tmp3.data.thighL), heart]
            for(var i=0; i<vtmp.length; i++) {
              if(vtmp[i] === 0 && i !== 2) {
                vtmp[i] = ''
              }
            }

            if(client.data.nutritionId !== undefined) {
              nutrition = client.data.nutritionId
            }

            this.setState({
              id: client.id,
              item: tmp3,
              name: client.data.name,
              values: vtmp,
              back: '/clients/'+client.id+'/health',
              nutrition: nutrition,
              list: tmps,
              item1: tmp3,
              item2: tmps[0],
              blood: tmpb,
              custom: tmpc,
              valuesBlood: tmpbv,
              valueCustom: tmpc,
            });
          }
          
        }
      }
    }
  }


  setInitValue(val) {
    var label = val
    if(global.spaceLbs && val !== '0' && val !== 0) {
      label = (val/2.54).toFixed(1)
    }
    return label
  }


  getImage(image, variable) {
    Firebase.storage().ref().child(image).getDownloadURL().then((url) => {
      this.setState({[variable]: url})
    }).catch((error) => {
      // Handle any errors
    })
  }



  // Client update stuff ------------------------------------------------------------



  displayImage(file, image, display) {
    if(file === null) {
      if(image !== '') {
        return (
          <div className="inner" style={{backgroundImage: 'url('+image+')'}}> </div>
        )
      } else {
        return (
          <div className="inner"></div>
        )
      }
      
    } else {
      return (
        <div className="inner" style={{backgroundImage: 'url('+display+')'}}> </div>
      )
    }
  }


  deleteNutrition() {
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation updateWeight($id: Int!) {
        delete_weights_by_pk(id: $id) {
          id
        }
      }
    `;

    client.mutate({
      variables: { id: parseInt(this.state.item.data.nutrition) },
      mutation
    }).then((result) => {
      //console.log(result)
      console.log('done')
    }).catch((error) => {
      console.log(error);
    });
  }



  // Images overlay ------------------------------------------------------------



  showOverlayImages() {
    this.setState({showOverlayImages: true})
    setTimeout(() => {
      this.setState({
        hiddenImages: '',
        image11: '',
        image12: '',
        image13: '',
        image14: '',
        image21: '',
        image22: '',
        image23: '',
        image24: '',
      }, () => {
        this.getOverlayImage(this.state.item1.data.image, 'image11')
        this.getOverlayImage(this.state.item1.data.image2, 'image12')
        this.getOverlayImage(this.state.item1.data.image3, 'image13')
        this.getOverlayImage(this.state.item1.data.image4, 'image14')
        this.getOverlayImage(this.state.item2.data.image, 'image11')
        this.getOverlayImage(this.state.item2.data.image2, 'image12')
        this.getOverlayImage(this.state.item2.data.image3, 'image13')
        this.getOverlayImage(this.state.item2.data.image4, 'image14')
      });
    }, 100);
  }


  hideOverlayImages() {
    this.setState({hiddenImages: 'hidden'})
    setTimeout(() => {
      this.setState({
        showOverlayImages: false,
      });
    }, 500);
  }


  getOverlayImage(image, variable) {
    if(image !== '' && image !== undefined) {
      Firebase.storage().ref().child(image).getDownloadURL().then((url) => {
        this.setState({[variable]: url})
      }).catch((error) => {
        // Handle any errors
      })
    }
  }


  renderOverlayImage(image) {
    if(image !== '' && image !== undefined) {
      return (
        <div className="image" style={{backgroundImage: 'url('+image+')'}}> </div>
      )
    } else {
      return (
        <div className="image"></div>
      )
    }
  }


  renderOverlayImages() {
    if(this.state.showOverlayImages) {
      var list = this.state.list
      list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
      return (
        <div className={'overlay dark '+this.state.hiddenImages}   >
          <div className="btn tertiary close rgt mt-20 mr-20" onClick={() => this.hideOverlayImages()}>Close</div>
          <div className="side clear">
          <select value={this.state.item1.id} onChange={event => this.setItem(event, 'item1')}>
              {list.map(item => (
                <option value={item.id} key={item}>{Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('dddd, D MMMM YYYY')}</option>
              ))}
            </select>
            <div className="col-3 clear">
              {this.renderOverlayImage(this.state.image11)}
            </div>
            <div className="col-3">
              {this.renderOverlayImage(this.state.image12)}
            </div>
            <div className="col-3">
              {this.renderOverlayImage(this.state.image13)}
            </div>
            <div className="col-3">
              {this.renderOverlayImage(this.state.image14)}
            </div>
          </div>
          <div className="side">
            <select value={this.state.item2.id} onChange={event => this.setItem(event, 'item2')}>
              {list.map(item => (
                <option value={item.id} key={item.id}>{Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('dddd, D MMMM YYYY')}</option>
              ))}
            </select>
            <div className="col-3 clear">
              {this.renderOverlayImage(this.state.image21)}
            </div>
            <div className="col-3">
              {this.renderOverlayImage(this.state.image22)}
            </div>
            <div className="col-3">
              {this.renderOverlayImage(this.state.image23)}
            </div>
            <div className="col-3">
              {this.renderOverlayImage(this.state.image24)}
            </div>
          </div>
        </div>
      )
    }
  }


  setItem(event, item) {
    var tmp = null
    for(var ass of this.state.list) {
      if(ass.id === event.target.value) {
        tmp = ass
      }
    }
    
    if(item === 'item1') {
      this.setState({
        item1: tmp,
        image11: '',
        image12: '',
        image13: '',
        image14: '',
      }, () => {
        this.getOverlayImage(this.state.item1.data.image, 'image11')
        this.getOverlayImage(this.state.item1.data.image2, 'image12')
        this.getOverlayImage(this.state.item1.data.image3, 'image13')
        this.getOverlayImage(this.state.item1.data.image4, 'image14')
      })
    } else {
      this.setState({
        item2: tmp,
        image21: '',
        image22: '',
        image23: '',
        image24: '',
      }, () => {
        this.getOverlayImage(this.state.item2.data.image, 'image21')
        this.getOverlayImage(this.state.item2.data.image2, 'image22')
        this.getOverlayImage(this.state.item2.data.image3, 'image23')
        this.getOverlayImage(this.state.item2.data.image4, 'image24')
      })
    }
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalDelete: false,
    })
  }


  deleteAssessment() {
    this.hideModals()
    if(this.state.item.data.image !== '') {
      Firebase.storage().ref('images/assessments/'+global.uid+'/'+this.state.aid+'.jpg').delete()
    }
    if(this.state.item.data.image2 !== '' && this.state.item.data.image2 !== undefined) {
      Firebase.storage().ref('images/assessments/'+global.uid+'/'+this.state.aid+'-2.jpg').delete()
    }
    if(this.state.item.data.image3 !== '' && this.state.item.data.image3 !== undefined) {
      Firebase.storage().ref('images/assessments/'+global.uid+'/'+this.state.aid+'-3.jpg').delete()
    }
    if(this.state.item.data.image4 !== '' && this.state.item.data.image4 !== undefined) {
      Firebase.storage().ref('images/assessments/'+global.uid+'/'+this.state.aid+'-4.jpg').delete()
    }
    if(this.state.item.data.nutrition !== undefined && this.state.item.data.nutrition !== "") {
      this.deleteNutrition()
    }
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id+'/assessments/'+this.state.aid).remove()
    global.message = 'Health log entry successfully deleted'
    this.props.history.push(this.state.back)
  }



  // Display stuff ------------------------------------------------------------



  renderTitle() {
    if(this.state.item !== null) {
      return Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('D MMM YYYY - hh:mm a')
    }
  }


  renderProgress() {
    if(this.state.list.length > 1) {
      return (
        <button className="btn tertiary small rgt" onClick={() => this.showOverlayImages()}>View progress</button>
      )
    }
  }


  renderMeasure(value) {
    var label = value.toFixed(1)
    if(global.spaceLbs) {
      label = (value/2.54).toFixed(1)
    }
    return label
  }


  renderButtons() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className="col-12">
          <div className="width-6">
            <Link to={'/clients/'+this.state.id+'/edit-assessment/'+this.state.item.id} className="btn primary mb-20">Edit details</Link>
            <button className="btn tertiary close width-12" onClick={() => this.setState({showModalDelete: true})}>Delete log entry</button>
          </div>
        </div>
      )
    }
  }


  renderInfoCustom() {
    if(this.state.custom !== '') {
      return <InfoData label='Notes' value={this.state.item.data.notes === '' ? '-' : this.state.item.data.notes}/>
    }
  }


  renderInfo() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-sidebar">
          <div className="col-6 mt-20">
            <h3 className="mb-20 lft">Images</h3>
            {this.renderProgress()}
            <div className="col-6 mb-20 clear">
              <div className="healthlog image mb-10">
                {this.displayImage(this.state.file, this.state.image, this.state.fileDisplay)}
              </div>
              <label>Front</label>
            </div>
            <div className="col-6 mb-20">
              <div className="healthlog image mb-10">
                {this.displayImage(this.state.file2, this.state.image2, this.state.fileDisplay2)}
              </div>
              <label>Back</label>
            </div>
            <div className="col-6 mb-20">
            <div className="healthlog image mb-10">
                {this.displayImage(this.state.file3, this.state.image3, this.state.fileDisplay3)}
              </div>
              <label>Left</label>
            </div>
            <div className="col-6 mb-20">
            <div className="healthlog image mb-10">
                {this.displayImage(this.state.file4, this.state.image4, this.state.fileDisplay4)}
              </div>
              <label>Right</label>
            </div>
          </div>

          <div className="col-6 pl-30 mt-20">
            <h3 className="mb-20">Overview</h3>
            <div className="clear"></div>
            <InfoData label='Weight' value={this.state.item.data.weight === 0 ? '-' : HelperPro.showWeight(this.state.item.data.weight.toFixed(1))+' '+(global.spaceLbs ? 'lb' : 'kg')}/>
            <InfoData label='Body fat percentage' value={this.state.item.data.fat === 0 ? '-' : this.state.item.data.fat.toFixed(1)+'%'}/>
            <InfoData label='Resting heart rate' value={(this.state.item.data.heart === undefined || this.state.item.data.heart === 0) ? '-' : this.state.item.data.heart.toFixed(0)+' bpm'}/>
            <InfoData label='Blood pressure' value={(this.state.blood === undefined || this.state.blood === '') ? '-' : this.state.blood}/>
            {this.renderInfoCustom()}
            <InfoData label='Notes' value={this.state.item.data.notes === '' ? '-' : this.state.item.data.notes}/>
            <div className="sv-30"></div>
            <div className="healthlog segmental">
              <h3 className="mb-20">Segmental Circumference</h3>
              <div className="info clear">
                <div className="circle lft mr-10">1</div>
                  <div className="main">
                  <label>Neck</label>
                  <p>{this.state.item.data.neck === 0 ? '-' : this.renderMeasure(this.state.item.data.neck)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
              <div className="info clear">
                <div className="circle lft mr-10">2</div>
                  <div className="main">
                  <label>Chest</label>
                  <p>{this.state.item.data.chest === 0 ? '-' : this.renderMeasure(this.state.item.data.chest)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
              <div className="info clear">
                <div className="circle lft mr-10">3</div>
                  <div className="main">
                  <label>Abdomen</label>
                  <p>{this.state.item.data.abdomen === 0 ? '-' : this.renderMeasure(this.state.item.data.abdomen)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
              <div className="info clear">
                <div className="circle lft mr-10">4</div>
                  <div className="main">
                  <label>Hip</label>
                  <p>{this.state.item.data.hip === 0 ? '-' : this.renderMeasure(this.state.item.data.hip)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
              <div className="info clear">
                <div className="circle lft mr-10">5</div>
                  <div className="main">
                  <label>Right arm</label>
                  <p>{this.state.item.data.armR === 0 ? '-' : this.renderMeasure(this.state.item.data.armR)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
              <div className="info clear">
                <div className="circle lft mr-10">6</div>
                  <div className="main">
                  <label>Left arm</label>
                  <p>{this.state.item.data.armL === 0 ? '-' : this.renderMeasure(this.state.item.data.armL)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
              <div className="info clear">
                <div className="circle lft mr-10">7</div>
                  <div className="main">
                  <label>Right thigh</label>
                  <p>{this.state.item.data.thighR === 0 ? '-' : this.renderMeasure(this.state.item.data.thighR)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
              <div className="info clear">
                <div className="circle lft mr-10">8</div>
                  <div className="main">
                  <label>Left thigh</label>
                  <p>{this.state.item.data.thighL === 0 ? '-' : this.renderMeasure(this.state.item.data.thighL)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
            </div>

          </div>
          
          

          <div className="clear sv-40"></div>
          {this.renderButtons()}
          <div className="clear sv-40"></div>
        </div>
        <Header title={'Health Log '+this.renderTitle()} link={'/clients/'+this.state.id+'/health'}/>
        <Navigation active='clients' />
        {this.renderOverlayImages()}
        <ModalMessageSimple type='deletelog' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteAssessment()}/>
      </div>
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      if(this.state.item !== null) {
        return this.renderInfo()
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <Header title={'Health Log '+this.renderTitle()} link={global.linkCli}/>
            <Navigation active='clients' />
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='healthlog-locked' type='simple'/>
          </div>
          <Header title={'Health Log '+this.renderTitle()} link={global.linkCli}/>
          <Navigation active='clients' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(ClientsAsessmentPage));