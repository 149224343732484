import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import Connector from '../../data/Connector';
import HelperBusiness from '../../helper/Business';
import HelperPage from '../../helper/Page';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { gql } from "@apollo/client";

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import InputClient from '../../components/Form/client';
import InputDate from '../../components/Form/input-date';
import Select from '../../components/Form/select';
import FormCard from '../../components/Form/card';
import Loader from '../../components/Form/loader';
import ModalClients from '../../components/Modals/clients';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class NewNutritionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      back: ROUTES.NUTRITION,
      start: Moment().endOf('week').format('YYYY-MM-DD'),
      end: Moment().endOf('week').add(14, 'days').format('YYYY-MM-DD'),
      client: null,
      showModalClients: false,
      token: '',
      preselected: false,
      values: ['', '', '', '', ''],
      error: [false, false, false, false, false],
      loading: false,
      locked: false,
      dates: [
        {value: Moment().endOf('week').format('YYYY-MM-DD'), name: 'This Sunday, '+Moment().endOf('week').format('D MMM YYYY')},
        {value: Moment().endOf('week').add(7, 'days').format('YYYY-MM-DD'), name: 'Next Sunday, '+Moment().endOf('week').add(7, 'days').format('D MMM YYYY')},
        {value: Moment().endOf('week').add(14, 'days').format('YYYY-MM-DD'), name: 'Sunday, '+Moment().endOf('week').add(14, 'days').format('D MMM YYYY')},
        {value: Moment().endOf('week').add(21, 'days').format('YYYY-MM-DD'), name: 'Sunday, '+Moment().endOf('week').add(21, 'days').format('D MMM YYYY')},
      ]
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('New Nutrition Plan - PT Mate')
    this._isMounted = true
    
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    global.nutritionData = null
    this.configureData()

    EventEmitter.subscribe('logLoaded', (event) => {
      if(this.state.loading) {
        this.checkLog()
      }
    })
    EventEmitter.subscribe('tokenLoaded', (event) => this.setState({token: global.clientToken}))
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var tmp = []
      var back = ROUTES.NUTRITION
      var preselected = false
      for(var item of global.clients) {
        if(item.data.uid !== '' || (item.data.parent !== undefined && item.data.parent !== '')) {
          if(item.data.nutritionEnd === undefined || Moment(item.data.nutritionEnd, 'X') < Moment()) {
            tmp.push(item)
          }
        }
      }
      var arr = this.props.location.pathname.split('/')
      if(arr[arr.length-1] !== 'new-client') {
        for(var item2 of tmp) {
          if(item2.id === arr[arr.length-1]) {
            this.modalClick(item2)
            preselected = true
            back = '/clients/'+item2.id+'/health'
          }
        }
      }
      this.setState({
        clients: tmp,
        clientsList: tmp,
        preselected: preselected,
        back: back,
      })
    }
  }


  checkLog() {
    for(var item of global.userLog) {
      if(item.data.title === 'subupdateerror' || item.data.title === 'subbillingerror' || item.data.title === 'subcancelerror') {
        EventEmitter.dispatch('showMessageError', item.data.message);
        this.setState({loading: false})
        Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove()
      }
      if(item.data.title === 'cardnew') {
        this.setState({
          loading: false,
        })
        Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove()
        this.createPlan()
      }
      if(item.data.title === 'cardnewerror') {
        this.setState({
          loading: false,
        })
        EventEmitter.dispatch('showMessageError', item.data.messsage);
        Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove()
      }
    }
  }


  updateValues(val, key) {
    this.setState({
      [key]: val
    });
  }


  checkCreatePlan() {
    if(global.userCard !== '') {
      this.createPlan()
    } else {
      this.setState({error: [false, false, false, false, false]})
      var tmp = [false, false, false, false, false]
      var passed = true
      if(this.state.values[0] === '') {
        tmp[0] = true
        passed = false
      }
      if(this.state.values[1] === '') {
        tmp[1] = true
        passed = false;
      }
      if(this.state.values[2] === '') {
        tmp[2] = true
        passed = false;
      }
      if(this.state.values[3] === '') {
        tmp[3] = true
        passed = false;
      }
      if(this.state.values[4] === '') {
        tmp[4] = true
        passed = false;
      }
      this.setState({error: tmp})
      if(passed) {
        // Execute payment
        this.setState({
          loading: true,
        })
        var callFunction = Firebase.functions().httpsCallable('replaceCard');
        callFunction({customer: global.userStripe, name: this.state.values[0], number: this.state.values[1], month: this.state.values[2], year: this.state.values[3], cvc: this.state.values[4], current: ''}).then(function(result) {
          //var sanitizedMessage = result.data.text;
        });
      }
    }
  }


  createPlan() {
    if(!this.state.locked) {
      if(this.state.client.data.nutritionId === undefined) {
        var arr = this.state.client.data.name.split(' ')
        var last = '-'
        if(arr.length > 1) {
          last = arr[1]
        }
        if(last === '' || last === ' ') {
          last = '-'
        }
        this.setState({
          locked: true
        })
        this.createUser(arr[0], last)
      } else {
        this.updateUser()
      }
    }
    
  }


  createUser(first, last) {
    var email = this.state.client.data.email+this.state.client.id

    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation CreateUser($first: String!, $last: String!, $email: String!, $date: timestamp!, $start: timestamp!, $end: timestamp!) {
        insert_users_one(object: { email: $email, first_name: $first, last_name: $last, created_at: $date, updated_at: $date, current_period_start: $start, current_period_end: $end, activate_member: true, form_status: "registered"}) {
          id
          current_period_start
          current_period_end
          form_status
        }
      }
    `;

    client.mutate({
      variables: { first: first, last: last, email: email, date: Moment().format('YYYY-MM-DD'), start: Moment(this.state.start, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss'), end: Moment(this.state.end, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss') },
      mutation
    }).then((result) => {
      //console.log(result)
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id).update({
        nutritionId: String(result.data.insert_users_one.id),
        nutritionStart: parseInt(Moment(result.data.insert_users_one.current_period_start, 'YYYY-MM-DD').format('X')),
        nutritionEnd: parseInt(Moment(result.data.insert_users_one.current_period_end, 'YYYY-MM-DD').format('X')),
        nutritionStatus: 'registered'
      })
      this.createUserGym(result.data.insert_users_one.id)
    }).catch((error) => {
      console.log(error);
      this.setState({
        locked: false
      })
    });
  }


  createUserGym(id) {
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation CreateGymUser($id: Int!, $date: timestamp!, $gym: Int!) {
        insert_gyms_users_one(object: { gym_id: $gym, user_id: $id, created_at: $date, updated_at: $date}) {
          id
        }
      }
    `;

    client.mutate({
      variables: { id: id, gym: parseInt(global.userNutritionGym), date: Moment().format('YYYY-MM-DD') },
      mutation
    }).then((result) => {
      global.message = 'Nutrition plan successfully created'
      let text = 'Great news! '+global.userName+' just set up a nutrition plan for you, starting Sunday '+Moment(this.state.start, 'YYYY-MM-DD').format('D MMMM')+'. Healthy delicious meals are coming your way!'
      if(this.state.token !== '') {
        Connector.sendPushNotification(this.state.token, 'Welcome to Nutrition', text, 'nutrition', '')
      }
      // Add email here
      this.props.history.push(this.state.back)
    }).catch((error) => {
      console.log(error);
      EventEmitter.dispatch('showMessageError', error);
      this.setState({
        locked: false
      })
    });
  }


  updateUser() {
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation UpdateDates($id: Int!, $start: timestamp!, $end: timestamp!) {
        update_users(where: { id: { _eq: $id} }, _set: { current_period_start: $start, current_period_end: $end }) {
          returning {
            current_period_start
            current_period_end
          }
        }
      }
    `;

    client.mutate({
      variables: { id: parseInt(this.state.client.data.nutritionId), start: Moment(this.state.start, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss'), end: Moment(this.state.end, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss') },
      mutation
    }).then((result) => {
      var cps = Moment(result.data.update_users.returning[0].current_period_start, 'YYYY-MM-DDTHH:mm:ss.SSS')
      var cpe = Moment(result.data.update_users.returning[0].current_period_end, 'YYYY-MM-DDTHH:mm:ss.SSS')
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id).update({
        nutritionStart: parseInt(cps.format('X')),
        nutritionEnd: parseInt(cpe.format('X'))
      })
      global.message = 'Nutrition plan successfully created'
      let text = 'Great news!'+global.userName+' just set up a nutrition plan for you, starting Sunday '+Moment(this.state.start, 'YYYY-MM-DD').format('D MMMM')+'. Healthy delicious meals are coming your way!'
      if(this.state.token !== '') {
        Connector.sendPushNotification(this.state.token, global.userName, text, 'nutrition', '')
      }
      this.createUserPlan()
    }).catch((error) => {
      console.log(error);
      EventEmitter.dispatch('showMessageError', error);
      this.setState({
        locked: false
      })
    });
  }


  createUserPlan() {
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation CreatePlan($id: Int!) {
        createPlan(userId: $id) {
          response
        }
      }
    `;
    client.mutate({
      variables: { id: parseInt(this.state.client.data.nutritionId) },
      mutation
    })
    this.props.history.push(this.state.back)
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalClients: false
    })
  }


  modalClick(item) {
    this.hideModals()
    this.setState({
      client: item,
    })
    Connector.getClientToken(item.data.uid)
  }



  // Display stuff ------------------------------------------------------------



  renderCard() {
    if(global.userCard !== '') {
      return (
        <div className="infobox primary mb-30">
          <p>Your saved card will be charged each Sunday based on your total volume that week ({global.curSym}1.10 per client per day).</p>
        </div>
      )
    } else {
      if(HelperBusiness.getRoleIncl('')) {
        return (
          <div>
            <h3 className="mb-20 pt-20" style={{textAlign: 'center'}}>Please provide a payment method</h3>
            <FormCard values={this.state.values} error={this.state.error} onChange={(event) => this.setState({values: event})}/>
            <div className="clear sv-20"></div>
            <div className="infobox primary mb-30">
              <p>You will be charged each Sunday based on your total volume that week ({global.curSym}1.10 per client per day).</p>
            </div>
          </div>
        )
      } else {
        return (
          <div className="form">
            <h4>No payment method</h4>
            <p>The business owner or manager needs to provide a valid payment method.</p>
          </div>
        )
      }
      
    }
  }


  renderBottom() {
    if(Moment(this.state.end, 'YYYY-MM-DD') > Moment() && Moment(this.state.end, 'YYYY-MM-DD') > Moment(this.state.start, 'YYYY-MM-DD') && this.state.client !== null) {
      var days = Moment(this.state.end, 'YYYY-MM-DD').diff(Moment(this.state.start, 'YYYY-MM-DD'), 'days')
      var cost = days*1.1
      var label = cost.toFixed(2)
      var end = Moment(global.userNutritionDate, 'X').add(14, 'days')
      if(global.userNutritionTrial !== "") {
        end = Moment(global.userNutritionTrial, 'YYYY-MM-DDTHH:mm:ss')
      }
      if(Moment(this.state.start, 'YYYY-MM-DD') < end) {
        var diff = Moment(global.userNutritionDate, 'X').add(14, 'days').diff(Moment(this.state.start, 'YYYY-MM-DD'), 'days')
        cost -= 1.1*diff
        label = cost.toFixed(2)+' (Includes '+diff+' days of free trial)'
        if(cost < 0) {
          label = '0.00 (Covered by your free trial)'
        }
      }
      return (
        <div>
          <div className="info">
            <label>Duration</label>
            <p className="large">{days} day{days === 1 ? '' : 's'}</p>
            <p className="clear small">Estimated cost: {global.curSym}{label}</p>
          </div>
          {this.renderCard()}
          {this.renderButton()}
        </div>
      )
    }
  }


  renderButton() {
    if(Moment(this.state.end, 'YYYY-MM-DD') > Moment(this.state.start, 'YYYY-MM-DD')) {
      return (
        <button className="btn primary" onClick={() => this.checkCreatePlan()}>Create Nutrition Plan</button>
      )
    }
  }


  renderPlanInfo() {
    if(this.state.client !== null) {
      if(this.state.client.data.nutritionId !== '' && this.state.client.data.nutritionId !== undefined) {
        return (
          <div className="infobox">
            <h5>Note</h5>
            <p>Your client will see the first meal plan and shopping list on Sunday, {Moment(this.state.start, 'YYYY-MM-DD').format('D MMMM')} in the Member App. The meal plan itself starts on Monday, {Moment(this.state.start, 'YYYY-MM-DD').add(1, 'days').format('D MMMM')}.</p>
          </div>
        )
      } else {
        return (
          <div className="infobox">
            <h5>Note</h5>
            <p>Your client will have access to Nutrition and set their preferences in the Member App on Sunday, {Moment(this.state.start, 'YYYY-MM-DD').format('D MMMM')}. Their meal plan starts the day after setting them (Monday, {Moment(this.state.start, 'YYYY-MM-DD').add(1, 'days').format('D MMMM')}.</p>
          </div>
        )
      }
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('admin')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
              <div className="box">
                <InputClient id='nutrition' client={this.state.client} presel={this.state.preselected} clickElement={() => this.setState({showModalClients: true})}/>
                <div className="sv-20"></div>
                <Select label='Start date' value={this.state.start} values={this.state.dates} onChange={(event) => this.updateValues(event, 'start')}/>
                <InputDate label="End date" value={Moment(this.state.end, 'YYYY-MM-DD').format('YYYY-MM-DD')} format='YYYY-MM-DD' min={Moment().format('YYYY-MM-DD')} onChange={(event) => this.updateValues(event, 'end')}/>
                {this.renderBottom()}
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id='newnutrition'/>
                {this.renderPlanInfo()}
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>
          <div className="btn-news" onClick={() => window.Intercom('startTour', '320981')}>Show me around</div>
          <Header title='New Nutrition Plan' link={this.state.back}/>
          <Navigation active='health' />
          <ModalClients show={this.state.showModalClients} onHide={() => this.hideModals()} clickElement={(event) => this.modalClick(event)}/>
          <Loader show={this.state.loading}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='nutrition-locked' type='simple'/>
          </div>
          <Header title='New Nutrition Plan' link={'/overview'}/>
          <Navigation active='health' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(NewNutritionPage));