import React, { Component } from 'react';
import HelperEmpty from '../../helper/EmptyLarge';



const DataEmptySearch  = [
  {
    id: 'clients',
    title: 'No clients found',
    text: 'There are no clients\nmatching your search criteria',
  },
  {
    id: 'recurring',
    title: 'No recurring templates found',
    text: 'There are no recurring templates\nmatching your search criteria',
  },
  {
    id: 'programs',
    title: 'No programs found',
    text: 'There are no programs\nmatching your search criteria',
  },
  {
    id: 'plans',
    title: 'No training plans found',
    text: 'There are no training plans\nmatching your search criteria',
  },
  {
    id: 'nutrition',
    title: 'No nutrition plans found',
    text: 'There are no nutrition plans\nmatching your search criteria',
  },
  {
    id: 'habits',
    title: 'No habits found',
    text: 'There are no habits\nmatching your search criteria',
  },
  {
    id: 'payments',
    title: 'No payments found',
    text: 'There are no payments\nmatching your search criteria',
  },
  {
    id: 'debits',
    title: 'No memberships found',
    text: 'There are no memberships\nmatching your search criteria',
  },
  {
    id: 'products',
    title: 'No products found',
    text: 'There are no products\nmatching your search criteria',
  },
  {
    id: 'invoices',
    title: 'No invoices found',
    text: 'There are no invoices\nmatching your search criteria',
  },
  {
    id: 'expenses',
    title: 'No expenses found',
    text: 'There are no expenses\nmatching your search criteria',
  },
]



class ListEmpty extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      var: props.var
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      id: props.id,
      var: props.var
    }
  }


  render() {
    var data = null
    var text = ''
    for(var item of DataEmptySearch) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(data !== null) {
      text = data.text.replace('$var', this.state.var)
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1 static"></div>
            <div className="img2 static"></div>
            <div className="img3 static"></div>
            <div className="img4 static"></div>
            {HelperEmpty.renderImage('search', true)}
          </div>
          <h3 className="mb-20">{data.title}</h3>
          <p className="mb-20">{text}</p>
        </div>
      )
    }
  }
}


export default ListEmpty;