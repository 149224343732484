import React, { Component } from 'react';
import Moment from 'moment';

import Cal from '../../helper/Calendar';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ListIcon from '../../components/List/icon';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import ListEmptySearch from '../../components/List/empty-search';
import ModalMessageSimple from '../../components/Modals/message-simple';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class RecurringPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recurring: global.recurring,
      clients: global.clients,
      sessions: global.sessions,
      archive: global.archive,
      expfixed: [],
      expired: [],
      search: '',
      searchExpired: '',
      showModalLocked: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Recurring Templates - PT Mate', ROUTES.RECURRING)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('recurringLoaded', (event) => this.getLists())
    EventEmitter.subscribe('locationSet', (event) => this.setRecurringList())
    this.configureData()
    this.getLists()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      this.setState({
        clients: global.clients,
        sessions: global.sessions,
        archive: global.archive,
      });
    }
  }


  getLists() {
    if(this._isMounted) {
      var tmp1 = []
      var tmp2 = []
      for(var item of global.recurring) {
        if(item.data.dateEnd === undefined) {
          tmp1.push(item)
        } else {
          if(Moment(item.data.dateEnd, 'X') > Moment()) {
            tmp1.push(item)
          } else {
            tmp2.push(item)
          }
        }
      }
      this.setState({
        recurring: tmp1,
        expired: tmp2,
        expfixed: tmp2,
      });
    }
  }


  getTemplates(group) {
    var count = 0
    for(var item of this.state.recurring) {
      if(item.data.group && item.data.availability && !group) {
        count++
      }
      if(item.data.group && !item.data.availability && group) {
        count++
      }
      if(!item.data.group && !group) {
        count++
      }
    }
    return count
  }


  getSessions() {
    var count = 0
    var today = parseInt(Moment().format('X'))
    for(var item of this.state.sessions) {
      if(global.loc === '' || item.data.location === global.loc) {
        var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY').format('X'))
        if(item.data.template !== undefined && item.data.template !== '' && date < today) {
          count++
        }
      }
    }
    for(var item2 of this.state.archive) {
      if(global.loc === '' || item2.data.location === global.loc) {
        if(item2.data.template !== undefined && item2.data.template !== '') {
          count++
        }
      }
    }
    return count
  }


  getClients() {
    var clients = []
    for(var item of this.state.sessions) {
      if(global.loc === '' || item.data.location === global.loc) {
        if(item.data.template !== undefined && item.data.template !== '' && clients.indexOf(item.data.client) === -1 && !item.data.group) {
          clients.push(item.data.client)
        }
      }
    }
    for(var item2 of this.state.archive) {
      if(global.loc === '' || item2.data.location === global.loc) {
        if(item2.data.template !== undefined && item2.data.template !== '' && clients.indexOf(item2.data.client) === -1 && !item2.data.group) {
          clients.push(item2.data.client)
        }
      }
    }
    return clients.length
  }


  getName(item) {
    var label = 'Group sessions'
    if(!item.data.group) {
      label = 'Deleted client'
    }
    if(item.data.availability) {
      label = 'Available 1:1 session'
    }
    for(var client of this.state.clients) {
      if(client.id === item.data.client) {
        label = client.data.name
      }
    }
    for(var client2 of global.clientsInactive) {
      if(client2.id === item.data.client) {
        label = client2.data.name
      }
    }
    if(item.data.group && item.data.client !== '') {
      label = item.data.client
    }
    if(item.data.type === 'event') {
      label = item.data.title+' (Event)'
    }
    return label
  }

  
  getIcon(item) {
    var label = 'session'
    var color = 'secondary'
    if(item.data.group) {
      label = 'group'
      color = 'primary'
      if(item.data.availability) {
        label = 'session'
        color = 'secondary'
      }
    }
    if(item.data.type === 'event') {
      label = 'event'
      color = 'quarternary'
    }
    return [label, color]
  }


  getLink(item) {
    var label = '/calendar/recurring-templates/sessions/'+item.id
    if(item.data.group) {
      label = '/calendar/recurring-templates/group/'+item.id
      if(item.data.availability) {
        label = '/calendar/recurring-templates/availability/'+item.id
      }
    }
    if(item.data.type === 'event') {
      label = '/calendar/recurring-templates/events/'+item.id
    }
    return label
  }


  filterTemplates = event => {
    this.setState({
      search: event.target.value
    }, then => {
      this.setRecurringList()
    })
  }


  setRecurringList() {
    var tmp = []
    var tmp2 = []
    var final = []
    for(var item of global.recurring) {
      if(item.data.dateEnd === undefined) {
        tmp.push(item)
      } else {
        if(Moment(item.data.dateEnd, 'X') > Moment()) {
          tmp.push(item)
        }
      }
    }
    if(global.loc !== '') {
      for(var t of tmp) {
        if(t.data.location === global.loc) {
          tmp2.push(t)
        }
      }
    } else {
      tmp2 = tmp
    }
    final = tmp2
    if(this.state.search !== '') {
      final = []
      for(var item of tmp2) {
        if(item.data.group) {
          var name = 'group sessions'
          if(item.data.client !== '') {
            name = item.data.client
          }
          if(name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && item.data.group) {
            final.push(item)
          }
        } else {
          for(var cl of global.clients) {
            if(cl.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && cl.id === item.data.client) {
              final.push(item)
            }
          }
          for(var cl2 of global.clientsInactive) {
            if(cl2.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && cl2.id === item.data.client) {
              final.push(item)
            }
          }
        }
      }
    }
    this.setState({
      recurring: final
    })
  }


  filterTemplatesExpired = event => {
    this.setState({
      searchExpired: event.target.value
    }, then => {
      var tmp = []
      var final = []
      for(var item of global.recurring) {
        if(item.data.dateEnd !== undefined) {
          if(Moment(item.data.dateEnd, 'X') > Moment()) {
            //tmp1.push(item)
          } else {
           tmp.push(item)
          }
        }
      }
      final = tmp
      if(this.state.searchExpired !== '') {
        final = []
        for(var item of tmp) {
          if(item.data.group) {
            var name = 'group sessions'
            if(item.data.client !== '') {
              name = item.data.client
            }
            if(name.toLowerCase().indexOf(this.state.searchExpired.toLowerCase()) !== -1 && item.data.group) {
              final.push(item)
            }
          } else {
            for(var cl of global.clients) {
              if(cl.data.name.toLowerCase().indexOf(this.state.searchExpired.toLowerCase()) !== -1 && cl.id === item.data.client) {
                final.push(item)
              }
            }
            for(var cl2 of global.clientsInactive) {
              if(cl2.data.name.toLowerCase().indexOf(this.state.searchExpired.toLowerCase()) !== -1 && cl2.id === item.data.client) {
                final.push(item)
              }
            }
          }
        }
      }
      this.setState({
        expired: final
      })
    })
  };


  
  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalLocked: false,
    })
  }


  clickModal() {
    this.hideModals()
    this.props.history.push(ROUTES.SUBSCRIPTION)
  }



  // Display stuff ------------------------------------------------------------



  renderBottom() {
    if(global.recurring.length === 0) {
      return (
        <ListEmpty id='recurring'/>
      )
    } else {
      var list = this.state.recurring
      list.sort((a,b) => this.getName(a).localeCompare(this.getName(b)))
      if(list.length === 0 && this.state.search !== '') {
        return (
          <div className="col-12 clear">
            <div className="listheader">
              <div className="lft mt-10">
                <ListHeader id='rectemplates' number={list.length}/>
              </div>
              <input type="text" className="light search large rgt" placeholder="Filter by name or client" value={this.state.search} onChange={(val) => this.filterTemplates(val)} />
              <div className="clear"></div>
            </div>
            <ListEmptySearch id='recurring'/>
          </div>
        )
      } else {
        return (
          <div className="col-12 clear">
            <div className="listheader">
              <div className="lft mt-10">
                <ListHeader id='rectemplates' number={list.length}/>
              </div>
              <input type="text" className="light search large rgt" placeholder="Filter by name or client" value={this.state.search} onChange={(val) => this.filterTemplates(val)} />
              <div className="clear"></div>
            </div>
            {list.map(item => (
              <ListIcon 
                key={item.id}
                icon={this.getIcon(item)[0]}
                color={this.getIcon(item)[1]}
                link={this.getLink(item)}
                button='edit'
                columns={[{text: this.getName(item), width: 4}, {text: Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('dddd')+' '+Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('LT'), width: 3}, {text: 'every '+(item.data.interval === 7 ? 'week' : 'fortnight'), width: 2}, {text: Cal.getDuration(item.data.duration), width: 2}]}
              />
            ))}
            <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.NEWRECGROUP)}>Add another recurring session</button>
          </div>
        )
      }
    }
  }


  renderExpired() {
    if(this.state.expfixed.length > 0) {
      var list = this.state.expired
      list.sort((a,b) => this.getName(a).localeCompare(this.getName(b)))
      if(list.length === 0) {
        return (
          <div className="col-12 clear">
            <div className="listheader">
              <div className="lft mt-10">
                <ListHeader id='recexptemplates' number={list.length}/>
              </div>
              <input type="text" className="light search large rgt" placeholder="Filter by name or client" value={this.state.searchExpired} onChange={(val) => this.filterTemplatesExpired(val)} />
              <div className="clear"></div>
            </div>
            <ListEmptySearch id='recurring'/>
          </div>
        )
      } else {
        return (
          <div className="col-12 clear">
            <div className="listheader">
              <div className="lft mt-10">
                <ListHeader id='recexptemplates' number={list.length}/>
              </div>
              <input type="text" className="light search large rgt" placeholder="Filter by name or client" value={this.state.searchExpired} onChange={(val) => this.filterTemplatesExpired(val)} />
              <div className="clear"></div>
            </div>
            {list.map(item => (
              <ListIcon 
                key={item.id}
                icon={this.getIcon(item)[0]}
                color={this.getIcon(item)[1]}
                link={this.getLink(item)}
                button='edit'
                columns={[{text: this.getName(item), width: 4}, {text: Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('dddd')+' '+Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('LT'), width: 3}, {text: 'Ended '+Moment(item.data.dateEnd, 'X').format('D MMM YYYY'), width: 2}, {text: Cal.getDuration(item.data.duration), width: 2}]}
              />
            ))}
          </div>
        )
      }
    }
  }


  renderActions() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      var item1 = {id: 'rec11', link: ROUTES.NEWRECSESSION}
      var item2 = {id: 'recgroup', link: ROUTES.NEWRECGROUP}
      var item3 = {id: 'recevent', link: ROUTES.NEWRECEVENT}
      if(HelperBusiness.getRoleIncl('')) {
        if(global.userSubStatus === 'active' && global.userSubPlan === 'spark') {
          item1 = {id: 'rec11', link: ''}
          item2 = {id: 'recgroup', link: ''}
          item3 = {id: 'recevent', link: ''}
        }
      } else if(HelperBusiness.getRole('trainer')) {
        item3 = {id: '', link: ''}
      }
      return (
        <WidgetActions
          item1={item1} clickItem1={() => this.setState({showModalLocked: true})}
          item2={item2} clickItem2={() => this.setState({showModalLocked: true})}
          item3={item3} clickItem3={() => this.setState({showModalLocked: true})}
        />
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <div className="col-9">
              <WidgetOverview
                item1={{id: 'rec11', value: this.getTemplates(false), prev: ''}}
                item2={{id: 'recgroup', value: this.getTemplates(true), prev: ''}}
                item3={{id: 'recsessions', value: this.getSessions(), prev: ''}}
                item4={{id: 'recclients', value: this.getClients(), prev: ''}}
              />
            </div>
            {this.renderActions()}
            {this.renderBottom()}
            {this.renderExpired()}
            <div className="sv-80 clear"></div>
          </div>
          <Header type='calendar' active='recurring'/>
          <Navigation active='calendar' />
          <ModalMessageSimple type='recurring' show={this.state.showModalLocked} onHide={() => this.hideModals()} clickMainButton={() => this.clickModal()}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='recurring-locked' type='simple'/>
          </div>
          <Header type='simple' title='Calendar'/>
          <Navigation active='calendar' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(RecurringPage));