import React, { Component } from 'react';
import HelperEmpty from '../../helper/EmptyLarge';



const DataFormInfo  = [
  {
    id: 'newclient',
    title: 'Add a new client',
    text: "Enter your client's details to add them to your list. Check 'More Options' to add more details.",
    image: 'newclient'
  },
  {
    id: 'newclient-noemail',
    title: 'Add a new client',
    text: "Enter your client's details to add them to your list. Check 'More Options' to add more details.",
    image: 'newclient',
    notes: 'Note',
    notestext: "You won't be able to send programs or training plans to this client.",
  },
  {
    id: 'clientimport',
    title: 'Import your existing client list',
    text: "Upload a CSV file to quickly import your client list. Your clients will automatically be created and invited to join PT Mate to connect with you.",
    image: 'importclients'
  },
  {
    id: 'newnutrition',
    title: 'New Nutrition Plan',
    text: "Assign a nutrition plan to a client. PT Mate will take care of everything for you. You can monitor your client's progress in their Health tab.",
    image: 'newnutrition'
  },
  {
    id: 'newhabit',
    title: 'Create a new habit',
    text: "Create a new habit and set a start and end date. You'll be able to track your client's compliance in their Health tab.",
    image: 'newhabit',
    notes: 'Note',
    notestext: "Your clients will have to log their habits on their Member App or Member Web Area.",
  },
  {
    id: 'edithabit',
    title: 'Update habit',
    text: "Update your habit and set a start and end date. You'll be able to track your client's compliance in their Health tab.",
    image: 'edithabit',
  },
  {
    id: 'newinvoice',
    title: 'Create an invoice',
    text: "Create an invoice and send it to your client. They will receive an email and a notification on their Member App/Portal.\n\nYour client can either pay it on their Member App/Portal or outside of PT Mate.",
    image: 'newinvoice',
  },
  {
    id: 'newexpense',
    title: 'Log an expense',
    text: "Log an expense to keep track of the financials of your business. You can analyse them in your insights or create financial reports listing your expenses.",
    image: 'newexpense',
  },
  {
    id: 'editexpense',
    title: 'Edit expense',
    text: "Update your expense to keep track of the financials of your business. You can analyse them in your insights or create financial reports listing your expenses.",
    image: 'editexpense',
  },
  {
    id: 'newpayment',
    title: 'Process a payment',
    text: "Securely process a one-off payment for a client with Stripe. Your client will automatically receive the receipt on the Member App and Member Web Portal.",
    image: 'newpayment',
  },
  {
    id: 'payinvoice',
    title: 'Pay an invoice',
    text: "Securely process a one-off payment for a client with Stripe. Your client will automatically receive the receipt on the Member App and Member Web Portal.",
    image: 'newpayment',
  },
  {
    id: 'directdebit',
    title: 'Set up a membership',
    text: "Set up a recurring payment for a client. Your client will be charged automatically and they'll receive the payment receipts on the Member App and Member Web Portal. Powered by Stripe.",
    image: 'newdebit',
  },
  {
    id: 'prodnewsessions',
    title: 'New pack of sessions',
    text: "Create a pack of sessions you can sell to your clients. Sessions in the pack don't have an expiry date unless you set one.",
    image: 'newproduct',
    notes: 'Note',
    notestext: "This product is only available for one-off payments. If you want to create a product for recurring automatic payments, please select Membership.",
  },
  {
    id: 'prodeditsessions',
    title: 'Edit pack of sessions',
    text: "Update the pack of sessions you can sell to your clients.",
    image: 'editproduct',
    notes: 'Note',
    notestext: "To change the type of sessions, you'll have to delete this product and create a new one.",
  },
  {
    id: 'prodnewdebit',
    title: 'New membership',
    text: "Create a membership you can set up for your clients. They'll be charged periodically according to the billing cycle.",
    image: 'newproduct',
    notes: 'Note',
    notestext: "You can limit the available amount of sessions per billing period. Leave the field empty if you want unlimited sessions.",
  },
  {
    id: 'prodeditdebit',
    title: 'Edit membership',
    text: "Change your product's name. To modify other details, you'll have to delete it and create a new product.",
    image: 'editproduct',
    notes: 'Note',
    notestext: "Changes and/or deletion of this product won't affect any active memberships.",
  },
  {
    id: 'prodnewother',
    title: 'New other product',
    text: "Create product to sell anything but training or sessions, such as merchandising, equipment or additional services.",
    image: 'newproduct',
  },
  {
    id: 'prodeditother',
    title: 'Edit product',
    text: "Use this product to sell anything but training or sessions, such as merchandising, equipment or additional services.",
    image: 'editproduct',
  },
  {
    id: 'addstaff',
    title: 'Add a staff member',
    text: "Enter your staff member's details to create their account. They can always update them once they log in.",
    image: 'newclient',
    notes: 'Note',
    notestext: "This will create an account for your staff member with a temporary password. An email will be sent once you tap the 'add staff member' button option.",
  },
  {
    id: 'rec11new',
    title: 'New 1:1 recurring session template',
    text: "Create recurring 1:1 sessions for a client to automate your schedule. You can edit and or delete each future session individually from your Calendar tab.",
    image: 'newsession',
    notes: 'Run sessions through PT Mate',
    notestext: "Add a program to your sessions so you can run them through PT Mate and log your client's results.",
  },
  {
    id: 'rec11edit',
    title: 'Edit 1:1 recurring session template',
    text: "Update the recurring session template for this client. To change the frequency and/or client, please delete the template and create a new one.",
    image: 'editsession',
    notes: 'Note',
    notestext: "Only future sessions using this template will be updated. You can always edit past sessions from your Calendar tab.",
  },
  {
    id: 'recgroup1',
    title: 'New public group recurring session template',
    text: "Create recurring group sessions any client can book in to. You can edit and or delete each future session individually from your Calendar tab.",
    image: 'newsession',
    notes: 'Run sessions through PT Mate',
    notestext: "Add a program to your sessions so you can run them through PT Mate and log your client's results.",
  },
  {
    id: 'recgroup2',
    title: 'New restricted group recurring session template',
    text: "Create recurring group sessions only clients of the selected groups can book in to. You can edit and or delete each future session individually from your Calendar tab.",
    image: 'newsessionexclusive',
    notes: 'Run sessions through PT Mate',
    notestext: "Add a program to your sessions so you can run them through PT Mate and log your client's results.",
  },
  {
    id: 'recgroup3',
    title: 'New private group recurring session template',
    text: "Create recurring group sessions only selected clients can book in to. You can edit and or delete each future session individually from your Calendar tab.",
    image: 'newsessionprivate',
    notes: 'Run sessions through PT Mate',
    notestext: "Add a program to your sessions so you can run them through PT Mate and log your client's results.",
  },
  {
    id: 'recavail',
    title: 'New recurring available 1:1 session template',
    text: "Create a recurring time slot for your clients to book a 1:1 session. You can edit and or delete each future slot individually from your Calendar tab.",
    image: 'newsession',
    notes: 'Note: These are not yet sessions',
    notestext: "A client needs to book the slot to convert it into a session.",
  },

  {
    id: 'recgroup1edit',
    title: 'Edit public group recurring session template',
    text: "Only the selected clients can book into these sessions. To change the session frequence, please delete the template and create a new one.",
    image: 'editsession',
    notes: 'Note',
    notestext: "Only future sessions using this template will be updated. You can always edit past sessions from your Calendar tab.",
  },
  {
    id: 'recgroup2edit',
    title: 'Edit restricted group recurring session template',
    text: "Only clients of the selected groups can book into these sessions. To change the session frequence, please delete the template and create a new one.",
    image: 'editsessionexclusive',
    notes: 'Note',
    notestext: "Only future sessions using this template will be updated. You can always edit past sessions from your Calendar tab.",
  },
  {
    id: 'recgroup3edit',
    title: 'Edit private group recurring session template',
    text: "Create recurring group sessions only selected clients can book in to. You can edit and or delete each future session individually from your Calendar tab.",
    image: 'editsessionprivate',
    notes: 'Note',
    notestext: "Only future sessions using this template will be updated. You can always edit past sessions from your Calendar tab.",
  },
  {
    id: 'recavailedit',
    title: 'Edit recurring available 1:1 session template',
    text: "A recurring time slot for your clients to book a 1:1 session. To change the session frequence, please delete the template and create a new one.",
    image: 'editsession',
    notes: 'Note',
    notestext: "Only future sessions using this template will be updated. You can always edit past sessions from your Calendar tab.",
  },
  {
    id: 'recevent',
    title: 'New recurring event template',
    text: "Create recurring calendar events, such as meetings, assessments or reminders. Clients can book into your bookable events. Internal events are invisble to your clients",
    image: 'newsession',
  },
  {
    id: 'receventedit',
    title: 'Edit recurring event template',
    text: "Edit your recurring calendar events. Clients can book into your bookable events. Note: You can't change the event type. If you wish to do so, please cancel this template and create a new one.",
    image: 'editsession',
  },
  {
    id: 'cal11',
    title: 'New 1:1 session',
    text: "Create a 1:1 session for a client. Adding a virtual session link will allow your client to join the session directly from their Member App or Member Web Portal (www.ptmate.me).",
    image: 'newsession',
    notes: 'Run the session through PT Mate',
    notestext: "Save your session first and then add a program to it. This allows you to run the session through PT Mate and log your client's results."
  },
  {
    id: 'cal11edit',
    title: 'Edit 1:1 session',
    text: "Edit this 1:1 session. You can run it through PT Mate if you assign a program to the session.\n\nAdding a virtual session link will allow your client to join the session directly from their Member App or Member Web Portal (www.ptmate.me).",
    image: 'editsession',
  },
  {
    id: 'calavail',
    title: 'New bookable 1:1 time slot',
    text: "Create a time slot for your clients to book a 1:1 session. They will be able to book in from their Member App. If the session is taken they will enter a waiting list.",
    image: 'newsession',
    notes: 'Note: This is not a 1:1 session yet',
    notestext: 'A client needs to book the slot to convert it into a session. They can do so on the Member App, or you can book them in.',
  },
  {
    id: 'calgroup1',
    title: 'New public group session',
    text: "Create a group session any client can book in to. Adding a virtual session link will allow your clients to join the session directly from their Member App.",
    image: 'newsession',
    notes: 'Run the session through PT Mate',
    notestext: "Save your session first and then add a program to it. This allows you to run the session through PT Mate and log your clients' results.",
  },
  {
    id: 'calgroup2',
    title: 'New restricted group session',
    text: "Create a group session for selected client groups. Only clients in these groups see the session and can book into it.",
    image: 'newsessionexclusive',
    notes: 'Run the session through PT Mate',
    notestext: "Save your session first and then add a program to it. This allows you to run the session through PT Mate and log your clients' results.",
  },
  {
    id: 'calgroup3',
    title: 'New private group session',
    text: "Create a group session only selected clients can see an book in to. You can also choose this option for PT sessions with 2 or more clients.",
    image: 'newsessionprivate',
    notes: 'Run the session through PT Mate',
    notestext: "Save your session first and then add a program to it. This allows you to run the session through PT Mate and log your clients' results.",
  },
  {
    id: 'calavailedit',
    title: 'Edit available 1:1 session',
    text: "Your clients to book a 1:1 session for the selected time slot. They will be able to book in from their Member App. If the session is taken they will enter a waiting list.\n\nAdding a virtual session link will allow your client to join the session directly from their Member App.",
    image: 'editsession',
  },
  {
    id: 'calgroup1edit',
    title: 'Edit public group session',
    text: "Any client can see and book into this session.\n\nAdding a virtual session link will allow your clients to join the session directly from their Member App.",
    image: 'editsession',
  },
  {
    id: 'calgroup2edit',
    title: 'Edit restricted group session',
    text: "Only clients in the selected client groups can see the session and can book into it.\n\nAdding a virtual session link will allow your clients to join the session directly from their Member App.",
    image: 'editsessionexclusive',
  },
  {
    id: 'calgroup3edit',
    title: 'Edit private group session',
    text: "Only selected clients can see an book into this session. You can also choose this option for PT sessions with 2 or more clients.\n\nAdding a virtual session link will allow your clients to join the session directly from their Member App.",
    image: 'editsessionprivate',
  },
  {
    id: 'calevent',
    title: 'Edit private group session',
    text: "Create a calendar event, such as meetings, assessments or reminders. Clients can book into your bookable events. Internal events are invisble to your clients. ",
    image: 'newsession',
  },
  {
    id: 'caleventedit',
    title: 'Edit bookable event',
    text: "Edit your calendar event. You can use it for meetings, assessments, reminders, etc. Clients can book into your bookable events.",
    image: 'editsession',
  },
  {
    id: 'caleventedit2',
    title: 'Edit internal event',
    text: "Edit your calendar event. You can use it for meetings, assessments, reminders, etc. This event is not visible to your clients.",
    image: 'editsession',
  },
  {
    id: 'newclientgroup',
    title: 'New client group',
    text: "Create restricted group sessions for your client groups and filter your reports by group to make business admin easier.",
    image: 'newgroup',
  },
  {
    id: 'editclientgroup',
    title: 'Edit client group',
    text: "Create restricted group sessions for your client groups and filter your reports by group to make business admin easier.",
    image: 'editgroup',
  },{
    id: 'newlocation',
    title: 'New location',
    text: "Create a location you can assign your sessions and recurring sessions to. Give your clients and staff access to the location and all its sessions.",
    image: 'newlocation',
    notes: 'Note',
    notestext: "You can change the location on the top of your screen. You can either see all locations or select a specific location.",
  },
  {
    id: 'editlocation',
    title: 'Edit location',
    text: "Edit your location name and access. Your changes won't have an effect on past sessions.",
    image: 'editlocation',
    notes: 'Note',
    notestext: "You can change the location on the top of your screen. You can either see all locations or select a specific location.",
  },
]



class WidgetFormInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      id: props.id,
    }
  }


  renderNotes(data) {
    if(data.notes !== undefined && data.notes !== '') {
      return (
        <div className="infobox mb-30">
          <h5>{data.notes}</h5>
          <p>{data.notestext}</p>
        </div>
      )
    }
  }


  render() {
    var data = null
    for(var item of DataFormInfo) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(data !== null) {
      return (
        <div>
          <div className="image empty large mb-30">
            <div className="illustration">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmpty.renderImage(data.image)}
            </div>
          </div>
          <p style={{marginBottom: 30, whiteSpace: 'pre-wrap'}}>{data.text}</p>
          {this.renderNotes(data)}
        </div>
      )
    }
  }
}


export default WidgetFormInfo;