import React, { Component } from 'react';
//import { withTranslation } from "react-i18next";
//import lang from 'i18next';



const DataOverview = [
  {
    id: 'activeclients',
    //title: 'overview:previous',
    title: 'Active clients',
  },
  {
    id: 'sessions',
    title: '1:1 sessions',
  },
  {
    id: 'group',
    title: 'Group sessions',
    class: 'group',
  },
  {
    id: 'training',
    title: 'Training sessions',
  },
  {
    id: 'clientgroups',
    title: 'Client groups',
  },
  {
    id: 'progtime',
    title: 'Avg. time (min)',
  },
  {
    id: 'progblocks',
    title: 'Average blocks',
  },
  {
    id: 'progtotal',
    title: 'Total programs',
  },
  {
    id: 'plans',
    title: 'Total plans',
  },
  {
    id: 'planweeks',
    title: 'Average weeks',
  },
  {
    id: 'planclients',
    title: 'Clients sent to',
  },
  {
    id: 'nutactive',
    title: 'Active plans',
  },
  {
    id: 'nutinactive',
    title: 'Inactive plans',
  },
  {
    id: 'nutdays',
    title: 'Active days this week',
  },
  {
    id: 'habtotal',
    title: 'Total habits',
  },
  {
    id: 'habactive',
    title: 'Active clients',
  },
  {
    id: 'habaverage',
    title: 'Avg. habits per client',
  },
  {
    id: 'income',
    title: 'Income (After fees)',
  },
  {
    id: 'payments',
    title: 'Payments',
  },
  {
    id: 'payclients',
    title: 'Clients charged',
  },
  {
    id: 'debits',
    title: 'Memberships',
  },
  {
    id: 'prodtotal',
    title: 'Total',
  },
  {
    id: 'prodpacks',
    title: 'Packs',
  },
  {
    id: 'invtotal',
    title: 'Total invoices',
  },
  {
    id: 'invopen',
    title: 'Open',
  },
  {
    id: 'invpaid',
    title: 'Paid',
  },
  {
    id: 'invoverdue',
    title: 'Overdue',
  },
  {
    id: 'stafftotal',
    title: 'Total staff',
  },
  {
    id: 'staffactive',
    title: 'Active',
  },
  {
    id: 'staffinactive',
    title: 'Inactive',
  },
  {
    id: 'forms',
    title: 'Forms',
  },
  {
    id: 'formdocs',
    title: 'Uploaded documents',
  },
  {
    id: 'formclients',
    title: 'Clients with documents',
  },
  {
    id: 'leads',
    title: 'Leads',
  },
  {
    id: 'leadsnew',
    title: 'New',
  },
  {
    id: 'leadsclients',
    title: 'Converted to client',
  },
  {
    id: 'cal11',
    title: '1:1 sessions',
  },
  {
    id: 'calgroup',
    title: 'Group sessions',
  },
  {
    id: 'caltotal',
    title: 'Total active clients',
  },
  {
    id: 'rec11',
    title: '1:1 templates',
  },
  {
    id: 'recgroup',
    title: 'Group templates',
  },
  {
    id: 'recsessions',
    title: 'Sessions run',
  },
  {
    id: 'recclients',
    title: 'Clients',
  },
  {
    id: 'archive',
    title: 'Total sessions',
  },
  {
    id: 'planssent',
    title: 'Training plans sent',
  },
  {
    id: 'plansavail',
    title: 'Training plans available',
  },
  {
    id: 'programssent',
    title: 'Programs sent',
  },
  {
    id: 'programsavail',
    title: 'Programs available',
  },
  {
    id: 'emailstotal',
    title: 'Total emails',
  },
  {
    id: 'emailssent',
    title: 'Emails sent',
  },
  {
    id: 'emailsclients',
    title: 'Total clients sent to',
  },
  {
    id: 'unpaid',
    title: 'Unpaid sessions',
  },
  {
    id: 'locationstotal',
    title: 'Locations',
  },
  {
    id: 'locationsavg',
    title: 'Avg clients per location',
  },
]



class WidgetActions extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      item1: props.item1,
      item2: props.item2,
      item3: props.item3,
      item4: props.item4,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      item1: props.item1,
      item2: props.item2,
      item3: props.item3,
      item4: props.item4,
    }
  }


  getClass1() {
    var label = 'info large col-3'
    if(this.state.item2.id === '') {
      label = 'info large col-6'
    }
    return label
  }


  renderCursymPrev(val) {
    if(this.state.item1.id === 'income' && this.state.item1.value !== 'N/A' && val !== '-') {
      return global.curSym
    }
  }


  renderCursym() {
    if(this.state.item1.id === 'income' && this.state.item1.value !== 'N/A') {
      return global.curSym
    }
  }


  renderItem1Prev() {
    if(this.state.item1.prev !== '' && global.dateRange !== '60days' && global.dateRange !== 'all') {
      var diff = this.state.item1.value-this.state.item1.prev
      var badge = ''
      var val = this.state.item1.prev
      if(diff > 0) { badge = ' green'}
      if(diff < 0) { badge = ' red'}
      if(this.state.dateRange === '60days') {
        val = '-'
        badge = ''
      }
      if(this.state.item1.id === 'income' && this.state.item1.value !== 'N/A' && val !== '-') {
        diff = diff.toFixed(2)
      }
      return (
        <span className={'pill'+badge+(val === '-' ? ' none' : '')} title='Change compared to previous period'>{diff > 0 ? '+' : ''}{this.renderCursymPrev(val)}{diff}</span>
      )
    }
  }


  renderItem1() {
    var data = null
    for(var item of DataOverview) {
      if(item.id === this.state.item1.id) {
        data = item
      }
    }
    if(data !== null) {
      return (
        <div className={this.getClass1()}>
          <label>{data.title}</label>
          <p>{this.renderCursym()}{this.state.item1.value}</p>
          {this.renderItem1Prev()}
        </div>
      )
    }
  }


  renderItem2Prev() { 
    if(this.state.item2.prev !== '' && global.dateRange !== '60days' && global.dateRange !== 'all') {
      var diff = this.state.item2.value-this.state.item2.prev
      var badge = ''
      var val = this.state.item2.prev
      if(diff > 0) { badge = ' green'}
      if(diff < 0) { badge = ' red'}
      if(this.state.dateRange === '60days') {
        val = '-'
        badge = ''
      }
      return (
        <span className={'pill'+badge+(val === '-' ? ' none' : '')} title='Change compared to previous period'>{diff > 0 ? '+' : ''}{diff}</span>
      )
    }
  }


  renderItem2() {
    var data = null
    for(var item of DataOverview) {
      if(item.id === this.state.item2.id) {
        data = item
      }
    }
    if(data !== null) {
      return (
        <div className='info large col-3'>
          <label>{data.title}</label>
          <p>{this.state.item2.value}</p>
          {this.renderItem2Prev()}
        </div>
      )
    }
  }


  renderItem3Prev() {
    if(this.state.item3.prev !== '' && global.dateRange !== '60days' && global.dateRange !== 'all') {
      var diff = this.state.item3.value-this.state.item3.prev
      var badge = ''
      var val = this.state.item3.prev
      if(diff > 0) { badge = ' green'}
      if(diff < 0) { badge = ' red'}
      if(this.state.dateRange === '60days') {
        val = '-'
        badge = ''
      }
      return (
        <span className={'pill'+badge+(val === '-' ? ' none' : '')} title='Change compared to previous period'>{diff > 0 ? '+' : ''}{diff}</span>
      )
    }
  }


  renderItem3() {
    var data = null
    for(var item of DataOverview) {
      if(item.id === this.state.item3.id) {
        data = item
      }
    }
    if(data !== null) {
      return (
        <div className='info large col-3'>
          <label>{data.title}</label>
          <p>{this.state.item3.value}</p>
          {this.renderItem3Prev()}
        </div>
      )
    }
  }


  renderItem4Prev() {
    if(this.state.item4.prev !== '' && global.dateRange !== '60days' && global.dateRange !== 'all') {
      var diff = this.state.item4.value-this.state.item4.prev
      var badge = ''
      var val = this.state.item4.prev
      if(diff > 0) { badge = ' green'}
      if(diff < 0) { badge = ' red'}
      if(this.state.dateRange === '60days') {
        val = '-'
        badge = ''
      }
      return (
        <span className={'pill'+badge+(val === '-' ? ' none' : '')} title='Change compared to previous period'>{diff > 0 ? '+' : ''}{diff}</span>
      )
    }
  }


  renderItem4Button(data) {
    if(data.id === 'unpaid') {
      return <button className="btn tertiary small lft" onClick={() => this.props.clickButton()}>View details</button>
    }
  }


  renderItem4() {
    var data = null
    for(var item of DataOverview) {
      if(item.id === this.state.item4.id) {
        data = item
      }
    }
    if(data !== null) {
      return (
        <div className='info large col-3'>
          <label>{data.title}</label>
          <p>{this.state.item4.value}</p>
          {this.renderItem4Prev()}
          {this.renderItem4Button(data)}
        </div>
      )
    }
  }


  render() {
    return (
      <div className="pt-20">
        {this.renderItem1()}
        {this.renderItem2()}
        {this.renderItem3()}
        {this.renderItem4()}
        <div className="clear"></div>
      </div>
    )
  }
}


export default WidgetActions;
//export default withTranslation(['overview'])(WidgetQuicklinks);