import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';

import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import InputDate from '../../components/Form/input-date';
import Select from '../../components/Form/select';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalForm from '../../components/Modals/form';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class NewExpensePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      expenses: global.expenses,
      categories: [],
      date: Moment().format('YYYY-MM-DD'),
      values: ['', 'admin1', ''],
      tax: '',
      gst: '',
      location: global.loc,
      locations: [],
      item: null,
      error: [false, false, false, false],
      valueCat: '',
      showModalDelete: false,
      showModalCat: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Expense - PT Mate')
    this._isMounted = true

    global.currentClient = '';
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('expensesLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationsLoaded', (event) => this.configureData())
    this.configureData()
    var arr = this.props.location.pathname.split('/')
    if(arr[arr.length-1] !== 'new') {
      this.setState({id: arr[arr.length-1]})
    }
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var tmp = []
      var tmp2 = ''
      var tmp3 = ''
      var tmp23 = []
      for(var cat of global.expensesCat) {
        tmp.push({name: cat.data.name, value: cat.id})
      }
      if(global.locations.length > 0) {
        tmp23 = [{value: '', name: '- Not assigned -'}]
        for(var l of global.locations) {
          tmp23.push({value: l.id, name: l.data.name})
        }
      }
      tmp.sort((a, b) => a.name.localeCompare(b.name))
      tmp.push({name: '-New category -', value: 'addcat'})
      this.setState({
        expenses: global.expenses,
        categories: tmp,
        locations: tmp23,
        location: global.loc
      }, () => {
        // Existing product
        var location = ''
        var arr = this.props.location.pathname.split('/')
        if(arr[arr.length-1] !== 'new') {
          var tmp = ['', '', '']
          var date = Moment().format('YYYY-MM-DD')
          for(var item of this.state.expenses) {
            if(item.id === arr[arr.length-1]) {
              date = Moment(item.data.date, 'X').format('YYYY-MM-DD')
              tmp = [item.data.name, item.data.cat, item.data.amount.toFixed(2)]
              if(item.data.tax !== undefined) {
                tmp2 = item.data.tax
              }
              if(item.data.gst !== undefined) {
                tmp3 = item.data.gst
              }
              if(item.data.location !== undefined) {
                location = item.data.location
              }
            }
          }
          this.setState({
            id: arr[arr.length-1],
            values: tmp,
            tax: tmp2,
            gst: tmp3,
            date: date,
            location: location
          })
        }
      })
    }
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalDelete: false,
      showModalCat: false,
    })
  }


  deleteExpense() {
    this.hideModals()
    Firebase.database().ref('/expenses/'+global.uid+'/'+this.state.id).remove()
    global.message = 'Expense successfully deleted'
    this.props.history.push(ROUTES.EXPENSES)
  }


  addCategory() {
    if(this.state.valueCat !== '') {
      this.hideModals()
      var obj = Firebase.database().ref('/expensesCat/'+global.uid).push()
      obj.set({
        name: this.state.valueCat,
      })
      var tmp = this.state.values
      tmp[1] = obj.key
      this.setState({
        values: tmp
      })
    }
  }



  // Interactive ------------------------------------------------------------



  updateValues(val, pos) {
    if(val === 'addcat') {
      this.setState({showModalCat: true})
    } else {
      if(pos < 3) {
        var tmp = this.state.values
        tmp[pos] = val
        this.setState({
          values: tmp,
        });
      } else {
        this.setState({
          date: Moment(val, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        });
      }
    }
  }


  setCat(event) {
    var tmp = this.state.values
    tmp[1] = event.target.value
    if(event.target.value !== 'addcat') {
      this.setState({
        values: tmp
      });
    } else {
      tmp[1] = 'admin1'
      this.showOverlayCat()
      this.setState({
        values: tmp
      });
    }
  }


  checkCreateExpense() {
    var tmp = [false, false, false, false]
    if(this.state.values[0] !== '' && this.state.values[1] !== '' && this.state.values[2] !== '') {
      this.createExpense()
    }
    if(this.state.values[0] === '') {
      tmp[0] = true
    }
    if(this.state.values[1] === '') {
      tmp[1] = true
    }
    if(this.state.values[2] === '') {
      tmp[2] = true
    }
    console.log(tmp)
    this.setState({
      error: tmp
    })
  }


  createExpense() {
    var date = Moment(this.state.date, 'YYYY-MM-DD').format('X')
    var tax = null
    var gst = null
    var location = null
    if(this.state.tax !== '') {
      tax = parseFloat(this.state.tax)
    }
    if(this.state.location !== '') {
      location = this.state.location
    }
    if(this.state.gst !== '') {
      gst = parseFloat(this.state.gst)
    }
    if(this.state.id === '') {
      Firebase.database().ref('/expenses/'+global.uid).push({
        name: this.state.values[0],
        cat: this.state.values[1],
        amount: parseFloat(this.state.values[2]),
        date: parseInt(date),
        tax: tax,
        gst: gst,
        location: location,
      }).then(() => {
        global.message = 'Expense successfully logged'
        this.props.history.push(ROUTES.EXPENSES)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.database().ref('/expenses/'+global.uid+'/'+this.state.id).update({
        name: this.state.values[0],
        cat: this.state.values[1],
        amount: parseFloat(this.state.values[2]),
        date: parseInt(date),
        tax: tax,
        gst: gst,
        location: location,
      }).then(() => {
        global.message = 'Expense successfully updated'
        this.props.history.push(ROUTES.EXPENSES)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
    
  }



  // Display stuff ------------------------------------------------------------



  renderLocation() {
    if(global.locations.length > 0) {
      return <Select label='Location' value={this.state.location} values={this.state.locations} onChange={(event) => this.setState({location: event})}/>
    }
  }


  renderDelete() {
    if(this.state.id !== '') {
      return (
        <div>
          <div className="sv-20"></div>
          <button className="btn tertiary close width-12" onClick={() => this.setState({showModalDelete: true})}>Delete expense</button>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('admin')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
              <div className="box">
                <InputText label='Name*' value={this.state.values[0]} placeholder='Name your expense' error={this.state.error[0]} errormsg='Please enter a name' onChange={(event) => this.updateValues(event, 0)}/>
                <Select label='Category*' value={this.state.values[1]} values={this.state.categories} error={this.state.error[1]} errormsg='Please select a category' onChange={(event) => this.updateValues(event, 1)}/>
                <InputNumber label='Amount*' placeholder='Dollar value' value={this.state.values[2]} error={this.state.error[2]} errormsg='Please enter an amount' onChange={(event) => this.updateValues(event, 2)}/>
                <InputNumber label='Tax' placeholder='Dollar value' value={this.state.tax} onChange={(event) => this.setState({tax: event})}/>
                <InputNumber label='GST' placeholder='Dollar value' value={this.state.gst} onChange={(event) => this.setState({gst: event})}/>
                <InputDate label="Date*" placeholder='Select a date' value={Moment(this.state.date, 'YYYY-MM-DD').format('YYYY-MM-DD')} format='YYYY-MM-DD' error={this.state.error[3]} errormsg='Please select a date' onChange={(event) => this.updateValues(event, 3)}/>
                {this.renderLocation()}
                <div className="sv-20"></div>
                <button className="btn primary" onClick={() => this.checkCreateExpense()}>{this.state.id === '' ? 'Log expense' : 'Update expense'}</button>
                {this.renderDelete()}
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id={this.state.id === '' ? 'newexpense' : 'editexpense'}/>
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>
          <Header title={this.state.id === '' ? 'New Expense' : 'Edit Expense'} link={ROUTES.EXPENSES}/>
          <Navigation active='billing' />
          <ModalMessageSimple type='deleteexpense' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteExpense()}/>
          <ModalForm type='newcategory' show={this.state.showModalCat} onHide={() => this.hideModals()} value1={this.state.valueCat} updateValue1={(event) => this.setState({valueCat: event})} clickMainButton={() => this.addCategory()}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='expenses-locked' type='simple'/>
          </div>
          <Header title='Expense' link={global.currentPage}/>
          <Navigation active='billing' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(NewExpensePage));