import React, { Component } from 'react';


const DataMessageDouble = [
  {
    id: 'newclient1',
    title: 'Create client without sending invite?',
    text: "You haven't entered an email address to send an invite to. Do you want to create this client regardless?",
    button: 'Yes, create client',
    button2: 'Cancel',
  },
  {
    id: 'newclient2',
    title: 'Create client without phone number?',
    text: "You haven't entered a valid phone number. This means your client won't automatically connect and will have to seach for you manually when signing up. Do you want to continue?",
    button: 'Yes, create client',
    button2: 'Cancel',
  },
  {
    id: 'enablenutrition',
    title: 'Attention',
    text: "Your free trial will start immediately. Are you sure you want to continue?",
    button: 'Yes, get started now',
    button2: 'Cancel',
  },
  {
    id: 'noshow',
    title: 'Not attended',
    text: "Do you want to charge your client for this session or just mark it as not attended without affecting their available sessions?",
    button: 'Just mark as "not attended"',
    button2: 'Mark as "not attended" & charge the session',
  },
  {
    id: 'saveform',
    title: 'Save changes?',
    text: "The progress you've made hasn't been saved yet. Do you want to save this form before you exit the page?",
    button: 'Save changes',
    button2: 'Exit without saving',
  },
  {
    id: 'inviteclient',
    title: 'Send email invite',
    text: "Do you want to send your client an email invite to connect with you on the Member App?",
    button: 'Send invite now',
    button2: 'Cancel',
  },
  {
    id: 'notattended',
    title: 'Not attended',
    text: "Do you want to charge your client for this session or just mark it as not attended without affecting their available sessions?",
    button: 'Just mark as "not attended"',
    button2: 'Mark as "not attended" & charge the session',
  },
  {
    id: 'clientinvite',
    title: 'Send email invites',
    text: "You have $var clients that provided an email address and are not connected via the Member App/Web Portal. Do you want to send them an invite to connect with you now?",
    button: 'Send invites now',
    button2: 'Cancel',
  },
  {
    id: 'family',
    title: 'Convert to family account',
    text: "This converts $var to a family account, allowing you to attach multiple profiles to it. All billing will be managed by this Main Account. What do you want to do?",
    button: 'Add a new client profile',
    button2: 'Link an existing client',
  },
  {
    id: 'confirmdebitwarning',
    title: "Warning: Full amount won't be charged",
    text: "Memberships are designed for recurring payments.\n\nCancelling the membership before it completes its first billing cycle will result in charging a smaller (prorated) amount instead of the full $var.\n\nTo avoid this, change the end date to charge the full amount, or process a one-off payment instead.",
    button: 'Create membership',
    button2: 'Process a one-off payment instead',
  },
]

class ModalMessageDouble extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      var: props.var
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      var: props.var,
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  clickMainButton() {
    this.hide()
    this.props.clickMainButton()
  }


  clickSecondaryButton() {
    this.hide()
    this.props.clickSecondaryButton()
  }


  render() {
    var data = null
    var text = ''
    for(var item of DataMessageDouble) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(this.state.show && data !== null) {
      text = data.text.replace('$var', this.state.var)
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
          <h2 className="lft mb-20">{data.title}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <p className="clear mb-30">{text}</p>
            <button className="btn primary mb-20" onClick={() => this.clickMainButton()}>{data.button}</button>
            <button className="btn tertiary width-12" onClick={() => this.clickSecondaryButton()}>{data.button2}</button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default ModalMessageDouble;