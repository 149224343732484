import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import Firebase from 'firebase';
import Connector from '../../data/Connector';

import OverviewPage from '../Overview';
import EventEmitter from '../../helper/Emitter';
import Message from '../../components/Message';
import ModalMessageSimple from '../../components/Modals/message-simple';



class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      type: 'login',
      reset: '',
      password: '',
    };
  }


  componentDidMount() {
    document.title = 'Business Portal - PT Mate'
  }


  setType(type) {
    this.setState({type: type})
  }



  // Display stuff ------------------------------------------------------------



  renderForm() {
    return (
      <div>
        <SignInFormHome />
        <div className="social">
          <Link to={ROUTES.SIGN_UP} className='btn tertiary width-12' style={{marginTop:13, color: '#1DC5C9'}}>No account yet? Register for free here</Link>
        </div>
      </div>
    )
  }

  render() {
    if(Firebase.auth().currentUser != null) {
      if(Firebase.auth().currentUser.displayName !== null) {
        Connector.setUser()
        return (
          <OverviewPage/>
        )
      } else {
        this.props.history.push(ROUTES.SIGN_UP2);
      }
      return (
        <div/>
      )
    } else {
      return (
        <div className="content login">
          <div className="box login">
            <div style={{textAlign: 'center'}}>
              <img src="/img/logo.svg" alt="PT Mate Business Portal"/>
            </div>
            <h1 className="mt-20 mb-30">Business Portal Login</h1>
            <SignInFormHome />
            <div className="signup">
              <Link to={ROUTES.SIGN_UP} className='btn tertiary width-12'>No account yet? Register for free here</Link>
            </div>
          </div>
          <Message/>
        </div>
      )
    }
  }
}


class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: null,
    };
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    const { email, password } = this.state;
    event.preventDefault();
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        EventEmitter.dispatch('loginLogged', '');
        this.setState({
          email: '',
          password: '',
          error: null,
        });
        if(Firebase.auth().currentUser.displayName === 'client') {
          Firebase.auth().signOut()
          setTimeout(() => {
            window.location = 'https://ptmate.me'
          }, 250);
        } else {
          Connector.setUser()
          this.props.history.push(ROUTES.OVERVIEW);
        }
        
      })
      .catch(error => {
        console.log('error')
        console.log(error)
        this.setState({ error });
      });
  };
  

  render() {
    const { email, password, error } = this.state;
    return (
      <div>
        <form>
          <div className="form-item">
          <label>Email</label>
            <input type="text" placeholder="Your email address" value={email} onChange={this.onChange} name="email" />
          </div>
          <div className="form-item">
          <label>Password</label>
            <input type="password" placeholder="Your password" value={password} onChange={this.onChange} name="password" />
          </div>
          <div className="clear sv-10"></div>
          <button className="btn primary" onClick={this.onSubmit} type="submit">Log in</button>
          {error && <p className="error center">{error.message}</p>}
          <div className="sv-20"></div>
          <Link to={ROUTES.PASSWORD} className="password">Forgot your password?</Link>
          <div className="sv-30"></div>
          </form>
      </div>
    )
  }
}


const SignInFormHome = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);


export default LandingPage;
export { SignInFormHome };