import React, { Component } from 'react';
import { Link } from 'react-router-dom';



class ListTraining extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      unit: props.unit,
      color: props.color,
      columns: props.columns,
      link: props.link
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      value: props.value,
      unit: props.unit,
      color: props.color,
      columns: props.columns,
      link: props.link
    }
  }


  render() {
    var list = []
    if(this.state.columns !== undefined) {
      for(var col of this.state.columns) {
        list.push(col)
      }
    }
    list.splice(0, 1)
    return (
      <div className="box list simple highlight mb-10">
        <Link to={this.state.link}>
          <div className={"col-"+this.state.columns[0].width+(this.state.columns[0].w1280 === undefined ? '' : ' col-'+this.state.columns[0].w1280+'-1280')}>
            <div className={"icon "+this.state.color}>
              <p>{this.state.value}{this.state.unit}</p>
            </div>
            <p><strong>{this.state.columns[0].text}</strong></p>
          </div>
          {list.map(item => (
            <div className={"col-"+item.width+(item.w1280 === undefined ? '' : ' col-'+item.w1280+'-1280')} key={item.text}>
              <p>{item.text}</p>
            </div>
          ))}
          <div className="col-2">
            <button className="btn tertiary small rgt">View details</button>
          </div>
          <div className="clear"></div>
        </Link>
      </div>
    )
  }
}


export default ListTraining;