import React, { Component } from 'react';



class ModalMessageBasic extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      title: props.title ?? '',
      text: props.text,
      type: props.type ?? 'product',
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      title: props.title ?? '',
      text: props.text,
      type: props.type ?? 'product',
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  render() {
    if(this.state.show) {
      if(this.state.type === 'product') {
        return (
          <div className={'overlay '+this.state.hidden}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="lft mb-30">Product info</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
              <p className="clear" style={{whiteSpace: 'pre'}}>{this.state.text}</p>
            </div>
          </div>
        )
      } else {
        return (
          <div className={'overlay '+this.state.hidden}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="lft mb-20">Client note</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
              <h4 className="clear">{this.state.title}</h4>
              <p className="clear" style={{whiteSpace: 'pre'}}>{this.state.text}</p>
            </div>
          </div>
        )
      }
      
    } else {
      return null
    }
  }
}


export default ModalMessageBasic;