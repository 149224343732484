import React, { Component } from 'react';
import HelperClients from '../../helper/Clients';
import ListClient from '../List/client';



class ModalClients extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      multiple: props.multiple ?? false,
      title: props.title ?? 'Select a client',
      button: props.button ?? 'Send to selected',
      search: '',
      all: false,
      clients: props.clients ?? global.clients,
      clientsList: props.clients ?? global.clients,
      groups: global.clientGroups,
      selected: props.selected ?? [],
      gselected: '',
      data: props.data ?? '',
      object: props.object ?? null,
      limit: props.limit ?? true,
    };
    if(props.show) {
      this.show()
      this.setList()
      if(this.state.limit) {
        this.configureClients()
      }
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      multiple: props.multiple ?? false,
      title: props.title ?? 'Select a client',
      button: props.button ?? 'Send to selected',
      clients: props.clients ?? global.clients,
      groups: global.clientGroups,
      selected: props.selected ?? this.state.selected,
      data: props.data ?? '',
      object: props.object ?? null,
      limit: props.limit ?? true,
    }, () => {
      if(this.state.show) {
        this.show()
        this.setList()
        if(this.state.limit) {
          this.configureClients()
        }
      }
    })
  }


  configureClients() {
    if(global.loc !== '') {
      var tmp = []
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          for(var item of this.state.clients) {
            if(loc.data.clients.indexOf(item.id) !== -1) {
              tmp.push(item)
            }
          }
        }
      }
      this.setState({
        clients: tmp,
        clientsList: tmp
      })
    }
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      this.setState({
        hidden: '',
      });
    }, 100);
  }


  hide() {
    this.setState({
      hidden: 'hidden',
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }



  // Filters -----------------------------------------------------


  
  filterGroup(event) {
    this.setState({
      gselected: event.target.value
    }, () => {
      this.setList()
    })
  }


  filterClients = event => {
    this.setState({
      search: event.target.value
    }, () => {
      this.setList()
    })
  }


  setList() {
    var tmp = []
    for(var item of this.state.clients) {
      if((item.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && this.state.search !== '') || this.state.search === '') {
        tmp.push(item)
      }
    }
    if(this.state.gselected !== '') {
      for(var item2 of this.state.groups) {
        if(item2.id === this.state.gselected && tmp.length > 0) {
          for(var i=tmp.length-1; i>-1; i--) {
            if(item2.data.clients.indexOf(tmp[i].id) === -1) {
              tmp.splice(i, 1)
            }
          }
        }
      }
    }
    this.setState({clientsList: tmp})
  }


  clickElement(item) {
    if(this.state.multiple) {
      var tmp = this.state.selected
      var add = true
      if(tmp.length > 0) {
        for(var i=0; i<tmp.length; i++) {
          if(tmp[i] === item.id) {
            tmp.splice(i, 1)
            add = false
          }
        }
      }
      
      if(add) {
        tmp.push(item.id)
      }
      this.setState({
        selected: tmp
      })
    } else {
      this.hide()
      this.props.clickElement(item)
    }
  }


  selectAll() {
    if(this.state.all) {
      this.setState({
        selected: [],
        all: false
      })
    } else {
      var tmp = []
      for(var item of this.state.clientsList) {
        if((item.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && this.state.search !== '') || this.state.search === '') {
          tmp.push(item.id)
        }
      }
      this.setState({
        selected: tmp,
        all: true
      })
    }
  }



  // Display stuff -----------------------------------------------------



  getTitle() {
    var title = this.state.title
    if(this.state.multiple && this.state.selected.length > 0) {
      title = this.state.selected.length+' selected'
    }
    return title
  }


  getLine1(item) {
    var label = ''
    if(this.state.data === 'response') {
      label = HelperClients.getForm(item, this.state.object.id, 'date', this.state.object.data.pre, 'response')
    } else if(this.state.data === 'sendform') {
      label = HelperClients.getForm(item, this.state.object.id, 'date', this.state.object.data.pre, 'select')
    }
    return label
  }


  getLine2(item) {
    var label = ''
    if(this.state.data === 'response') {
      label = HelperClients.getForm(item, this.state.object.id, 'version', this.state.object.data.pre, 'response')
    } else if(this.state.data === 'sendform') {
      label = HelperClients.getForm(item, this.state.object.id, 'version', this.state.object.data.pre, 'select')
    }
    return label
  }


  getSelected(item) {
    var label = ''
    for(var client of this.state.selected) {
      if(client === item.id) {
        label = ' selected'
      }
    }
    return label
  }


  renderSearch() {
    var width = 330
    if(this.state.groups.length > 0) {
      width = 205
    }
    if(this.state.multiple) {
      width -= 40
    }
    if(this.state.groups.length === 0) {
      return (
        <div>
          <div className={this.state.multiple ? 'lft' : 'none'}>
            <div className={this.state.all ? 'checkbox container active' : 'checkbox container'} onClick={() => this.selectAll()}>
              <div className="check"><div className="inner"></div></div>
            </div>
          </div>
          <div style={{width: width,float:'right'}}>
            <input type="text" placeholder="Filter by name" value={this.state.search} onChange={(val) => this.filterClients(val)}/>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className={this.state.multiple ? 'lft' : 'none'} style={{marginRight: 2}}>
            <div className={this.state.all ? 'checkbox container active' : 'checkbox container'} onClick={() => this.selectAll()}>
              <div className="check"><div className="inner"></div></div>
            </div>
          </div>
          <div style={{width: width,float:'left'}}>
            <input type="text" placeholder="Filter by name" value={this.state.search} onChange={(val) => this.filterClients(val)}/>
          </div>
          <div style={{width: 121,float:'right'}}>
            <select onChange={event => this.filterGroup(event)} value={this.state.gselected}>
              <option value=''>All groups</option>
              {this.state.groups.map(item => (
                <option key={item.id} value={item.id}>{item.data.name}</option>
              ))}
            </select>
          </div>
        </div>     
      )
    }
  }


  renderButton() {
    if(this.state.multiple) {
      return (
        <button className="btn primary" onClick={() => this.props.clickMainButton(this.state.selected)}>{this.state.button}</button>
      )
    }
  }


  renderContent() {
    if(this.state.clientsList.length === 0) {
      return (
        <div className={this.state.multiple ? 'scroll withbutton' : 'scroll'}>
          <div className="empty small pt-50">
            <h4>No clients</h4>
          </div>
        </div>
      )
    } else {
      var list = this.state.clientsList
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className={this.state.multiple ? 'scroll withbutton' : 'scroll'}>
          {list.map(item => (
            <ListClient key={item.id} client={item} type={this.getSelected(item)} line1={this.state.data === '' ? '' : this.getLine1(item)} line2={this.state.data === '' ? '' : this.getLine2(item)} clickElement={() => this.clickElement(item)}/>
          ))}
        </div>
      )
    }
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box clients">
            <h2 className="mb-20 lft">{this.getTitle()}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <div className="clear"></div>
            {this.renderSearch()}
            <div className="clear sv-20"></div>
            {this.renderContent()}
            {this.renderButton()}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default ModalClients;