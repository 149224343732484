import React, { Component } from 'react';
import 'firebase/functions';
import Firebase from 'firebase';
import Moment from 'moment';
import Connector from '../../data/Connector';

import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class UsersPage extends Component {
  render() {
    return (
      <AuthUserContext.Consumer>
    {authUser => (
      <Users/>
    )}
  </AuthUserContext.Consumer>
    )
  }
}



class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trainers: [],
      search: '',
      order: 'date',
      user: '',
      data: null,
      value: '',
      val1: '',
      val2: '',
      clients: [],
      clientsList: [],
      showModalClient: false,
      hiddenClient: 'hidden',
    };
  }


  componentDidMount() {
    Connector.setUser()
    Connector.loadTrainers()
    global.backLink = ROUTES.ADMINASSISTANT;
    global.currentClient = '';
    global.currentSession = '';
    global.currentPage = 'admin';
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainersLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainerSelectedLoaded', (event) => this.configureTrainer())
    EventEmitter.subscribe('adminClientsLoaded', (event) => this.configureClients())
    this.configureData()
  }


  configureData() {
    var tmp = []
    for(var item of global.spaces) {
      if(item.data.name !== undefined) {
        tmp.push(item)
      }
    }
    this.setState({
      trainers: tmp
    })
  }


  configureTrainer() {
    this.setState({
      data: global.trainerSelected
    })
    if(global.adminClients.length === 0) {
      Connector.loadAdminClients()
    }
  }


  configureClients() {
    var tmp = []
    for(var item of global.adminClients) {
      if(item.data.name !== undefined) {
        tmp.push(item)
      }
    }
    this.setState({
      clients: tmp
    })
  }


  getSub(user) {
    var label = 'Spark'
    if(user.data.subPlanId !== '' && user.data.subPlanId !== undefined) {
      label = user.data.subPlanId
    }
    for(var item of global.stripePlans) {
      if(item.plan === user.data.subPlanId) {
        label = item.name
      }
    }
    return label
  }


  getStatus(user) {
    var label = ''
    if(user.data.subStatus === 'trialing') {
      label = '- Trial ends '+Moment(user.data.subEnd, 'X').format('D MMM YYYY')
    }
    if(user.data.subStatus === 'canceled') {
      label = '- Cancelled '+Moment(user.data.subEnd, 'X').format('D MMM YYYY')
    }
    return label
  }


  filterUsers = event => {
    this.setState({search: event.target.value });
    var tmp = []
    if(event.target.value !== '') {
      for(var item of global.spaces) {
        if(item.data.name !== undefined) {
          var business = '////////////'
          var email = '////////////'
          if(item.data.business !== undefined) {
            business = item.data.business
          }
          if(item.data.email !== undefined) {
            email = item.data.email
          }
          if(item.data.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 || business.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 || email.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1) {
            tmp.push(item)
          }
        }
        
      }
    } else {
      for(var item2 of global.spaces) {
        if(item2.data.name !== undefined) {
          tmp.push(item2)
        }
      }  
    }
    this.setState({trainers: tmp})
  };


  selectTrainer(item) {
    this.setState({
      user: item.id,
      selected: item
    })
    Connector.getTrainerSelected(item.id)
  }


  changeUser() {
    global.uidUser = this.state.selected.id
    global.uid = this.state.selected.id

    global.userName = Firebase.auth().currentUser.displayName

    Connector.loadUserSpace((result) => {
      // Load business data
      if(global.uidUser !== undefined && global.uidUser !== '') {
        Connector.loadStripePlans((result) => {
          //this.checkUserSubscription()
          Connector.loadStaff()
        })
      }
    })
    // Main business user
    Connector.loadUser((result) => {
      global.currentWeek = parseInt(Moment().format('w'));
      if(global.userPhone !== '') {
      if(global.uidUser !== undefined && global.uidUser !== '') {
        Connector.loadClients((result) => {
          if(global.clients.length > 0) {
            Connector.loadChats((result) => {})
            Connector.loadChatsGroup((result) => {})
          }
        })
        Connector.loadSessions((result) => {})
        Connector.loadRecurring((result) => {})
        Connector.loadPrograms((result) => {})
        Connector.loadPlans((result) => {})
        Connector.loadActivity((result) => {})
        Connector.loadExercises((result) => {})
        Connector.loadProducts((result) => {})
        Connector.loadPayments((result) => {})
        Connector.loadInvoices((result) => {})
        Connector.loadExpenses((result) => {})
        Connector.loadExpensesCat((result) => {})
        Connector.loadLog((result) => {})
        Connector.loadEvents((result) => {})
        Connector.loadCommunity((result) => {})
        Connector.loadClientGroups((result) => {})
        Connector.loadForms((result) => {})
        Connector.loadHabits((result) => {})
        Connector.loadDocuments((result) => {})
        Connector.loadEmails((result) => {})
        Connector.loadLocations((result) => {})
        Connector.loadUserSpaces()
        
        if(global.userRole !== '') {
          Connector.loadSpaceImage()
        }
        Connector.loadPublic()
      }
    }})
  }


  onChange = event => {
    var tmp1 = (parseFloat(event.target.value)/2.2046).toFixed(2)
    var tmp2 = (tmp1*2.2046).toFixed(1)
    this.setState({
      value: event.target.value,
      val1: tmp1,
      val2: tmp2
    });
  };



  // Clients modal ------------------------------------------------------------



  showModalClients() {
    var tmp = []
    for(var item of this.state.clients) {
      for(var tr of item.trainers) {
        if(tr.data.trainer === this.state.selected.id && global.trainerSelected.active.indexOf(item.id) !== -1) {
          tmp.push(item)
        }
      }
    }
    this.setState({
      showModalClient: true,
      clientsList: tmp
    })
    setTimeout(() => {
      this.setState({
        hiddenClient: '',
      });
    }, 100);
  }


  hideModalClients() {
    this.setState({
      hiddenClient: 'hidden',
    })
    setTimeout(() => {
      this.setState({showModalClient: false});
    }, 500);
  }


  renderMobileDetail(version) {
    if(version !== undefined) {
      var arr = version.split('-')
      var arr2 = arr[1].split(' ')
      //var ver = '1'
      var ver = 520
      var vcode = ''
      var ok = 'ok'
      if(arr2.length > 1) {
        ver = arr2[2]
      }
      vcode = ver.split('.')
      ver = vcode[0]+vcode[1]
      if(vcode.length > 2) {
        ver += vcode[2]
      } else {
        ver += '0'
      }
      if(version.indexOf('Android') !== -1) {
        if(parseInt(ver) < 520) {
          ok = 'update'
          if(parseInt(ver) < 440) {
            ok = 'urgent'
          }
        }
      } else {
        if(parseInt(ver) < 520) {
          ok = 'update'
          if(parseInt(ver) < 440) {
            ok = 'urgent'
          }
        }
      }
      if(ok === 'ok') {
        return ''
      } else if(ok === 'update') {
        return <span className="pill grey">Update</span>
      } else if(ok === 'urgent') {
        return <span className="pill red">Update urgently</span>
      }
    }
  }


  renderAvatar(version) {
    if(version !== undefined) {
      if(version.indexOf('Android') !== -1) {
        return <div className="avatar" style={{backgroundImage: 'url(/img/admin-android.jpg)'}}></div>
      } else {
        return <div className="avatar" style={{backgroundImage: 'url(/img/admin-apple.jpg)'}}></div>
      }
    } else {
      return <div className="avatar"></div>
    }
  }


  renderClientsContent() {
    if(this.state.clientsList.length === 0) {
      if(this.state.clients.length === 0) {
        return (
          <p className="empty">No clients connected via the Member App yet</p>
        )
      } else {
        return (
          <p className="empty">No clients</p>
        )
      }
      
    } else {
      var list = this.state.clientsList
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className="scroll">
          {list.map(item => (
            <div className="list bare" key={item.id}>
              {this.renderAvatar(item.data.mobile)}
              <div className="main">
                <h4>{item.data.name}</h4>
                <p><span>{item.data.mobile}</span><br/>{this.renderMobileDetail(item.data.mobile)}</p>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderModalClients() {
    if(this.state.showModalClient) {
      return (
        <div className={'overlay '+this.state.hiddenClient}>
          <div className="box clients">
            <h2 className="mb-20 lft">Business' clients</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalClients()}>Close</button>
            <p className="clear mb-30"><strong>{this.state.clientsList.length} connected - {this.getNumber('ok')} ok - {this.getNumber('update')} to update</strong></p>
            {this.renderClientsContent()}
          </div>
        </div>
      )
    }
  }


  getNumber(type) {
    var label = 0
    for(var item of this.state.clientsList) {
      if(item.data.mobile !== undefined) {
        var arr = item.data.mobile.split('-')
        var arr2 = arr[1].split(' ')
        var ver = '1'
        if(arr2.length > 1) {
          ver = arr2[2]
        }
        if(item.data.mobile.indexOf('Android')) {
          if(ver === '5.5.1' && type === 'ok') {
            label++
          }
          if(ver !== '5.5.1' && type !== 'ok') {
            label++
          }
        } else {
          if(ver === '5.5' && type === 'ok') {
            label++
          }
          if(ver !== '5.5' && type !== 'ok') {
            label++
          }
        }
      }
    }
    return label
  }



  // Display stuff ------------------------------------------------------------



  renderFilters() {
    return (
      <div className="listheader clear">
        <h5 className="lft" style={{opacity: '.4', marginTop: 12, marginRight: 20}}>SORT:</h5>
        <h5 className="lft" style={{marginRight: 20, cursor: 'pointer', marginTop: 12, opacity: this.state.order === 'date' ? 1 : 0.4}} onClick={() => this.setState({order: 'date'})}>BY DATE</h5>
        <h5 className="lft" style={{margin: 0, cursor: 'pointer', marginTop: 12, opacity: this.state.order === 'name' ? 1 : 0.4}} onClick={() => this.setState({order: 'name'})}>BY NAME</h5>
        <input type="text" className="search light rgt" placeholder="Filter by name" value={this.state.search} onChange={(val) => this.filterUsers(val)} />
        <div className="clear"></div>
      </div>
    )
  }


  renderDetails() {
    return (
      <div className="col-12 mt-20">
        <div className="box">
          <h4 className="lft mb-40">{this.state.selected.data.name}</h4>
          <button className="btn tertiary small close rgt" onClick={() => this.setState({user: ''})}>Close</button>
          <div className="info col-3 clear">
            <label>UID</label>
            <p>{this.state.selected.id}</p>
          </div>
          <div className="info col-3">
            <label>Email</label>
            <p>{this.state.selected.data.email}</p>
          </div>
          <div className="info col-3">
            <label>Phone</label>
            <p>{this.state.selected.data.phone}</p>
          </div>
          <div className="info col-3">
            <label>Subscription</label>
            <p>{this.getSub(this.state.selected)} {this.getStatus(this.state.selected)}</p>
          </div>
          <div className="sv-20 clear"></div>
          <div className="col-3">
            <button className="btn tertiary" onClick={() => this.changeUser()}>Hijack user</button>
          </div>
          <div className="col-3">
            <button className="btn tertiary" onClick={() => this.showModalClients()}>View clients</button>
          </div>
          <div className="sv-30 clear"></div>
        </div>
      </div>
    )
  }


  renderTrainers() {
    if(this.state.trainers.length === 0) {
      return (
        <div className="empty pt-60" style={{opacity: '.3'}}>
          <h2>Loading business users</h2>
        </div>
      )
    } else {
      var list = this.state.trainers
      if(this.state.order === 'name') {
        list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      } else {
        list.sort((a,b) => Moment(b.data.imageDate, 'DD/MM/YYYY HH:mm') - Moment(a.data.imageDate, 'DD/MM/YYYY HH:mm'));
      }
      
      return (
        <div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id} onClick={() => this.selectTrainer(item)}>
              <div className="col-5">
                <p><strong>{item.data.name !== '' ? item.data.name : '<NO NAME>'}</strong><br/>{item.data.email}</p>
                <div className="clear"></div>
              </div>
              <div className="col-4">
                <p>{this.getSub(item)} {this.getStatus(item)}</p>
              </div>
              <div className="col-3">
                <p>{item.data.uid}<br/></p>
              </div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderSwitch() {
    if(this.state.user === '') {
      return (
        <div className="col-12">
          {this.renderFilters()}
          {this.renderTrainers()}
        </div> 
      )
    } else {
      return (
        this.renderDetails()
      )
    }
  }


  render() {
    if(global.uidUser === 'L81v7rXOuTPWqVFfU89icsSOdLx1' || global.uidUser === 'EjXQG2v3hjcYr32l6oV2actRiBF2' || global.uidUser === 'T36OTWHMlhSnywhkhp6Q5yJcWKh2') {
      return (
        <div>
          <div className="content withtabs">
            {this.renderSwitch()}
          </div>
          <div className="header withtabs">
            <div className="content">
              <h2>Admin</h2>
              <Link to={'/admin/users'} className='tab active'>
                <p>Business Users</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/admin/clients'} className='tab'>
                <p>Clients</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/admin/movements'} className='tab'>
                <p>Movements</p>
                <div className="bg"></div>
              </Link>
            </div>
          </div>
          <Navigation active=''/>
          {this.renderModalClients()}
        </div>
      )
    } else {
      return (
        <div style={{paddingTop: 40}}><Link to={ROUTES.OVERVIEW} className="btn tertiary">Go to overview</Link></div>
      )
    }
  }
}



const condition = authUser => !!authUser;

export default withAuthorization(condition)(UsersPage);