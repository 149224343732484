import React, { Component } from 'react';
import Firebase from 'firebase';
import EventEmitter from '../../helper/Emitter';



class ModalPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      hidden: 'hidden',
      valuesPass: ['', '', ''],
      errorPass: [false, false, false]
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      show: props.show,
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  onChangePass = event => {
    var tmp = this.state.valuesPass
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({valuesPass: tmp});
  };


  checkPassword() {
    this.setState({errorPass: [false, false, false]})
    var tmp = [false, false, false]
    if(this.state.valuesPass[0] === '') { tmp[0] = true }
    if(this.state.valuesPass[1] === '') { tmp[1] = true }
    if(this.state.valuesPass[2] === '') { tmp[2] = true }
    this.setState({errorPass: tmp})
    if(!tmp[0] && !tmp[1] && !tmp[2]) {
      this.changePassword()
    }
  }


  changePassword() {
    this.hide()
    this.props.clickButton()
    const credential = Firebase.auth.EmailAuthProvider.credential(Firebase.auth().currentUser.email, this.state.valuesPass[2])
    Firebase.auth().currentUser.reauthenticateWithCredential(credential).then(() => {
      Firebase.auth().currentUser.updatePassword(this.state.valuesPass[0]).then(() => {
        EventEmitter.dispatch('showMessage', 'Password successfully changed');
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }).catch((error) => {
      EventEmitter.dispatch('showMessageError', error.message);
    });
  }


  renderErrorPass(num) {
    var texts = ['Please enter a password', 'Both fields need to match', 'Please enter your current password']
    if(this.state.errorPass[num]) {
      return (
        <p className="error">{texts[num]}</p>
      )
    }
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}   >
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Change password</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <p className="clear mb-20">Update your account password by entering a new one here. The values in both fields need to match.</p>
            <div className="form-item">
              <label>Your current password*</label>
              <input type="password" value={this.state.valuesPass[2]} className={this.state.errorPass[2] ? 'error': ''} name="2" onChange={this.onChangePass}/>
              {this.renderErrorPass(2)}
            </div>
            <div className="form-item">
              <label>New password*</label>
              <input type="password" placeholder="Enter a new password" value={this.state.valuesPass[0]} className={this.state.errorPass[0] ? 'error': ''} name="0" onChange={this.onChangePass}/>
              {this.renderErrorPass(0)}
            </div>
            <div className="form-item">
              <label>Confirm new password*</label>
              <input type="password" placeholder="Repeat the new password" value={this.state.valuesPass[1]} className={this.state.errorPass[1] ? 'error': ''} name="1" onChange={this.onChangePass}/>
              {this.renderErrorPass(1)}
            </div>
            <div className="sv-10"></div>
            <button className="btn primary" onClick={() => this.checkPassword()}>Change password</button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default ModalPassword;