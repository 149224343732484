import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import HelperBusiness from '../../helper/Business';



class HeaderBilling extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      type: props.type ?? 'header',
      active: props.active ?? '',
    };
  }


  renderSettings() {
    if(HelperBusiness.getRoleIncl('')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.ADMINSETTINGS} className={this.state.active === 'settings' ? 'tab active' : 'tab'}>
            <p>Settings</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.ADMINSETTINGS} className="item">
            <div className="bg"></div>
            <div className="text">Settings</div>
          </Link>
        )
      }
    }
  }


  renderLocations() {
    if(global.uidUser === 'L81v7rXOuTPWqVFfU89icsSOdLx1' || global.uidUser === 'EjXQG2v3hjcYr32l6oV2actRiBF2' || global.uidUser === 'T36OTWHMlhSnywhkhp6Q5yJcWKh2') {
      if(HelperBusiness.getRoleIncl('admin')) {
        if(this.state.type === 'header') {
          return (
            <Link to={ROUTES.LOCATIONS} className={this.state.active === 'locations' ? 'tab active' : 'tab'}>
              <p>Locations</p>
              <div className="bg"></div>
            </Link>
          )
        } else {
          return (
            <Link to={ROUTES.LOCATIONS} className="item">
              <div className="bg"></div>
              <div className="text">Locations</div>
            </Link>
          )
        }
      }
    }
  }


  renderEmails() {
    if(HelperBusiness.getRoleIncl('admin')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.ADMINEMAILS} className={this.state.active === 'emails' ? 'tab active' : 'tab'}>
            <p>Emails</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.ADMINEMAILS} className="item">
            <div className="bg"></div>
            <div className="text">Emails</div>
          </Link>
        )
      }
    }
  }


  renderLeads() {
    if(HelperBusiness.getRoleIncl('admin')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.ADMINPUBLIC} className={this.state.active === 'leads' ? 'tab active' : 'tab'}>
            <p>Leads</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.ADMINPUBLIC} className="item">
            <div className="bg"></div>
            <div className="text">Leads</div>
          </Link>
        )
      }
    }
  }


  renderStaff() {
    if(HelperBusiness.getRoleIncl('admin')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.ADMINSTAFF} className={this.state.active === 'staff' ? 'tab active' : 'tab'}>
            <p>Staff</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.ADMINSTAFF} className="item">
            <div className="bg"></div>
            <div className="text">Staff</div>
          </Link>
        )
      }
    }
  }


  render() {
    if(this.state.type === 'header') {
      return (
        <div className="track">
          <Link to={ROUTES.ADMINASSISTANT} className={this.state.active === 'overview' ? 'tab active' : 'tab'}>
            <p>Overview</p>
            <div className="bg"></div>
          </Link>
          {this.renderStaff()}
          <Link to={ROUTES.ADMINDOC} className={this.state.active === 'forms' ? 'tab active' : 'tab'}>
            <p>Forms & Documents</p>
            <div className="bg"></div>
          </Link>
          {this.renderLeads()}
          {this.renderEmails()}
          {this.renderLocations()}
          {this.renderSettings()}
        </div>
      )
    } else {
      return (
        <div>
          <Link to={ROUTES.ADMINASSISTANT} className="item">
            <div className="bg"></div>
            <div className="text">Overview</div>
          </Link>
          {this.renderStaff()}
          <Link to={ROUTES.ADMINDOC} className="item">
            <div className="bg"></div>
            <div className="text">Forms & Documents</div>
          </Link>
          {this.renderLeads()}
          {this.renderEmails()}
          {this.renderLocations()}
          {this.renderSettings()}
        </div>
      )
    }
  }
}


export default HeaderBilling;