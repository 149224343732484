import React, { Component } from 'react';
import HelperClients from '../../helper/Clients';
import HelperEmpty from '../../helper/Empty';



class InputInvited extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      type: props.type ?? '',
      rec: props.recurring ?? false,
      clients: props.clients ?? [],
      groups: props.groups ?? [],
      memberships: props.memberships ?? [],
      event: props.event ?? false,
      error: props.error ?? false,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      type: props.type ?? '',
      rec: props.recurring ?? false,
      clients: props.clients ?? [],
      groups: props.groups ?? [],
      memberships: props.memberships ?? [],
      event: props.event ?? false,
      error: props.error ?? false,
    }
  }


  getName(item, type) {
    var label = 'Name'
    if(type === 'group') {
      for(var gr of global.clientGroups) {
        if(gr.id === item.id) {
          label = gr.data.name
        }
      }
    } else if(type === 'membership') {
      for(var pr of global.products) {
        if(pr.id === item.id) {
          label = pr.data.name
        }
      }
    } else {
      for(var cl of global.clients) {
        if(cl.id === item) {
          label = cl.data.name
        }
      }
    }
    for(var staff of global.userStaff) {
      if(staff.id === item) {
        label = staff.data.name
      }
    }
    return label
  }


  getClient(id) {
    var client = null
    for(var item of global.clients) {
      if(item.id === id) {
        client = item
      }
    }
    return client
  }


  renderClientImage(item) {
    if(this.state.type === 'staff') {
      return (
        <div className="avatar">
          <img src='/img/list-staff.svg' className='staff' alt="Staff member" style={{width: 16, height: 16}}/>
        </div>
      )
    } else {
      return HelperClients.getImage(this.getClient(item))
    }
  }


  renderClients() {
    var empty = this.state.rec ? 'Select the clients\nthat can book into the sessions' : 'Select the clients\nthat can book into this session'
    if(this.state.type === 'location') {
      empty = 'Select the clients\nwith access to this location'
    }
    if(this.state.type === 'staff') {
      empty = 'Select the staff members\nwith access to this location'
    }
    if(this.state.clients.length === 0) {
      return <div className="list-item empty-item static" style={{whiteSpace: 'pre-wrap'}}>{empty}</div>
    } else {
      return (
        <div>
          {this.state.clients.map(item => (
            <div className="list-item" key={item}>
              {this.renderClientImage(item)}
              {this.getName(item, 'client')}
              <div className="btn-action" title="Remove" onClick={() => this.props.clickRemove(item)}>{HelperEmpty.renderImage('action-delete')}</div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderGroups() {
    if(this.state.groups.length === 0) {
      return <div className="list-item empty-item static" style={{whiteSpace: 'pre-wrap'}}>{this.state.rec ? 'Select the client groups\nthat can book into the sessions' : 'Select the client groups\nthat can book into this session'}</div>
    } else {
      return (
        <div>
          {this.state.groups.map(item => (
            <div className="list-item" key={item}>
              {this.getName(item, 'group')}
              <div className="btn-action" title="Remove" onClick={() => this.props.clickRemove(item)}>{HelperEmpty.renderImage('action-delete')}</div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderMemberships() {
    if(!this.state.event) {
      if(this.state.memberships.length === 0) {
        return (
          <div>
            <div className="list-item empty-item static" style={{whiteSpace: 'pre-wrap'}}>{this.state.rec ? 'Select the memberships\nthat can book into the sessions' : 'Select the memberships\nthat can book into this session'}</div>
            <div className="list-item static">
              <button className="btn tertiary small width-12" onClick={() => this.props.clickMemberships()}>Select memberships</button>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            {this.state.memberships.map(item => (
              <div className="list-item" key={item}>
                {this.getName(item, 'membership')}
                <div className="btn-action" title="Remove" onClick={() => this.props.clickRemove(item)}>{HelperEmpty.renderImage('action-delete')}</div>
              </div>
            ))}
            <div className="list-item static">
              <button className="btn tertiary small width-12" onClick={() => this.props.clickMemberships()}>Select memberships</button>
            </div>
          </div>
        )
      }
    }
  }


  renderError(message) {
    if(this.state.error) {
      return (
        <p className="error">{message}</p>
      )
    }
  }


  renderLabel() {
    if(this.state.event) {
      return (
        <div>
          <label>Clients who can book in</label>
          <div className="radios">
            <button className={this.state.type === '' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.props.clickType('')}>Any client</button>
            <button className={this.state.type === 'group' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.props.clickType('group')}>Groups</button>
            <button className={this.state.type === 'client' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.props.clickType('client')}>Individuals</button>
          </div>
        </div>
      )
    } else if(this.state.type === 'location') {
      return <label>Clients</label>
    } else if(this.state.type === 'staff') {
      return <label>Staff</label>
    } else {
      return <label>Session access</label>
    }
  }


  render() {
    if(this.state.type === '') {
      return (
        <div>
          {this.renderLabel()}
          <div className="form-list">
            <div className="list-item empty-item">{this.state.rec ? 'Sessions are open for all clients to book into' : 'This session is open for all clients to book into'}</div>
          </div>
        </div>
      )
    } else if(this.state.type === 'group') {
      return (
        <div>
          {this.renderLabel()}
          <div className={this.state.error ? 'form-list error': 'form-list'}>
            {this.renderGroups()}
            <div className="list-item static">
              <button className="btn tertiary small width-12" onClick={() => this.props.clickGroups()}>Select client groups</button>
            </div>
            {this.renderMemberships()}
          </div>
          {this.renderError(this.state.event ? 'Please select at least one client group' : 'Please select at least one client group or membership')}
        </div>
      )
    } else if(this.state.type === 'private' || this.state.type === 'client' || this.state.type === 'location' || this.state.type === 'staff') {
      return (
        <div>
          {this.renderLabel()}
          <div className={this.state.error ? 'form-list error': 'form-list'}>
            {this.renderClients()}
            <div className="list-item static">
              <button className="btn tertiary small width-12" onClick={() => this.props.clickClients()}>{this.state.type === 'staff' ? 'Select staff members' : 'Select clients'}</button>
            </div>
          </div>
          {this.renderError('Please select at least one client')}
        </div>
      )
    }
  }
}


export default InputInvited;