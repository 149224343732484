import React, { Component } from 'react';
import Moment from 'moment';

import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import Cal from '../../helper/Calendar';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetActions from '../../components/Widgets/actions';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import ListEmptySearch from '../../components/List/empty-search';
import ListSimple from '../../components/List/simple';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class ExpensesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expenses: global.expenses,
      expensesList: global.expenses,
      categories: global.expensesCat,
      dateRange: global.dateRange,
      show: 'all',
      search: '',
      amount: 0,
      previous: 0,
      num1: 0,
      num2: 0
    };
  }


  componentDidMount() {
    HelperPage.initialize('Expenses - PT Mate', ROUTES.EXPENSES)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('expensesLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var val1 = 0
      var val2 = 0
      var num1 = 0
      var num2 = 0
      var minDate = Cal.getDateRangeDiff()[0]
      var minDate2 = Cal.getDateRangeDiff()[2]

      var tmp = []
      for(var item of global.expenses) {
        if(global.loc === '' || item.data.location === global.loc) {
          var dt = Moment(item.data.date, 'X').format('X')
          if(dt > minDate) {
            tmp.push(item)
            num1++
            val1 += item.data.amount
          }
          if(dt > minDate2 && dt <= minDate) {
            num2++
            val2 += item.data.amount
          }
        }
      }
      this.configureList(tmp)

      this.setState({
        expenses: tmp,
        categories: global.expensesCat,
        amount: val1,
        previous: val2,
        num1: num1,
        num2: num2
      })
    }
  }


  configureList(expenses) {
    var tmp = []
    for(var item of expenses) {
      if(this.state.search === '' || (item.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && this.state.search !== '')) {
        tmp.push(item)
      }
    }
    this.setState({
      expensesList: tmp
    })
  }


  getExt(info) {
    var label = 'sessions'
    if(info === 'Group sessions') {
      label = 'group'
    }
    return label
  }


  getAmountDiff() {
    var num = this.state.amount-this.state.previous
    var color = ''
    var text = '-'
    if(num > 0) {
      text = '+'+global.curSym+num.toFixed(2)
      color = 'red'
    } else if(num < 0) {
      text = global.curSym+num.toFixed(2)
      color = 'green'
    } else if(num === 0 && this.state.amount !== 0) {
      text = global.curSym+num
    }
    if(global.dateRange !== '60days') {
      return (
        <span className={'pill '+color}>{text}</span>
      )
    }
  }


  getNumberDiff() {
    var num = this.state.num1-this.state.num2
    var color = ''
    var text = '-'
    if(num > 0) {
      text = '+'+num
      color = 'red'
    } else if(num < 0) {
      text = num
      color = 'green'
    } else if(num === 0 && this.state.num1 !== 0) {
      text = num
    }
    if(global.dateRange !== '60days') {
      return (
        <span className={'pill '+color}>{text}</span>
      )
    }
  }



  // Interactive stuff ------------------------------------------------------------



  setDateRange() {
    this.setState({
        dateRange: global.dateRange
    }, () => {
        this.configureData()
    });
  }


  filterExpenses = event => {
    this.setState({
      search: event.target.value
    }, () => {
      this.configureList(this.state.expenses)
    });
  }



  // Display stuff ------------------------------------------------------------



  getPastTime() {
    var label = 'in the last 30 days'
    if(this.state.dateRange === '7days') {
      label = 'in the last 7 days'
    } else if(this.state.dateRange === 'month') {
      label = 'this month'
    } else if(this.state.dateRange === 'week') {
      label = 'this week'
    } else if(this.state.dateRange === '60days') {
      label = 'in the last 60 days'
    }
    return label
  }


  renderExpenseCategory(id) {
    var label = 'General'
    for(var item of this.state.categories) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    return label
  }


  renderExpenses() {
    if(this.state.expensesList.length === 0) {
      if(this.state.search === '') {
        return (
          <ListEmpty id='noexpenses' var={this.getPastTime()} type='simple'/>
        )
      } else {
        return (
          <div>
            <div className="listheader">
              <div className="mt-5 lft">
                <ListHeader id='expenses' number={this.state.expensesList.length}/>
              </div>
              <input type="text" className="search light large rgt" placeholder="Filter by name" value={this.state.search} onChange={(val) => this.filterExpenses(val)} />
              <div className="clear"></div>
            </div>
            <ListEmptySearch id='expenses'/>
          </div>
        )
      }
      
    } else {
      var list = this.state.expensesList
      list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
      list.sort((a,b) => Moment(b.data.timestamp, 'X') - Moment(a.data.timestamp, 'X'));
      return (
        <div>
          <div className="listheader">
            <div className="mt-5 lft">
              <ListHeader id='expenses' number={this.state.expensesList.length}/>
            </div>
            <input type="text" className="search light large rgt" placeholder="Filter by name" value={this.state.search} onChange={(val) => this.filterExpenses(val)} />
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <ListSimple
              key={item.id}
              link={'/billing/edit-expense/'+item.id}
              button='edit'
              columns={[{text: item.data.name, width: 3, w1280: 6}, {text: this.renderExpenseCategory(item.data.cat), width: 3, w1280: 0}, {text: global.curSym+item.data.amount.toFixed(2), width: 2}, {text: Moment(item.data.date, 'X').format('D MMM YYYY'), width: 3}]}
            />
          ))}
          <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.NEWEXPENSE)}>Log another expense</button>
        </div>
      )
    }
  }


  renderActions() {
    var item1 = {id: 'report', link: ROUTES.REPORT}
    if(HelperBusiness.getRoleIncl('admin')) {
      item1 = {id: 'newexpense', link: ROUTES.NEWEXPENSE}
    }
    return (
      <WidgetActions
        item1={item1}
        item2={{id: '', link: ''}}
        item3={{id: '', link: ''}}
      />
    )
  }


  renderContent() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          <div className="col-9 pt-20">
            <div className='info large col-6'>
              <label>Total expenses</label>
              <p>{global.curSym}{this.state.amount.toFixed(2)}</p>
              {this.getAmountDiff()}
            </div>
            <div className='info large col-3'>
              <label>Logged</label>
              <p>{this.state.num1}</p>
              {this.getNumberDiff()}
            </div>
            <div className='info large col-3'>
              <label>Categories</label>
              <p>{this.state.categories.length}</p>
            </div>
            <div className="clear"></div>
          </div>
          {this.renderActions()}
          <div className="col-12 clear">
            {this.renderExpenses()}
            <div className="sv-80 clear"></div>
          </div>
          <div className="sv-20 clear"></div>
        </div>
        <Header type='billing' active='expenses' selector={true} updateSelect={() => this.setDateRange()}/>
        <Navigation active='billing' />
      </div>
    )
  }


  renderBase() {
    if(HelperBusiness.getRole('assistant,assistant2,trainer,cs')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='billing-locked'/>
          </div>
          <Header type='simple' title='Billing & Finance'/>
          <Navigation active='billing' />
        </div>
      )
    } else {
      return (
        this.renderContent()
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(ExpensesPage));