import React, { Component } from 'react';



class ModalOptions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      hidden: 'hidden',
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      show: props.show,
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  clickButton(num) {
    this.hide()
    if(num === 1) {
      this.props.clickButton1()
    } else if(num === 2) {
      this.props.clickButton2()
    } else if(num === 3) {
      this.props.clickButton3()
    }
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Replace program</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <p className="clear mb-30">How do you want to replace the session's program?</p>
            <button className="btn primary mb-20" onClick={() => this.clickButton(1)}>Create a new program</button>
            <button className="btn secondary mb-20" onClick={() => this.clickButton(2)}>Select another from list</button>
            <button className="btn tertiary width-12" onClick={() => this.clickButton(3)}>Delete Program</button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default ModalOptions;