import React, { Component } from 'react';
import Moment from 'moment';



class InfoPrice extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      type: props.type ?? '',
      id: props.id,
      product: props.product,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      type: props.type ?? '',
      id: props.id,
      product: props.product,
    }
  }


  renderAvailable() {
    if(this.state.type === 'payment') {
      if(this.state.id !== '') {
        var label = "Sessions of this pack don't expire"
        if(this.state.product.data.expires !== undefined) {
          label = 'Sessions of this pack expire '+Moment().add(this.state.product.data.expires, this.state.product.data.expType).format('D MMM YYYY')
        }
        if(this.state.product.data.type === 'other') {
          label = ''
        }
        return (
          <p className="small">{label}</p>
        )
      }
    } else if(this.state.type === 'debit') {
      return (
        <p className="small">Billed {this.state.product.data.billing}ly</p>
      )
    } else if(this.state.type === 'client') {
      return (
        <p className="small">Select a client</p>
      )
    }
  }


  renderStock() {
    if(this.state.product.data.stock !== undefined && this.state.product.data.stock !== -1) {
      return <p className="small">{this.state.product.data.stock} in stock</p>
    }
  }


  renderPriceLabel() {
    if(this.state.product.data.type === 'other') {
      return 'Amount due'
    } else {
      var label = 'Sessions'
      if(this.state.product.data.stype === 'group') {
        label = this.state.product.data.sessions+' group session'
        if(this.state.product.data.sessions !== 1) {
          label += 's'
        }
        if(this.state.product.data.type === 'subscription' && this.state.product.data.sessions === 0) {
          label = 'Unlimited group sessions'
        }
      } else if(this.state.product.data.stype === '11') {
        label = this.state.product.data.sessions+' 1:1 session'
        if(this.state.product.data.sessions !== 1) {
          label += 's'
        }
        if(this.state.product.data.type === 'subscription' && this.state.product.data.sessions === 0) {
          label = 'Unlimited 1:1 sessions'
        }
      } else if(this.state.product.data.stype === 'both') {
        label = this.state.product.data.sessions+' group session'
        if(this.state.product.data.sessions !== 1) {
          label += 's'
        }
        if(this.state.product.data.type === 'subscription' && this.state.product.data.sessions === 0) {
          label = 'Unlimited group sessions'
        }
        if(this.state.product.data.sessions11 !== 0) {
          label += ' & '+this.state.product.data.sessions11+' 1:1 session'
          if(this.state.product.data.sessions11 !== 1) {
            label += 's'
          }
        } else {
          label += ' & unlimited 1:1 sessions'
        }
       
      }
      return label
    }
  }


  render() {
    if(this.state.id !== '') {
      return (
        <div className="info">
          <label>{this.renderPriceLabel()}</label>
          <p className="large">{global.curSym}{this.state.product.data.price.toFixed(2)}</p>
          {this.renderAvailable()}
          {this.renderStock()}
        </div>
      )
    } else {
      return null
    }
  }
}


export default InfoPrice;