import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Moment from 'moment';
import Firebase from 'firebase';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperBusiness from '../../helper/Business';
import Header from '../../components/Headers/form';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class ExercisePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parent: '',
      id: '',
      exercises: [],
      title: 'Movement',
      desc: '',
      cat: 0,
      type: 0,
      user: '',
      muscles: [],
      values: ['', ''],
      variations: [],
      musclesEdit: [],
      files: [null],
      error: false,
      errorText: '',
      editing: false,
      showOverlay: false,
      hidden: 'hidden',
      custImages: [],
      tracks: 'reps'
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Movement Details - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)

    global.currentClient = '';
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
    this.configureData()
  }


  configureData() {
    var parent = ''
    var id = ''
    var user = ''
    var title = 'Movement'
    var desc = ''
    var cat = 0
    var type = 0
    var muscles = []
    var mstring = ""
    var tmp = []
    var tmp2 = []
    var tracks = 'reps'

    var arr = this.props.location.pathname.split('/')
    id = arr[arr.length-1]

    for(var item of global.exercises) {
      for(var i=0; i<item.data.subIds.length; i++) {
        if(item.data.subIds[i] === id) {
          parent = item.id
          //muscles = item.data.muscles
          cat = item.data.cat
          type = item.data.type
          user = item.data.user
          if(item.data.subMuscles !== undefined) {
            mstring = item.data.subMuscles[i]
          }
          if(item.data.subUnits !== undefined) {
            if(item.data.subUnits.length > i) {
              tracks = item.data.subUnits[i]
            }
            
          }
          if(mstring.charAt(0) === ',') {
            mstring = mstring.substring(1, mstring.length)
          }
          tmp.push({
            id: item.data.subIds[i],
            name: item.data.subTitles[i],
            desc: item.data.subDescs[i],
            tool: parseInt(item.data.subTools[i]),
            image: item.data.subImages[i],
            video: item.data.subVideos[i],
            unit: tracks
          })
          tmp2.push(null)
        }
      }
    }

    if(mstring !== "") {
      var arr2 = mstring.split(',')
      for(var ar of arr2) {
        muscles.push(parseInt(ar))
      }
    }

    this.setState({
      parent: parent,
      id: id,
      user: user,
      title: title,
      desc: desc,
      muscles: muscles,
      musclesEdit: muscles,
      exercises: tmp,
      cat: cat,
      type: type,
      values: [title, desc],
      variations: tmp,
      files: tmp2,
      tracks: tracks
    })
  }


  setImage(file, index) {
    var tmp = this.state.files
    var tmp2 = this.state.variations
    //var img = item.image
    /*if(item.image !== '') {
      img = obj.key+'-'+Moment().format('DDMMYYYYHHmmssSSS')+c+'.jpg'
    }*/
    tmp[index] = file
    //tmp2[index].image = URL.createObjectURL(file)
    tmp2[index].image = this.state.id+'-'+Moment().format('DDMMYYYYHHmmssSSS')+index+'.jpg'
    this.setState({
      files: tmp,
      variations: tmp2
    })
  }


  displayImage(index) {
    if(this.state.variations.length > 0) {
      if(this.state.files[index] === null) {
        if(this.state.variations[index].image === '') {
          return (
            <div className="ex-image"></div>
          )
        } else if(this.state.variations[index].image.indexOf('adm-') !== -1) {
          return (
            <div className="ex-image" style={{backgroundImage: 'url(/img/exercises/'+this.state.variations[index].image+'.jpg)'}}></div>
          )
        } else {
          return (
            <div className="ex-image" style={{backgroundImage: 'url('+this.state.variations[index].image+')'}}></div>
          )
        }
      } else {
        return (
          <div className="ex-image" style={{backgroundImage: 'url('+this.state.variations[index].image+')'}}></div>
        )
      }
    }
    
  }



   // Interactive stuff ------------------------------------------------------------



  onChange = event => {
    var tmp = this.state.values
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({values: tmp});
  };


  setCat(event) {
    this.setState({
      cat: event.target.value
    })
  }


  setType(event) {
    this.setState({
      type: event.target.value
    })
  }


  addVariation() {
    var tmp = this.state.variations
    var tmp2 = this.state.files
    tmp.push({
      name: '',
      desc: '',
      tool: 0,
      image: '',
      video: '',
      unit: 'reps'
    })
    tmp2.push(null)
    this.setState({
      variations: tmp,
      files: tmp2,
    })
  }


  deleteVariation(index) {
    var tmp = this.state.variations
    var tmp2 = this.state.files
    tmp.splice(index, 1)
    tmp2.splice(index, 1)
    this.setState({
      variations: tmp,
      files: tmp2,
    })
  }


  onChangeName(event, index) {
    var tmp = this.state.variations
    tmp[index].name = event.target.value
    this.setState({
      variations: tmp
    })
  }


  onChangeDesc(event, index) {
    var tmp = this.state.variations
    tmp[index].desc = event.target.value
    this.setState({
      variations: tmp
    })
  }


  onChangeVideo(event, index) {
    var tmp = this.state.variations
    tmp[index].video = event.target.value
    this.setState({
      variations: tmp
    })
  }


  setTool(event, index) {
    var tmp = this.state.variations
    tmp[index].tool = event.target.value
    this.setState({
      variations: tmp
    })
  }


  getMuscles(index) {
    if(this.state.musclesEdit.indexOf(index) !== -1) {
      return "ex-muscle active"
    } else {
      return "ex-muscle"
    }
  }


  setMuscles(index) {
    var add = true
    var tmp = this.state.musclesEdit
    for(var i=tmp.length-1; i>=0; i--) {
      if(tmp[i] === index) {
        add = false
        tmp.splice(i, 1)
      }
    }
    if(add) {
      tmp.push(index)
    }
    this.setState({
      musclesEdit: tmp
    })
  }


  revertEditing() {
    this.configureData()
    this.setState({
      editing: false
    })
  }


  deleteExercise() {
    this.hideOverlay()
    this.setState({
      editing: false
    })
    for(var ex of global.exercises) {
      if(ex.id === this.state.parent) {
        if(ex.data.subIds.length === 1) {
          Firebase.database().ref('/exercises/'+this.state.parent).remove()
          .then((data)=>{
            global.message = 'Movement successfully deleted'
            this.props.history.push(ROUTES.EXERCISES)
          }).catch((error)=>{
            EventEmitter.dispatch('showMessageError', error.message);
          })
        } else {
          var names = []
          var tools = []
          var descs = []
          var images = []
          var videos = []
          var muscles = []
          var tracks = []
          for(var ex2 of global.exercises) {
            if(ex2.id === this.state.parent) {
              names = ex.data.subTitles
              tools = ex2.data.subTools
              descs = ex2.data.subDescs
              images = ex2.data.subImages
              videos = ex2.data.subVideos
              if(ex2.data.subMuscles !== undefined) {
                muscles = ex2.data.subMuscles
              }
              if(ex2.data.subUnits !== undefined) {
                tracks = ex2.data.subUnits
              }
            }
            for(var i=0; i<ex2.data.subIds.length; i++) {
              if(ex2.data.subIds[i] === this.state.id) {
                names.splice(i, 1)
                tools.splice(i, 1)
                descs.splice(i, 1)
                images.splice(i, 1)
                videos.splice(i, 1)
                muscles.splice(i, 1)
                tracks.splice(i, 1)
              }
            }
          }
          if(muscles.length === 0) {
            for(var nm of names) {
              console.log(nm)
              muscles.push('')
            }
          }
          if(tracks.length === 0) {
            for(var nm2 of names) {
              console.log(nm2)
              tracks.push('reps')
            }
          }
          Firebase.database().ref('/exercises/'+this.state.parent).update({
            subTitles: names,
            subDescs: descs,
            subTools: tools,
            subImages: images,
            subVideos: videos,
            subMuscles: muscles,
            subUnits: tracks
          }).then((data)=>{
            EventEmitter.dispatch('showMessage', 'Movement successfully deleted')
            this.props.history.push(ROUTES.EXERCISES)
          }).catch((error)=>{
            EventEmitter.dispatch('showMessageError', error.message);
          })
        }
      }
    }
  }



  // Delete overlay ------------------------------------------------------------



  showOverlay() {
    this.setState({showOverlay: true})
    setTimeout(() => {
      this.setState({hidden: ''});
    }, 100);
  }


  hideOverlay() {
    this.setState({hidden: 'hidden'})
    setTimeout(() => {
      this.setState({showOverlay: false});
    }, 500);
  }


  renderOverlay() {
    if(this.state.showOverlay) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box text">
            <h3>Delete movement</h3>
            <button className="btn tertiary small close" onClick={() => this.hideOverlay()}>Close</button>
            <p className="clear">Do you want delete this movement? Programs using it won't be affected.</p>
            <button className="btn primary" onClick={() => this.deleteExercise()}>Delete movement</button>
          </div>
        </div>
      )
    }
  }



  // Create exercises ------------------------------------------------------------



  checkUpdateExercise() {
    this.setState({
      error: false,
    })
    var passed = true
    for(var item of this.state.variations) {
      if(item.name === '') {
        passed = false
      }
    }
    if(passed) {
      this.updateExercise()
    } else {
      this.setState({
        error: true,
      })
    }
  }


  updateExercise() {
    //var ids = []
    var names = []
    var tools = []
    var descs = []
    var images = []
    var videos = []
    var muscles = []
    var tracks = []
    var c = 0
    for(var ex of global.exercises) {
      if(ex.id === this.state.parent) {
        names = ex.data.subTitles
        tools = ex.data.subTools
        descs = ex.data.subDescs
        images = ex.data.subImages
        videos = ex.data.subVideos
        if(ex.data.subMuscles !== undefined) {
          muscles = ex.data.subMuscles
        }
        if(ex.data.subUnits !== undefined) {
          tracks = ex.data.subUnits
        }
      }
      for(var i=0; i<ex.data.subIds.length; i++) {
        if(ex.data.subIds[i] === this.state.id) {
          c = i;
        }
      }
    }
    if(muscles.length === 0) {
      for(var nm of names) {
        console.log(nm)
        muscles.push('')
      }
    }
    if(tracks.length === 0) {
      for(var nm2 of names) {
        console.log(nm2)
        muscles.push('reps')
      }
    }
    names[c] = this.state.variations[0].name
    descs[c] = this.state.variations[0].desc
    tools[c] = parseInt(this.state.variations[0].tool)
    images[c] = this.state.variations[0].image
    videos[c] = this.state.variations[0].video
    
    var mstring = ""
    for(var msl of this.state.musclesEdit) {
      mstring += ","+String(msl)
    }
    muscles[c] = mstring

    tracks[c] = this.state.tracks

    this.uploadImages(this.state.parent, images)
    Firebase.database().ref('/exercises/'+this.state.parent).update({
      subTitles: names,
      subDescs: descs,
      subTools: tools,
      subImages: images,
      subVideos: videos,
      subMuscles: muscles,
      subUnits: tracks
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', 'Movement successfully updated')
      setTimeout(() => {
        this.revertEditing()
      }, 500);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  uploadImages(key, ids) {
    for(var i=0; i<ids.length; i++) {
      if(this.state.files[i] !== null) {
        const sr = Firebase.storage().ref()
        const fr = sr.child('images/exercises/'+ids)
        fr.put(this.state.files[i]).then(() => {
          //
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }
    } 
  }



  // Display stuff ------------------------------------------------------------



  renderError() {
    if(this.state.error) {
      return (
        <div className="inline-message red">
          <strong>Please name your movement</strong>
        </div>
      )
    }
  }


  getImg(image) {
    var label = ''
    for(var item of this.state.custImages) {
      if(item.image === image) {
        label = item.url
      }
    }
    if(label === '') {
      Firebase.storage().ref().child(image).getDownloadURL().then((url) => {
        var tmp = this.state.custImages
        tmp.push({
          image: image,
          url: url
        })
        this.setState({
          custImages: tmp
        })
      }).catch((error) => {})
    }
    return label
  }


  renderImage(img) {
    if(img === '') {
      return (
        <div className="mov-image"></div>
      )
    } else if(img.indexOf('adm-') !== -1) {
      return (
        <img src={'/img/exercises/'+img+'.jpg'} style={{width: '100%'}} alt="Movement"/>
      )
    } else {
      return (
        <img src={this.getImg('images/exercises/'+img)+''} style={{width: '100%'}} alt="Movement"/>
      )
    }
  }


  renderVideo(item) {
    if(item.video !== '') {
      return (
        <div className="pt-20">
          <a href={item.video} target="_blank" rel="noopener noreferrer" className="btn tertiary">Watch video</a>
        </div>
      )
    }
  }


  formatDesc(desc) {
    var label = desc.split('\n')
    return (
      <p>
        {label.map(item => (
          <span key={item}>{item}<br/></span>
        ))}
      </p>
    )
    
  }


  renderTracks() {
    var co = false
    for(var item of global.exToolsWeight) {
      if(String(item) === String(this.state.variations[0].tool)) {co = true}
    }
    if(co) {
      return (
        <div>
          <div style={{height: 5}}></div>
          <label>Also tracks weight</label>
        </div>
      )
    }
  }

   
  renderEditing() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-comments withtabs">
          {this.renderError()}
          <div className="box">
            <h3>Edit movement</h3>
            <div className="clear">
              {this.displayImage(0)}
              <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], 0);}}/>
            </div>

            <div className="form">
              <label>Name*</label>
              <input type="text" value={this.state.variations[0].name} onChange={(val) => this.onChangeName(val, 0)}/>
              <label>Video</label>
              <input type="text" value={this.state.variations[0].video} onChange={(val) => this.onChangeVideo(val, 0)}/>
              <label>Equipment</label>
              <select value={this.state.variations[0].tool} onChange={event => this.setTool(event, 0)}>
                {global.exTools.map((item, index) => (
                  <option key={index} value={index}>{item} ({global.exToolsCount[index]})</option>
                ))}
              </select>
            </div>
            <div>
              <div className="space-20"></div>
              <label>Tracks</label>
              <div className="radios">
                <button className={this.state.tracks === 'reps' ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({tracks: 'reps'})}>Reps</button>
                <button className={this.state.tracks === 'dist' ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({tracks: 'dist'})}>Distance</button>
                <button className={this.state.tracks === 'cals' ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({tracks: 'cals'})}>Calories</button>
                <button className={this.state.tracks === 'time' ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({tracks: 'time'})}>Time</button>
              </div>
              {this.renderTracks()}
            </div>
            <div className="form">
              <label>Description</label>
              <textarea className="big" value={this.state.variations[0].desc} onChange={(val) => this.onChangeDesc(val, 0)}></textarea>
            </div>
          </div>

          <div className="comments">
            <div className="muscles">
              {global.exMuscles.map((item, index) => (
                <div className={'muscle col-3 '+this.getMuscles(index)} key={index} onClick={() => this.setMuscles(index)}>
                  <img src={global.exMusclesImages[index]} alt={item}/>
                  <p>{item}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="clear space-40"></div>
          <div className="width-6">
            <button className="btn primary" onClick={() => this.checkUpdateExercise()}>Update movement</button>
          </div>
          <div className="clear space-20"></div>
          <button className="btn tertiary width-12 close" onClick={() => this.showOverlay()}>Delete movement</button>
          <div className="clear space-20"></div>
          <button className="btn tertiary width-12 close" onClick={() => this.revertEditing()}>Cancel</button>
          <div className="clear space-20"></div>
        </div>

        <div className="header">
          <Link to={ROUTES.EXERCISES} className="back"></Link>
          <h1>{this.state.title}</h1>
        </div>
        <Navigation active='programming' />
        {this.renderOverlay()}
      </div>
    )
  }


  renderButton() {
    if(this.state.user === global.uid && HelperBusiness.getRoleIncl('')) {
      return (
        <div>
          <button className="btn tertiary width-12 edit" onClick={() => this.setState({editing: true})}>Edit movement</button>
          <div className="space-20"></div>
        </div>
      )
    }
  }


  renderTracksStatic(item) {
    var label = global.exToolsCount[item.tool]
    if(item.unit !== '') {
      label = 'tracks reps'
      if(item.unit === 'dist') {
        label = 'tracks distance'
      }
      if(item.unit === 'cals') {
        label = 'tracks calories'
      }
      if(item.unit === 'time') {
        label = 'tracks time'
      }
      if(global.exToolsWeight.indexOf(item.tool) !== -1) {
        label += ' and weight'
      }
    }
    return label
  }


  renderBase() {
    if(this.state.editing) {
      return (
        this.renderEditing()
      )
    } else {
      if(this.state.exercises.length > 0) {
        var item = this.state.exercises[0]
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content form-sidebar withtabs">
              <div className="col-6 mt-20">
                <div className="box">
                  <p>{this.formatDesc(item.desc)}</p>
                  {this.renderVideo(item)}
                </div>
              </div>
  
              <div className="col-6 sidebar">
                <h3 className="mt-30 mb-30">{global.exTools[item.tool]}</h3>
                <h4 className="mb-50">{this.renderTracksStatic(item)}</h4>
                <div className="muscles none">
                  {this.state.muscles.map(item => (
                    <div className="muscle col-3" key={item}>
                      <img src={global.exMusclesImages[item]} alt={global.exMuscles[item]}/>
                      <p>{global.exMuscles[item]}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="clear sv-40"></div>
              {this.renderButton()}
            </div>
            <Header title={item.name} link={ROUTES.EXERCISES}/>
            <Navigation active='programming' />
          </div>
        )
      } else {
        return (
          <div>
            <Header title={this.state.title} link={ROUTES.EXERCISES}/>
            <Navigation active='programming' />
          </div>
        )
      }
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(ExercisePage));