import React, { Component } from 'react';
import HeaderOverview from '../Headers/overview';
import HeaderClients from '../Headers/clients';
import HeaderCalendar from '../Headers/calendar';
import HeaderProgramming from '../Headers/programming';
import HeaderHealth from '../Headers/health';
import HeaderBilling from '../Headers/billing';
import HeaderAdmin from '../Headers/admin';
import HeaderAccount from '../Headers/account';



class Header extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      active: props.active ?? '',
      type: props.type ?? '',
      title: props.title ?? '',
      selector: props.selector ?? false,
      dateRange: global.dateRange,
      dates: props.dates ?? false,
      date1: props.date1,
      date2: props.date2,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      dateRange: global.dateRange,
      date1: props.date1,
      date2: props.date2,
    }
  }


  setDateRange(event) {
    global.dateRange = event.target.value
    this.setState({dateRange: event.target.value})
    this.props.updateSelect()
  }


  renderDates() {
    if(this.state.dates) {
      return (
        <div className="dates rgt">
          <label className="lft">Date range</label>
          <input type="date" className="light lft ml-10" value={this.state.date1} onChange={(event) => this.props.setDate1(event)}/>
          <input type="date" className="light lft ml-10" value={this.state.date2} onChange={(event) => this.props.setDate2(event)}/>
          <button className="btn tertiary small ml-20 mt-10" onClick={() => this.props.setDates()}>Go</button>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderSelect() {
    if(this.state.selector) {
      return (
        <div className="rgt mt-10">
          <label className="lft">Show stats of</label>
          <select className="light lft" onChange={event => this.setDateRange(event)} value={this.state.dateRange}>
            <option value='week'>This week</option>
            <option value='7days'>Last 7 days</option>
            <option value='30days'>Last 30 days</option>
            <option value='month'>This month</option>
            <option value='60days'>Last 60 days</option>
          </select>
        </div>
      )
    }
  }


  renderName() {
    if(global.userName !== '' && global.userName !== null) {
      const ar = global.userName.split(' ')
      return ar[0]
    }
  }


  render() {
    if(this.state.type === 'simple') {
      return (
        <div className="header">
          <div className="content">
            <h2>{this.state.title}</h2>
          </div>
        </div>
      ) 
    } else if(this.state.type === 'home') {
      return (
        <div className="header withtabs">
          <div className="content">
            <h2 className="lft">Welcome back, {this.renderName()}</h2>
            {this.renderSelect()}
            <div className="clear"></div>
            <HeaderOverview active={this.state.active} type='header'/>
          </div>
        </div>
      )
    } else if(this.state.type === 'clients') {
      return (
        <div className="header withtabs">
          <div className="content">
            <h2 className="lft">Clients</h2>
            {this.renderSelect()}
            <div className="clear"></div>
            <HeaderClients active={this.state.active} type='header'/>
          </div>
        </div>
      )
    } else if(this.state.type === 'calendar') {
      return (
        <div className="header withtabs">
          <div className="content">
            <h2 className="lft">Calendar</h2>
            {this.renderSelect()}
            {this.renderDates()}
            <div className="clear"></div>
            <div className="header-tabs">
              <HeaderCalendar active={this.state.active} type='header'/>
            </div>
          </div>
        </div>
      )
    } else if(this.state.type === 'programming') {
      return (
        <div className="header withtabs">
          <div className="content">
            <h2 className="lft">Programming</h2>
            {this.renderSelect()}
            <div className="clear"></div>
            <HeaderProgramming active={this.state.active} type='header'/>
          </div>
        </div>
      )
    } else if(this.state.type === 'health') {
      return (
        <div className="header withtabs">
          <div className="content">
            <h2 className="lft">Health</h2>
            {this.renderSelect()}
            <div className="clear"></div>
            <HeaderHealth active={this.state.active} type='header'/>
          </div>
        </div>
      )
    } else if(this.state.type === 'billing') {
      return (
        <div className="header withtabs">
          <div className="content">
            <h2 className="lft">Billing & Finance</h2>
            {this.renderSelect()}
            {this.renderDates()}
            <div className="clear"></div>
            <div className="header-tabs">
              <HeaderBilling active={this.state.active} type='header'/>
            </div>
          </div>
        </div>
      )
    } else if(this.state.type === 'admin') {
      return (
        <div className="header withtabs">
          <div className="content">
            <h2 className="lft">Admin</h2>
            {this.renderSelect()}
            {this.renderDates()}
            <div className="clear"></div>
            <div className="header-tabs">
              <HeaderAdmin active={this.state.active} type='header'/>
            </div>
          </div>
        </div>
      )
    } else if(this.state.type === 'account') {
      return (
        <div className="header withtabs">
          <div className="content">
            <h2 className="lft">Account</h2>
            <div className="clear"></div>
            <div className="header-tabs">
              <HeaderAccount active={this.state.active} type='header'/>
            </div>
          </div>
        </div>
      )
    }
  }
}


export default Header;