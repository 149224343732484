import React, { Component } from 'react';
import Moment from 'moment';
import 'firebase/functions';
import Connector from '../../data/Connector';
import HelperCal from '../../helper/Calendar';
import HelperBusiness from '../../helper/Business';
import HelperPage from '../../helper/Page';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import ListEmpty from '../../components/List/empty';
import HelperEmptyImg from '../../helper/EmptyLarge';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import InputDate from '../../components/Form/input-date';
import Select from '../../components/Form/select';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class CalendarReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: global.clients,
      sessionsList: [],
      sessions: global.sessions,
      archive: global.archive,
      start: Moment('01/01/2020', 'DD/MM/YYYY').format('YYYY-MM-DD'),
      end: Moment().format('YYYY-MM-DD'),
      currentClient: '',
      cgroup: '',
      location: global.loc,
      total: 0,
      pt: 0,
      group: 0,
      served: [],
      created: false,
      margin: 0,
      staff: global.userStaff,
      trainers: [],
      trainer: 'all'
    };
  }


  componentDidMount() {
    HelperPage.initialize('Calendar Reports - PT Mate', ROUTES.RECURRING)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('staffLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      if(global.archive.length === 0 && global.uidUser !== '') {
        Connector.loadArchive((result) => {})
      }
      var tmp1 = []
      var tmp2 = []
      var tmp3 = []
      var tmp4 = []
      var tmp22 = []
      for(var item1 of global.clients) {
        tmp1.push(item1)
      }
      for(var item2 of global.sessions) {
        tmp2.push(item2)
      }
      for(var item3 of global.archive) {
        tmp3.push(item3)
      } 
      for(var item4 of global.userStaff) {
        if((item4.data.role === 'assistant' || item4.data.role === 'assistant2' || item4.data.role === 'trainer') && item4.data.status === 'active') {
          tmp4.push(item4)
        }
      }
      tmp4.sort((a,b) => a.data.name - a.data.name)
      if(tmp4.length > 0) {
        tmp22 = [{value: 'all', name: 'All'}, {value: global.uidUser, name: 'Yourself'}]
        for(var t of tmp4) {
          tmp22.push({value: t.id, name: t.data.name})
        }
      }
      this.setState({
        clients: tmp1,
        sessions: tmp2,
        archive: tmp3,
        staff: tmp4,
        trainers: tmp22,
      })
    }
  }


  setClient(event) {
    this.setState({
      currentClient: event.target.value
    })
  }


  setGroup(event) {
    this.setState({
      cgroup: event,
      currentClient: '',
    }, () => {
      this.setClientList()
    })
  }


  setLocation(event) {
    this.setState({
      location: event,
      currentClient: '',
    }, () => {
      this.setClientList()
    })
  }


  setClientList() {
    var tmp = []
    var list1 = []
    var list2 = []
    var ids = []
    for(var c1 of global.clients) {
      ids.push(c1.id)
    }

    if(this.state.cgroup === '') {
      list1 = ids
    } else {
      for(var gr of global.clientGroups) {
        if(gr.id === this.state.cgroup && gr.data.clients !== undefined) {
          list1 = gr.data.clients
        }
      }
    }
    
    if(this.state.location === '') {
      list2 = list1
    } else {
      for(var loc of global.locations) {
        if(loc.id === this.state.location && loc.data.clients !== undefined) {
          list2 = loc.data.clients
        }
      }
    }
    
    for(var client of global.clients) {
      if(list1.indexOf(client.id) !== -1 && list2.indexOf(client.id) !== -1) {
        tmp.push(client)
      }
    }

    this.setState({
      clients: tmp,
    })
  }


  configureList() {
    var tmp = []
    var tmp2 = ""
    var total = 0
    var pt = 0
    var group = 0
    var served = []
    var startStr = this.state.start+' 00:00'
    var endStr = this.state.end+' 23:59'
    var start = Moment(startStr, 'YYYY-MM-DD HH:mm').format('X')
    var end = Moment(endStr, 'YYYY-MM-DD HH:mm').format('X')

    for(var client of this.state.clients) {
      if(this.state.currentClient !== '') {
        if(client.id === this.state.currentClient) {
          tmp2 += ','+client.id
        }
      } else {
        tmp2 += ","+client.id
      }
    }
    
    for(var item of this.state.sessions) {
      var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
      var add = false
      if(this.state.trainer === 'all') {
        add = true
      } else {
        if(item.data.trainerId === this.state.trainer || (item.data.trainerId === undefined && this.state.trainer === global.uidUser)) {
          add = true
        }
      }
      if(this.state.location !== '') {
        if(item.data.location === undefined) {
          add = false
        } else {
          if(item.data.location !== this.state.location) {
            add = false
          }
        }
      }
      if(add) {
        if(tmp2.indexOf(item.data.client) === -1 && !item.data.group) {
          add = false
        }
        if(item.data.group) {
          var tmpadd = false
          if(item.data.clients !== undefined) {
            for(var cl1 of item.data.clients) {
              if(tmp2.indexOf(cl1) !== -1) {
                tmpadd = true
              }
            }
          }
          add = tmpadd
        }
      }
      if(date >= start && date <= end && add && date < Moment().format('X')) {
        tmp.push(item)
        total++
        if(item.data.group) {
          group++
          if(item.data.clients !== undefined) {
            for(var att of item.data.clients) {
              if(served.indexOf(att) === -1) {
                served.push(att)
              }
            }
          }
        } else {
          pt++
          if(served.indexOf(item.data.client) === -1) {
            served.push(item.data.client)
          }
        }
      }
    }
    // Archive
    for(var item2 of this.state.archive) {
      var date2 = Moment(item2.data.date, 'DD/MM/YYYY HH:mm').format('X')
      var add2 = false
      if(this.state.trainer === 'all') {
        add2 = true
      } else {
        if(item2.data.trainerId === this.state.trainer || (item2.data.trainerId === undefined && this.state.trainer === global.uidUser)) {
          add2 = true
        }
      }
      if(this.state.location !== '') {
        if(item2.data.location === undefined) {
          add2 = false
        } else {
          if(item2.data.location !== this.state.location) {
            add2 = false
          }
        }
      }
      if(add2) {
        if(tmp2.indexOf(item2.data.client) === -1 && !item2.data.group) {
          add2 = false
        }
        if(item2.data.group) {
          var tmpadd2 = false
          if(item2.data.clients !== undefined) {
            for(var cl2 of item2.data.clients) {
              if(tmp2.indexOf(cl2) !== -1) {
                tmpadd2 = true
              }
            }
          }
          add2 = tmpadd2
        }
      }
      if(date2 >= start && date2 <= end && add2 && date2 < Moment().format('X')) {
        tmp.push(item2)
        total++
        if(item2.data.group) {
          group++
          if(item2.data.clients !== undefined) {
            for(var att2 of item2.data.clients) {
              if(served.indexOf(att2) === -1) {
                served.push(att2)
              }
            }
          }
        } else {
          pt++
          if(served.indexOf(item2.data.client) === -1) {
            served.push(item2.data.client)
          }
        }
      }
    }
    this.setState({
      sessionsList: tmp,
      total: total,
      pt: pt,
      group: group,
      served: served
    })
  }



  // Interactive stuff ------------------------------------------------------------



  createReport() {
    if(this.state.start !== '' && this.state.end !== '') {
      this.setState({
        created: true
      })
      this.configureList()
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'report');
      }, 1000);
    }
  }


  gotoSession(item) {
    if(item.data.group) { 
      this.props.history.push('/calendar/group-session/'+item.id)
    } else {
      this.props.history.push('/calendar/session/'+item.id)
    }
  }



  // Display stuff ------------------------------------------------------------



  renderName(item) {
    var label = ''
    if(item.data.group) {
      if(item.data.client !== '') {
        label = item.data.client
      } else {
        label = 'Group session'
      }
    } else {
      for(var client of this.state.clients) {
        if(client.id === item.data.client) {
          label = client.data.name
        }
      }
      for(var client2 of global.clientsInactive) {
        if(client2.id === item.data.client) {
          label = client2.data.name
        }
      }
    }
    return label
  }


  renderType(item) {
    var label = 'Group session'
    if(item.data.availability) {
      label = 'Available 1:1 session'
    }
    if(!item.data.group) {
      label = '1:1 session'
    }
    if(item.data.locationName !== undefined && item.data.locationName !== '') {
      label = item.data.locationName
    }
    return label
  }


  renderAttendance(item) {
    var label = '-'
    if(item.data.group) {
      label = '-'
      if(item.data.clients !== undefined) {
        if(item.data.clients.length > 0) {
          label = item.data.clients.length
        }
      }
    } else {
      label = '-'
      if(item.data.attendance === 3) {
        label = 'Yes'
      } else if(item.data.attendance === 4) {
        label = 'No'
      }
    }
    return label
  }


  renderWaiting(item) {
    var label = '-'
    if(item.data.waiting !== undefined) {
      if(item.data.waiting.length > 0) {
        label = item.data.waiting.length
      }
    }
    return label
  }


  renderSessions() {
    if(this.state.sessionsList.length === 0) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1 static"></div>
            <div className="img2 static"></div>
            <div className="img3 static"></div>
            <div className="img4 static"></div>
            {HelperEmptyImg.renderImage('reports')}
          </div>
          <h3 className="mb-20">Nothing found</h3>
          <p className="mb-20">No sessions match your search criteria</p>
        </div>
      )
    } else {
      var list = this.state.sessionsList
      list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
      return (
        <div>
          {list.map(item => (
            <div className="list bare" key={item.id} onClick={() => this.props.history.push('/calendar/session/'+item.id)}>
              <div className="col-4">
                <div className={item.data.group ? 'icon primary lft' : 'icon secondary lft'}>
                  <div className={item.data.group ? 'inner group' : 'inner session'}></div>
                </div>
                <p className="lft"><strong>{this.renderName(item)}</strong><br/>{this.renderType(item)}</p>
              </div>
              <div className="col-3">
                <p>{item.data.timestamp !== undefined ? Moment(item.data.timestamp, 'X').format('llll') : Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('llll')}</p>
              </div>
              <div className="col-1">
                <p>{HelperCal.getDuration(item.data.duration)}</p>
              </div>
              <div className="col-2">
                <p className="center">{this.renderAttendance(item)}</p>
              </div>
              <div className="col-2">
              <p className="center">{this.renderWaiting(item)}</p>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderPrint() {
    if(this.state.created) {
      return (
        <button className="btn secondary" onClick={() => window.print()}>Print</button>
      )
    }
  }


  renderClients() {
    if(global.clients.length > 0) {
      var list = [{value: '', name: 'All clients'}]
      if(this.state.clients.length > 0) {
        this.state.clients.sort((a, b) => a.data.name.localeCompare(b.data.name))
        for(var item of this.state.clients) {
          list.push({value: item.id, name: item.data.name})
        }
      }
      return (
        <div className="col-2">
          <Select label='Client' value={this.state.currentClient} values={list} onChange={(event) => this.setState({currentClient: event})}/>
        </div>
      )
    }
  }


  renderLogo() {
    if(global.spaceImage !== '') {
      return (
        <img src={global.spaceImage} alt={global.userBusiness}/>
      )
    } else {
      return (
        <img src="/img/logo.svg" alt="PT Mate"/>
      )
    }
  }


  renderReport() {
    if(this.state.created) {
      return (
        <div>
          <div className="col-12">
            <div className="print-header">
              {this.renderLogo()}
              <h2>{global.userBusiness}</h2>
              <h3>Session Report {Moment(this.state.start, 'YYYY-MM-DD').format('D MMM YYYY')} - {Moment(this.state.end, 'YYYY-MM-DD').format('D MMM YYYY')}</h3>
            </div>
            <div className="print-box mb-40">
              <h3 className="mb-30">Summary</h3>
              <div className="info large col-3 clear">
                <label>Total sessions</label>
                <p>{this.state.total}</p>
              </div>
              <div className="info large col-3">
                <label>1:1 Sessions</label>
                <p>{this.state.pt}</p>
              </div>
              <div className="info large col-3">
                <label>Group sessions</label>
                <p>{this.state.group}</p>
              </div>
              <div className="info large col-3">
                <label>Clients</label>
                <p>{this.state.served.length}</p>
              </div>
            </div>  
          </div>
          <div className="col-12 clear print-data mt-20">
            <h3 className="mb-10">Sessions</h3>
            <div className="list bare" style={{background: 'none', cursor: 'default'}}>
              <div className="col-4"><h5>Name</h5></div>
              <div className="col-3"><h5>Date & time</h5></div>
              <div className="col-1"><h5>Duration</h5></div>
              <div className="col-2"><h5 style={{textAlign: 'center'}}>Attended</h5></div>
              <div className="col-2"><h5 style={{textAlign: 'center'}}>Waiting list</h5></div>
              <div className="clear"></div>
            </div>
            {this.renderSessions()}
          </div>
          <div className="sv-40 clear"></div>
        </div>
      )
    }
  }


  renderLocations() {
    if(global.locations.length > 0) {
      var list = [{value: '', name: 'All'}]
      global.locations.sort((a, b) => a.data.name.localeCompare(b.data.name))
      for(var loc of global.locations) {
        list.push({value: loc.id, name: loc.data.name})
      }
      return (
        <div className="col-2">
          <Select label='Location' value={this.state.location} values={list} onChange={(event) => this.setLocation(event)}/>
        </div>
      )
    }
  }


  renderGroups() {
    if(global.clientGroups.length > 0) {
      var list = [{value: '', name: 'All'}]
      global.clientGroups.sort((a, b) => a.data.name.localeCompare(b.data.name))
      for(var clg of global.clientGroups) {
        list.push({value: clg.id, name: clg.data.name})
      }
      return (
        <div className="col-2">
          <Select label='Client group' value={this.state.cgroup} values={list} onChange={(event) => this.setGroup(event)}/>
        </div>
      )
    }
  }


  renderTrainers() {
    if(this.state.trainers.length > 0) {
      return (
        <div className="col-2">
          <Select label='Trainer' value={this.state.trainer} values={this.state.trainers} onChange={(event) => this.setState({trainer: event})}/>
        </div>
      )
    }
  }


  renderEnabled() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          <div className="col-12 noprint mt-20">
            <div className="box">
              <h2 className="mb-20">Create a report</h2>
              <div className="col-2 clear">
                <InputDate label="From" value={this.state.start} format='YYYY-MM-DD' onChange={(event) => this.setState({start: event})}/>
              </div>
              <div className="col-2">
                <InputDate label="To" value={this.state.end} format='YYYY-MM-DD' onChange={(event) => this.setState({end: event})}/>
              </div>
              {this.renderTrainers()}
              {this.renderGroups()}
              {this.renderLocations()}
              {this.renderClients()}
              <div className="col-2 mt-10 clear">
                <button className="btn primary" onClick={() => this.createReport()}>Create report</button>
              </div>
              <div className="col-2 mt-10">
                {this.renderPrint()}
              </div>
              <div className="clear"></div>
            </div>
            <div className="sv-40"></div>
          </div>
          {this.renderReport()}
        </div>
        <div className="noprint">
          <Header type='calendar' active='reports'/>
          <Navigation active='calendar' />
        </div>
      </div>
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      if(global.maxClients !== 0) {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content form-container withtabs">
              <ListEmpty id='reports-locked2'/>
            </div>
            <Header type='calendar' active='reports'/>
            <Navigation active='calendar' />
          </div>
        )
      } else {
        return (
          this.renderEnabled()
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='reports-locked' type='simple'/>
          </div>
          <Header type='simple' title='Calendar'/>
          <Navigation active='calendar' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(CalendarReportPage));