import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Firebase from 'firebase';
import 'firebase/functions';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import ListEmpty from '../../components/List/empty';
import ModalForm from '../../components/Modals/form';
import { AuthUserContext, withAuthorization } from '../../components/Session';


class AccountAffiliatesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spaces: [],
      valueReferral: '',
      showModalReferral: false,
    };
  }


  componentDidMount() {
    document.title = 'Affiliates - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)
    Connector.setUser()
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainersLoaded', (event) => this.configureData())
    this.configureData()
    if(global.spaces.length === 0) {
      Connector.loadTrainers()
    }
  }


  configureData() { 
    var tmp = []
    for(var item of global.spaces) {
      if(global.affiliates.indexOf(item.id) !== -1) {
        tmp.push(item)
      }
    }
    this.setState({
      spaces: tmp
    })
  }


  getPaying() {
    var num = 0
    for(var item of this.state.spaces) {
      if(item.data.subStatus === 'active' && item.data.subPlanId !== 'price_1NV5UQAd6uNQtfqaVnBkZ86A') {
        num++
      }
    }
    return num
  }


  getRevenue() {
    var num = 0
    for(var item of this.state.spaces) {
      if(item.data.subStatus === 'active' && item.data.subPlanId !== 'price_1NV5UQAd6uNQtfqaVnBkZ86A') {
        num += 5
      }
    }
    return num
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalReferral: false,
    })
  }


  sendReferral() {
    if(this.state.valueReferral !== '') {
      this.hideModals()
      var callFunction1 = Firebase.functions().httpsCallable('sendReferralEmail');
      callFunction1({email: this.state.valueReferral, name: global.userName, business: global.userBusiness, link: 'https://ptmate.app/affiliate/'+global.uid}).then(function(result) {})
      EventEmitter.dispatch('showMessage', 'Email invite successfully sent');
    }
  }



  // Display stuff ------------------------------------------------------------



  getStatus(item) {
    if(item.data.subStatus === 'active' && item.data.subPlanId !== 'price_1NV5UQAd6uNQtfqaVnBkZ86A') {
      return <span className="pill green">Subscribed</span>
    } else {
      return <span className="pill">Not subscribed</span>
    }
  }


  getMoney(item) {
    if(item.data.subStatus === 'active' && item.data.subPlanId !== 'price_1NV5UQAd6uNQtfqaVnBkZ86A') {
      return "$5.00"
    }
  }


  renderAffiliates() {
    if(this.state.spaces.length === 0) {
      return (
        <ListEmpty id='affiliates' type='simple'/>
      )
    } else {
      var list = this.state.spaces
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          {list.map(item => (
            <div className="box list simple highlight mb-10">
              <div className="col-4">
                <p className="lft"><strong>{item.data.name}</strong></p>
              </div>
              <div className="col-4">
                <p>{item.data.business}</p>
              </div>
              <div className="col-2">
                <p>{this.getStatus(item)}</p>
              </div>
              <div className="col-2">
                <p className="rgt">{this.getMoney(item)}</p>
              </div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          <div className="infobox primary large mb-10">
            <img src="/img/affiliates.svg" className="lft mr-20" alt="Become an ambassador"/>
            <div className="lft">
              <h2 className="mb-10" style={{color: '#ffffff'}}>Earn money by inviting others to join</h2>
              <p>Invite others to join PT Mate and earn money for each invitee converted into a paid subscriber.<br/>We'll pay you <strong>{global.curSym}5 per converted subscriber, per month</strong> for as long as they are on a paid subscription plan</p>
            </div>
            <div className="clear"></div>
          </div>
          
          <div className="infobox large mb-50">
            <p><strong>Your Affiliate link:</strong> https://ptmate.app/affiliate/{global.uid}</p>
          </div>
          <div className='info large col-4'>
            <label>Your affiliates</label>
            <p>{this.state.spaces.length}</p>
          </div>
          <div className='info large col-4'>
            <label>Subscribed</label>
            <p>{this.getPaying()}</p>
          </div>
          <div className='info large col-4'>
            <label>Your monthly revenue</label>
            <p>{global.curSym}{this.getRevenue().toFixed(2)}</p>
          </div>
          <div className="sv-30 clear"></div>
          <h3 className="mb-20">Affiliates list</h3>
          {this.renderAffiliates()}
          <div className="sv-20"></div>
          <button className="btn tertiary width-12" onClick={() => this.setState({showModalReferral: true})}>Send email invite</button>
          <div className="sv-30"></div>
        </div>
        <Header type='account' active='affiliates'/>
        <Navigation active='' />
        <ModalForm type={'referral'} show={this.state.showModalReferral} onHide={() => this.hideModals()} value1={this.state.valueReferral} updateValue1={(event) => this.setState({valueReferral: event})} clickMainButton={() => this.sendReferral()}/>
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(AccountAffiliatesPage));