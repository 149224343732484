import React, { Component } from 'react';
import Moment from 'moment';
import InputDate from '..//Form/input-date';
import InputRadio from '..//Form/radio';
import InputToggle from '..//Form/toggle';
import Select from '..//Form/select';



class ModalMessageSimple extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      value: props.value ?? 'both,all,all,false,'+Moment().add(-1, 'month').format('YYYY-MM-YY')+','+Moment().format('YYYY-MM-YY')+',both,both,both,both,both',
      stype: props.stype ?? 'clients',
      type: 'active',
      group: 'all',
      debit: 'all',
      showTime: false,
      date1: Moment().add(-1, 'month').format('YYYY-MM-DD'),
      date2: Moment().format('YYYY-MM-DD'),
      sessions: 'both',
      signups: 'both',
      disconnect: 'both',
      payment: 'both',
      nutrition: 'both',
      groups: [],
      debits: [],
    };
    if(props.show) {
      this.show()
    }
    this.configureData()
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      value: props.value ?? 'both,all,all,false,'+Moment().add(-1, 'month').format('YYYY-MM-YY')+','+Moment().format('YYYY-MM-YY')+',both,both,both,both,both',
      stype: props.stype ?? 'clients',
    }, () => {
      if(this.state.show) {
        this.show()
      }
      this.configureData()
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      this.setState({
        hidden: '',
      });
    }, 100);
  }


  hide() {
    this.setState({
      hidden: 'hidden',
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  configureData() {
    var tmp1 = [{value: 'all', name: 'All or none'}]
    var tmp2 = [{value: 'all', name: 'All or none'}]
    for(var item1 of global.clientGroups) {
      tmp1.push({value: item1.id, name: item1.data.name})
    }
    for(var item2 of global.products) {
      if(item2.data.type === 'subscription') {
        tmp2.push({value: item2.id, name: item2.data.name})
      }
    }
    var ar = this.state.value.split(',')
    var show = false
    if(ar[3] === 'true') {
      show = true
    }
    this.setState({
      groups: tmp1,
      debits: tmp2,
      type: ar[0],
      group: ar[1],
      debit: ar[2],
      showTime: show,
      date1: ar[4],
      date2: ar[5],
      sessions: ar[6],
      signups: ar[7],
      disconnect: ar[8],
      payment: ar[9],
      nutrition: ar[10],
    })
  }


  updateValues(key, value) {
    this.setState({
      [key]: value
    }, () => {
      var label = this.state.type+','+this.state.group+','+this.state.debit+','+String(this.state.showTime)+','+this.state.date1+','+this.state.date2+','+this.state.sessions+','+this.state.signups+','+this.state.disconnect+','+this.state.payment+','+this.state.nutrition
      this.props.updateFilter(label)
    })
  }


  updateType(event) {
    if(event === 'clients') {
      var label = 'both,all,all,false,'+Moment().add(-1, 'month').format('YYYY-MM-YY')+','+Moment().format('YYYY-MM-YY')+',both,both,both,both,both'
      this.props.updateFilter(label)
    }
    this.props.updateType(event)
  }


  clickMainButton() {
    this.hide()
    this.props.clickMainButton()
  }


  renderTime() {
    if(this.state.showTime) {
      return (
        <div>
          <InputDate label={'From'} value={this.state.date1} format='YYYY-MM-DD' max={Moment().format('YYYY-MM-DD')} onChange={(event) => this.updateValues('date1', Moment(event).format('YYYY-MM-DD'))}/>
          <InputDate label={'To'} value={this.state.date2} format='YYYY-MM-DD' max={Moment().format('YYYY-MM-DD')} onChange={(event) => this.updateValues('date2', Moment(event).format('YYYY-MM-DD'))}/>
          <div className="space-10"></div>
          <InputRadio
            label='Have the clients attended sessions in the time period?'
            value={this.state.sessions}
            clickElement={(event) => this.updateValues('sessions', event)}
            values={[{name: 'Yes', value: 'yes'}, {name: 'No', value: 'no'}, {name: 'Both', value: 'both'}]}
          />
          <div className="space-10"></div>
          <InputRadio
            label='Have the clients connected in the time period?'
            value={this.state.signups}
            clickElement={(event) => this.updateValues('signups', event)}
            values={[{name: 'Yes', value: 'yes'}, {name: 'No', value: 'no'}, {name: 'Both', value: 'both'}]}
          />
          <div className="space-10"></div>
          <InputRadio
            label='Have the clients disconnected in the time period?'
            value={this.state.disconnect}
            clickElement={(event) => this.updateValues('disconnect', event)}
            values={[{name: 'Yes', value: 'yes'}, {name: 'No', value: 'no'}, {name: 'Both', value: 'both'}]}
          />
          <div className="space-10"></div>
          <InputRadio
            label='Did the clients make a payment in the time period?'
            value={this.state.payment}
            clickElement={(event) => this.updateValues('payment', event)}
            values={[{name: 'Yes', value: 'yes'}, {name: 'No', value: 'no'}, {name: 'Both', value: 'both'}]}
          />
          <div className="space-10"></div>
          <InputRadio
            label='Did the clients have an active nutrition plan?'
            value={this.state.nutrition}
            clickElement={(event) => this.updateValues('nutrition', event)}
            values={[{name: 'Yes', value: 'yes'}, {name: 'No', value: 'no'}, {name: 'Both', value: 'both'}]}
          />
        </div>
      )
    }
  }


  renderForm() {
    if(this.state.stype === 'clients') {
      return (
        <div className="clear scroll">
          <InputRadio
            label='Send to'
            value={this.state.stype}
            clickElement={(event) => this.updateType(event)}
            values={[{name: 'Clients', value: 'clients'}, {name: 'Leads', value: 'leads'}]}
          />
          <InputRadio
            label='Clients'
            value={this.state.type}
            clickElement={(event) => this.updateValues('type', event)}
            values={[{name: 'Active', value: 'active'}, {name: 'Inactive', value: 'inactive'}, {name: 'Both', value: 'both'}]}
          />
          <Select label='Client group' value={this.state.group} values={this.state.groups} onChange={(event) => this.updateValues('group', event)}/>
          <Select label='Clients that are on a membership' value={this.state.debit} values={this.state.debits} onChange={(event) => this.updateValues('debit', event)}/>
          <InputToggle label='Set date range' value={this.state.showTime} onChange={(event) => this.updateValues('showTime', event)} text="Turn this on to select a time period and define your parameters, such as sessions attended and new sign-ups."/>
          {this.renderTime()}
        </div>
      )
    } else {
      return (
        <div className="clear scroll">
          <InputRadio
            label='Send to'
            value={this.state.stype}
            clickElement={(event) => this.updateType(event)}
            values={[{name: 'Clients', value: 'clients'}, {name: 'Leads', value: 'leads'}]}
          />
        </div>
      )
    }
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box clients">
            <h2 className="mb-20 lft">Filter clients</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            {this.renderForm()}
            <button className="btn primary" onClick={() => this.clickMainButton()}>Apply filter</button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default ModalMessageSimple;