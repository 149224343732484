import React, { Component } from 'react';
import Moment from 'moment';
import InputText from '../../components/Form/input-text';
import Textarea from '../../components/Form/textarea';
import InputNumber from '../../components/Form/input-number';
import InputDate from '../../components/Form/input-date';
import InputFile from '../../components/Form/input-file';
import Select from '../../components/Form/select';
import InputRadio from '../../components/Form/radio';


const DataForm = [
  {
    id: 'referral',
    title: 'Send an email invite',
    text: "Send your affiliate link to a friend. When they sign up to a paid subscription with PT Mate, you'll earn $5 per month for as long as they are subscribed to a paid plan.",
    input1: 'text',
    label1: 'Email to send the invite to*',
    button: 'Send invite',
  },
  {
    id: 'editnote',
    title: 'Edit note',
    text: "",
    input1: 'text',
    label1: 'Title*',
    input2: 'textarea',
    label2: 'Description',
    button: 'Update note',
    secondary: 'Delete this note'
  },
  {
    id: 'leadspage',
    title: 'Page details',
    text: "This description will be displayed on your public leads page. You can edit your colour scheme and logo on the Business Settings tab.",
    input1: 'textarea',
    label1: 'Description (optional)',
    button: 'Update description',
  },
  {
    id: 'pausedebit',
    title: 'Pause membership?',
    text: "This will pause the membership and your client won't be charged until the resume date. After that, the first charge will happen on the next scheduled date.",
    input1: 'date',
    label1: 'Pause until',
    button: 'Pause membership',
  },
  {
    id: 'assignfamily',
    title: 'Assign membership',
    text: "Select the family member you want to assign this membership to.",
    input1: 'select',
    label1: '',
    button: 'Save changes',
  },
  {
    id: 'newcategory',
    title: 'Add a category',
    text: '',
    input1: 'text',
    label1: 'Name',
    button: 'Add category',
  },
  {
    id: 'prevsessions',
    title: 'Sessions before PT Mate',
    text: 'If you want to transfer your previous client sessions over to PT Mate, you can enter the amount here.',
    input1: 'number',
    label1: '1:1 sessions',
    input2: 'number',
    label2: 'Group sessions',
    button: 'Update sessions',
  },
  {
    id: 'habitday',
    title: '$vartitle',
    text: '$var',
    input1: 'radio',
    label1: 'Status',
    input2: 'textarea',
    label2: 'Notes (optional)',
    button: 'Save changes',
  },
  {
    id: 'createpost',
    title: 'Create a new post',
    text: '',
    input1: 'textarea',
    label1: 'Post',
    input2: 'file',
    label2: 'Add an image',
    button: 'Create your post',
  },
  {
    id: 'editpost',
    title: 'Edit your post',
    text: '',
    input1: 'textarea',
    label1: 'Post',
    input2: 'file',
    label2: 'Image',
    button: 'Save changes',
  },
  {
    id: 'createreply',
    title: 'Reply to a post',
    text: '',
    input1: 'textarea',
    label1: '',
    button: 'Reply',
  },
  {
    id: 'editreply',
    title: 'Edit your reply',
    text: '',
    input1: 'textarea',
    label1: '',
    button: 'Save changes',
  },
  {
    id: 'blocknotes',
    title: 'Notes',
    text: '',
    input1: 'textarea',
    label1: 'Notes',
    button: 'Done',
  },
  {
    id: 'exnotes',
    title: 'Movement notes',
    text: '',
    input1: 'textarea',
    label1: 'Notes',
    button: 'Done',
  },
  {
    id: 'testemail',
    title: 'Send a test email',
    text: '',
    input1: 'text',
    label1: 'Email address',
    button: 'Send',
  },
]

class ModalForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      value1: props.value1 ?? '',
      values1: props.values1 ?? [],
      value2: props.value2 ?? '',
      values2: props.values2 ?? [],
      var: props.var ?? '',
      vartitle: props.vartitle ?? '',
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      value1: props.value1 ?? '',
      values1: props.values1 ?? [],
      value2: props.value2 ?? '',
      values2: props.values2 ?? [],
      var: props.var ?? '',
      vartitle: props.vartitle ?? '',
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  clickMainButton() {
    this.hide()
    this.props.clickMainButton()
  }


  clickSecondary() {
    this.hide()
    this.props.clickSecondary()
  }


  renderText(text) {
    if(text !== '') {
      return (
        <p className="clear mb-20">{text}</p>
      )
    }
  }


  renderInput2(data) {
    if(data.input2 !== undefined) {
      if(data.input2 === 'text') {
        return <InputText label={data.label2} value={this.state.value2} onChange={(event) => this.props.updateValue2(event)}/>
      } else if(data.input2 === 'number') {
        return <InputNumber label={data.label2} value={this.state.value2} onChange={(event) => this.props.updateValue2(event)}/>
      } else if(data.input2 === 'date') {
        return <InputDate label={data.label2} value={this.state.value2} format='YYYY-MM-DD' onChange={(event) => this.props.updateValue2(Moment(event).format('YYYY-MM-DD'))}/>
      } else if(data.input2 === 'textarea') {
        return <Textarea label={data.label2} value={this.state.value2} onChange={(event) => this.props.updateValue2(event)}/>
      } else if(data.input2 === 'file') {
        return <InputFile label={data.label2} onChange={(event) => this.props.updateValue2(event)}/>
      }
    }
  }


  renderInput1(data) {
    if(data.input1 === 'text') {
      return <InputText label={data.label1} value={this.state.value1} onChange={(event) => this.props.updateValue1(event)}/>
    } else if(data.input1 === 'number') {
      return <InputNumber label={data.label1} value={this.state.value1} onChange={(event) => this.props.updateValue1(event)}/>
    } else if(data.input1 === 'date') {
      return <InputDate label={data.label1} value={this.state.value1} format='YYYY-MM-DD' onChange={(event) => this.props.updateValue1(Moment(event).format('YYYY-MM-DD'))}/>
    } else if(data.input1 === 'select') {
      return <Select label={data.label1} value={this.state.value1} values={this.state.values1} onChange={(event) => this.props.updateValue1(event)}/>
    } else if(data.input1 === 'textarea') {
      return <Textarea label={data.label1} value={this.state.value1} onChange={(event) => this.props.updateValue1(event)}/>
    } else if(data.input1 === 'radio') {
      return <InputRadio label={data.label1} value={this.state.value1} values={this.state.values1} clickElement={(event) => this.props.updateValue1(event)}/>
    } else if(data.input1 === 'file') {
      return <InputFile label={data.label1} onChange={(event) => this.props.updateValue1(event)}/>
    }
  }


  renderSecondaryButton(data) {
    if(data.secondary !== undefined) {
      return (
        <div>
          <div className="sv-20"></div>
          <button className="btn tertiary width-12" onClick={() => this.clickSecondary()}>{data.secondary}</button>
        </div>
      )
    }
  }


  render() {
    var data = null
    var text = ''
    for(var item of DataForm) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(this.state.show && data !== null) {
      data.title = data.title.replace('$vartitle', this.state.vartitle)
      text = data.text.replace('$var', this.state.var)
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{data.title}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            {this.renderText(text)}
            {this.renderInput1(data)}
            {this.renderInput2(data)}
            <div className="sv-10"></div>
            <button className="btn primary" onClick={() => this.clickMainButton()}>{data.button}</button>
            {this.renderSecondaryButton(data)}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default ModalForm;