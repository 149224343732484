import React, { Component } from 'react';
import Firebase from 'firebase';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Cal from '../../helper/Calendar';
import HelperPage from '../../helper/Page';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalItems from '../../components/Modals/items';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class PlanPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      values: ['','','', ''],
      week: '',
      day: 'day1',
      showModalPrograms: false,
      showModalDelete: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Edit Training Plan - PT Mate')
    HelperPage.checkMessage()
    this._isMounted = true
    global.currentDay = 'day1'
    global.planLocked = false;

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('plansLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      var id = arr[arr.length-1]
      var tmp1 = null
      var tmp2 = ''
      var values = []
      for(var item of global.plans) {
        if(item.id === id) {
          tmp1 = item
          tmp2 = item.id
          values = [item.data.name, item.data.description, item.data.video, item.data.sessions]
          global.tmpweeks = item.weeks
          global.tmpid = item.id
        }
      }

      if(this.state.item !== null) {
        if(global.currentWeekPlan === '') {
          global.currentDay = 'day1'
          global.currentWeekPlan = item.weeks[0].id
        }
      }

      global.linkPro = '/programming/edit-plan/'+id;

      var tmp3 = []
      for(var client of global.clients) {
        if(client.data.uid !== '') {
          tmp3.push(client)
        }
      }

      this.setState({
        id: tmp2,
        item: tmp1,
        values: values
      })
    }
  }



  // Interactive stuff ------------------------------------------------------------



  updateValues(val, pos) {
    var tmp = this.state.values
    tmp[pos] = val
    this.setState({
      values: tmp,
    });
  }


  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    var ar1 = result.draggableId.split('||')
    var id = ar1[0]
    
    var ar2 = result.source.droppableId.split('||')
    var ar3 = result.destination.droppableId.split('||')

    var week1 = ar2[1]
    var day1 = 'day'+ar2[2]
    var list1 = []
    var week2 = ar3[1]
    var day2 = 'day'+ar3[2]
    var list2 = []
    var tl2 = []

    for(var wk1 of global.tmpweeks) {
      if(wk1.id === week1) {
        if(day1 === 'day1') {
          list1 = wk1.data.day1.split(',')
          list1.splice(0,1)
          list1.splice(result.source.index, 1)
        }
        if(day1 === 'day2') {
          list1 = wk1.data.day2.split(',')
          list1.splice(0,1)
          list1.splice(result.source.index, 1)
        }
        if(day1 === 'day3') {
          list1 = wk1.data.day3.split(',')
          list1.splice(0,1)
          list1.splice(result.source.index, 1)
        }
        if(day1 === 'day4') {
          list1 = wk1.data.day4.split(',')
          list1.splice(0,1)
          list1.splice(result.source.index, 1)
        }
        if(day1 === 'day5') {
          list1 = wk1.data.day5.split(',')
          list1.splice(0,1)
          list1.splice(result.source.index, 1)
        }
        if(day1 === 'day6') {
          list1 = wk1.data.day6.split(',')
          list1.splice(0,1)
          list1.splice(result.source.index, 1)
        }
        if(day1 === 'day7') {
          list1 = wk1.data.day7.split(',')
          list1.splice(0,1)
          list1.splice(result.source.index, 1)
        }
        var string1 = ''
        for(var l1 of list1) {
          string1 += ','+l1
        }
        Firebase.database().ref('/plans/'+global.uid+'/'+global.tmpid+'/weeks/'+week1).update({
          [day1]: string1
        })
      }
    }
    for(var wk2 of global.tmpweeks) {
      if(wk2.id === week2) {
        if(day2 === 'day1') {
          tl2 = wk2.data.day1.split(',')
          tl2.splice(0,1)
          list2 = [...tl2.slice(0, result.destination.index), id, ...tl2.slice(result.destination.index)]
        }
        if(day2 === 'day2') {
          tl2 = wk2.data.day2.split(',')
          tl2.splice(0,1)
          list2 = [...tl2.slice(0, result.destination.index), id, ...tl2.slice(result.destination.index)]
        }
        if(day2 === 'day3') {
          tl2 = wk2.data.day3.split(',')
          tl2.splice(0,1)
          list2 = [...tl2.slice(0, result.destination.index), id, ...tl2.slice(result.destination.index)]
        }
        if(day2 === 'day4') {
          tl2 = wk2.data.day4.split(',')
          tl2.splice(0,1)
          list2 = [...tl2.slice(0, result.destination.index), id, ...tl2.slice(result.destination.index)]
        }
        if(day2 === 'day5') {
          tl2 = wk2.data.day5.split(',')
          tl2.splice(0,1)
          list2 = [...tl2.slice(0, result.destination.index), id, ...tl2.slice(result.destination.index)]
        }
        if(day2 === 'day6') {
          tl2 = wk2.data.day6.split(',')
          tl2.splice(0,1)
          list2 = [...tl2.slice(0, result.destination.index), id, ...tl2.slice(result.destination.index)]
        }
        if(day2 === 'day7') {
          tl2 = wk2.data.day7.split(',')
          tl2.splice(0,1)
          list2 = [...tl2.slice(0, result.destination.index), id, ...tl2.slice(result.destination.index)]
        }
      }
      var string2 = ''
      for(var l2 of list2) {
        string2 += ','+l2
      }
      Firebase.database().ref('/plans/'+global.uid+'/'+global.tmpid+'/weeks/'+week2).update({
        [day2]: string2
      })
    }
  }


  addWeek() {
    var num = 0
    for(var item of this.state.item.weeks) {
      if(item.data.id >= num) {
        num = item.data.id+1
      }
    }
    Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks').push({
      id: num,
      name: 'Week '+num,
      day1: '',
      day2: '',
      day3: '',
      day4: '',
      day5: '',
      day6: '',
      day7: '',
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', 'Week successfully created')
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  setCurrent(id, num) {
    global.currentWeekPlan = id
    global.currentDay = 'day'+num
    global.currentPlan = this.state.item.id
    this.props.history.push('/programming/plan/'+this.state.item.id+'/new-program')
  }


  duplicateWeek(item) {
    var num = this.state.item.weeks.length
    var list = []
    var d1 = ''
    var d2 = ''
    var d3 = ''
    var d4 = ''
    var d5 = ''
    var d6 = ''
    var d7 = ''
    var key = Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/').push()
    if(item.data.day1 !== '' && item.data.day1 !== undefined) {
      let ar1 = item.data.day1.split(',')
      ar1.splice(0,1)
      for(var a1 of ar1) {
        d1 += ','+a1+'-'+num
        list.push(a1)
      }
    }
    if(item.data.day2 !== '' && item.data.day2 !== undefined) {
      let ar2 = item.data.day2.split(',')
      ar2.splice(0,1)
      for(var a2 of ar2) {
        d2 += ','+a2+'-'+num
        list.push(a2)
      }
    }
    if(item.data.day3 !== '' && item.data.day3 !== undefined) {
      let ar3 = item.data.day3.split(',')
      ar3.splice(0,1)
      for(var a3 of ar3) {
        d3 += ','+a3+'-'+num
        list.push(a3)
      }
    }
    if(item.data.day4 !== '' && item.data.day1 !== undefined) {
      let ar4 = item.data.day4.split(',')
      ar4.splice(0,1)
      for(var a4 of ar4) {
        d4 += ','+a4+'-'+num
        list.push(a4)
      }
    }
    if(item.data.day5 !== '' && item.data.day1 !== undefined) {
      let ar5 = item.data.day5.split(',')
      ar5.splice(0,1)
      for(var a5 of ar5) {
        d5 += ','+a5+'-'+num
        list.push(a5)
      }
    }
    if(item.data.day6 !== '' && item.data.day1 !== undefined) {
      let ar6 = item.data.day6.split(',')
      ar6.splice(0,1)
      for(var a6 of ar6) {
        d6 += ','+a6+'-'+num
        list.push(a6)
      }
    }
    if(item.data.day7 !== '' && item.data.day7 !== undefined) {
      let ar7 = item.data.day7.split(',')
      ar7.splice(0,1)
      for(var a7 of ar7) {
        d7 += ','+a7+'-'+num
        list.push(a7)
      }
    }
    Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+key.key).update({
      id: num,
      name: 'Week '+(num+1),
      day1: d1,
      day2: d2,
      day3: d3,
      day4: d4,
      day5: d5,
      day6: d6,
      day7: d7,
    })
    // Programs
    for(var pr of this.state.item.programs) {
      if(list.indexOf(pr.id) !== -1) {
        Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/workouts/'+pr.id+'-'+num).update(
          pr.data
        ).then((data)=>{
          //
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
        for(var block of pr.blocks) {
          Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/workouts/'+pr.id+'-'+num+'/blocks/'+block.id).push(
            block.data
          )
        }
      }
    }
  }


  duplicateProgram(id, week, day) {
    var item = null
    for(var pr of this.state.item.programs) {
      if(pr.id === id) {
        item = pr
      }
    }
    this.setState({
      week: week,
      day: day
    }, () => {
      if(item !== null) {
        this.selectProgram(item)
      }
    })
  }


  deleteProgram(id) {
    var ar = []
    var string = ''
    var day = ''
    var weekId = ''
    for(var week of this.state.item.weeks) {
      if(week.data.day1.indexOf(id) !== -1) {
        ar = week.data.day1.split(',')
        day = 'day1'
        weekId = week.id
      } else if(week.data.day2.indexOf(id) !== -1) {
        ar = week.data.day2.split(',')
        day = 'day2'
        weekId = week.id
      } else if(week.data.day3.indexOf(id) !== -1) {
        ar = week.data.day3.split(',')
        day = 'day3'
        weekId = week.id
      } else if(week.data.day4.indexOf(id) !== -1) {
        ar = week.data.day4.split(',')
        day = 'day4'
        weekId = week.id
      } else if(week.data.day5.indexOf(id) !== -1) {
        ar = week.data.day5.split(',')
        day = 'day5'
        weekId = week.id
      } else if(week.data.day6.indexOf(id) !== -1) {
        ar = week.data.day6.split(',')
        day = 'day6'
        weekId = week.id
      } else if(week.data.day7.indexOf(id) !== -1) {
        ar = week.data.day7.split(',')
        day = 'day7'
        weekId = week.id
      }
    }
    ar.splice(0,1)
    for(var i=ar.length-1; i>=0; i--) {
      if(ar[i] === id) {
        ar.splice(i, 1)
      }
    }
    for(var pr of ar) {
      string += ','+pr
    }
    if(day === 'day1') {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+weekId).update({
        day1: string
      })
    } else if(day === 'day2') {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+weekId).update({
        day2: string
      })
    } else if(day === 'day3') {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+weekId).update({
        day3: string
      })
    } else if(day === 'day4') {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+weekId).update({
        day4: string
      })
    } else if(day === 'day5') {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+weekId).update({
        day5: string
      })
    } else if(day === 'day6') {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+weekId).update({
        day6: string
      })
    } else if(day === 'day7') {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+weekId).update({
        day7: string
      })
    }
    Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/workouts/'+id).remove()
    .then((data)=>{
      EventEmitter.dispatch('showMessage', 'Program successfully deleted');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  finishEditing() {
    var name = this.state.values[0]
    var sessions = this.state.values[3]
    if(name === '') {
      name = 'New Training Plan'
    }
    if(sessions === '') {
      sessions = '0'
    }
    Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id).update({
      name: name,
      description: this.state.values[1],
      video: this.state.values[2],
      sessions: parseInt(sessions)
    })
    .then((data)=>{
      this.props.history.push('/programming/plan/'+this.state.item.id)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Modals ------------------------------------------------------------



  showModalDelete(week) {
    this.setState({
      week: week,
      showModalDelete: true
    })
  }


  showModalPrograms(week, day) {
    this.setState({
      day: 'day'+day,
      week: week,
      showModalPrograms: true
    })
  }


  hideModals() {
    this.setState({
      showModalDelete: false,
      showModalPrograms: false,
    })
  }


  selectProgram(item) {
    var ar = []
    var string = ''
    for(var week of this.state.item.weeks) {
      if(week.id === this.state.week) {
        if(this.state.day === 'day1') {
          ar = week.data.day1.split(',')
          string = week.data.day1
        } else if(this.state.day === 'day2') {
          ar = week.data.day2.split(',')
          string = week.data.day2
        } else if(this.state.day === 'day3') {
          ar = week.data.day3.split(',')
          string = week.data.day3
        } else if(this.state.day === 'day4') {
          ar = week.data.day4.split(',')
          string = week.data.day4
        } else if(this.state.day === 'day5') {
          ar = week.data.day5.split(',')
          string = week.data.day5
        } else if(this.state.day === 'day6') {
          ar = week.data.day6.split(',')
          string = week.data.day6
        } else if(this.state.day === 'day7') {
          ar = week.data.day7.split(',')
          string = week.data.day7
        }
      }
    }
    var obj = Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/workouts/').push()
    ar.push(obj.key)
    string += ','+obj.key
    Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/workouts/'+obj.key).update(
      item.data
    ).then((data)=>{
      EventEmitter.dispatch('showMessage', 'Program successfully added');
      this.updateDay(string)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    this.hideModals()
  }


  updateDay(string) {
    if(this.state.day === 'day1') {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+this.state.week).update({
        day1: string
      })
    } else if(this.state.day === 'day2') {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+this.state.week).update({
        day2: string
      })
    } else if(this.state.day === 'day3') {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+this.state.week).update({
        day3: string
      })
    } else if(this.state.day === 'day4') {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+this.state.week).update({
        day4: string
      })
    } else if(this.state.day === 'day5') {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+this.state.week).update({
        day5: string
      })
    } else if(this.state.day === 'day6') {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+this.state.week).update({
        day6: string
      })
    } else if(this.state.day === 'day7') {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+this.state.week).update({
        day7: string
      })
    }
  }


  deleteWeek() {
    this.hideModals()
    var progs = []
    var num = 0
    for(var week of this.state.item.weeks) {
      if(week.id === this.state.week) {
        num = week.data.id
        var ar1 = week.data.day1.split(',')
        for(var item1 of ar1) { progs.push(item1) }
        var ar2 = week.data.day2.split(',')
        for(var item2 of ar2) { progs.push(item2) }
        var ar3 = week.data.day3.split(',')
        for(var item3 of ar3) { progs.push(item3) }
        var ar4 = week.data.day4.split(',')
        for(var item4 of ar4) { progs.push(item4) }
        var ar5 = week.data.day5.split(',')
        for(var item5 of ar5) { progs.push(item5) }
        var ar6 = week.data.day6.split(',')
        for(var item6 of ar6) { progs.push(item6) }
        var ar7 = week.data.day7.split(',')
        for(var item7 of ar7) { progs.push(item7) }
      }
    }
    for(var i=progs.length-1; i>=0; i--) {
      if(progs[i] === '') {
        progs.splice(i, 1)
      }
    }
    for(var wk of this.state.item.weeks) {
      if(wk.data.id > num && wk.id !== this.state.week) {
        var newNum = parseInt(wk.data.id)-1
        Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+wk.id).update({
          id: newNum,
          name: 'Week '+newNum
        })
      }
    }
    for(var pr of progs) {
      Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/workouts/'+pr).remove()
    }
    Firebase.database().ref('/plans/'+global.uid+'/'+this.state.item.id+'/weeks/'+this.state.week).remove()
    .then((data) => {
      EventEmitter.dispatch('showMessage', 'Week successfully deleted')
      
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Display stuff ------------------------------------------------------------



  renderProgramData(id) {
    var mins = ''
    var exercises = ''
    var name = ''
    for(var item of this.state.item.programs) {
      if(item.id === id) {
        name = item.data.name
        exercises = item.data.exercises+' movements'
        mins = Cal.getDuration(item.data.time)
        if(item.data.exercises === 1) {
          exercises = item.data.exercises+' movement'
        }
      }
    }
    return [name, exercises, mins]
  }


  renderEmpty(programs) {
    if(programs.length === 0) {
      return (
        <div className="empty small pt-30">
          <h4>Rest day</h4>
          <p>Add a program to<br/>make it a training day</p>
        </div>
      )
    }
  }


  renderWeekday(id, day) {
    var programs = []
    for(var item of this.state.item.weeks) {
      if(item.id === id) {
        if(day === 1) {
          programs = item.data.day1.split(',')
        } else if(day === 2) {
          programs = item.data.day2.split(',')
        } else if(day === 3) {
          programs = item.data.day3.split(',')
        } else if(day === 4) {
          programs = item.data.day4.split(',')
        } else if(day === 5) {
          programs = item.data.day5.split(',')
        } else if(day === 6) {
          programs = item.data.day6.split(',')
        } else if(day === 7) {
          programs = item.data.day7.split(',')
        }
      }
    }
    programs.splice(0,1)
    return (
      <div>
        
        <Droppable droppableId={'droppable||'+id+'||'+day}>
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="area">
              <span>{this.renderEmpty(programs)}</span>
              {programs.map((item, index) => (
                <Draggable draggableId={item+'||'+id+'||'+day} index={index} key={item+id+day}>
                  
                  {(provided, snapshot) => (
                    
                    <div className="cal-session" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <p><strong>{this.renderProgramData(item)[0]}</strong><br/>{this.renderProgramData(item)[1]}</p>
                      <div className="actions">
                        <p>Drag to move</p>
                        <Link to={'/programming/plan/'+this.state.item.id+'/edit-program/'+item} className="btn tertiary edit clear lft">Edit</Link>
                        <button className="btn tertiary add lft" onClick={() => this.duplicateProgram(item, id, 'day'+day)}>Duplicate</button>
                        <button className="btn tertiary close lft" onClick={() => this.deleteProgram(item)}>Delete</button>
                        <div className="clear"></div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <div className="btns">
          <button className="btn tertiary small width-12" onClick={() => this.showModalPrograms(id, day)}>Add existing program</button>
          <div className="sv-10"></div>
          <button className="btn tertiary small add width-12"onClick={() => this.setCurrent(id, day)}>Create a new program</button>
          <div className="sv-10"></div>
        </div>
      </div>
    )
  }


  renderDelete(index) {
    if(this.state.item.weeks.length > 1) {
      return (
        <button className="btn tertiary small close rgt" onClick={() => this.showModalDelete(index)} style={{marginLeft: 10}}>Delete</button>
      )
    }
  }


  renderWeeks() {
    return (
      <div>
        <DragDropContext onDragEnd={this.onDragEnd}>
        {this.state.item.weeks.map((item, index) => (
          <div key={item.id}>
            <div className="box mb-20">
              <h3 className="mb-20 lft">Week {index+1}</h3>
              {this.renderDelete(item.id)}
              <button className="btn tertiary small add rgt" onClick={() => this.duplicateWeek(item)}>Duplicate</button>
              <div className="planweek clear calmain calendar">
                <div style={{minWidth: '1000px'}}>
                  <div className="weekday cal-top lft edit">
                    <h3 className="mb-10">Day 1</h3>
                    {this.renderWeekday(item.id, 1)}
                  </div>
                  <div className="weekday cal-top lft edit">
                    <h3 className="mb-10">Day 2</h3>
                    {this.renderWeekday(item.id, 2)}
                  </div>
                  <div className="weekday cal-top lft edit">
                    <h3 className="mb-10">Day 3</h3>
                    {this.renderWeekday(item.id, 3)}
                  </div>
                  <div className="weekday cal-top lft edit">
                    <h3 className="mb-10">Day 4</h3>
                    {this.renderWeekday(item.id, 4)}
                  </div>
                  <div className="weekday cal-top lft edit">
                    <h3 className="mb-10">Day 5</h3>
                    {this.renderWeekday(item.id, 5)}
                  </div>
                  <div className="weekday cal-top lft edit">
                    <h3 className="mb-10">Day 6</h3>
                    {this.renderWeekday(item.id, 6)}
                  </div>
                  <div className="weekday cal-top lft edit last">
                    <h3 className="mb-10">Day 7</h3>
                    {this.renderWeekday(item.id, 7)}
                  </div>
                  <div className="clear"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
        </DragDropContext>
      </div>
    )
  }


  renderContent() {
    if(this.state.item !== null) {
      return (
        <div>
          <div className="infobox primary mb-30">
            <p>Please note: Any changes you make to the weeks and programs are automatically saved</p>
          </div>
          <div className="form-item">
            <div className="col-3">
              <InputText label='Name' value={this.state.values[0]} onChange={(event) => this.updateValues(event, 0)}/>
            </div>
            <div className="col-3">
              <InputText label='Description' value={this.state.values[1]} onChange={(event) => this.updateValues(event, 1)}/>
            </div>
            <div className="col-3">
              <InputText label='Video (Youtube or Vimeo)' value={this.state.values[2]} onChange={(event) => this.updateValues(event, 2)}/>
            </div>
            <div className="col-3">
              <InputNumber label='Include 1:1 sessions (optional)' value={this.state.values[3]} onChange={(event) => this.updateValues(event, 3)}/>
            </div>
            <div className="clear"></div>
          </div>
          <div className="sv-20"></div>

          {this.renderWeeks()}

          <div className="lft col-4">&nbsp;</div>
          <div className="lft col-4 pt-10">
            <button className="btn primary" onClick={() => this.addWeek()}>Add a week</button>
          </div>
          <div className="clear sv-40"></div>

          <div className="lft col-4">&nbsp;</div>
          <div className="lft col-4">
            <button className="btn secondary" onClick={() => this.finishEditing()}>Finish editing</button>
          </div>
          
          <div className="clear sv-40"></div>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content">
          {this.renderContent()}
        </div>
        <div className="btn-news" onClick={() => window.Intercom('startTour', '320671')}>Show me around</div>
        <Header title='Edit Training Plan' link={'/programming/plan/'+this.state.id}/>
        <Navigation active='programming' />
        <ModalMessageSimple type='deleteweek' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteWeek()}/>
        <ModalItems show={this.state.showModalPrograms} type={'programs'} items={global.programs} onHide={() => this.hideModals()} clickElement={(event) => this.selectProgram(event)}/>
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(PlanPage));