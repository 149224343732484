import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';



class Overview extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      hidden: 'hidden',
    };
  }


  componentWillReceiveProps(props) {
    this.setState({
      show: props.show,
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      this.setState({
        hidden: '',
      });
    }, 100);
  }


  hide() {
    this.setState({
      hidden: 'hidden',
    })
    setTimeout(() => {
      this.setState({show: false})
      this.props.onHide(false);
    }, 500);
  }


  renderContent() {
    return (
      <div className="scroll">
        <div className="list bare static">
          <div className="main">
            <img src="/img/feature.png" alt="New feature" style={{width: 300}}/>
            <p style={{fontSize: 11}}>23 Aug 2024</p>
            <h4><strong>Set up memberships on the Member App</strong></h4>
            <p>Your clients can now set up memberships on their Member App/Portal the same way they make a payment.</p>
          </div>
          <div className="clear"></div>
        </div>
        <div className="list bare static">
          <div className="main">
            <p style={{fontSize: 11}}>9 Aug 2024</p>
            <h4><strong>Our Affiliate Program is here</strong></h4>
            <p>Refer a friend, or two, or three... Earn $5 per month per new user you refer for as long as they are on a paid subscription.<br/><Link to={ROUTES.AFFILIATES} className="btn tertiary small lft">Learn more</Link></p>
          </div>
          <div className="clear"></div>
        </div>
      </div>
    )
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box clients">
            <h2 className="mb-20 lft">Latest news</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <div className="clear"></div>
            {this.renderContent()}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default Overview;