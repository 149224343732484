import Moment from 'moment';


// User variables

global.uid = '';
global.uidUser = '';
global.userName = '';
global.userBusiness = '';
global.userEmail = '';
global.userBday = Moment();
global.userPhone = '';
global.userStripe = '';
global.userMessage = 0;
global.userCard = '';
global.userSubPlanId = '';
global.userSubPlan = 'spark';
global.userSubId = '';
global.userSubStatus = 'trialing';
global.userSubBilling = '';
global.userSubCancel = false;
global.userSubEnd = 0;
global.userSubItemId = '';
global.userStripeConnect = '';
global.userStripeBank = '';
global.userStripeAddress = '';
global.timerStart = 5;
global.userImageDate = Moment();
global.userTemplates = [];
global.userImage = '';
global.userEmailsSent = [];
global.userVerified = false;
global.userOnboarding = ['', ''];
global.userBadges = [];
global.userComments = true;
global.userShowBooked = true;
global.userAllowBooking = false;
global.userCountry = "";
global.userCurrency = "aud";
global.userTypes = [];
global.userExperience = 0;
global.userCommunity = false;
global.userCommunityPost = false;
global.userActivityTypes = 'sessions,business,client,community,billing';
global.userReminder = 24;
global.userSessionReminder = 99999;
global.userSubdomain = "";
global.userLimitBooking = false;
global.userLbs = false;
global.userChargeSessions = true;

global.cards = [];
global.coupon = [];
global.userSignup = false;

global.spaceImage = '';
global.spaceTheme = 'blue';
global.spacePin = '';
global.spaceAddress = '';
global.spacePhone = '';
global.spaceEmail = '';
global.spacePreEx = '';
global.spaceClient = '';
global.spaceOwner = '';
global.spaceLbs = false;
global.spaceNoshow = 'session';
global.spaceNoshowFee = 0;
global.spaceAllowRecurring = false;
global.spaceAllowHabits = false;
global.spaceInvoice = 1;
global.spaceInvoice1 = "";
global.spaceInvoice2 = "";
global.spaceEmailUsage = [];
global.spaceEmailReminder = false;
global.spaceWelcome = ''
global.spaceWelcomeTime = 0
global.spaceGST = 0
global.spaceLocations = []

global.currentMonth = 1;
global.currentDay = parseInt(Moment().format('D'));
global.currentWeek = parseInt(Moment().format('w'));
global.currentCalendarView = 'week';
global.currentDate = Moment().format('DD/MM/YYYY');
global.currentTime = '05';

global.userRole = '';
global.currentAssistant = '';
global.userStaff = [];
global.userAssistant = [];
global.userSpaces = [];
global.spaces = [];
global.userUser = '';
global.clientPlans = [];
global.userStaffStatus = 'active';

global.publicData = [];
global.publicRequests = [];
global.showOnboarding = false;
global.userConnectClient = null;
global.clientToken = '';
global.clientTokens = [];
global.forms = [];

global.userDataLoaded = false;
global.currentLocation = '';
global.showTrial = true;
global.affiliates = [];

// Nutrition

global.userNutritionId = ""
global.userNutritionGym = ""
global.userNutritionDate = 0
global.userNutritionTrial = ""
global.userNutritionStart = 0
global.userNutritionEnd = 0

// Member

global.credits = []
global.subscriptions = []
global.connect = []
global.emergency = ['', '', '']
global.userParent = ''
global.userRestricted = false
global.userLinked = []
global.nutritionMeal = null
global.nutritionRecipes = null
global.nutritionWeek = 0
global.nutritionWeeks = null
global.nutritionData = null
global.nutritionMeals = []
global.nutritionDate = '1900-01-01'
global.nutritionCals = 0
global.clientEmailReminder = true
global.userAvatar = ''



// UI variables

global.currentClient = '';
global.currentSession = '';
global.backLink = '/overview';
global.dateRange = '30days';
global.message = '';
global.currentPage = '/overview';
global.updatingRec = false;
global.dataWeight = [];
global.currentWeekPlan = '';
global.currentDay = '';
global.currentPlan = '';
global.plansUid = '';
global.planLocked = false;
global.clientsLoaded = false;
global.badgeLocked = false;
global.chatCreate = false;
global.programLocked = false;
global.archiveLocked = false;
global.activityLocked = false;
global.paymentsLocked = false;
global.eventsLocked = false;
global.benchmark = false;
global.num = 99;
global.logging = false;
global.portalChange = false;



// Navigation

global.linkCli = '';
global.linkCal = '';
global.linkPro = '';
global.linkBil = '';
global.linkAdm = '';



// Data arrays

global.clients = [];
global.clientGroups = [];
global.clientsImages = [];
global.clientsInactive = [];
global.sessions = [];
global.archive = [];
global.events = []
global.clientBest = [];
global.clientBestGroup = [];
global.chats = [];
global.chatsGroup = [];
global.activity = [];
global.community = [];
global.communityImages = []

global.sessionsTraining = [];
global.archiveTraining = [];
global.recurring = [];

global.programs = [];
global.programsClient = [];
global.plans = [];
global.exercises = [];

global.payments = [];
global.products = [];
global.paymentsArchive = [];
global.expenses = [];
global.expensesCat = [];
global.invoices = [];
global.documents = [];
global.emails = [];
global.locations = [];

global.userLog = [];
global.syncDate = 0;
global.maxClients = 0;
global.maxPrograms = 0;
global.maxPlans = 0;
global.maxTrainers = 0;
global.showHabits = false;

global.trainerSelected = null;
global.allSubs = [];
global.healthlog = [];
global.habits = [];
global.adminList = [];

global.showOverview = false;
global.signup = false;
global.loc = '';



// Programming

global.proTypes = [
  'AMRAP',
  'EMOM',
  'Intervals',
  'Tabata',
  'No time',
  'For time'
]

global.exTypes = [
  'Strength',
  'Free Weights',
  'Gymnastics/Body weight',
  'Cables',
  'Pin Loaded Machines',
  'Abs & Core'
]

global.exTypesImages = [
  'type-strength',
  'type-freeweights',
  'type-gymnastics',
  'type-cables',
  'type-pin',
  'type-abs',
]

global.exCats = [
  'Monostructural',
  'Strength - Isolated',
  'Strength - Compound',
  'Mobility/Stretching',
  'Warm up/Activation',
  'Cool down',
  'Metabolic Conditioning',
  'Workout',
  'Stamina builder',
  'Skill',
  'Strength - Stamina',
  'Stabilisation',
  'Accessories'
]

global.exCatsIcons = [
  'training',
  'prog-strength',
  'prog-strength',
  'prog-stretch',
  'prog-warmup',
  'prog-warmup',
  'prog-metcon',
  'training',
  'prog-stamina',
  'prog-skill',
  'prog-strength',
  'prog-stabilization',
  'prog-accessory',
]

global.exTools = [
  'Body weight',
  'Kettlebell',
  '2 Kettlebells',
  'Barbell',
  'Dumbbell',
  '2 Dumbbells',
  'Cardio - No equipment',
  'Cardio machine',
  'Pin loaded cables',
  'Skipping rope',
  'Weight plate',
  'Other',
  //New stuff
  'Deadball', //12
  'Swiss ball', //13
  'Medicine ball', //14
  'Suspension trainer', //15
  'Resistance band', //16
  'Rig', //17
  'Rings', //18
  'Dowel rod', //19
  'Foam roller', //20
  'Mobility ball', //21
  'Battle ropes', //22
  'Plyo box', //23 
  'Strongman', //24
  'Strongman (Distance)', //25
  'Boxing gear', //26
  'Cardio machine (Cals)', //27//28
]

global.exToolsWeight = [1, 2, 3, 4, 5, 8, 10, 12, 14, 24, 25, 26]
global.lbsDown = 1/2.2046
global.lbsUp = 2.2046

global.exToolsCount = [
  'tracks reps',
  'tracks reps & weight',
  'tracks reps & weight',
  'tracks reps & weight',
  'tracks reps & weight',
  'tracks reps & weight',
  'tracks distance & time',
  'tracks distance & time',
  'tracks reps & weight',
  'tracks reps',
  'tracks reps & weight',
  'tracks reps',
  //New stuff
  'tracks reps & weight', //12
  'tracks reps', //13
  'tracks reps & weight', //14
  'tracks reps', //15
  'tracks reps', //16
  'tracks reps', //17
  'tracks reps', //18
  'tracks reps', //19
  'tracks reps', //20
  'tracks reps', //21
  'tracks reps', //22
  'tracks reps', //23 
  'tracks reps', //24
  'tracks distance', //25
  'tracks reps & weight', //26
  'tracks calories', //27
]

global.exToolsImages = [
  '/img/tool-bodyweight',
  '/img/tool-kb',
  '/img/tool-kbs',
  '/img/tool-barbell',
  '/img/tool-dumbbell',
  '/img/tool-dumbbells',
  '/img/tool-cardio',
  '/img/tool-treadmill',
  '/img/tool-machine',
  '/img/tool-rope',
  '/img/tool-plate',
  '/img/tool-other'
]

global.exMuscles = [
  "Deltoid",
  "Pectoralis major",
  "Pectoralis minor",
  "Rhomboids",
  "Levator scapulae",
  "Supraspinatus",
  "Infraspinatus",
  "Trapezius",
  "Teres major",
  "Teres minor",
  "Serratus anterior",
  "Biceps brachii",
  "Triceps brachii",
  "Brachialis",
  "Brachioradialis",
  "Pronator teres",
  "Flexor carpi radialis",
  "Extensor carpi radialis",
  "Extensor carpi ulnaris",
  "Extensor digitorum",
  "Flexor carpi ulnaris",
  "Rectus abdominis",
  "Abdominal external oblique",
  "External oblique",
  "Latissimus dorsi",
  "Serratus posterior inferior",
  "Illiopsoas",
  "Pectineus",
  "Adductor longus",
  "Tensor fasciae latae",
  "Gracilis",
  "Gluteus minimus",
  "Gluteus medius",
  "Gluteus maximus",
  "Gemellus muscles",
  "Sartorius",
  "Rectus femoris",
  "Vastus lateralis",
  "Vastus medialis",
  "Biceps femoris",
  "Semitendinosus",
  "Seminmembranosus",
  "Fibularis longus",
  "Tibialis anterior",
  "Soleus and gastrocnemius",
  "Peroneus longus",
  "Tibialis posterior",
]

global.exMusclesImages = [
  '/img/muscles/work-body-deltoid.png',
  '/img/muscles/work-body-pectmajor.png',
  '/img/muscles/work-body-pectminor.png',
  '/img/muscles/work-body-rhomboid.png',
  '/img/muscles/work-body-levscapulae.png',
  '/img/muscles/work-body-supraspinatus.png',
  '/img/muscles/work-body-infraspinatus.png',
  '/img/muscles/work-body-trapezius.png',
  '/img/muscles/work-body-teresmajor.png',
  '/img/muscles/work-body-teresminor.png',
  '/img/muscles/work-body-serratusant.png',
  '/img/muscles/work-body-biceps.png',
  '/img/muscles/work-body-triceps.png',
  '/img/muscles/work-body-brachialis.png',
  '/img/muscles/work-body-brachioradialis.png',
  '/img/muscles/work-body-pronatorteres.png',
  '/img/muscles/work-body-flexorcarpi.png',
  '/img/muscles/work-body-extcarpiradialis.png',
  '/img/muscles/work-body-extcarpiulnaris.png',
  '/img/muscles/work-body-extdigitorum.png',
  '/img/muscles/work-body-flexcarpiulnaris.png',
  '/img/muscles/work-body-abs.png',
  '/img/muscles/work-body-absextobl.png',
  '/img/muscles/work-body-extobl.png',
  '/img/muscles/work-body-latdorsi.png',
  '/img/muscles/work-body-serratuspostinf.png',
  '/img/muscles/work-body-illiopsoas.png',
  '/img/muscles/work-body-pectineus.png',
  '/img/muscles/work-body-addlong.png',
  '/img/muscles/work-body-tensorfaslat.png',
  '/img/muscles/work-body-gracilis.png',
  '/img/muscles/work-body-glutmin.png',
  '/img/muscles/work-body-glutmed.png',
  '/img/muscles/work-body-glutmax.png',
  '/img/muscles/work-body-gemellus.png',
  '/img/muscles/work-body-sartorius.png',
  '/img/muscles/work-body-rectfemoris.png',
  '/img/muscles/work-body-vastuslat.png',
  '/img/muscles/work-body-vastusmed.png',
  '/img/muscles/work-body-bicepsfemoris.png',
  '/img/muscles/work-body-semitendinosus.png',
  '/img/muscles/work-body-seminmembr.png',
  '/img/muscles/work-body-fiblong.png',
  '/img/muscles/work-body-tibant.png',
  '/img/muscles/work-body-soleus.png',
  '/img/muscles/work-body-peroneus.png',
  '/img/muscles/work-body-tibialis.png',
]


global.exTags = [
  'Mobility',
  'Activation',
  'Strength',
  'Cardiovascular',
  'Skill',
  'Warm up',
  'Accessory',
  'Stability',
  'Metabolic Conditioning',
  'Stretch'
]


global.expCats = [
  {
    id: 'admin1',
    name: 'General'
  },
  {
    id: 'admin2',
    name: 'Supplies'
  },
  {
    id: 'admin3',
    name: 'Equipment'
  },
  {
    id: 'admin4',
    name: 'Admin'
  },
  {
    id: 'admin5',
    name: 'Vehicle & Travel'
  },
  {
    id: 'admin6',
    name: 'Cleaning & Clothing'
  },
  {
    id: 'admin7',
    name: 'Education'
  },
  {
    id: 'admin8',
    name: 'Business deductions'
  },
  {
    id: 'admin9',
    name: 'Gifts & Donations'
  },
]


global.stripePlans = [
  {
    id: 'plan_FzlJQAD3sqGE9q',
    plan: 'plan_FzlJQAD3sqGE9q',
    name: 'Spark',
    type: 'spark',
  },
  // New Plans
  {
    id: 'price_1NV88JAd6uNQtfqa0D18LlY5',
    plan: 'price_1NV88JAd6uNQtfqa0D18LlY5',
    name: 'Found Monthly',
    type: 'foundmonthly',
  },
  {
    id: 'price_1NV8ANAd6uNQtfqa6iAxEMIS',
    plan: 'price_1NV8ANAd6uNQtfqa6iAxEMIS',
    name: 'Foundation Yearly',
    type: 'foundyearly',
  },
  {
    id: 'price_1NV8DGAd6uNQtfqatY3e8pUm',
    plan: 'price_1NV8DGAd6uNQtfqatY3e8pUm',
    name: 'Basic Monthly',
    type: 'basicmonthly',
  },
  {
    id: 'price_1NV8F8Ad6uNQtfqa4yHBpG8o',
    plan: 'price_1NV8F8Ad6uNQtfqa4yHBpG8o',
    name: 'Basic Yearly',
    type: 'basicyearly',
  },
  {
    id: 'price_1NV8IcAd6uNQtfqaM6TkUcLP',
    plan: 'price_1NV8IcAd6uNQtfqaM6TkUcLP',
    name: 'Pro Monthly',
    type: 'promonthly',
  },
  {
    id: 'price_1NV8KGAd6uNQtfqa397dxb23',
    plan: 'price_1NV8KGAd6uNQtfqa397dxb23',
    name: 'Pro Yearly',
    type: 'proyearly',
  },
  {
    id: 'price_1NlUnTAd6uNQtfqaccbmNsAb',
    plan: 'price_1NlUnTAd6uNQtfqaccbmNsAb',
    name: 'Pro Yearly',
    type: 'proyearly',
  },
  {
    id: 'price_1OOCAEAd6uNQtfqaqhBopmgh',
    plan: 'price_1OOCAEAd6uNQtfqaqhBopmgh',
    name: 'Business Monthly',
    type: 'businessmonthly',
  },
  {
    id: 'price_1OOCCzAd6uNQtfqalqT5jeD6',
    plan: 'price_1OOCCzAd6uNQtfqalqT5jeD6',
    name: 'Business Yearly',
    type: 'businessyearly',
  },
  {
    id: 'price_1NV5UQAd6uNQtfqaVnBkZ86A',
    plan: 'price_1NV5UQAd6uNQtfqaVnBkZ86A',
    name: 'Spark',
    type: 'spark',
  },
];

global.pricesAU = ['A$16.90','A$169','A$27.90','A$279','A$43.90','A$439','A$54.90','A$549','A$274.50']
global.pricesUS = ['$14.90','$149','$25.90','$259','$41.90','$419','$52.90','$529','$264.50']
global.pricesUK = ['£11.90','£119','£21.90','£239','£34.90','£349','£51.90','£519']
global.pricesEU = ['12.90€','129€','23.90€','239€','37.90€','379€','52.90€','529€']
global.pricesCA = ['CA$14.90','CA$149','CA$25.90','CA$259','CA$41.90','CA$419','CA$52.90','CA$529']
global.pricesNZ = ['NZ$16.90','NZ$169','NZ$27.90','NZ$279','NZ$43.90','NZ$439','NZ$54.90','NZ$549']
global.prices = []
global.curSym = '$'
global.stripeFee = '2.25% + A$0.30'
//Intl.DateTimeFormat().resolvedOptions().timeZone



global.themeColors = ['#1DC5C9', '#81DB24', '#FB631F', '#8A15E9', '#0C82AC']
global.emptyColors = ['#1DC5C9', '#CAD4DE', '#ABCFD0', '#999696', '#68999A']

global.themes = [
  {id: 'blue', colors: ['#1DC5C9', '#81DB24', '#FB631F', '#8A15E9', '#0C82AC'], empty: ['#1DC5C9', '#CAD4DE', '#ABCFD0', '#999696', '#68999A']},
  {id: 'darkblue', colors: ['#0C82AC', '#81DB24', '#FB631F', '#8A15E9', '#1DC5C9'], empty: ['#0C82AC', '#B4C1C9', '#90B6B7', '#91999C', '#718398']},
  {id: 'green', colors: ['#81DB24', '#1DC5C9', '#0C82AC', '#8A15E9', '#FB631F'], empty: ['#81DB24', '#BBD3C9', '#97C2AB', '#A6AC9C', '#718782']},
  {id: 'yellow', colors: ['#FAB54A', '#1DC5C9', '#81DB24', '#FB631F', '#8A15E9'], empty: ['#FAB54A', '#C5C4BA', '#B5B19D', '#999696', '#727356']},
  {id: 'orange', colors: ['#FB631F', '#6CD69D', '#1DC5C9', '#81DB24', '#FAB54A'], empty: ['#FB691F', '#C1B8B3', '#B6B3AE', '#999696', '#847D75']},
  {id: 'red', colors: ['#DE1053', '#8A15E9', '#6CD69D', '#1DC5C9', '#FB631F'], empty: ['#DE1053', '#C3B9C3', '#999097', '#999696', '#8D848A']},
  {id: 'purple', colors: ['#8A15E9', '#DE1053', '#1DC5C9', '#0C82AC', '#FB691F'], empty: ['#8A15E9', '#B4B4C9', '#90A2B7', '#999696', '#75697D']},
  {id: 'vividblue', colors: ['#4695ED', '#6CD69D', '#FB691F', '#1DC5C9', '#8A15E9'], empty: ['#4695ED', '#BEC4CA', '#98B7C4', '#999696', '#68859A']},
  {id: 'vividgreen', colors: ['#6CD69D', '#FAB54A', '#1DC5C9', '#FB691F', '#0C82AC'], empty: ['#6CD69D', '#B4C5C9', '#90B7A9', '#999696', '#739793']},
  {id: 'darkgreen', colors: ['#53A238', '#DE1053', '#FAB54A', '#8A15E9', '#1DC5C9'], empty: ['#53A238', '#B3C5C4', '#88AF9F', '#ADAEA9', '#62866A']},
  {id: 'pink', colors: ['#EB0BD1', '#8A15E9', '#1DC5C9', '#81DB24', '#FAB54A'], empty: ['#EB0BD1', '#B7B3C5', '#9C92A0', '#999696', '#AC97A9']},
  {id: 'brown', colors: ['#8F5D46', '#FAB54A', '#1DC5C9', '#81DB24', '#DE1053'], empty: ['#8F5D46', '#B5ADAD', '#948F88', '#999696', '#A6998B']},
]


global.avatars = [
  "animal1", "animal2", "animal3", "animal4", "animal5", "animal6", "animal7", "animal8", "animal9", "animal10", "animal11", "animal12", "animal13", "animal14", "animal15", "animal16", "animal17", "animal18", "animal19", "animal20",
  "nature1", "nature2", "nature3", "nature4", "nature5", "nature6", "nature7", "nature8", "nature9", "nature10", "nature11", "nature12", "nature13", "nature14", "nature15", "nature16", "nature17", "nature18", "nature19", "nature20",
  "plant1", "plant2", "plant3", "plant4", "plant5", "plant6", "plant7", "plant8", "plant9", "plant10", "plant11", "plant12", "plant13", "plant14", "plant15", "plant16", "plant17", "plant18", "plant19", "plant20",
  "sky1", "sky2", "sky3", "sky4", "sky5", "sky6", "sky7", "sky8", "sky9", "sky10", "sky11", "sky12", "sky13", "sky14", "sky15", "sky16", "sky17", "sky18", "sky19", "sky20",
  "vehicle1", "vehicle2", "vehicle3", "vehicle4", "vehicle5", "vehicle6", "vehicle7", "vehicle8", "vehicle9", "vehicle10", "vehicle11", "vehicle12", "vehicle13", "vehicle14", "vehicle15", "vehicle16", "vehicle17", "vehicle18", "vehicle19", "vehicle20"
]