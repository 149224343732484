import React, { Component } from 'react';
import Connector from '../../data/Connector';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class StaffInactivePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
    }
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Business Portal - PT Mate'
    window.scrollTo(0, 0)
  }


  logoutUser() {
    HelperPage.signOut()
  }



  // Display stuff ------------------------------------------------------------



  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content member-select">
              <div className="member-confirm unauth">
                <h2>You don't have access</h2>
                <p>You don't have access to the Business Web Portal<br/>Please contact your space admin or owner.</p>
              </div>
              <div className="space-40"></div>
              <button className="btn primary" onClick={() => this.logoutUser()}>Close</button>
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(StaffInactivePage));