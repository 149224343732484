import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import HelperBusiness from '../../helper/Business';



class HeaderBilling extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      active: props.active ?? '',
      type: props.type ?? ''
    };
  }


  /*renderPayoutInfo() {
    if(this.state.active === 'payments' || this.state.active === 'debits' || this.state.active === 'reports') {
      return (
        <div className="appstore">
          <p style={{marginBottom: '0px'}}>Payouts from Stripe happen weekly,<br/>usually on Mondays. <span onClick={() => this.props.onClickInfo()}>Learn more</span></p>
        </div>
      )
    }
  }*/

  
  renderInsights() {
    if(HelperBusiness.getRoleIncl('admin,finance,cs')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.BILINSIGHTS} className={this.state.active === 'insights' ? 'tab active' : 'tab'}>
            <p>Insights</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.BILINSIGHTS} className="item">
            <div className="bg"></div>
            <div className="text">Insights</div>
          </Link>
        )
      }
    }
  }


  renderExpenses() {
    if(HelperBusiness.getRoleIncl('admin,finance')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.EXPENSES} className={this.state.active === 'expenses' ? 'tab active' : 'tab'}>
            <p>Expenses</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.EXPENSES} className="item">
            <div className="bg"></div>
            <div className="text">Expenses</div>
          </Link>
        )
      }
    }
  }


  renderProducts() {
    if(HelperBusiness.getRoleIncl('')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.PRODUCTS} className={this.state.active === 'products' ? 'tab active' : 'tab'}>
            <p>Products</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.PRODUCTS} className="item">
            <div className="bg"></div>
            <div className="text">Products</div>
          </Link>
        )
      }
    }
  }


  renderDebits() {
    if(HelperBusiness.getRoleIncl('admin,finance,cs')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.DEBITS} className={this.state.active === 'debits' ? 'tab active' : 'tab'}>
            <p>Memberships</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.DEBITS} className="item">
            <div className="bg"></div>
            <div className="text">Memberships</div>
          </Link>
        )
      }
    }
  }


  renderInvoices() {
    if(HelperBusiness.getRoleIncl('admin,finance')) {
      if(this.state.type === 'header') {
        return (
          <Link to={ROUTES.INVOICES} className={this.state.active === 'invoices' ? 'tab active' : 'tab'}>
            <p>Invoices</p>
            <div className="bg"></div>
          </Link>
        )
      } else {
        return (
          <Link to={ROUTES.INVOICES} className="item">
            <div className="bg"></div>
            <div className="text">Invoices</div>
          </Link>
        )
      }
    }
  }


  render() {
    if(this.state.type === 'header') {
      return (
        <div className="track">
          <Link to={ROUTES.BILLING} className={this.state.active === 'payments' ? 'tab active' : 'tab'}>
            <p>Payments</p>
            <div className="bg"></div>
          </Link>
          {this.renderDebits()}
          {this.renderProducts()}
          {this.renderInvoices()}
          {this.renderExpenses()}
          {this.renderInsights()}
          <Link to={ROUTES.REPORT} className={this.state.active === 'reports' ? 'tab active' : 'tab'}>
            <p>Reports</p>
            <div className="bg"></div>
          </Link>
        </div>
      )
    } else {
      return (
        <div>
          <Link to={ROUTES.BILLING} className="item">
            <div className="bg"></div>
            <div className="text">Payments</div>
          </Link>
          {this.renderDebits()}
          {this.renderProducts()}
          {this.renderInvoices()}
          {this.renderExpenses()}
          {this.renderInsights()}
          <Link to={ROUTES.REPORT} className="item">
            <div className="bg"></div>
            <div className="text">Reports</div>
          </Link>
        </div>
      )
    }
  }
}


export default HeaderBilling;