import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Moment from 'moment';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';

import { AuthUserContext, withAuthorization } from '../../components/Session';


class EmailUsagePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      months: global.spaceEmailUsage,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Email Usage - PT Mate')
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('emailUsageLoaded', (event) => this.configureData())
    Connector.loadEmailUsage()
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      this.setState({
        months: global.spaceEmailUsage
      })
    }
  }


  getEmailsCurrent() {
    var label = 0
    if(global.spaceEmailUsage[Moment().format('MM-YYYY')] !== undefined) {
      label = global.spaceEmailUsage[Moment().format('MM-YYYY')]
    }
    return label
  }


  getCost(item) {
    var label = 0
    var num = 0
    if(global.spaceEmailUsage[item] !== undefined) {
      num = global.spaceEmailUsage[item]
    }
    var calc = num/1000
    label = Math.ceil(calc)*5
    return label.toFixed(2)
  }


  getTier() {
    var label = 0
    var num = 0
    if(global.spaceEmailUsage[Moment().format('MM-YYYY')] !== undefined) {
      num = global.spaceEmailUsage[Moment().format('MM-YYYY')]
    }
    var calc = num/1000
    label = Math.ceil(calc)
    return label
  }



  // Display stuff ------------------------------------------------------------



  renderList() {
    var list = Object.keys(global.spaceEmailUsage)
    if(list.length > 0) {
      list.sort((a,b) => Moment(b, 'MM-YYYY') - Moment(a, 'MM-YYYY'));
      return (
        <div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item}>
              <div className="col-4">
                <p>{Moment(item, 'MM-YYYY').format('MMMM YYYY')}</p>
              </div>
              <div className="col-4">
                <p>{global.spaceEmailUsage[item]}</p>
              </div>
              <div className="col-4">
                <p>{global.curSym}{this.getCost(item)}</p>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content">
          <div className="infobox large primary mb-30">
            <p>Note: Credits are reset each month. Any email credits unused will not roll over o the following month.</p>
          </div>
          <div className="col-9">
            <h3 className="mb-20">This month</h3>
            <div className='info large col-3 clear'>
              <label>Total emails sent</label>
              <p>{this.getEmailsCurrent()}</p>
              <span>of {this.getTier()*1000} in Tier {this.getTier()}</span>
            </div>
            <div className='info large col-3'>
              <label>Cost</label>
              <p>{this.getEmailsCurrent()}</p>
              <span>Tier {this.getTier()}</span>
            </div>
            <div className="clear"></div>
          </div>
          <div className="col-3">
            <h3 className="mb-20">Cost info</h3>
            <p>Based on the number of emails sent. Emails are available in blocks of 1000 emails, charged at {global.curSym}5 per block of 1000. This includes both marketing emails you send and session confirmation emails.</p>
          </div>
          <div className="listheader clear">
            <h3>Past usage</h3>
            <div className="clear"></div>
          </div>
          {this.renderList()}
          <div className="sv-30"></div>
        </div>
        <Header title='Email Usage' link={ROUTES.ADMINEMAILS}/>
        <Navigation active='' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(EmailUsagePage));