import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';

import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import Textarea from '../../components/Form/textarea';
import InputRadio from '../../components/Form/radio';
import InputToggle from '../../components/Form/toggle';
import InfoData from '../../components/Form/info';
import Loader from '../../components/Form/loader';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import ModalMessageSimple from '../../components/Modals/message-simple';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class ProductPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      clients: global.clients,
      products: global.products,
      type: 'sessions',
      values: ['', '', '', ''],
      errorSessions: [false, false, false, false],
      stype: '11',
      loading: false,
      billing: 'week',
      item: null,
      message: '',
      log: global.userLog,
      limit: false,
      limitNum: 0,
      limit11: false,
      limitNum11: 0,
      public: false,
      expires: false,
      expiry: 30,
      days: 'days',
      desc: '',
      showStock: false,
      stock: 0,
      showModalDelete: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Product - PT Mate')
    this._isMounted = true

    global.currentClient = '';
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('logLoaded', (event) => this.configureLog())
    EventEmitter.subscribe('productsLoaded', (event) => {
      this.configureData()
      if(this.state.loading) {
        global.message = this.state.message
        if(this.state.message === 'Product successfully updated') {
          EventEmitter.dispatch('showMessage', this.state.message);
          this.setState({
            loading: false
          })
        } else {
          setTimeout(() => {
            EventEmitter.dispatch('updateBadges', 'product');
          }, 1000);
          this.props.history.push(ROUTES.PRODUCTS)
        }
      }
    })
    this.configureData()
    var arr = this.props.location.pathname.split('/')
    if(arr[arr.length-1] !== 'new') {
      this.setState({id: arr[arr.length-1]})
    }
  }


  componentWillUnmount() {
    this._isMounted = false
    this.setState({
      loading: false
    })
  }


  configureData() {
    if(this._isMounted) {
      this.setState({
        products: global.products,
        stripe: global.userStripeConnect,
        bank: global.userStripeBank
      }, () => {
        // Existing product
        var arr = this.props.location.pathname.split('/')
        if(arr[arr.length-1] !== 'new') {
          var prod = null
          var tmp = ['', '', '']
          var type = ''
          var stype = '11'
          var billing = 'week'
          var isPublic = false
          var expires = false
          var expiry = 30
          var days = 'days'
          var desc = ''
          var showStock = false
          var stock = 0
          for(var item of this.state.products) {
            if(item.id === arr[arr.length-1]) {
              prod = item
              type = item.data.type
              stype = item.data.stype
              var tmps = 0
              if(item.data.sessions11 !== undefined) {
                tmps = item.data.sessions11
              }
              tmp = [item.data.name, item.data.sessions, item.data.price.toFixed(2), tmps]
              billing = item.data.billing
              if(item.data.public !== undefined) {
                isPublic = item.data.public
              }
              if(item.data.expires !== undefined) {
                expires = true
                expiry = item.data.expires
                days = item.data.expType
              }
              if(item.data.desc !== undefined) {
                desc = item.data.desc
              }
              if(item.data.stock !== undefined && item.data.stock !== -1) {
                showStock = true
                stock = item.data.stock
              }
            }
          }
          this.setState({
            id: arr[arr.length-1],
            values: tmp,
            type: type,
            stype: stype,
            billing: billing,
            item: prod,
            public: isPublic,
            expiry: expiry,
            expires: expires,
            days: days,
            desc: desc,
            showStock: showStock,
            stock: stock,
          })
        }
      })
    }
  }


  configureLog() {
    if(this._isMounted) {
      this.setState({
        log: global.userLog
      }, () => {
        if(this.state.loading) {
          for(var item of this.state.log) {
            if(item.data.title === 'planerror' || item.data.title === 'planupdateerror' || item.data.title === 'plandelerror') {
              EventEmitter.dispatch('showMessageError', item.data.message);
              Firebase.database().ref('/log/'+global.uid+'/'+item.id).remove()
              this.setState({
                loading: false,
              })
            }
          }
        }
      })
    }
  }



  // Membership ------------------------------------------------------------



  checkCreateDebit() {
    this.setState({errorSessions: [false, false, false, false]})
    var tmp = [false, false, false]
    if(this.state.values[0] === '' || this.state.values[0].length > 22) {
      tmp[0] = true
    }
    if(this.state.values[2] === '') {
      tmp[2] = true
    }
    this.setState({errorSessions: tmp})
    if(!tmp[0] && !tmp[1]) {
      if(this.state.id === '') {
        this.createDebit()
      } else {
        this.updateDebit()
      }
      
    }
  }


  createDebit() {
    var group = 'no'
    var sessions = 0
    var sessions11 = 0
    var pub = "no"
    var stock = -1
    if(this.state.limit && this.state.limitNum !== '') {
      sessions = parseInt(this.state.limitNum)
    }
    if(this.state.limit11 && this.state.limitNum11 !== '') {
      sessions11 = parseInt(this.state.limitNum11)
    }
    if(this.state.stype === 'group') {
      group = 'yes'
    } else if(this.state.stype === 'both') {
      group = 'both'
    }
    if(this.state.public) {
      pub = "yes"
    }
    if(this.state.showStock && this.state.stock !== '') {
      stock = parseInt(this.state.stock)
    }
    global.badgeLocked = true
    var callFunction = Firebase.functions().httpsCallable('connectedManageProduct');
    callFunction({type: "create", account: global.userStripeConnect, name: this.state.values[0], interval: this.state.billing, amount: parseInt(parseFloat(this.state.values[2])*100), group: group, sessions: sessions, sessions11: sessions11, currency: global.userCurrency, public: pub, stock: stock}).then(function(result) {});
    this.setState({
      loading: true,
      message: 'Product successfully created'
    })
  }


  updateDebit() {
    var pub = "no"
    var stock = -1
    if(this.state.public) {
      pub = "yes"
    }
    if(this.state.showStock && this.state.stock !== '') {
      stock = parseInt(this.state.stock)
    }
    var callFunction = Firebase.functions().httpsCallable('connectedManageProduct');
    callFunction({type: "update", account: global.userStripeConnect, name: this.state.values[0], plan: this.state.item.data.sku, product: this.state.item.data.product, public: pub, stock: stock}).then(function(result) {});
    this.setState({
      loading: true,
      message: 'Product successfully updated'
    })
  }



  // Pack of sessions ------------------------------------------------------------



  updateValues(val, num) {
    var tmp = this.state.values
    tmp[num] = val
    this.setState({
      values: tmp
    });
  }


  updateValuesNum(val, key) {
    this.setState({
      [key]: val
    });
  }


  onChangeExpiry = event => {
    this.setState({expiry: parseInt(event.target.value)});
  };


  checkCreatePack() {
    this.setState({errorSessions: [false, false, false, false]})
    var tmp = [false, false, false, false]
    if(this.state.values[0] === '' || this.state.values[0].length > 22) {
      tmp[0] = true
    }
    if(this.state.values[1] === '') {
      tmp[1] = true
    }
    if(this.state.values[2] === '') {
      tmp[2] = true
    }
    if(this.state.values[3] === '' && this.state.stype === 'both') {
      tmp[3] = true
    }
    this.setState({errorSessions: tmp})
    if(!tmp[0] && !tmp[1] && !tmp[2] && !tmp[3]) {
      if(this.state.id === '') {
        this.createPack()
      } else {
        this.updatePack()
      }
      
    }
  }


  createPack() {
    global.badgeLocked = true
    var sessions11 = 0
    if(this.state.values[3] !== '' && this.state.stype === 'both') {
      sessions11 = parseInt(this.state.values[3])
    }
    var stock = null
    if(this.state.showStock && this.state.stock !== '') {
      stock = parseInt(this.state.stock)
    }
    if(this.state.expires) {
      Firebase.database().ref('/packs/'+global.uid).push({
        billing: '', 
        stype: this.state.stype,
        name: this.state.values[0],
        price: parseFloat(this.state.values[2]),
        product: '',
        sessions: parseInt(this.state.values[1]),
        sessions11: sessions11,
        sku: '', 
        type: 'sessions',
        uid: global.uid,
        public: this.state.public,
        expires: parseInt(this.state.expiry),
        expType: this.state.days,
        desc: this.state.desc,
        stock: stock,
      }).then(() => {
        global.message = 'Product successfully created'
        setTimeout(() => {
          EventEmitter.dispatch('updateBadges', 'product');
        }, 1000);
        this.props.history.push(ROUTES.PRODUCTS)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.database().ref('/packs/'+global.uid).push({
        billing: '', 
        stype: this.state.stype,
        name: this.state.values[0],
        price: parseFloat(this.state.values[2]),
        product: '',
        sessions: parseInt(this.state.values[1]),
        sessions11: sessions11,
        sku: '', 
        type: 'sessions',
        uid: global.uid,
        public: this.state.public,
        desc: this.state.desc,
        stock: stock,
      }).then(() => {
        global.message = 'Product successfully created'
        setTimeout(() => {
          EventEmitter.dispatch('updateBadges', 'product');
        }, 1000);
        this.props.history.push(ROUTES.PRODUCTS)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  updatePack() {
    var stock = null
    if(this.state.showStock && this.state.stock !== '') {
      stock = parseInt(this.state.stock)
    }
    Firebase.database().ref('/packs/'+global.uid+'/'+this.state.id).update({
      name: this.state.values[0],
      price: parseFloat(this.state.values[2]),
      sessions: parseInt(this.state.values[1]),
      sessions11: parseInt(this.state.values[3]),
      public: this.state.public,
      desc: this.state.desc,
      stock: stock
    }).then(() => {
      EventEmitter.dispatch('showMessage', 'Product successfully updated');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    if(this.state.expires) {
      Firebase.database().ref('/packs/'+global.uid+'/'+this.state.id).update({
        expires: parseInt(this.state.expiry),
        expType: this.state.days
      })
    } else {
      Firebase.database().ref('/packs/'+global.uid+'/'+this.state.id+'/expires').remove()
      Firebase.database().ref('/packs/'+global.uid+'/'+this.state.id+'/expType').remove()
    }
  }


  deleteProduct() {
    this.hideModals()
    if(this.state.type === 'sessions' || this.state.type === 'other') {
      Firebase.database().ref('/packs/'+global.uid+'/'+this.state.id).remove()
      global.message = 'Product successfully deleted'
      this.props.history.push(ROUTES.PRODUCTS)
    } else {
      var callFunction = Firebase.functions().httpsCallable('connectedManageProduct');
      callFunction({type: 'delete', account: global.userStripeConnect, plan: this.state.item.data.sku, product: this.state.item.data.product}).then(function(result) {});
      this.setState({
        loading: true,
        message: 'Product successfully deleted'
      })
      for(var item1 of global.sessions) {
        if(item1.data.memberships !== undefined) {
          if(item1.data.memberships.indexOf(this.state.id) !== -1) {
            for(var i1=0; i1<item1.data.memberships.length; i1++) {
              if(item1.data.memberships[i1] === this.state.id) {
                item1.data.memberships.split(i1, 1)
              }
            }
            Firebase.database().ref('/sessions/'+global.uid+'/'+item1.id).update({
              memberships: item1.data.memberships
            })
          }
        }
      }
      for(var item2 of global.recurring) {
        if(item2.data.memberships !== undefined) {
          if(item2.data.memberships.indexOf(this.state.id) !== -1) {
            for(var i2=0; i2<item2.data.memberships.length; i2++) {
              if(item2.data.memberships[i2] === this.state.id) {
                item2.data.memberships.split(i2, 1)
              }
            }
            Firebase.database().ref('/recurring/'+global.uid+'/'+item1.id).update({
              memberships: item2.data.memberships
            })
          }
        }
      }
    }
  }


  checkCreateOther() {
    this.setState({errorSessions: [false, false, false, false]})
    var tmp = [false, false, false]
    if(this.state.values[0] === '') {
      tmp[0] = true
    }
    if(this.state.values[2] === '') {
      tmp[2] = true
    }
    this.setState({errorSessions: tmp})
    if(!tmp[0] && !tmp[1] && !tmp[2]) {
      if(this.state.id === '') {
        this.createOther()
      } else {
        this.updateOther()
      }
      
    }
  }


  createOther() {
    global.badgeLocked = true
    var stock = null
    if(this.state.showStock && this.state.stock !== '') {
      stock = parseInt(this.state.stock)
    }
    Firebase.database().ref('/packs/'+global.uid).push({
      billing: '', 
      group: false,
      name: this.state.values[0],
      price: parseFloat(this.state.values[2]),
      product: '',
      sessions: 0,
      sku: '', 
      type: 'other',
      uid: global.uid,
      public: this.state.public,
      desc: this.state.desc,
      stock: stock,
    }).then(() => {
      global.message = 'Product successfully created'
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'product');
      }, 1000);
      this.props.history.push(ROUTES.PRODUCTS)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  updateOther() {
    var stock = null
    if(this.state.showStock && this.state.stock !== '') {
      stock = parseInt(this.state.stock)
    }
    Firebase.database().ref('/packs/'+global.uid+'/'+this.state.id).update({
      name: this.state.values[0],
      price: parseFloat(this.state.values[2]),
      public: this.state.public,
      desc: this.state.desc,
      stock: stock,
    }).then(() => {
      EventEmitter.dispatch('showMessage', 'Product successfully updated');
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalDelete: false,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderButtonDelete() {
    if(this.state.id !== '') {
      return (
        <div>
          <div className="sv-20"></div>
          <button className="btn tertiary close width-12" onClick={() => this.setState({showModalDelete: true})}>Delete product</button>
        </div>
        
      )
    }
  }


  renderProductName() {
    if(this.state.values[0].length < 23) {
      return ' ('+(22-this.state.values[0].length)+' characters left)'
    } else {
      return ' ('+(this.state.values[0].length-22)+' characters too long)'
    }
  }


  renderDesc() {
    return <Textarea label='Description (optional' value={this.state.desc} onChange={(event) => this.updateValuesNum(event, 'desc')}/>
  }


  renderPublic() {
    return <InputToggle label='Make available for client purchase' value={this.state.public} onChange={() => this.setState({public: !this.state.public})} text="Allow your clients to purchase this product directly on the Member App/Web Portal"/>
  }


  renderStock() {
    if(this.state.showStock) {
      return (
        <InputNumber label={this.state.type === 'debit' ? 'Number available' : 'Amount in stock'} value={this.state.stock} onChange={(event) => this.setState({stock: event})}/>
      )
    }
  }


  renderOther() {
    return (
      <div>
        <InputText label='Product name*' placeholder="Product name" value={this.state.values[0]} error={this.state.errorSessions[0]} errormsg='Please enter a name' onChange={(event) => this.updateValues(event, 0)}/>
        <InputNumber label={'Price ('+(global.userCurrency === 'aud' ? 'AUD' : 'USD')+')*'} placeholder={'Price in '+global.curSym} value={this.state.values[2]} error={this.state.errorSessions[2]} errormsg='Please enter a price' onChange={(event) => this.updateValues(event, 2)}/>
        {this.renderDesc()}
        {this.renderPublic()}
        <InputToggle label='Limit stock' value={this.state.showStock} onChange={() => this.setState({showStock: !this.state.showStock})} text="Set a limited stock amount for this product"/>
        {this.renderStock()}
        <div className="sv-30"></div>
        <button className="btn primary" onClick={() => this.checkCreateOther()}>{this.state.id === '' ? 'Create product' : 'Update product'}</button>
        {this.renderButtonDelete()}
      </div>
    )
  }


  renderSessionType() {
    if(this.state.id === '') {
      return (
        <InputRadio
          label='Session type'
          value={this.state.stype}
          clickElement={(event) => this.setState({stype: event})}
          values={[{name: '1:1', value: '11'}, {name: 'Group', value: 'group'}, {name: 'Both', value: 'both'}]}
        />
      )
    }
  }


  renderExpiry() {
    if(this.state.expires) {
      return (
        <div>
          <label>Sessions expire after</label>
          <div className="col-6" style={{paddingLeft:0}}>
            <input type="number" placeholder="Number" value={this.state.expiry} onChange={this.onChangeExpiry}/>
          </div>
          <div className="col-6" style={{paddingRight:0}}>
            <div className="radios">
              <button className={this.state.days === 'days' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({days: 'days'})}>Days</button>
              <button className={this.state.days === 'months' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({days: 'months'})}>Months</button>
            </div>
          </div>
          <div className="clear sv-30"></div>
        </div>
      )
    }
  }


  renderSessionsBottom() {
    if(this.state.id === '') {
      return (
        <div>
          <InputToggle label='Set expiry for sessions' value={this.state.expires} onChange={() => this.setState({expires: !this.state.expires})} text="Available sessions expire after set time"/>
          {this.renderExpiry()}
          {this.renderPublic()}
          <InputToggle label='Limit stock' value={this.state.showStock} onChange={() => this.setState({showStock: !this.state.showStock})} text="Set a limited stock amount for this product"/>
          {this.renderStock()}
          <div className="sv-20"></div>
          <button className="btn primary" onClick={() => this.checkCreatePack()}>Create product</button>
        </div>
      )
    } else {
      return (
        <div>
          <InputToggle label='Set expiry for sessions' value={this.state.expires} onChange={() => this.setState({expires: !this.state.expires})} text="Available sessions expire after set time"/>
          {this.renderExpiry()}
          {this.renderPublic()}
          <InputToggle label='Limit stock' value={this.state.showStock} onChange={() => this.setState({showStock: !this.state.showStock})} text="Set a limited stock amount for this product"/>
          {this.renderStock()}
          <div className="info">
            <label>Type</label>
            <p>{this.state.stype === 'group' ? 'Group' : (this.state.stype === '11' ? '1:1' : 'Group & 1:1')} sessions</p>
          </div>
          <div className="sv-20"></div>
          <button className="btn primary" onClick={() => this.checkCreatePack()}>Update product</button>
          {this.renderButtonDelete()}
        </div>
      )
    }
  }


  renderSessions11() {
    if(this.state.stype === 'both') {
      return <InputNumber label='Number of 1:1 sessions*' placeholder="1:1 sessions" value={this.state.values[3]} error={this.state.errorSessions[3]} errormsg='Please enter the number of sessions' onChange={(event) => this.updateValues(event, 3)}/>
    }
  }


  renderSessions() {
    return (
      <div>
        <InputText label={'Product name*'+this.renderProductName()} placeholder="Product name" value={this.state.values[0]} error={this.state.errorSessions[0]} errormsg='Please enter a name not longer than 22 characters' onChange={(event) => this.updateValues(event, 0)}/>
        {this.renderSessionType()}
        <InputNumber label={'Number of '+(this.state.stype === 'both' ? 'group' : '')+' sessions*'} placeholder={(this.state.stype === 'both' ? 'Group s' : 'S')+'essions'} value={this.state.values[1]} error={this.state.errorSessions[1]} errormsg='Please enter the number of sessions' onChange={(event) => this.updateValues(event, 1)}/>
        {this.renderSessions11()}
        <InputNumber label={'Price ('+(global.userCurrency === 'aud' ? 'AUD' : 'USD')+')*'} placeholder={'Price in '+global.curSym} value={this.state.values[2]} error={this.state.errorSessions[2]} errormsg='Please enter a price' onChange={(event) => this.updateValues(event, 2)}/>
        {this.renderDesc()}
        {this.renderSessionsBottom()}
      </div>
    )
  }


  renderDebitLimitInfo() {
    if(this.state.limit || (this.state.limit11 && this.state.stype === 'both')) {
      return (
        <div className="infobox mb-10">
          <p>This product will allow you to set up pre-paid memberships. Note that they will only become active on the date of the first charge.</p>
        </div>
      )
    }
  }


  renderDebitLimit() {
    if(this.state.limit) {
      return <InputNumber label={'Number of '+(this.state.stype === 'both' ? 'group' : '')+' sessions'} placeholder='Sessions per billing cycle' value={this.state.limitNum} onChange={(event) => this.updateValuesNum(event, 'limitNum')}/>
    }
  }


  renderDebitLimit11() {
    if(this.state.limit11) {
      return <InputNumber label='Number of 1:1 sessions' placeholder='Sessions per billing cycle' value={this.state.limitNum11} onChange={(event) => this.updateValuesNum(event, 'limitNum11')}/>
    }
  }


  renderDebitSessions(type) {
    if(this.state.item !== null) {
      if(type === 'default') {
        if(this.state.item.data.sessions === undefined || this.state.item.data.sessions === 0) {
          return 'No limit'
        } else {
          return this.state.item.data.sessions
        }
      } else {
        if(this.state.item.data.sessions11 === undefined || this.state.item.data.sessions11 === 0) {
          return 'No limit'
        } else {
          return this.state.item.data.sessions11
        }
      }
      
    }
  }


  renderDebitButton() {
    if(global.userStripeConnect === '' || global.userStripeConnect === undefined) {
      return (
        <div className="infobox small yellow">
          <h5>You need to enable Stripe payments</h5>
          <p>Memberships are set up using Stripe. Please enable Payments with Stripe to be able to set up memberships. This will also allow you to charge your clients directly through the app.</p>
          <div className="space-20"></div>
          <Link to={ROUTES.ACCOUNTBILLING} className="btn tertiary width-12 small" style={{textDecoration: 'none'}}>Enable payments with Stripe now</Link>
        </div>
      )
    } else {
      return <button className="btn primary" onClick={() => this.checkCreateDebit()}>Create product</button>
    }
  }


  renderDebit11() {
    if(this.state.stype === 'both') {
      return <InputToggle label='Limit 1:1 sessions' value={this.state.limit11} onChange={() => this.setState({limit11: !this.state.limit11})} text="Limit available number of 1:1 sessions per billing cycle"/>
    }
  }


  renderDebit() {
    if(this.state.id === '') {
      return (
        <div>
          <InputText label={'Product name*'+this.renderProductName()} placeholder="Product name" value={this.state.values[0]} error={this.state.errorSessions[0]} errormsg='Please enter a name not longer than 22 characters' onChange={(event) => this.updateValues(event, 0)}/>
          <InputRadio
            label='Billing cycle'
            value={this.state.billing}
            clickElement={(event) => this.setState({billing: event})}
            values={[{name: 'Weekly', value: 'week'}, {name: 'Fortnightly', value: 'fortnight'}, {name: 'Monthly', value: 'month'}]}
          />
          <InputNumber label={'Price ('+(global.userCurrency === 'aud' ? 'AUD' : 'USD')+')*'} placeholder={'Price in '+global.curSym} value={this.state.values[2]} error={this.state.errorSessions[2]} errormsg='Please enter a price' onChange={(event) => this.updateValues(event, 2)}/>
          {this.renderSessionType()}
          {this.renderDebit11()}
          {this.renderDebitLimit11()}
          <InputToggle label={this.state.stype === 'both' ? 'Limit group sessions' : 'Limit sessions'} value={this.state.limit} onChange={() => this.setState({limit: !this.state.limit})} text={'Limit available number of '+(this.state.stype === 'both' ? 'group' : '')+' sessions per billing cycle'}/>
          {this.renderDebitLimit()}
          {this.renderDebitLimitInfo()}
          {this.renderPublic()}
          <InputToggle label='Limit availability' value={this.state.showStock} onChange={() => this.setState({showStock: !this.state.showStock})} text="Limit the number of times this product can be used/set up"/>
          {this.renderStock()}
          <div className="clear sv-30"></div>
          {this.renderDebitButton()}
        </div>
      )
    } else {
      return (
        <div>
          <InputText label={'Product name*'+this.renderProductName()} value={this.state.values[0]} error={this.state.errorSessions[0]} errormsg='Please enter a name not longer than 22 characters' onChange={(event) => this.updateValues(event, 0)}/>
          <div className="sv-10"></div>
          <InfoData label='Billing cycle' value={<span style={{textTransform: 'capitalize'}}>{this.state.billing}ly</span>}/>
          <InfoData label='Price' value={global.curSym+this.state.values[2]}/>
          <InfoData label='Type' value={this.state.stype === 'group' ? 'Group sessions' : (this.state.stype === '11' ? '1:1 sessions' : 'Group & 1:1 sessions')}/>
          <InfoData label={'Sessions per '+this.state.billing} value={this.renderDebitSessions('default')}/>
          {this.renderStatic11()}
          {this.renderPublic()}
          <InputToggle label='Limit availability' value={this.state.showStock} onChange={() => this.setState({showStock: !this.state.showStock})} text="Limit the number of times this product can be used/set up"/>
          {this.renderStock()}
          <div className="sv-20"></div>
          <button className="btn primary" onClick={() => this.checkCreateDebit()}>Update product</button>
          {this.renderButtonDelete()}
        </div>
      )
    }
  }


  renderStatic11() {
    if(this.state.stype === 'both') {
      return <InfoData label={'1:1 sessions per '+this.state.billing} value={this.renderDebitSessions('11')}/>
    }
  }


  renderType() {
    if(this.state.type === 'sessions') {
      return (
        this.renderSessions()
      )
    } else if(this.state.type === 'debit' || this.state.type === 'subscription') {
      return (
        this.renderDebit()
      )
    } else {
      return (
        this.renderOther()
      )
    }
  }


  renderRadios() {
    if(this.state.id === '') {
      return (
        <div className="radios tabs">
          <button className={this.state.type === 'sessions' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({type: 'sessions'})}>Pack</button>
          <button className={this.state.type === 'debit' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({type: 'debit'})}>Membership</button>
          <button className={this.state.type === 'other' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({type: 'other'})}>Other</button>
        </div>
      )
    }
  }


  renderComments() {
    if(this.state.type === 'sessions') {
      return (
        <WidgetFormInfo id={this.state.id === '' ? 'prodnewsessions' : 'prodeditsessions'}/>
      )
    } else if(this.state.type === 'debit' || this.state.type === 'subscription') {
      return (
        <WidgetFormInfo id={this.state.id === '' ? 'prodnewdebit' : 'prodeditdebit'}/>
      )
    } else {
      return (
        <WidgetFormInfo id={this.state.id === '' ? 'prodnewother' : 'prodeditother'}/>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
              <div className="box withtabs">
                {this.renderRadios()}
                <div className="sv-20"></div>
                <div className="inner">
                  {this.renderType()}
                </div>
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                {this.renderComments()}
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>
          <div className="btn-news" onClick={() => window.Intercom('startTour', '321095')}>Show me around</div>
          <Header title={this.state.id === '' ? 'New Product' : 'Product Details'} link={ROUTES.PRODUCTS}/>
          <Navigation active='billing' />
          <ModalMessageSimple type='deleteproduct' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteProduct()}/>
          <Loader show={this.state.loading}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
          <ListEmpty id='products-locked' type='simple'/>
          </div>
          <Header title='Product' link={global.currentPage}/>
          <Navigation active='billing' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(ProductPage));