import React, { Component } from 'react';
import { Link } from 'react-router-dom';



const DataActions = [
  {
    id: 'newclient',
    title: 'Add a new client',
  },
  {
    id: 'importclient',
    title: 'Import your client list',
  },
  {
    id: 'clientdetails',
    title: 'View details',
  },
  {
    id: 'emailinvites',
    title: 'Send email invites',
  },
  {
    id: '11session',
    title: 'Create a 1:1 session',
  },
  {
    id: 'report',
    title: 'Create a report',
  },
  {
    id: 'newclientgroup',
    title: 'Create a new group',
  },
  {
    id: 'newprogram',
    title: 'Create a program',
  },
  {
    id: 'newbenchmark',
    title: 'Create a benchmark program',
  },
  {
    id: 'newmovement',
    title: 'Create a new movement',
  },
  {
    id: 'newplan',
    title: 'Create a training plan',
  },
  {
    id: 'newnutrition',
    title: 'New nutrition plan',
  },
  {
    id: 'nutbilling',
    title: "View this week's billing",
  },
  {
    id: 'newhabit',
    title: "New habit",
  },
  {
    id: 'newpayment',
    title: 'Process a payment',
  },
  {
    id: 'newdebit',
    title: "Set up a membership",
  },
  {
    id: 'newproduct',
    title: "Create a new product",
  },
  {
    id: 'newinvoice',
    title: "Create an invoice",
  },
  {
    id: 'newexpense',
    title: "Log an expense",
  },
  {
    id: 'newstaff',
    title: 'Add a staff member',
  },
  {
    id: 'staffinvite',
    title: 'Send invite',
  },
  {
    id: 'newform',
    title: 'Create a form',
  },
  {
    id: 'uploaddoc',
    title: 'Upload a document',
  },
  {
    id: 'downloaddoc',
    title: 'Download PDF templates',
  },
  {
    id: 'cal11',
    title: 'New 1:1 session',
  },
  {
    id: 'calgroup',
    title: 'New group session',
  },
  {
    id: 'calevent',
    title: 'New event',
  },
  {
    id: 'rec11',
    title: 'New 1:1 template',
  },
  {
    id: 'recgroup',
    title: 'New group template',
  },
  {
    id: 'recevent',
    title: 'New event template',
  },
  {
    id: 'rec11client',
    title: 'New recurring session',
  },
  {
    id: 'sendprogram',
    title: 'Send a program',
  },
  {
    id: 'sendplan',
    title: 'Send a training plan',
  },
  {
    id: 'trial',
    title: 'Set up a free trial',
  },
  {
    id: 'newemail',
    title: 'Create a new email',
  },
  {
    id: 'emailblacklist',
    title: "Manage no-send list",
  },
  {
    id: 'emailusage',
    title: "View this month's billing",
  },
  {
    id: 'newlocation',
    title: "Add a new location",
  },
]



class WidgetActions extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      item1: props.item1 ?? {id: ''},
      item2: props.item2 ?? {id: ''},
      item3: props.item3 ?? {id: ''},
      ext: props.ext ?? false,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      item1: props.item1 ?? {id: ''},
      item2: props.item2 ?? {id: ''},
      item3: props.item3 ?? {id: ''},
      ext: props.ext ?? false,
    }
  }


  renderItem1() {
    var data = null
    var link = ''
    for(var item of DataActions) {
      if(item.id === this.state.item1.id) {
        data = item
        link = this.state.item1.link
      }
    }
    if(data !== null) {
      if(link !== '') {
        return (
          <Link to={link} className='action'>{data.title}</Link>
        )
      } else {
        return (
          <div className='action' onClick={() => this.props.clickItem1()}>{data.title}</div>
        )
      }
    }
  }


  renderItem2() {
    var data = null
    var link = ''
    for(var item of DataActions) {
      if(item.id === this.state.item2.id) {
        data = item
        link = this.state.item2.link
      }
    }
    if(data !== null) {
      if(link !== '') {
        return (
          <Link to={link} className='action'>{data.title}</Link>
        )
      } else {
        return (
          <div className='action' onClick={() => this.props.clickItem2()}>{data.title}</div>
        )
      }
    }
  }


  renderItem3() {
    var data = null
    var link = ''
    for(var item of DataActions) {
      if(item.id === this.state.item3.id) {
        data = item
        link = this.state.item3.link
      }
    }
    if(data !== null) {
      if(link !== '') {
        return (
          <Link to={link} className='action'>{data.title}</Link>
        )
      } else {
        return (
          <div className='action' onClick={() => this.props.clickItem3()}>{data.title}</div>
        )
      }
    }
  }


  render() {
    return (
      <div className="col-3">
        {this.renderItem1()}
        {this.renderItem2()}
        {this.renderItem3()}
      </div>
    )
  }
}


export default WidgetActions;