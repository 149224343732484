import React, { Component } from 'react';
import ListClient from '../List/client';



const DataInputClient = [
  {
    id: 'payment',
    title: 'Select a client',
    line1: 'Tap to select a client',
    line2: 'for the payment',
    error: 'Please select a client',
  },
  {
    id: 'habit',
    title: 'Select a client',
    line1: 'Tap to select a client',
    line2: 'for the habit',
    error: 'Please select a client',
  },
  {
    id: 'nutrition',
    title: 'Select a client',
    line1: 'Tap to select the client you',
    line2: 'want to create the plan for',
    error: 'Please select a client',
  },
  {
    id: 'invoice',
    title: 'Select a client',
    line1: 'Tap to select a client',
    line2: 'for the invoice',
    error: 'Please select a client',
  },
  {
    id: 'payment',
    title: 'Select a client',
    line1: 'Tap to select a client',
    line2: 'for the payment',
    error: 'Please select a client',
  },
  {
    id: 'debit',
    title: 'Select a client',
    line1: 'Tap to select a client',
    line2: 'for the membership',
    error: 'Please select a client',
  },
  {
    id: 'session',
    title: 'Select a client',
    line1: 'Tap to select a client',
    line2: 'for the session',
    error: 'Please select a client',
  },
  {
    id: 'recurring',
    title: 'Select a client',
    line1: 'Tap to select a client',
    line2: 'for the sessions',
    error: 'Please select a client',
  },
]


class InputClient extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      client: props.client,
      preselected: props.presel ?? false,
      error: props.error ?? false,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      id: props.id,
      client: props.client,
      preselected: props.presel ?? false,
      error: props.error ?? false,
    }
  }


  renderClient() {
    if(this.state.client === null) {
      var data = null
      for(var item of DataInputClient) {
        if(item.id === this.state.id) {
          data = item
        }
      }
      if(data !== null) {
        return (
          <ListClient title={data.title} line1={data.line1} line2={data.line2} type='placeholder' clickElement={() => this.props.clickElement()}/>
        )
      }
    } else {
      if(this.state.preselected) {
        return (
          <ListClient client={this.state.client} type='static'/>
        )
      } else {
        return (
          <ListClient client={this.state.client} clickElement={() => this.props.clickElement()}/>
        )
      }
      
    }
  }


  renderError() {
    if(this.state.error) {
      var error = 'Error'
      for(var item of DataInputClient) {
        if(item.id === this.state.id) {
          error = item.error
        }
      }
      return (
        <div className="form">
          <p className="error">{error}</p>
        </div>
      )
    }
  }


  render() {
    return (
      <div>
        <label>Client</label>
        {this.renderClient()}
        {this.renderError()}
      </div>
    )
  }
}


export default InputClient;