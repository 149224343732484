import React, { Component } from 'react';
import Moment from 'moment';
import Firebase from 'firebase';

import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetOverview from '../../components/Widgets/overview';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import ListSimple from '../../components/List/simple';
import InputDate from '../../components/Form/input-date';
import Textarea from '../../components/Form/textarea';
import InfoData from '../../components/Form/info';
import ModalForm from '../../components/Modals/form';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class PublicPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'disabled',
      name: global.userName,
      desc: '',
      color: 'blue',
      image: '',
      video: '',
      requests: [],
      requestsList: [],
      requestsIn: [],
      sessions: [],
      search: '',
      dateRange: global.dateRange,
      showOverlayRequest: false,
      hiddenRequest: 'hidden',
      margin: 0,
      value1: '',
      value2: '',
      value3: '',
      value4: 'blue',
      notes: '',
      date: Moment().add(1, 'month').format('YYYY-MM-DD'),
      cdate: '',
      file: null,
      selected: null,
      action: '',
      exists: false,
      showModalDetails: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Leads - PT Mate', ROUTES.ADMINPUBLIC)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('publicRequestsLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      this.setState({
        requests: global.publicRequests,
        sessions: global.sessions
      }, () => {
        //this.setRequests(this.state.requests)
        this.setRequests(global.publicRequests)
        this.setRequestsIn()
      })

      if(global.publicData.length > 0) {
        var desc = ''
        var status = 'disabled'
        if(global.publicData[0].desc !== undefined) {
          desc = global.publicData[0].desc
        }
        if(global.publicData[0].status !== undefined) {
          status = global.publicData[0].status
        }
        this.setState({
          status: status,
          name: global.publicData[0].name,
          desc: desc,
          color: global.publicData[0].color,
          image: global.publicData[0].image,
          video: global.publicData[0].video,
        })
      }
    }
  }


  filterRequests = event => {
    this.setState({search: event.target.value });
    var tmp = []
    if(event.target.value !== '') {
      for(var item of this.state.requests) {
        if(item.data.name !== undefined) {
          if(item.data.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1) {
            tmp.push(item)
          }
        }
      }
      this.setRequests(tmp)
    } else {
      this.setRequests(this.state.requests)
    }
  };


  setDateRange() {
    this.setState({
      dateRange: global.dateRange
    }, () => {
      if(this.state.search !== '') {
        var tmp = []
        for(var item of this.state.requests) {
          if(item.data.name !== undefined) {
            if(item.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
              tmp.push(item)
            }
          }
        }
        this.setRequests(tmp)  
      }
      this.setRequests(this.state.requests)
      this.setRequestsIn()
    })
  }


  setRequests(list) {
    var tmp = []
    var minDate = Moment().add(-31, 'days')
    if(this.state.dateRange === '7days') {
      minDate = Moment().add(-8, 'days')
    }
    if(this.state.dateRange === 'week') {
      minDate = Moment().startOf('week')
    }
    if(this.state.dateRange === '60days') {
      minDate = Moment().add(-61, 'days')
    }
    for(var item of list) {
      if(item.data.status === 'new') {
        tmp.push(item)
      } else {
        if(this.state.dateRange === '7days' || this.state.dateRange === '30days' || this.state.dateRange === '60days' || this.state.dateRange === 'week') {
          if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm') > minDate && Moment(item.data.sent, 'DD/MM/YYYY HH:mm') < Moment()) {
            tmp.push(item)
          }
        } else if(this.state.dateRange === 'month') {
          if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.sent, 'DD/MM/YYYY HH:mm') < Moment()) {
            tmp.push(item)
          }
        } else {
          if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm').format('X') < Moment()) {
            tmp.push(item)
          }
        }
      }
      
    }
    this.setState({
      requestsList: tmp
    })
  }


  setRequestsIn() {
    var tmp = []
    var minDate = Moment().add(-31, 'days')
    if(this.state.dateRange === '7days') {
      minDate = Moment().add(-8, 'days')
    }
    if(this.state.dateRange === 'week') {
      minDate = Moment().startOf('week')
    }
    if(this.state.dateRange === '60days') {
      minDate = Moment().add(-61, 'days')
    }
    for(var item of this.state.requests) {
      if(this.state.dateRange === '7days' || this.state.dateRange === '30days' || this.state.dateRange === '60days' || this.state.dateRange === 'week') {
        if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm') > minDate && Moment(item.data.sent, 'DD/MM/YYYY HH:mm') < Moment()) {
          tmp.push(item)
        }
      } else if(this.state.dateRange === 'month') {
        if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.sent, 'DD/MM/YYYY HH:mm') < Moment()) {
          tmp.push(item)
        }
      } else {
        if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm').format('X') < Moment()) {
          tmp.push(item)
        }
      }
    }
    this.setState({
      requestsIn: tmp
    })
  }


  getSession(id) {
    var label = 'Contact request'
    if(id !== '') {
      label = 'Group session'
      for(var item of this.state.sessions) {
        if(item.id === id && item.data.client !== '') {
          label = item.data.client
        }
        if(item.id === id && item.data.availability) {
          label = 'Available 1:1 session'
        }
      }
    }
    return label
  }


  getSessionFull(id) {
    var label = 'Contact request'
    if(id !== '') {
      label = 'Group session'
      for(var item of this.state.sessions) {
        if(item.id === id) {
          var date = Moment(item.data.timestamp, 'X').format('LLL')
          label = 'Group session - '+date
          if(item.id === id) {
            if(item.data.client !== '') {
              label = item.data.client+' - '+date
            }
            if(item.data.availability) {
              label = 'Available 1:1 session - '+date
            }
          }
        }
      }
    }
    return label
  }


  getStatus(item) {
    var label = ''
    if(item.data.status === 'new') {
      label = 'New'
    }
    if(item.data.status === 'converted') {
      label = 'Client created'
    }
    if(item.data.status === 'added') {
      label = 'Client added to session'
    }
    if(item.data.status === 'phone') {
      label = 'Contacted via phone'
    }
    if(item.data.status === 'email') {
      label = 'Contacted via email'
    }
    if(item.data.status === 'later') {
      label = 'Contact again on '+Moment(item.data.date, 'X').format('D MMM YYYY')
    }
    return label
  }


  getNumber(status) {
    var num = 0
    for(var item of this.state.requestsIn) {
      if(item.data.status === status) {
        num++
      }
    }
    return num
  }


  getDifRequests(type) {
    var num = 0
    var arr = ['', '+0']
    var minDate = Moment().add(-61, 'days')
    var maxDate = Moment().add(-30, 'days')
    if(this.state.dateRange === '7days') {
      minDate = Moment().add(-7, 'days')
      maxDate = Moment().add(-15, 'days')
    }
    if(this.state.dateRange === 'week') {
      minDate = Moment().startOf('week')
      maxDate = Moment().startOf('week').add(-7, 'days')
    }
    if(this.state.dateRange === '60days') {
      minDate = Moment().add(-60, 'days')
      maxDate = Moment().add(-120, 'days')
    }
    for(var item of this.state.requests) {
      if(this.state.dateRange === '7days' || this.state.dateRange === '30days' || this.state.dateRange === '60days' || this.state.dateRange === 'week') {
        if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm') > minDate && Moment(item.data.sent, 'DD/MM/YYYY HH:mm') < maxDate) {
          num++
        }
      } else if(this.state.dateRange === 'month') {
        if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().add(-1, 'month').format('MM/YYYY')) {
          num++
        }
      } else {
        if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm').format('X') < Moment()) {
          num++
        }
      }
    }

    arr[1] = String(this.state.requestsIn.length-num)
    if(num < this.state.requestsIn.length) {
      arr[0] = 'green'
      arr[1] = '+'+arr[1]
    } else if(num > this.state.requestsIn.length) {
      arr[0] = 'red'
    }

    if(type === 'badge') {
      if(this.state.dateRange !== '60days') {
        return (
          <div className={'badge '+arr[0]} title='Change compared to previous period'>{arr[1]}</div>
        )
      } else {
        return (
          <div className={'badge'} title='Change compared to previous period'>N/A</div>
        )
      }
    } else {
      return num
    }
    
  }


  getDifNew(type) {
    var num = 0
    var arr = ['', '+0']
    var minDate = Moment().add(-61, 'days')
    var maxDate = Moment().add(-30, 'days')
    if(this.state.dateRange === '7days') {
      minDate = Moment().add(-7, 'days')
      maxDate = Moment().add(-15, 'days')
    }
    if(this.state.dateRange === 'week') {
      minDate = Moment().startOf('week')
      maxDate = Moment().startOf('week').add(-7, 'days')
    }
    if(this.state.dateRange === '60days') {
      minDate = Moment().add(-60, 'days')
      maxDate = Moment().add(-120, 'days')
    }
    for(var item of this.state.requests) {
      if(this.state.dateRange === '7days' || this.state.dateRange === '30days' || this.state.dateRange === '60days' || this.state.dateRange === 'week') {
        if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm') > minDate && Moment(item.data.sent, 'DD/MM/YYYY HH:mm') < maxDate && item.data.status === 'new') {
          num++
        }
      } else if(this.state.dateRange === 'month') {
        if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().add(-1, 'month').format('MM/YYYY') && item.data.status === 'new') {
          num++
        }
      } else {
        if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm').format('X') < Moment() && item.data.status === 'new') {
          num++
        }
      }
    }

    var num2 = 0
    for(var req of this.state.requestsIn) {
      if(req.data.status === 'new') {
        num2++
      }
    }
    arr[1] = String(num2-num)
    if(num < num2) {
      arr[0] = 'green'
      arr[1] = '+'+arr[1]
    } else if(num > num2) {
      arr[0] = 'red'
    }

    if(type === 'badge') {
      if(this.state.dateRange !== '60days') {
        return (
          <div className={'badge '+arr[0]} title='Change compared to previous period'>{arr[1]}</div>
        )
      } else {
        return (
          <div className={'badge'} title='Change compared to previous period'>N/A</div>
        )
      }
    } else {
      return num
    }
    
  }


  getDifConverted(type) {
    var num = 0
    var arr = ['', '+0']
    var minDate = Moment().add(-61, 'days')
    var maxDate = Moment().add(-30, 'days')
    if(this.state.dateRange === '7days') {
      minDate = Moment().add(-7, 'days')
      maxDate = Moment().add(-15, 'days')
    }
    if(this.state.dateRange === 'week') {
      minDate = Moment().startOf('week')
      maxDate = Moment().startOf('week').add(-7, 'days')
    }
    if(this.state.dateRange === '60days') {
      minDate = Moment().add(-60, 'days')
      maxDate = Moment().add(-120, 'days')
    }
    for(var item of this.state.requests) {
      if(this.state.dateRange === '7days' || this.state.dateRange === '30days' || this.state.dateRange === '60days' || this.state.dateRange === 'week') {
        if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm') > minDate && Moment(item.data.sent, 'DD/MM/YYYY HH:mm') < maxDate && (item.data.status === 'converted' || item.data.status === 'added')) {
          num++
        }
      } else if(this.state.dateRange === 'month') {
        if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().add(-1, 'month').format('MM/YYYY') && (item.data.status === 'converted' || item.data.status === 'added')) {
          num++
        }
      } else {
        if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm').format('X') < Moment() && (item.data.status === 'converted' || item.data.status === 'added')) {
          num++
        }
      }
    }

    var num2 = 0
    for(var req of this.state.requestsIn) {
      if(req.data.status === 'converted' || req.data.status === 'added') {
        num2++
      }
    }
    arr[1] = String(num2-num)
    if(num < num2) {
      arr[0] = 'green'
      arr[1] = '+'+arr[1]
    } else if(num > num2) {
      arr[0] = 'red'
    }

    if(type === 'badge') {
      if(this.state.dateRange !== '60days') {
        return (
          <div className={'badge '+arr[0]} title='Change compared to previous period'>{arr[1]}</div>
        )
      } else {
        return (
          <div className={'badge'} title='Change compared to previous period'>N/A</div>
        )
      }
    } else {
      return num
    }
  }



  // Modalsy ------------------------------------------------------------



  showModalDetails() {
    this.setState({
      showModalDetails: true,
      value1: this.state.name,
      value2: this.state.video,
      value3: this.state.desc,
      value4: this.state.color,
      file: null,
    })
  }


  hideModals() {
    this.setState({
      showModalDetails: false,
    })
  }


  updateDetails() {
    this.hideModals()
    Firebase.database().ref('/public/'+global.uid).update({
      name: global.userBusiness,
      desc: this.state.value3,
      color: global.spaceTheme,
      video: '',
      status: this.state.status,
      image: '',
    }).then(() => {
      EventEmitter.dispatch('showMessage', 'Details successfully updated')
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  setStatus() {
    global.badgeLocked = true
    var status = 'active'
    if(this.state.status === 'active') {
      status = 'disabled'
    }
    this.setState({
      status: status
    })
    Firebase.database().ref('/public/'+global.uid).update({
      name: global.userBusiness,
      desc: this.state.desc,
      color: global.spaceTheme,
      video: '',
      image: '',
      status: status
    })
    setTimeout(() => {
      EventEmitter.dispatch('updateBadges', 'public');
    }, 1000);
  }



  // Request overlay ------------------------------------------------------------



  showOverlayRequest(item) {
    var ex = false
    var notes = ''
    var date = ''
    var bnotes = ''
    if(item.data.notes !== undefined) {
      notes = item.data.notes
    }
    if(item.data.businessnotes !== undefined) {
      bnotes = item.data.businessnotes
    }
    if(item.data.date !== undefined) {
      date = item.data.date
    }
    for(var session of this.state.sessions) {
      if(session.id === item.data.session) {
        ex = true
      }
    }
    this.setState({
      showOverlayRequest: true,
      selected: item,
      exists: ex,
      notes: bnotes,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenRequest: '',
        margin: -height/2,
        action: '',
        cdate: date,
      });
    }, 100);
  }


  hideOverlayRequest() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenRequest: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayRequest: false});
    }, 500);
  }


  renderOptions() {
    if(this.state.selected.data.status === 'new' || this.state.selected.data.status === '') {
      if(this.state.exists) {
        return (
          <select onChange={event => this.selectAction(event)} value={this.state.action}>
            <option value={''} disabled>- Select an action -</option>
            <option value={'converted'}>Convert into a new client</option>
            <option value={'added'}>Convert into client and add to session</option>
            <option disabled>────────────────────</option>
            <option value={'phone'}>Mark as contacted via phone</option>
            <option value={'email'}>Mark as contacted via email</option>
            <option value={'later'}>Contact again later</option>
            <option disabled>────────────────────</option>
            <option value={'delete'}>Delete this lead</option>
          </select>
        )
      } else {
        return (
          <select onChange={event => this.selectAction(event)} value={this.state.action}>
            <option value={''} disabled>- Select an action -</option>
            <option value={'converted'}>Convert into a new client</option>
            <option disabled>────────────────────</option>
            <option value={'phone'}>Mark as contacted via phone</option>
            <option value={'email'}>Mark as contacted via email</option>
            <option value={'later'}>Contact again later</option>
            <option disabled>────────────────────</option>
            <option value={'delete'}>Delete this lead</option>
          </select>
        )
      }
      
    } else if(this.state.selected.data.status === 'converted' || this.state.selected.data.status === 'added') {
      return (
        <select onChange={event => this.selectAction(event)} value={this.state.action}>
          <option value={''} disabled>- Select an action -</option>
          <option value={'delete'}>Delete this lead</option>
        </select>
      )
    } else if(this.state.selected.data.status === 'phone' || this.state.selected.data.status === 'email') {
      if(this.state.exists) {
        return (
          <select onChange={event => this.selectAction(event)} value={this.state.action}>
            <option value={''} disabled>- Select an action -</option>
            <option value={'converted'}>Convert into a new client</option>
            <option value={'added'}>Convert into client and add to session</option>
            <option value={'later'}>Contact again later</option>
            <option disabled>────────────────────</option>
            <option value={'delete'}>Delete this lead</option>
          </select>
        )
      } else {
        return (
          <select onChange={event => this.selectAction(event)} value={this.state.action}>
            <option value={''} disabled>- Select an action -</option>
            <option value={'converted'}>Convert into a new client</option>
            <option value={'later'}>Contact again later</option>
            <option disabled>────────────────────</option>
            <option value={'delete'}>Delete this lead</option>
          </select>
        )
      }
    } else if(this.state.selected.data.status === 'later') {
      return (
        <select onChange={event => this.selectAction(event)} value={this.state.action}>
          <option value={''} disabled>- Select an action -</option>
          <option value={'converted'}>Convert into a new client</option>
          <option value={'phone'}>Mark as contacted via phone</option>
          <option value={'email'}>Mark as contacted via email</option>
          <option value={'later'}>Contact again later</option>
          <option value={''}>Don't contact later any more</option>
          <option disabled>────────────────────</option>
          <option value={'delete'}>Delete this lead</option>
        </select>
      )
    }
  }


  renderCurrentDate() {
    if(this.state.cdate !== '') {
      return <InfoData label='Contact again on' value={Moment(this.state.cdate, 'X').format('D MMM YYYY')}/>
    }
  }


  renderDate() {
    if(this.state.action === 'later') {
      return (
        <InputDate label={'Contact again on'} value={this.state.date} format='YYYY-MM-DD' min={Moment().format('YYYY-MM-DD')} onChange={(event) => this.setState({date: Moment(event).format('YYYY-MM-DD')})}/>
      )
    }
  }


  renderOverlayRequest() {
    if(this.state.showOverlayRequest) {
      return (
        <div className={'overlay '+this.state.hiddenRequest}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Manage lead</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayRequest()}>Close</button>
            <div className="clear"></div>
            <InfoData label='Name' value={this.state.selected.data.name}/>
            <InfoData label='Phone number' value={this.state.selected.data.phone}/>
            <InfoData label='Email address' value={this.state.selected.data.email === '' ? '-' : this.state.selected.data.email}/>
            <InfoData label='Requested session' value={this.getSessionFull(this.state.selected.data.session)}/>
            <InfoData label='Comments' value={this.state.selected.data.notes === '' ? '-' : this.state.selected.data.notes}/>
            {this.renderCurrentDate()}
            <Textarea label='Notes' value={this.state.notes} onChange={(event) => this.setState({notes: event})}/>
            <div className="form-item clear">
              <label>Select an action</label>
              {this.renderOptions()}
            </div>
            {this.renderDate()}
            <div className="clear sv-10"></div>
            <button className="btn primary" onClick={() => this.updateResponse()}>Save changes</button>
          </div>
        </div>
      )
    }
  }


  selectAction(event) {
    this.setState({
      action: event.target.value
    })
  }


  updateResponse() {
    this.hideOverlayRequest()
    var date = null
    console.log(this.state.action)
    if(this.state.action === 'later') {
      date = parseInt(Moment(this.state.date, 'YYYY-MM-DD').format('X'))
    }
    if(this.state.action === 'delete') {
      Firebase.database().ref('/public/'+global.uid+'/requests/'+this.state.selected.id).remove()
      EventEmitter.dispatch('showMessage', 'Request successfully deleted')
    } else {
      Firebase.database().ref('/public/'+global.uid+'/requests/'+this.state.selected.id).update({
        status: this.state.action,
        date: date,
        businessnotes: this.state.notes,
      })
      EventEmitter.dispatch('showMessage', 'Response successfully updated')
    }
    if(this.state.action === 'converted') {
      var obj = Firebase.database().ref('/clients/'+global.uid).push()
      obj.set({
        name: this.state.selected.data.name,
        phone: this.state.selected.data.phone,
        email: this.state.selected.data.email,
        birth: '01/01/1900',
        height: 0,
        goal: '',
        uid: '',
        image: '',
        imageDate: Moment().format('DD/MM/YYYY HH:mm'),
      }).then(() => {
        Firebase.database().ref('/clients/'+global.uid+'/'+obj.key+'/history').push({
          date: Moment().format('DD/MM/YYYY HH:mm'),
          title: 'Client added via lead form',
          desc: 'Requested a trial session and was converted into a client.'
        })
      }).catch((error)=>{})
    }
    if(this.state.action === 'added') {
      var obj2 = Firebase.database().ref('/clients/'+global.uid).push()
      obj2.set({
        name: this.state.selected.data.name,
        phone: this.state.selected.data.phone,
        email: this.state.selected.data.email,
        birth: '01/01/1900',
        height: 0,
        goal: '',
        uid: '',
        image: '',
        imageDate: Moment().format('DD/MM/YYYY HH:mm'),
      }).then(() => {
        Firebase.database().ref('/clients/'+global.uid+'/'+obj2.key+'/history').push({
          date: Moment().format('DD/MM/YYYY HH:mm'),
          title: 'Client added via lead form',
          desc: 'Requested a group trial session and was converted into a client.'
        })
        // Add to session
        for(var session of this.state.sessions) {
          if(session.id === this.state.selected.data.session) {
            var clients = []
            if(session.data.clients !== undefined) {
              clients = session.data.clients
            }
            clients.push(obj2.key)
            Firebase.database().ref('/sessions/'+global.uid+'/'+session.id).update({
              clients: clients
            })
          }
        }
      }).catch((error)=>{})
    }
  }



  // Display stuff ------------------------------------------------------------



  renderStatus() {
    if(this.state.status === 'disabled') {
      return (
        <p className="error big" style={{marginTop: 0, fontSize:  16, fontWeight: 400}}>Inactive (People can't access it)</p>
      )
    } else {
      return (
        <p>Active</p>
      )
    }
  }


  renderInfo() {
    if(HelperBusiness.getRoleIncl('admin')) {
      return (
        <div className="col-3">
          <h3 className="lft mb-30">Leads Page</h3>
          <button className="btn tertiary small rgt edit" onClick={() => this.showModalDetails()}>Edit description</button>
          <div className="clear">
            <div className="info">
              <label>Page URL</label>
              <p>Share this link to allow people to request trial sessions outside of PT Mate<br/><span style={{fontSize: 11}}>https://ptmate.app/trainer/{global.uid}</span></p>
            </div>
            <div className="form-item">
              <label>Status</label>
              <div className={this.state.status === 'active' ? 'toggle active' : 'toggle'} onClick={() => this.setStatus()}>
                <div className="mover"></div>
              </div>
              {this.renderStatus()}
            </div>
            <a href={'https://ptmate.app/trainer/'+global.uid} target="_blank" rel="noopener noreferrer" className="btn tertiary small lft">View page</a>
          </div>
        </div>
      )
    } else {
      return (
        <div className="col-3">
          <h3 className="mb-20">Leads Page</h3>
          <div className="clear">
          <div className="info">
              <label>Page URL</label>
              <p>Share this link to allow people to request trial sessions outside of PT Mate<br/><span style={{fontSize: 11}}>https://ptmate.app/trainer/{global.uid}</span></p>
            </div>
            <div className="info">
              <p className="label">Status</p>
              {this.renderStatus()}
            </div>
          </div>
        </div>
      )
    }
  }


  renderDot(item) {
    if(item.data.status === 'new') {
      return (
        <div className="bullet important red lft" title="New" style={{marginTop: 8, marginRight: 4}}></div>
      )
    }
  }


  renderFilters() {
    return (
      <div className="listheader clear">
        <div className="lft mt-5">
          <ListHeader id='leads' number={this.state.requestsList.length}/>
        </div>
        <input type="text" className="search light rgt" placeholder="Filter by name" value={this.state.search} onChange={(val) => this.filterRequests(val)} />
        <div className="clear"></div>
      </div>
    )
  }


  renderRequests() {
    if(this.state.requestsList.length === 0) {
      return (
        <ListEmpty id='noleads' type='simple'/>
      )
    } else {
      var list = this.state.requestsList
      list.sort((a,b) => Moment(b.data.sent, 'DD/MM/YYYY HH:mm') - Moment(a.data.sent, 'DD/MM/YYYY HH:mm'));
      return (
        <div>
          {list.map(item => (
            <ListSimple
              key={item.id}
              clickElement={() => this.showOverlayRequest(item)}
              button='manage'
              columns={[{text: <span>{this.renderDot(item)} {item.data.name}</span>, width: 5, w1280: 6}, {text: this.getStatus(item), width: 4, w1280: 3}, {text: Moment(item.data.sent, 'DD/MM/YYYY HH:mm').format('LLL'), width: 2}]}
            />
          ))}
        </div>
      )
    }
  }


  renderContent() {
    return (
      <div>
        <div className="content withtabs">
          <div className="col-9">
            <WidgetOverview
              item1={{id: 'leads', value: this.state.requests.length, prev: this.getDifRequests('num')}} 
              item2={{id: 'leadsnew', value: this.getNumber('new'), prev: this.getDifNew('num')}}
              item3={{id: 'leadsclients', value: this.getNumber('converted')+this.getNumber('added'), prev: this.getDifConverted('num')}}
              item4={{id: '', value: ''}}
            />
          </div>
          {this.renderInfo()}
          <div className="col-12">
            {this.renderFilters()}
            {this.renderRequests()}
            <div className="sv-80 clear"></div>
          </div> 
        </div>
        <Header type='admin' active='leads' selector={true} updateSelect={() => this.setDateRange()}/>
        <Navigation active='admin' />
        {this.renderOverlayRequest()}
        <ModalForm type={'leadspage'} show={this.state.showModalDetails} onHide={() => this.hideModals()} value1={this.state.value3} updateValue1={(event) => this.setState({value3: event})} clickMainButton={() => this.updateDetails()}/>
      </div>
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      if(global.maxPlans === 99999 || HelperBusiness.getRole('trainer,admin')) {
        return (
          <div className={'theme-'+global.spaceTheme}>
            {this.renderContent()}
          </div>
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              <ListEmpty id='leads-locked2'/>
              <div className="sv-20 clear"></div>
            </div>
            <Header type='admin' active='leads' selector={true} updateSelect={() => this.setDateRange()}/>
            <Navigation active='admin' />
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='leads-locked' type='simple'/>
          </div>
          <Header type='simple' title='Admin'/>
          <Navigation active='admin' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(PublicPage));