import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';



class HeaderOverview extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      type: props.type ?? 'header',
      active: props.active ?? '',
    };
  }


  render() {
    if(this.state.type === 'header') {
      return (
        <div>
          <Link to={ROUTES.OVERVIEW} className={this.state.active === 'activity' ? 'tab active' : 'tab'}>
            <p>Activity</p>
            <div className="bg"></div>
          </Link>
          <Link to={ROUTES.COMMUNITY} className={this.state.active === 'community' ? 'tab active' : 'tab'}>
            <p>Community</p>
            <div className="bg"></div>
          </Link>
        </div>
      )
    } else {
      return (
        <div>
          <Link to={ROUTES.OVERVIEW} className="item">
            <div className="bg"></div>
            <div className="text">Activity</div>
          </Link>
          <Link to={ROUTES.COMMUNITY} className="item">
            <div className="bg"></div>
            <div className="text">Community</div>
          </Link>
        </div>
      )
    }
  }
}


export default HeaderOverview;