import React, { Component } from 'react';
import Cal from '../../helper/Calendar';
import InputRadio from '../../components/Form/radio';
import Select from '../../components/Form/select';



class InputDuration extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      label: props.label,
      dur: props.duration ?? 30,
      cdur: props.custom ?? 75,
      custom: [],
      error: props.error ?? false,
      errormsg: props.errormsg ?? '',
    };
  }


  componentDidMount() {
    var tmp = []
    for(var i=0; i<61; i++) {
      tmp.push({name: Cal.getDuration(i*5), value: i*5})
    }
    this.setState({
      custom: tmp
    })
  }


  static getDerivedStateFromProps(props) {
    return {
      label: props.label,
      dur: props.duration ?? 30,
      cdur: props.custom ?? 75,
      error: props.error ?? false,
      errormsg: props.errormsg ?? '',
    }
  }


  renderError() {
    if(this.state.error) {
      return (
        <p className="error">{this.state.errormsg}</p>
      )
    }
  }


  renderCustom() {
    if(this.state.dur === 99) {
      return (
        <Select label='Custom duration' value={this.state.cdur} values={this.state.custom} onChange={(event) => this.props.setCustom(event)}/>
      )
    }
  }


  render() {
    return (
      <div>
        <InputRadio
          label='Duration (min)'
          value={this.state.dur}
          clickElement={(event) => this.props.setDuration(event)}
          values={[{name: '30', value: 30}, {name: '40', value: 40}, {name: '45', value: 45}, {name: '60', value: 60}, {name: '90', value: 90}, {name: 'Other', value: 99}]}
        />
        {this.renderCustom()}
        {this.renderError()}
      </div>
    )
  }
}


export default InputDuration;