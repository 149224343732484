import React, { Component } from 'react';
import Connector from '../../data/Connector';
import ConnectorMember from '../../data/ConnectorMember';
import Firebase from 'firebase';
import Moment from 'moment';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class StaffSearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spaces: global.userStaff,
      selection: false,
      search: '',
      allspaces: [],
      list: [],
      showModal: false,
      hidden: 'hidden',
      margin: 0,
      selected: null,
      img: null,
      error: false
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Business Portal - PT Mate'
    window.scrollTo(0, 0)
    global.programLocked = true
    global.showOverview = false

    Moment.updateLocale("en", { week: {
      dow: 1, // First day of week is Monday
    }});

    ConnectorMember.loadAllSpaces((result) => {})
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('allSpacesLoaded', (event) => this.setTrainers())
  }


  configureData() {
    if(Firebase.auth().currentUser.displayName !== 'staff' && Firebase.auth().currentUser.displayName !== 'client') {
      window.Intercom("boot", {
        app_id: "gwe4xp9u",
        name: global.userName+' (Assistant)', // Full name
        email: global.userEmail, // Email address
        phone: global.userPhone,
        user_id: Firebase.auth().currentUser.uid,
        created_at: Moment().format('X'),
        "Experience": 0,
        "Types": ']t'
      });
      window.Intercom("update");
    }
  }


  onChange = event => {
    this.setState({
      search: event.target.value
    });
  };


  filterList() {
    var tmp = []
    if(this.state.search !== '') {
      var val = this.state.search.toLowerCase()
      for(var item of this.state.allspaces) {
        if(item.data.name !== undefined && item.data.owner !== undefined && item.data.name !== '' && item.data.owner !== '') {
          var l1 = String(item.data.name).toLowerCase()
          var l2 = String(item.data.owner).toLowerCase()
          var num = parseInt(Math.random()*6+1)
          item.num = num
          if(l1.indexOf(val) !== -1 || l2.indexOf(val) !== -1) {
            tmp.push(item)
          }
          if(item.data.image !== '' && item.img === '') {
            this.getImageSpace(item)
          }
        }
        
      }
    }
    this.setState({
      list: tmp
    })
  }


  getImageSpace(item) {
    Firebase.storage().ref().child(item.data.image).getDownloadURL().then((url) => {
      var tmp = this.state.list
      for(var sp of tmp) {
        if(sp.id === item.id) {
          sp.img = url
        }
      }
      this.setState({
        list: tmp
      })
    }).catch((error) => {
      // Handle any errors
    })
  }


  setTrainers() {
    this.setState({
      spaces: global.userStaff,
      allspaces: global.spaces
    })
  }


  selectSpace(item) {
    global.userVerified = true
    global.uid = item.id
    global.userBusiness = item.name
    global.spaceOwner = item.owner
    global.spaceImage = item.image
    global.spaceClient = item.client
    global.userCommunity = item.community
    global.userCommunityPost = item.communityPost
    global.userLimitBooking = item.limitBooking
    global.userAllowBooking = item.allowBooking
    global.spaceAllowRecurring = item.allowRecurring
    global.num = item.num
    global.userStripeConnect = item.stripe
    global.clientToken = item.token
    ConnectorMember.loadSessions((result) => {})
    ConnectorMember.loadEvents((result) => {})
    ConnectorMember.loadForms((result) => {
      if(global.forms.length === 0) {
        this.props.history.push('/member/'+global.uid+'/home')
      } else {
        this.props.history.push('/member/'+global.uid+'/pre-exercise')
      }
    })
    ConnectorMember.loadClientGroups((result) => {})
    ConnectorMember.loadPayments((result) => {})
    Connector.loadInvoices((result) => {})
    ConnectorMember.loadLog((result) => {})
    ConnectorMember.loadChat((result) => {})
    ConnectorMember.loadChatsGroup((result) => {})
    ConnectorMember.loadClients((result) => {})
    ConnectorMember.loadClient((result) => {
      if(global.userParent !== '' && global.userParent !== undefined) {
        ConnectorMember.loadFamilyBilling(global.userParent)
      }
    })
    ConnectorMember.loadPrograms((result) => {})
    ConnectorMember.loadCommunity((result) => {})
    ConnectorMember.loadRecurring((result) => {})
    ConnectorMember.loadHabits((result) => {})
    Connector.loadDocuments((result) => {})
    ConnectorMember.loadTraining((result) => {})
    Connector.loadExercises((result) => {})
    if(item.theme !== undefined) {
      global.spaceTheme = item.theme
    } else {
      global.spaceTheme = 'blue'
    }
    if(item.image !== '') {
      Firebase.storage().ref().child(item.image).getDownloadURL().then((url) => {
        global.spaceImage = url
        EventEmitter.dispatch('userLoaded', 'loaded');
      }).catch((error) => {
        // Handle any errors
      })
    }
  }



  // Modal stuff ------------------------------------------------------------



  showModal(item) {
    this.setState({
      showModal: true,
      selected: item,
      img: null,
      value: ''
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      if(this.state.selected.data.image !== '') {
        this.getImage()
      }
      this.setState({
        hidden: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideModal() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModal: false});
    }, 500);
  }


  getImage() {
    if(this.state.selected.data.image !== '') {
      Firebase.storage().ref().child(this.state.selected.data.image).getDownloadURL().then((url) => {
        //global.spaceImage = url
        //EventEmitter.dispatch('userLoaded', 'loaded');
        this.setState({
          img: url
        })
      }).catch((error) => {
        // Handle any errors
      })
    }
  }


  renderImage() {
    if(this.state.img !== null) {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.img+')'}}></div>
      )
    } else {
      var inits = ''
      let arr = this.state.selected.data.name.split(' ')
      if(arr.length > 1) {
        inits = arr[0].charAt(0)+arr[1].charAt(0)
      } else {
        inits = arr[0].charAt(0)
      }
      return (
        <div className="avatar">
          <p>{inits}</p>
        </div>
      )
    }
  }


  renderError() {
    if(this.state.error) {
      return (
        <p className="error">PIN incorrect</p>
      )
    }
  }


  renderModal() {
    if(this.state.showModal) {
      return (
        <div className={'overlay '+this.state.hidden}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h3>Connect to space</h3>
            <button className="btn tertiary small close" onClick={() => this.hideModal()}>Close</button>
            <div className="client-list static">
              {this.renderImage()}
              <div className="text">
                <h4>{this.state.selected.data.name}</h4>
                <p>{this.state.selected.data.owner}<br/>{this.state.selected.data.email}</p>
              </div>
            </div>
            <div className="clear space-20"></div>
            <p className="clear">Please enter the Connection PIN. Unless stated otherwise, this is usually the space's phone number.</p>
            <div className="form">
            <label>Connection PIN</label>
              <input type="number" placeholder="Connection PIN" value={this.state.value} onChange={this.onChangePIN}/>
              {this.renderError()}
            </div>
            <div className="space-40"></div>
            <button className="btn primary" onClick={() => this.checkConnect()}>Connect</button>
          </div>
        </div>
      )
    }
  }


  onChangePIN = event => {
    this.setState({
      value: event.target.value
    });
  };


  checkConnect() {
    if(this.state.value === this.state.selected.data.pin) {
      this.connectSpace()
    } else {
      this.setState({
        error: true
      })
    }
  }


  connectSpace() {
    this.hideModal()
    var cid = ''
    global.uid = this.state.selected.id
    if(global.uid !== '') {
      Firebase.database().ref('/usersStaff/'+Firebase.auth().currentUser.uid).update({
        space: global.uid,
        role: 'assistant',
        status: 'active'
      })
      Firebase.database().ref('activity/'+global.uid).push({
        type: 'staff',
        data: global.uidUser+',assistant',
        date: Moment().format('DD/MM/YYYY HH:mm')
      })
      Firebase.database().ref('/spaces/'+global.uid+'/staff/'+global.uidUser).update({
        id: Firebase.auth().currentUser.uid,
        name: global.userName,
        role: 'assistant',
        status: 'active'
      }).then(() => {
        this.props.history.push('/overview')
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
      if(cid !== '') {
        Firebase.database().ref('/connect/'+cid).remove()
      }
    }
  }


  logoutUser() {
    global.uid = "";
    global.uidUser = "";
    global.userName = '';
    global.userBusiness = '';
    global.userEmail = '';
    global.userPhone = '';
    global.userStripe = '';
    global.userMessage = 0;
    global.userCard = '';
    global.userSubPlanId = '';
    global.userSubPlan = 'spark';
    global.userSubId = '';
    global.userSubStatus = 'trialing';
    global.userSubBilling = '';
    global.userSubCancel = false;
    global.userSubEnd = 0;
    global.userSubItemId = '';
    global.userStripeConnect = '';
    global.userStripeBank = '';
    global.userStripeAddress = '';
    global.timerStart = 5;
    global.userTemplates = [];
    global.userImage = '';
    global.userEmailsSent = [];
    global.userVerified = false;
    global.userOnboarding = ['', ''];
    global.userBadges = [];
    global.userComments = true;
    global.userShowBooked = true;
    global.userSubdomain = '';
    global.userLimitBooking = false;

    global.cards = [];
    global.coupon = [];
    global.userSignup = false;

    global.userRole = '';
    global.userStaff = [];
    global.spaces = [];
    global.clientPlans = [];

    global.publicData = [];
    global.publicRequests = [];
    global.showOnboarding = false;
    global.userConnectClient = null;
    global.clientToken = '';
    global.clientTokens = [];
    global.spaceClient = '';
    global.spaceTheme = 'blue';

    // Data arrays

    global.clients = [];
    global.clientGroups = [];
    global.clientsImages = [];
    global.clientsInactive = [];
    global.sessions = [];
    global.archive = [];
    global.events = []
    global.clientBest = [];
    global.clientBestGroup = [];
    global.chats = [];
    global.chatsGroup = [];
    global.activity = [];

    global.sessionsTraining = [];
    global.archiveTraining = [];
    global.recurring = [];

    global.programs = [];
    global.programsClient = [];
    global.plans = [];
    global.exercises = [];

    global.payments = [];
    global.invoices = [];
    global.products = [];

    global.userLog = [];
    global.syncDate = 0;
    global.maxClients = 0;
    global.maxPrograms = 0;
    global.maxPlans = 0;
    global.maxTrainers = 0;

    global.userDataLoaded = false;
    Firebase.auth().signOut()
  }



  // Display stuff ------------------------------------------------------------



  renderListImg(item) {
    if(item.img === '') {
      return (
        <div className={'image bg'+item.num}>
          <div className={'gradient theme-'+item.data.theme}></div>
        </div>
      )
    } else {
      return (
        <div className="image" style={{backgroundImage: 'url('+item.img+')'}}></div>
      )
    }
  }


  renderList() { 
    if(this.state.allspaces.length > 0 && this.state.search !== '') {
      var list = this.state.list
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          {list.map(item => (
              <div key={item.id} onClick={() => this.showModal(item)}>
                <div className="box highlight">
                  {this.renderListImg(item)}
                  <div className="text">
                    <h2>{item.data.name}</h2>
                    <p>{item.data.owner}<br/>Click to connect to this space</p>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="clear space-20"></div>
              </div>
            ))}
        </div>
      )
    }
  }


  renderBottom() {
    return (
      <div>
        <div className="space-50 clear"></div>
        <div className="col-8">
          <input type="text" placeholder="Search for your business space" value={this.state.search} onChange={this.onChange}/>
        </div>
        <div className="col-4">
          <button className="btn primary" onClick={() => this.filterList()}>Search</button>
        </div>
        <div className="clear space-40"></div>
        {this.renderList()}
      </div>
    )
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content member-select">
          <div className="member-confirm">
            <h2>Find your business</h2>
            <p>Connect to your business space. Use the search field to find your tribe by entering the business name or owner's name.</p>
            <div style={{position: 'fixed', top: 15, right: 15}}>
              <button className="btn tertiary small close" onClick={() => this.logoutUser()}>Log out</button>
            </div>
          </div>
          {this.renderBottom()}
        </div>
        
        {this.renderModal()}
      </div>
    )
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;

export default withRouter(withAuthorization(condition)(StaffSearchPage));

