import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import Connector from '../../data/Connector';
import Resizer from "react-image-file-resizer";
import { gql } from "@apollo/client";

import HelperPro from '../../helper/Programming';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import InputDate from '../../components/Form/input-date';

import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class ClientsNewAsessmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: global.currentClient,
      aid: '',
      values: ['', '', '', '', '', '', '', '', '', '', '', ''],
      valuesBlood: ['',''],
      valueCustom: '',
      file: null,
      fileDisplay: null,
      back: '',
      item: null,
      file2: null,
      fileDisplay2: null,
      file3: null,
      fileDisplay3: null,
      file4: null,
      fileDisplay4: null,
      date: Moment().format('YYYY-MM-DD'),
      editing: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Client Details - New Health Log Entry - PT Mate')
    this._isMounted = true

    EventEmitter.subscribe('clientsLoaded', (event) => {
      if(this.state.id === '') {
        this.getClient()
      }
    })
    
    var arr = this.props.location.pathname.split('/')
    var link = this.props.location.pathname.replace("new-assessment", "health");
    global.currentClient = arr[arr.length-2]
    if(this.props.location.pathname.indexOf('/edit-assessment/') !== -1) {
      link = this.props.location.pathname.replace("edit-assessment", "assessments");
      global.currentClient = arr[arr.length-3]
    }
    this.getClient()
    
    this.setState({
      back: link,
    })
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getClient() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      var editing = false
      global.currentClient = arr[arr.length-2]
      if(this.props.location.pathname.indexOf('/edit-assessment/') !== -1) {
        global.currentClient = arr[arr.length-3]
        editing = true
      }
      for(var client of global.clients) {
        if(client.id === global.currentClient) {
          this.setState({
            id: client.id,
            item: client,
            name: client.data.name,
            editing: editing,
          });
        }
      }
      if(editing) {
        this.configureEditing(arr[arr.length-1])
      }
    }
  }


  configureEditing(aid) {
    if(this._isMounted) {
      var tmp3 = null
      var tmps = []
      var tmpb = ''
      var tmpbv = ['','']
      var tmpc = ''
      var nutrition = ''
      var date = Moment().format('YYYY-MM-DD')
      for(var client of global.clients) {
        if(client.id === global.currentClient) {
          for(var ass of client.assessments) {
            tmps.push(ass)
            if(ass.id === aid) {
              tmp3 = ass
            }
          }

          if(tmp3 !== null) {
            if(tmp3.data.image !== '') {
              this.getImage(tmp3.data.image, 'image')
            }
            if(tmp3.data.image2 !== '' && tmp3.data.image2 !== undefined) {
              this.getImage(tmp3.data.image2, 'image2')
            }
            if(tmp3.data.image3 !== '' && tmp3.data.image3 !== undefined) {
              this.getImage(tmp3.data.image3, 'image3')
            }
            if(tmp3.data.image4 !== '' && tmp3.data.image4 !== undefined) {
              this.getImage(tmp3.data.image4, 'image4')
            }
            if(tmp3.data.blood1 !== undefined && tmp3.data.blood1 !== '') {
              tmpb = ass.data.blood1+' / '
              tmpbv[0] = ass.data.blood1
            } else {
              tmpb = '- / '
            }
            if(tmp3.data.blood2 !== undefined && tmp3.data.blood2 !== '') {
              tmpb += tmp3.data.blood2
              tmpbv[1] = tmp3.data.blood2
            } else {
              tmpb += '-'
            }
            if(tmp3.data.custom !== undefined) {
              tmpc = tmp3.data.custom
            }

            var heart = 0
            if(tmp3.data.heart !== undefined) {
              heart = tmp3.data.heart
            }
            var vtmp = [HelperPro.showWeight(tmp3.data.weight), tmp3.data.fat, tmp3.data.notes, this.setInitValue(tmp3.data.neck), this.setInitValue(tmp3.data.chest), this.setInitValue(tmp3.data.abdomen), this.setInitValue(tmp3.data.hip), this.setInitValue(tmp3.data.armR), this.setInitValue(tmp3.data.armL), this.setInitValue(tmp3.data.thighR), this.setInitValue(tmp3.data.thighL), heart]
            for(var i=0; i<vtmp.length; i++) {
              if(vtmp[i] === 0 && i !== 2) {
                vtmp[i] = ''
              }
            }

            if(client.data.nutritionId !== undefined) {
              nutrition = client.data.nutritionId
            }

            date = Moment(tmp3.data.date, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')

            this.setState({
              id: client.id,
              aid: aid,
              item: tmp3,
              name: client.data.name,
              values: vtmp,
              back: '/clients/'+client.id+'/health',
              nutrition: nutrition,
              list: tmps,
              item1: tmp3,
              item2: tmps[0],
              blood: tmpb,
              custom: tmpc,
              valuesBlood: tmpbv,
              valueCustom: tmpc,
              date: date,
            });
          }
          
        }
      }
    }
  }


  setInitValue(val) {
    var label = val
    if(global.spaceLbs && val !== '0' && val !== 0) {
      label = (val/2.54).toFixed(1)
    }
    return label
  }
  

  getImage(image, variable) {
    Firebase.storage().ref().child(image).getDownloadURL().then((url) => {
      this.setState({[variable]: url})
    }).catch((error) => {
      // Handle any errors
    })
  }



  // Client update stuff ------------------------------------------------------------



  onChange = event => {
    var tmp = this.state.values
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({values: tmp});
  };


  onChangeBlood = event => {
    var tmp = this.state.valuesBlood
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({valuesBlood: tmp});
  };


  onChangeCustom = event => {
    this.setState({valueCustom:  event.target.value});
  };


  setImage(file, image) {
    if(image === '1') {
      this.setState({
        file: file,
        fileDisplay:  URL.createObjectURL(file)
      })
    } else if(image === '2') {
      this.setState({
        file2: file,
        fileDisplay2:  URL.createObjectURL(file)
      })
    } else if(image === '3') {
      this.setState({
        file3: file,
        fileDisplay3:  URL.createObjectURL(file)
      })
    } else if(image === '4') {
      this.setState({
        file4: file,
        fileDisplay4:  URL.createObjectURL(file)
      })
    }
  }


  displayImage(file, display) {
    if(file === null) {
      return (
        <div className="inner"></div>
      )
    } else {
      return (
        <div className="inner" style={{backgroundImage: 'url('+display+')'}}> </div>
      )
    }
  }


  createAssessment() {
    var tmp = []
    var num = 0
    for(var item of this.state.values) {
      if(item === '') {
        tmp.push('0')
      } else {
        if(global.spaceLbs && num > 2 && num !== 11) {
          tmp.push((item*2.54).toFixed(1))
        } else {
          tmp.push(item)
        }
      }
      num++
    }

    var weight = tmp[0]
    if(global.spaceLbs) {
      weight = (tmp[0]*global.lbsDown).toFixed(2)
    }

    var date = Moment(this.state.date, 'YYYY-MM-DD').format('DD/MM/YYYY HH:mm')

    // DS CONNTECTION HERE
    tmp[2] = this.state.values[2]
    var obj = Firebase.database().ref('/clients/'+global.uid+'/'+global.currentClient+'/assessments').push()
    obj.set({
      weight: parseFloat(weight),
      fat: parseFloat(tmp[1]),
      notes: tmp[2],
      neck: parseFloat(tmp[3]),
      chest: parseFloat(tmp[4]),
      abdomen: parseFloat(tmp[5]),
      hip: parseFloat(tmp[6]),
      armR: parseFloat(tmp[7]),
      armL: parseFloat(tmp[8]),
      thighR: parseFloat(tmp[9]),
      thighL: parseFloat(tmp[10]),
      date: date,
      image: '',
      uid: global.uid,
      heart: parseFloat(tmp[11]),
      nutrition: '',
      blood1: this.state.valuesBlood[0],
      blood2: this.state.valuesBlood[1],
      custom: this.state.valueCustom,
    }).then(() => {
      global.message = 'Health log entry successfully created'
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    if(this.state.file !== null) {
      this.uploadImage(this.state.file, obj.key, 'image', obj.key)
    }
    if(this.state.file2 !== null) {
      this.uploadImage(this.state.file2, obj.key+'-2', 'image2', obj.key)
    }
    if(this.state.file3 !== null) {
      this.uploadImage(this.state.file3, obj.key+'-3', 'image3', obj.key)
    }
    if(this.state.file4 !== null) {
      this.uploadImage(this.state.file4, obj.key+'-4', 'image4', obj.key)
    }
    if(this.state.item.data.nutritionId === undefined || this.state.item.data.nutritionId === "" || tmp[0] === '0') {
      global.message = 'Health log entry successfully created'
      this.props.history.push(this.state.back)
    } else {
      this.createNutrition(obj.key, parseFloat(weight))
      this.createUserWeight(parseFloat(HelperPro.calculateWeight(tmp[0])))
    }
  }


  uploadImage(file, name, img, key) {
    const sr = Firebase.storage().ref()
      const fr = sr.child('images/assessments/'+global.uid+'/'+name+'.jpg')
      Resizer.imageFileResizer(
        file,
        800,
        800,
        "JPEG",
        60,
        0,
        (uri) => {
          var ar = uri.split(',')
          fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
            Firebase.database().ref('/clients/'+global.uid+'/'+global.currentClient+'/assessments/'+key).update({
              [img]: 'images/assessments/'+global.uid+'/'+name+'.jpg',
            })
          }).catch((error)=>{
            EventEmitter.dispatch('showMessageError', error.message);
          })
        },
        "base64",
        200,
        200
      );
  }


  createNutrition(key, weight) {

    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation createWeight($date: date!, $time: timestamp!, $weight: float8, $id: Int) {
        insert_weights_one(object: {created_at: $time, date_recorded: $date, updated_at: $time, u_weight: $weight, user_id: $id, weight: $weight}) {
          id
        }
      }
    `;

    client.mutate({
      variables: { id: parseInt(this.state.item.data.nutritionId), date: Moment().format('YYYY-MM-DD'), time: Moment().format('YYYY-MM-DDTHH:mm:ss'), weight: weight },
      mutation
    }).then((result) => {
      Firebase.database().ref('/clients/'+global.uid+'/'+global.currentClient+'/assessments/'+key).update({
        nutrition: String(result.data.insert_weights_one.id)
      })
      this.props.history.push(this.state.back)
    }).catch((error) => {
      console.log(error);
    });
  }


  createUserWeight(weight) {
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation updateUserWeight($id: Int!, $weight: float8) {
        update_users_by_pk(pk_columns: {id: $id},
          _set: {
              current_weight: $weight,
              u_current_weight: $weight,
          })
        {
          id
          current_weight
        }
      }
    `;

    client.mutate({
      variables: { id: parseInt(this.state.item.data.nutritionId), weight: weight },
      mutation
    }).then((result) => {
      //console.log(result)
      console.log('done')
    }).catch((error) => {
      console.log(error);
    });
  }



  // Update health log ------------------------------------------------------------

  

  updateAssessment() {
    var tmp = []
    var num = 0
    for(var item of this.state.values) {
      if(item === '') {
        tmp.push('0')
      } else {
        if(global.spaceLbs && num > 2 && num !== 11) {
          tmp.push((item*2.54).toFixed(1))
        } else {
          tmp.push(item)
        }
      }
      num++
    }

    var weight = tmp[0]
    if(global.spaceLbs) {
      weight = (tmp[0]*global.lbsDown).toFixed(2)
    }

    var date = Moment(this.state.date, 'YYYY-MM-DD').format('DD/MM/YYYY HH:mm')

    // DS CONNTECTION HERE
    tmp[2] = this.state.values[2]
    if(this.state.id !== '' && this.state.aid !== '') {
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id+'/assessments/'+this.state.aid).update({
        weight: parseFloat(weight),
        fat: parseFloat(tmp[1]),
        notes: tmp[2],
        neck: parseFloat(tmp[3]),
        chest: parseFloat(tmp[4]),
        abdomen: parseFloat(tmp[5]),
        hip: parseFloat(tmp[6]),
        armR: parseFloat(tmp[7]),
        armL: parseFloat(tmp[8]),
        thighR: parseFloat(tmp[9]),
        thighL: parseFloat(tmp[10]),
        heart: parseFloat(tmp[11]),
        blood1: this.state.valuesBlood[0],
        blood2: this.state.valuesBlood[1],
        custom: this.state.valueCustom,
        date: date,
      }).then(() => {
        global.message = 'Health log entry successfully updated'
        this.props.history.push(this.state.back)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
      if(this.state.file !== null) {
        this.uploadImageUpdate(this.state.file, this.state.item.id, 'image', this.state.item.id)
      }
      if(this.state.file2 !== null) {
        this.uploadImageUpdate(this.state.file2, this.state.item.id+'-2', 'image2', this.state.item.id)
      }
      if(this.state.file3 !== null) {
        this.uploadImageUpdate(this.state.file3, this.state.item.id+'-3', 'image3', this.state.item.id)
      }
      if(this.state.file4 !== null) {
        this.uploadImageUpdate(this.state.file4, this.state.item.id+'-4', 'image4', this.state.item.id)
      }
      if(this.state.item.data.nutrition !== undefined && this.state.item.data.nutrition !== "" && tmp[0] !== '0') {
        this.updateNutrition(parseFloat(weight))
      }
    }
  }


  uploadImageUpdate(file, name, img, key) {
    const sr = Firebase.storage().ref()
      const fr = sr.child('images/assessments/'+global.uid+'/'+name+'.jpg')
      Resizer.imageFileResizer(
        file,
        800,
        800,
        "JPEG",
        60,
        0,
        (uri) => {
          var ar = uri.split(',')
          fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
            Firebase.database().ref('/clients/'+global.uid+'/'+global.currentClient+'/assessments/'+key).update({
              [img]: 'images/assessments/'+global.uid+'/'+name+'.jpg',
            })
          }).catch((error)=>{
            EventEmitter.dispatch('showMessageError', error.message);
          })
        },
        "base64",
        200,
        200
      );
  }


  updateNutrition(weight) {
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation updateWeight($id: Int!, $user: Int!, $time: timestamp!, $weight: float8) {
        update_weights_by_pk(pk_columns: {id: $id},
          _set: {
            updated_at: $time,
            user_id: $user,
            weight: $weight,
            u_weight: $weight,
          })
          {
            id
          }
      }
    `;

    client.mutate({
      variables: { id: parseInt(this.state.item.data.nutrition), user: parseInt(this.state.nutrition), time: Moment().format('YYYY-MM-DDTHH:mm:ss'), weight: weight },
      mutation
    }).then((result) => {
      //console.log(result)
      console.log('done')
    }).catch((error) => {
      console.log(error);
    });
  }



  // Display stuff ------------------------------------------------------------



  renderButton() {
    if(this.state.editing) {
      return (
        <div>
          <button className="btn primary edit mb-20" onClick={() => this.updateAssessment()}>Update log entry</button>
          <Link to={this.state.back} className="btn tertiary close width-12">Cancel</Link>
        </div>
      )
    } else {
      return <button className="btn primary edit" onClick={() => this.createAssessment()}>Create log entry</button>
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-sidebar">
          <div className="col-6 mt-20">
            <h3 className="mb-20">Images</h3>
            <div className="col-6 clear">
              <div className="image healthlog edit mb-10">
                {this.displayImage(this.state.file, this.state.fileDisplay)}
              </div>
              <div>
                <label>Front</label>
                <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], '1');}}/>
              </div>
            </div>
            <div className="col-6 mb-20">
              <div className="image healthlog edit mb-10">
                {this.displayImage(this.state.file2, this.state.fileDisplay2)}
              </div>
              <div>
                <label>Back</label>
                <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], '2');}}/>
              </div>
            </div>
            <div className="col-6 clear mb-20">
              <div className="image healthlog edit mb-10">
                  {this.displayImage(this.state.file3, this.state.fileDisplay3)}
                </div>
                <div>
                  <label>Left</label>
                  <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], '3');}}/>
                </div>
              </div>
              <div className="col-6 mb-20">
                <div className="image healthlog edit mb-10">
                  {this.displayImage(this.state.file4, this.state.fileDisplay4)}
                </div>
                <div>
                  <label>Right</label>
                  <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], '4');}}/>
                </div>
              </div>

              <div className="clear sv-40"></div>
              <div className="col-12">
                <InputDate label={'Date'} value={Moment(this.state.date, 'YYYY-MM-DD').format('YYYY-MM-DD')} format='YYYY-MM-DD' max={Moment().format('YYYY-MM-DD')} onChange={(event) => this.setState({date: event})}/>
              </div>
            </div>

            <div className="col-6 mt-20 pl-30">
              <h3 className="mb-20">Overview</h3>
              <label className="clear">Weight ({global.spaceLbs ? 'lb' : 'kg'})</label>
              <input type="number" placeholder={'Weight in '+(global.spaceLbs ? 'lb' : 'kg')} value={this.state.values[0]} name="0" onChange={this.onChange}/>
              <div className="sv-20"></div>
              <label>Body fat (%)</label>
              <input type="number" value={this.state.values[1]} name="1" onChange={this.onChange}/>
              <div className="sv-20"></div>
              <label>Rest heart rate (bpm)</label>
              <input type="number" value={this.state.values[11]} name="11" onChange={this.onChange}/>
              <div className="sv-20"></div>
              <label>Blood pressure</label>
              <div style={{width: '50%', float: 'left', padding: '0 5px 0 0'}}>
                <input type="number" placeholder="Systolic" value={this.state.valuesBlood[0]} name="0" onChange={this.onChangeBlood}/>
              </div>
              <div style={{width: '50%', float: 'left', padding: '0 0 0 5px'}}>
                <input type="number" placeholder="Diastolic" value={this.state.valuesBlood[1]} name="1" onChange={this.onChangeBlood}/>
              </div>
              <div className="clear sv-20"></div>
              <label>Custom value</label>
              <input type="number" placeholder="Enter any additional value to track" value={this.state.valueCustom} onChange={this.onChangeCustom}/>
              <div className="sv-20"></div>
              <label>Notes</label>
              <textarea value={this.state.values[2]} name="2" onChange={this.onChange}></textarea>

              <div className="healthlog segmental mt-30">
                <h3 className="mb-20">Segmental Circumference</h3>
                <div className="clear info">
                  <div className="circle lft mr-10">1</div>
                  <div className="main lft">
                    <label>Neck ({global.spaceLbs ? 'in' : 'cm'})</label>
                    <input type="number" value={this.state.values[3]} name="3" onChange={this.onChange}/>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="clear info">
                  <div className="circle lft mr-10">2</div>
                  <div className="main lft">
                    <label>Chest ({global.spaceLbs ? 'in' : 'cm'})</label>
                    <input type="number" value={this.state.values[4]} name="4" onChange={this.onChange}/>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="clear info">
                  <div className="circle lft mr-10">3</div>
                  <div className="main lft">
                    <label>Abdomen ({global.spaceLbs ? 'in' : 'cm'})</label>
                    <input type="number" value={this.state.values[5]} name="5" onChange={this.onChange}/>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="clear info">
                  <div className="circle lft mr-10">4</div>
                  <div className="main lft">
                    <label>Hip ({global.spaceLbs ? 'in' : 'cm'})</label>
                    <input type="number" value={this.state.values[6]} name="6" onChange={this.onChange}/>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="clear info">
                  <div className="circle lft mr-10">5</div>
                  <div className="main lft">
                    <label>Right arm ({global.spaceLbs ? 'in' : 'cm'})</label>
                    <input type="number" value={this.state.values[7]} name="7" onChange={this.onChange}/>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="clear info">
                  <div className="circle lft mr-10">6</div>
                  <div className="main lft">
                    <label>Left arm ({global.spaceLbs ? 'in' : 'cm'})</label>
                    <input type="number" value={this.state.values[8]} name="8" onChange={this.onChange}/>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="clear info">
                  <div className="circle lft mr-10">7</div>
                  <div className="main lft">
                    <label>Right thigh ({global.spaceLbs ? 'in' : 'cm'})</label>
                    <input type="number" value={this.state.values[9]} name="9" onChange={this.onChange}/>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="clear info">
                  <div className="circle lft mr-10">8</div>
                  <div className="main lft">
                    <label>Left thigh ({global.spaceLbs ? 'in' : 'cm'})</label>
                    <input type="number" value={this.state.values[10]} name="10" onChange={this.onChange}/>
                  </div>
                  <div className="clear"></div>
                </div>
              </div>
            </div>
            <div className="clear sv-40"></div>
            <div className="width-6">
              {this.renderButton()}
            </div>
            <div className="clear sv-40"></div>
          </div>
        <Header title={this.state.editing ? 'Edit Log Entry' : 'New Log Entry'} link={this.state.back}/>
        <Navigation active='clients' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withRouter(withAuthorization(condition)(ClientsNewAsessmentPage));